import {useEffect, useState} from "react";
import "../css/SwitchSection.css";
import global from "../../../../../global";
import ShadowUtils from "../../../../../utils/ShadowUtils";
import {PanelCheckbox} from "../common/PanelCheckbox";
import Application from "../../../../../Application";
import MaterialUtils from "../../../../../utils/MaterialUtils";

interface Props {
    isLocked?: boolean;
}

export const ModelLigthningSection = ({isLocked}: Props) => {
    const app = global.app as Application;
    const editor = app.editor;

    const [isLoaded, setIsLoaded] = useState(false);
    const [castShadowEnabled, setCastShadowEnabled] = useState(false);
    const [reflection, setReflection] = useState(false);

    useEffect(() => {
        const selected = editor?.selected;
        if (castShadowEnabled !== undefined && selected && !(selected instanceof Array) && isLoaded) {
            ShadowUtils.applyReceiveShadow(selected, castShadowEnabled, true);
            ShadowUtils.applyCastShadow(selected, castShadowEnabled, true);

            app.call(`objectChanged`, selected, selected);
        }
    }, [castShadowEnabled, isLoaded]);

    const handleReflectionToggle = (reflectionEnabled: boolean) => {
        const selected = editor?.selected;

        if (selected && !(selected instanceof Array)) {
            MaterialUtils.toggleReflection(selected, !!reflectionEnabled);
            selected.userData.reflection = reflectionEnabled;
            setReflection(reflectionEnabled);
            app.call(`objectChanged`, selected, selected);
        }
    };

    const handleUpdate = () => {
        const selected = editor?.selected;

        if (selected && !(selected instanceof Array)) {
            setIsLoaded(true);
            setReflection(!!selected.userData?.reflection);
            setCastShadowEnabled(!!selected?.castShadow);
        }
    };

    useEffect(() => {
        if (!editor || !editor.selected) return;

        handleUpdate();
        app.on("objectSelected.ModelLigthningSection", handleUpdate);
        return () => {
            app.on("objectSelected.ModelLigthningSection", null);
        };
    }, []);

    return (
        <div className="SwitchSection">
            <PanelCheckbox
                v2
                isGray
                regular
                text="Reflect Light"
                checked={!!reflection}
                onChange={e => {
                    handleReflectionToggle(!!e.target.checked);
                }}
                isLocked={isLocked}
            />
            <PanelCheckbox
                v2
                isGray
                regular
                text="Shadows"
                checked={!!castShadowEnabled}
                onChange={e => {
                    setCastShadowEnabled(!!e.target.checked);
                }}
                isLocked={isLocked}
            />
        </div>
    );
};
