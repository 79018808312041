import styled from "styled-components";
import {regularFont} from "../../../../assets/style";

export const Title = styled.div`
    ${regularFont("s")}
    font-weight: var(--theme-font-bold);
`;

export const NoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--theme-font-regular);
    font-size: var(--theme-font-size-s);
    color: white;
    font-family: "Inter";
    height: calc(100% - 64px - 64px);
`;
