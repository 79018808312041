import {useEffect, useState} from "react";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {SelectRow} from "../common/SelectRow";
import global from "../../../../../global";
import {Item} from "../../common/BasicCombobox";
import {NumericInputRow} from "../common/NumericInputRow";
import {Separator} from "../common/Separator";

interface Props {
    isLocked: boolean;
}

export const AnimationSection = ({isLocked}: Props) => {
    const [propAnimationEnabled, setPropAnimationEnabled] = useState<boolean>(false);
    const [animationClips, setAnimationClips] = useState<Item[]>([{key: "0", value: "none"}]);
    const [selectedAnimation, setSelectedAnimation] = useState<Item>(animationClips[0]);
    const [animationSpeed, setAnimationSpeed] = useState<number>(1.0);
    const app = global.app;
    const editor = app?.editor;

    const handleAnimationSpeedChange = (value: number) => {
        setAnimationSpeed(value);
        if (app && editor?.selected && !(editor.selected instanceof Array)) {
            editor.selected.userData.animationSpeed = value;
            app.call(`objectChanged`, app.editor, editor.selected);
        }
    };

    const handlePropAnimationChange = (value: boolean) => {
        if (app && editor?.selected && !(editor.selected instanceof Array)) {
            setPropAnimationEnabled(value);
            const selected = editor.selected;
            if (value) {
                selected.userData.objectType = "gameProp";
            } else {
                delete selected.userData.objectType;
            }
            app.call(`objectChanged`, app.editor, editor.selected);
        }
    };

    useEffect(() => {
        const handleObjectSelected = () => {
            if (editor?.selected && !(editor.selected instanceof Array)) {
                const object = editor.selected;
                const animations = object._obj ? object._obj.animations : [];
                const animationNames = animations.map((clip: any) => clip.name);

                const animationClips: Item[] = [
                    {key: "0", value: "none"},
                    ...animationNames.map((name: string, index: number) => {
                        return {
                            key: `${index + 1}`,
                            value: name,
                        };
                    }),
                ];
                setAnimationClips(animationClips);

                // setIsGamePropEnabled(object.userData.objectType === "gameProp");
                const selectedAnimationClip = animationClips.find(
                    clip => clip.value === object.userData.selectedAnimation,
                );
                setSelectedAnimation(selectedAnimationClip || animationClips[0]);
                setAnimationSpeed(object.userData.animationSpeed || 1.0);
                setPropAnimationEnabled(object.userData.objectType === "gameProp");
            } else {
                setAnimationClips([{key: `0`, value: "none"}]);
                setSelectedAnimation(animationClips[0]);
                // setIsGamePropEnabled(false);
                setAnimationSpeed(1.0);
            }
        };
        handleObjectSelected();
        app?.on("objectSelected.ObjectPropertiesTab", handleObjectSelected);

        return () => {
            app?.on("objectSelected.ObjectPropertiesTab", null);
        };
    }, []);

    const handleAnimationClipChange = (item: Item) => {
        setSelectedAnimation(item);
        if (editor?.selected && !(editor.selected instanceof Array)) {
            const selected = editor.selected;
            selected.userData.selectedAnimation = item.value;
            if (item.value !== "none") {
                if (propAnimationEnabled) {
                    selected.userData.objectType = "gameProp";
                } else {
                    delete selected.userData.objectType;
                }
            } else {
                delete selected.userData.objectType;
            }
            app?.call(`objectChanged`, app?.editor, selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                text="Prop Animation"
                checked={propAnimationEnabled}
                onChange={e => {
                    handlePropAnimationChange(!!e.target.checked);
                }}
                isLocked={isLocked}
            />
            <Separator invisible />
            {propAnimationEnabled && (
                <>
                    <SelectRow
                        label="Animation for Prop"
                        data={animationClips}
                        value={selectedAnimation || animationClips[0]}
                        onChange={item => handleAnimationClipChange(item)}
                    />
                    <Separator invisible />
                </>
            )}
            {/* <PanelCheckbox
                text="Enable Game Prop"
                checked={isGamePropEnabled}
                onChange={toggleGameProp}
            /> */}
            <Separator invisible />
            <NumericInputRow
                label="Animation Speed"
                value={animationSpeed}
                setValue={handleAnimationSpeedChange}
                min={0}
            />
        </>
    );
};
