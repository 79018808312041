import {ImageSectionWrapper} from "../../common/ImageSection";
import {
    CloseIconWrapper,
    StyledUploadButton,
} from "../../../common/UploadField/UploadField.style";
import closeIcon from "../../../icons/trash.svg";

interface Props {
    label: string;
    image: string;
    setImage: React.Dispatch<React.SetStateAction<string>>;
    handleImageChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
    ) => void;
    imageKeyName: string;
    inputId: string;
}

export const WeaponImgSection = ({
    label,
    image: gunHudImage,
    setImage,
    handleImageChange,
    imageKeyName,
    inputId,
}: Props) => {
    return (
        <ImageSectionWrapper>
            <span className="text">{label}</span>
            <StyledUploadButton
                width="80px"
                height="80px"
                $bgImage={gunHudImage}
                onClick={() => document.getElementById(inputId)?.click()}>
                {(!gunHudImage || gunHudImage === "") && "+"}
                {gunHudImage && (
                    <CloseIconWrapper
                        className="closeIconWrapper"
                        onClick={e => {
                            e.stopPropagation();
                            setImage("");
                        }}>
                        <img src={closeIcon} alt="remove file" />
                    </CloseIconWrapper>
                )}
                <input
                    id={inputId}
                    type="file"
                    accept="image/*"
                    style={{display: "none"}}
                    onChange={e => handleImageChange(e, imageKeyName)}
                />
            </StyledUploadButton>
        </ImageSectionWrapper>
    );
};
