import React, {useState, createContext, ReactNode} from "react";

interface Animation {
    uuid: string;
    [key: string]: any; // Placeholder for other properties
}

interface State {
    mainModel: any;
    animations: Animation[];
    mixer: any;
    loading: boolean;
}

const initialState: State = {
    mainModel: null,
    animations: [],
    mixer: null,
    loading: false,
};

interface ContextProps extends State {
    addMainModel: (object: any) => void;
    addAnimationFromMainModel: (animations: Animation[]) => void;
    addAnimations: (animations: Animation[]) => void;
    changeName: (animation: Animation) => void;
    addMixer: (mixer: any) => void;
    deleteAnimation: (animationId: string) => void;
    toggleLoading: () => void;
    clearState: () => void;
    setAction: (action: any) => void;
    action: any;
}

export const ModelAnimationCombinerContext = createContext<ContextProps>(initialState as ContextProps);

export const ModelAnimationCombinerContextProvider = ({children}: {children: ReactNode}) => {
    const [state, setState] = useState<State>(initialState);
    const [action, setAction] = useState<any>(null);

    const addMainModel = (object: any) => {
        setState(prevState => ({...prevState, mainModel: object}));
    };

    const addAnimationFromMainModel = (animations: Animation[]) => {
        setState(prevState => ({...prevState, animations: [...animations]}));
    };

    const addAnimations = (animations: Animation[]) => {
        setState(prevState => ({
            ...prevState,
            animations: [...prevState.animations, ...animations],
        }));
    };

    const changeName = (animation: Animation) => {
        setState(prevState => ({
            ...prevState,
            animations: prevState.animations.map(anim => (anim.uuid === animation.uuid ? animation : anim)),
        }));
    };

    const addMixer = (mixer: any) => {
        setState(prevState => ({...prevState, mixer}));
    };

    const deleteAnimation = (animationId: string) => {
        setState(prevState => ({
            ...prevState,
            animations: prevState.animations.filter(animation => animation.uuid !== animationId),
        }));
    };

    const toggleLoading = () => {
        setState(prevState => ({...prevState, loading: !prevState.loading}));
    };

    const clearState = () => {
        setState(initialState);
    };

    return (
        <ModelAnimationCombinerContext.Provider
            value={{
                ...state,
                addMainModel,
                addAnimationFromMainModel,
                addAnimations,
                changeName,
                addMixer,
                deleteAnimation,
                toggleLoading,
                clearState,
                setAction,
                action,
            }}>
            {children}
        </ModelAnimationCombinerContext.Provider>
    );
};

export default ModelAnimationCombinerContextProvider;
