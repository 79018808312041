import styled from "styled-components";
import {flexCenter} from "../../../../assets/style";

export const Container = styled.form<{$isOpen?: boolean}>`
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
    width: 408px;
    min-height: 145px;
    background: var(--theme-grey-bg-tertiary);
    bottom: 72px;
    left: 50%;
    transform: translateX(-50%) scale(0);
    pointer-events: none;
    transition: transform 0.1s ease;
    position: fixed;
    ${({$isOpen}) =>
        $isOpen &&
        `
      transform: translateX(-50%) scale(1);
      pointer-events: all;
    `};

    .loaderWrapper {
        margin: 4px auto 0;
        height: 58px;
        ${flexCenter};
    }
`;

export const Title = styled.div`
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-bold);
    line-height: 16px;
    color: white;
`;

export const StyledTextArea = styled.textarea`
    margin-top: 4px;
    background: var(--theme-grey-bg);
    border: none;
    outline: none;
    resize: none;
    border-radius: 8px;
    padding: 3px 5px;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: #aeaeae;
    height: 58px;
    width: 100%;
`;

export const PromptWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 9px;
    margin-top: 9px;
`;

export const PromptItem = styled.div<{$isSelected?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #aeaeae;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    width: 100%;
    border: 1px solid var(--theme-grey-bg);
    transition: all 0.3s ease;
    border-radius: 8px;
    height: 22px;
    width: 100%;
    &:hover {
        border: 1px solid white;
        color: white;
    }

    ${({$isSelected}) =>
        $isSelected &&
        `
    border: 1px solid white;
    color: white;
    `}
`;

export const BottomBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
`;

export const SubmitButton = styled.button<{disabled?: boolean}>`
    border: none;
    width: 32px;
    height: 32px;
    background: var(--theme-container-main-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    border-top: 1px solid #0ea5e9;
    outline: none;
    ${({disabled}) =>
        disabled &&
        `
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
