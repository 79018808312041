import {useEffect, useState} from "react";
import {OBJECT_INTERACTION_OPTIONS, ObjectInteractionBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import ObjectInteractionBehaviorConverter from "../../../../../serialization/behaviours/ObjectInteractionBehaviorConverter";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {StyledButton} from "../../common/StyledButton";
import {Separator} from "../common/Separator";
import {Instruction} from "../RightPanel.style";

type Props = {
    behavior: ObjectInteractionBehaviorInterface;
};

export const ObjectInteractionBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);

    const targetBehavior = ObjectInteractionBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    useEffect(() => {
        if (!behavior.interaction) {
            setBehavior(prev => ({...prev, interaction: OBJECT_INTERACTION_OPTIONS.PICKUP_DROP}));
        }
    }, []);

    const handleInputChange = (value: any, name: keyof ObjectInteractionBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior({...targetBehavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };
    return (
        <>
            <SelectionOfButtons>
                <StyledButton
                    width="109px"
                    isBlue={behavior.interaction === OBJECT_INTERACTION_OPTIONS.PICKUP_DROP}
                    isActive={behavior.interaction !== OBJECT_INTERACTION_OPTIONS.PICKUP_DROP}
                    onClick={() => handleInputChange(OBJECT_INTERACTION_OPTIONS.PICKUP_DROP, "interaction")}>
                    <span>Pickup and Drop</span>
                </StyledButton>
                <StyledButton
                    width="109px"
                    isBlue={behavior.interaction === OBJECT_INTERACTION_OPTIONS.PUSH_PULL}
                    isActive={behavior.interaction !== OBJECT_INTERACTION_OPTIONS.PUSH_PULL}
                    onClick={() => handleInputChange(OBJECT_INTERACTION_OPTIONS.PUSH_PULL, "interaction")}>
                    <span>Push and Pull</span>
                </StyledButton>
            </SelectionOfButtons>
            <Separator />
            <Instruction>
                <div className="text">E key: to active</div>
                <div className="text">F key: to de-active</div>
            </Instruction>
        </>
    );
};
