import React, {useEffect, useState} from "react";
import global from "../global";

interface AppGlobalContextValue {
    projectPhase: number;
    setProjectPhase: React.Dispatch<React.SetStateAction<number>>;
    setIsSpawnPointPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsCheckPointPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isSpawnPointPanelOpen: boolean;
    isCheckPointPanelOpen: boolean;
    closeCheckSpawnPointsPanels: () => void;
    behaviorCount: number;
    setBehaviorCount: React.Dispatch<React.SetStateAction<number>>;
    isVolumePanelOpen: boolean;
    setIsVolumePanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isGameSettingsPanelOpen: boolean;
    setIsGameSettingsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isBillboardPanelOpen: boolean;
    setIsBillboardPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppGlobalContext = React.createContext<AppGlobalContextValue>(null!);

export interface AppGlobalContextProviderProps {
    children: React.ReactNode;
}

const AppGlobalContextProvider: React.FC<AppGlobalContextProviderProps> = ({children}) => {
    const app = (global as any).app as any;
    const [projectPhase, setProjectPhase] = useState(1);
    const [isSpawnPointPanelOpen, setIsSpawnPointPanelOpen] = useState(false);
    const [isCheckPointPanelOpen, setIsCheckPointPanelOpen] = useState(false);
    const [isGameSettingsPanelOpen, setIsGameSettingsPanelOpen] = useState(false);
    const [isVolumePanelOpen, setIsVolumePanelOpen] = useState(false);
    const [isBillboardPanelOpen, setIsBillboardPanelOpen] = useState(false);
    const [behaviorCount, setBehaviorCount] = useState(0);

    const closeCheckSpawnPointsPanels = () => {
        setIsCheckPointPanelOpen(false);
        setIsSpawnPointPanelOpen(false);
    };

    return (
        <AppGlobalContext.Provider
            value={{
                projectPhase,
                setProjectPhase,
                isCheckPointPanelOpen,
                isSpawnPointPanelOpen,
                setIsCheckPointPanelOpen,
                setIsSpawnPointPanelOpen,
                isGameSettingsPanelOpen,
                setIsGameSettingsPanelOpen,
                closeCheckSpawnPointsPanels,
                setIsVolumePanelOpen,
                isVolumePanelOpen,
                behaviorCount,
                setBehaviorCount,
                isBillboardPanelOpen,
                setIsBillboardPanelOpen,
            }}>
            {children}
        </AppGlobalContext.Provider>
    );
};

export default AppGlobalContextProvider;
