import styled from "styled-components";

export const StyledSection = styled.section`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
    width: calc(100% - 20px);
    height: 100%;
`;

export const MainGame = styled.section`
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    width: 100%;
    height: 100%;

    .heroGameWrapper {
        width: 100%;
        height: 100%;
        .game {
            height: 100%;
        }
    }
`;
