import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import ScriptBehaviorUpdater from "./ScriptBehaviorUpdater";
import {ENEMY_TYPES, ScriptBehaviorInterface, OBJECT_TYPES} from "../../types/editor";

class ScriptBehaviorConverter extends BaseBehaviorConverter<ScriptBehaviorInterface> {
    public static DEFAULT = new ScriptBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: ScriptBehaviorInterface): BehaviorUpdater {
        if (behavior && behavior.enabled) {
            const filteredScripts = this.obj.scripts.filter((s: any) => s.uuid !== behavior.id);

            this.obj.scripts = [
                ...filteredScripts,
                {
                    uuid: behavior.id,
                    name: behavior.name,
                    enabled: behavior.enabled,
                    type: behavior.scriptType,
                    source: behavior.source,
                    isBehaviorScript: true,
                    id: null,
                },
            ];
        }
        return new ScriptBehaviorUpdater(target);
    }

    clearBehaviorScripts(): void {
        this.obj.scripts = this.obj.scripts.filter((script: any) => !script.isBehaviorScript);
    }

    reset(): void {}

    getBehavior(target: THREE.Object3D, id: string): ScriptBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as ScriptBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have SCRIPT behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): ScriptBehaviorInterface {
        return {
            type: OBJECT_TYPES.SCRIPT,
            name: "Script.js",
            enabled: true, // Says whether the script should be interpreted
            scriptType: "javascript", // example: 'javascript'
            source: "", // code of script
            id, // unique id for the script
        };
    }
}

export default ScriptBehaviorConverter;
