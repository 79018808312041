import React, {useEffect, useState} from "react";
import {NumericInput} from "../../common/NumericInput";
import "../css/Section.css";
import "../css/AnimationSection.css";
import {StyledSelect} from "../../common/StyledSelect";
import {StyledSwitch} from "../../common/StyledSwitch";
import classNames from "classnames";
import {useAnimationContext} from "../../../../../context";
import {PanelSectionTitleSecondary} from "../RightPanel.style";

enum Ease {
    linear = "Linear",
    quadIn = "Quad In",
    quadOut = "Quad Out",
    quadInOut = "Quad In Out",
    cubicIn = "Cubic In",
    cubicOut = "Cubic Out",
    cubicInOut = "Cubic InOut",
    quartIn = "Quart In",
    quartOut = "Quart Out",
    quartInOut = "Quart InOut",
    quintIn = "Quint In",
    quintOut = "Quint Out",
    quintInOut = "Quint In Out",
    sineIn = "Sine In",
    sineOut = "Sine Out",
    sineInOut = "Sine In Out",
    backIn = "Back In",
    backOut = "Back Out",
    backInOut = "Back In Out",
    circIn = "Circ In",
    circOut = "Circ Out",
    circInOut = "Circ In Out",
    bounceIn = "Bounce In",
    bounceOut = "Bounce Out",
    bounceInOut = "Bounce In Out",
    elasticIn = "Elastic In",
    elasticOut = "Elastic Out",
    elasticInOut = "Elastic In Out",
}

enum BeginStatus {
    Current = "Current Status",
    Custom = "Custom Status",
}

const getEeseKey = (value: any) => {
    return Object.keys(Ease)[Object.values(Ease).indexOf(value)];
};

export const TweenAnimationSection = () => {
    const {animationsState, setAnimationsState} = useAnimationContext();
    //const app = (global as any).app;
    const [state, setState] = useState({
        show: false,
        expanded: true,

        beginStatus: BeginStatus.Custom,
        showBeginState: false,
        beginPositionX: 0,
        beginPositionY: 0,
        beginPositionZ: 0,
        beginRotationX: 0,
        beginRotationY: 0,
        beginRotationZ: 0,
        beginScaleLock: true,
        beginScaleX: 1,
        beginScaleY: 1,
        beginScaleZ: 1,

        ease: getEeseKey(Ease.linear),

        endStatus: BeginStatus.Custom,
        showEndState: false,
        endPositionX: 0,
        endPositionY: 0,
        endPositionZ: 0,
        endRotationX: 0,
        endRotationY: 0,
        endRotationZ: 0,
        endScaleLock: true,
        endScaleX: 1,
        endScaleY: 1,
        endScaleZ: 1,
    });

    useEffect(() => {
        handleUpdate();
        /*app.on(`animationSelected.TweenAnimationSection`, handleUpdate);
    app.on(`animationChanged.TweenAnimationSection`, handleUpdate);*/
    }, [animationsState]);

    const handleUpdate = () => {
        const animation = {...animationsState.selectedAnimation};
        if (!animation || animation.type !== "Tween") {
            setState(prevState => ({
                ...prevState,
                show: false,
            }));
            return;
        }

        let data = animation.data;

        let state = {
            show: true,
            beginStatus: data.beginStatus,
            showBeginState: data.beginStatus === BeginStatus.Custom,
            beginPositionX: data.beginPositionX,
            beginPositionY: data.beginPositionY,
            beginPositionZ: data.beginPositionZ,
            beginRotationX: (data.beginRotationX * 180) / Math.PI,
            beginRotationY: (data.beginRotationY * 180) / Math.PI,
            beginRotationZ: (data.beginRotationZ * 180) / Math.PI,
            beginScaleLock: data.beginScaleLock,
            beginScaleX: data.beginScaleX,
            beginScaleY: data.beginScaleY,
            beginScaleZ: data.beginScaleZ,
            ease: data.ease,
            endStatus: data.endStatus,
            showEndState: data.endStatus === BeginStatus.Custom,
            endPositionX: data.endPositionX,
            endPositionY: data.endPositionY,
            endPositionZ: data.endPositionZ,
            endRotationX: (data.endRotationX * 180) / Math.PI,
            endRotationY: (data.endRotationY * 180) / Math.PI,
            endRotationZ: (data.endRotationZ * 180) / Math.PI,
            endScaleLock: data.endScaleLock,
            endScaleX: data.endScaleX,
            endScaleY: data.endScaleY,
            endScaleZ: data.endScaleZ,
        };

        setState(prevState => ({...prevState, ...state}));
    };

    const handleChange = (value: any, name: string) => {
        if (value === null) {
            setState(prevState => ({...prevState, [name]: value}));
            return;
        }

        const {
            beginStatus,
            beginPositionX,
            beginPositionY,
            beginPositionZ,
            beginRotationX,
            beginRotationY,
            beginRotationZ,
            beginScaleLock,
            beginScaleX,
            beginScaleY,
            beginScaleZ,
            ease,
            endStatus,
            endPositionX,
            endPositionY,
            endPositionZ,
            endRotationX,
            endRotationY,
            endRotationZ,
            endScaleLock,
            endScaleX,
            endScaleY,
            endScaleZ,
        } = Object.assign({}, state, {
            [name]: value,
        });

        setAnimationsState((prevState: any) => {
            return {
                ...prevState,
                selectedAnimation: {
                    ...prevState.selectedAnimation,
                    data: {
                        beginStatus,
                        beginPositionX,
                        beginPositionY,
                        beginPositionZ,
                        beginRotationX: (beginRotationX * Math.PI) / 180,
                        beginRotationY: (beginRotationY * Math.PI) / 180,
                        beginRotationZ: (beginRotationZ * Math.PI) / 180,
                        beginScaleLock,
                        beginScaleX,
                        beginScaleY,
                        beginScaleZ,
                        ease,
                        endStatus,
                        endPositionX,
                        endPositionY,
                        endPositionZ,
                        endRotationX: (endRotationX * Math.PI) / 180,
                        endRotationY: (endRotationY * Math.PI) / 180,
                        endRotationZ: (endRotationZ * Math.PI) / 180,
                        endScaleLock,
                        endScaleX,
                        endScaleY,
                        endScaleZ,
                    },
                },
            };
        });
    };

    const {
        show,
        beginStatus,
        showBeginState,
        beginPositionX,
        beginPositionY,
        beginPositionZ,
        beginRotationX,
        beginRotationY,
        beginRotationZ,
        beginScaleLock,
        beginScaleX,
        beginScaleY,
        beginScaleZ,
        ease,
        endStatus,
        showEndState,
        endPositionX,
        endPositionY,
        endPositionZ,
        endRotationX,
        endRotationY,
        endRotationZ,
        endScaleLock,
        endScaleX,
        endScaleY,
        endScaleZ,
    } = state;

    if (!show) {
        return null;
    }

    return (
        <div className={classNames("Section", "AnimationSection")}>
            <div className="title">Tween Animation</div>
            <div className="box extended column">
                <PanelSectionTitleSecondary>
                    Begin Status
                </PanelSectionTitleSecondary>
                <StyledSelect
                    value={beginStatus || ""}
                    items={Object.values(BeginStatus)}
                    onChange={value => handleChange(value, "beginStatus")}
                />
            </div>
            {showBeginState && (
                <>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Position X
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={beginPositionX}
                            setValue={value =>
                                handleChange(value, "beginPositionX")
                            }
                            onBlur={() =>
                                !beginPositionX &&
                                handleChange(0, "beginPositionX")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Position Y
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={beginPositionY}
                            setValue={value =>
                                handleChange(value, "beginPositionY")
                            }
                            onBlur={() =>
                                !beginPositionY &&
                                handleChange(0, "beginPositionY")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Position Z
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={beginPositionZ}
                            setValue={value =>
                                handleChange(value, "beginPositionZ")
                            }
                            onBlur={() =>
                                !beginPositionZ &&
                                handleChange(0, "beginPositionZ")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Rotation X
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={beginRotationX}
                            setValue={value =>
                                handleChange(value, "beginRotationX")
                            }
                            onBlur={() =>
                                !beginRotationX &&
                                handleChange(0, "beginRotationX")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Rotation Y
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={beginRotationY}
                            setValue={value =>
                                handleChange(value, "beginRotationY")
                            }
                            onBlur={() =>
                                !beginRotationY &&
                                handleChange(0, "beginRotationY")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Rotation Z
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={beginRotationZ}
                            setValue={value =>
                                handleChange(value, "beginRotationZ")
                            }
                            onBlur={() =>
                                !beginRotationZ &&
                                handleChange(0, "beginRotationZ")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Scale Lock
                        </PanelSectionTitleSecondary>
                        <StyledSwitch
                            checked={beginScaleLock}
                            onChange={e =>
                                handleChange(
                                    !!e.target.checked,
                                    "beginScaleLock",
                                )
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Scale X
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={beginScaleX}
                            setValue={value =>
                                handleChange(value, "beginScaleX")
                            }
                            onBlur={() =>
                                !beginScaleX && handleChange(0, "beginScaleX")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Scale Y
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={beginScaleY}
                            setValue={value =>
                                handleChange(value, "beginScaleY")
                            }
                            onBlur={() =>
                                !beginScaleY && handleChange(0, "beginScaleY")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            Begin Scale Z
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={beginScaleZ}
                            setValue={value =>
                                handleChange(value, "beginScaleZ")
                            }
                            onBlur={() =>
                                !beginScaleZ && handleChange(0, "beginScaleZ")
                            }
                        />
                    </div>
                </>
            )}

            <div className="box extended column">
                <PanelSectionTitleSecondary>
                    Ease func
                </PanelSectionTitleSecondary>
                <StyledSelect
                    value={
                        Ease[
                            (ease ||
                                Ease.linear) as unknown as keyof typeof Ease
                        ]
                    }
                    items={Object.values(Ease)}
                    onChange={value => handleChange(getEeseKey(value), "ease")}
                />
            </div>

            <div className="box extended column">
                <PanelSectionTitleSecondary>
                    End Status
                </PanelSectionTitleSecondary>
                <StyledSelect
                    value={endStatus || ""}
                    items={Object.values(BeginStatus)}
                    onChange={value => handleChange(value, "endStatus")}
                />
            </div>
            {showEndState && (
                <>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Position X
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={endPositionX}
                            setValue={value =>
                                handleChange(value, "endPositionX")
                            }
                            onBlur={() =>
                                !endPositionX && handleChange(0, "endPositionX")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Position Y
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={endPositionY}
                            setValue={value =>
                                handleChange(value, "endPositionY")
                            }
                            onBlur={() =>
                                !endPositionY && handleChange(0, "endPositionY")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Position Z
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={endPositionZ}
                            setValue={value =>
                                handleChange(value, "endPositionZ")
                            }
                            onBlur={() =>
                                !endPositionZ && handleChange(0, "endPositionZ")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Rotation X
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={endRotationX}
                            setValue={value =>
                                handleChange(value, "endRotationX")
                            }
                            onBlur={() =>
                                !endRotationX && handleChange(0, "endRotationX")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Rotation Y
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={endRotationY}
                            setValue={value =>
                                handleChange(value, "endRotationY")
                            }
                            onBlur={() =>
                                !endRotationY && handleChange(0, "endRotationY")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Rotation Z
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={endRotationZ}
                            setValue={value =>
                                handleChange(value, "endRotationZ")
                            }
                            onBlur={() =>
                                !endRotationZ && handleChange(0, "endRotationZ")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Scale Lock
                        </PanelSectionTitleSecondary>
                        <StyledSwitch
                            checked={endScaleLock}
                            onChange={e =>
                                handleChange(!!e.target.checked, "endScaleLock")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Scale X
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={endScaleX}
                            setValue={value => handleChange(value, "endScaleX")}
                            onBlur={() =>
                                !endScaleX && handleChange(0, "endScaleX")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Scale Y
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={endScaleY}
                            setValue={value => handleChange(value, "endScaleY")}
                            onBlur={() =>
                                !endScaleY && handleChange(0, "endScaleY")
                            }
                        />
                    </div>
                    <div className="box extended">
                        <PanelSectionTitleSecondary>
                            End Scale Z
                        </PanelSectionTitleSecondary>
                        <NumericInput
                            className={"numeric-input"}
                            value={endScaleZ}
                            setValue={value => handleChange(value, "endScaleZ")}
                            onBlur={() =>
                                !endScaleZ && handleChange(0, "endScaleZ")
                            }
                        />
                    </div>
                </>
            )}
        </div>
    );
};
