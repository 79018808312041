import {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {TextInput} from "../../../editor/assets/v2/common/TextInput";
import {Separator} from "../../../editor/assets/v2/RightPanel/common/Separator";
import {StyledButton} from "../../../editor/assets/v2/common/StyledButton";
import {Bottom, LoginBox, Top, Wrapper} from "./LoginPage.style";
import {GoogleButton} from "./GoogleButton";
import {DiscordButton} from "./DiscordButton";
import {useAuthorizationContext} from "../../../context";
import {ROUTES} from "../../../AppRouter";
import {Onboarding} from "./Onboarding";
import {LoadingAnimation} from "../../../ui/progress/LoadingAnimation";

export const LoginPage = ({signup}: {signup?: boolean}) => {
    const {isAuthorized, dbUser} = useAuthorizationContext();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationOptions = {state: {from: signup ? ROUTES.SIGN_UP : ROUTES.LOGIN}};

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [onboarding, setOnboarding] = useState(false);
    const [showLoding, setShowLoding] = useState(false);

    const onCorrectAuth = () => {
        const from = location.state?.from;
        from ? navigate(from, {replace: true}) : navigate(ROUTES.DASHBOARD, {replace: true});
    };

    useEffect(() => {
        setShowLoding(true);
        if (isAuthorized && dbUser) {
            if (dbUser.username) {
                onCorrectAuth();
                setShowLoding(false);
            } else {
                setOnboarding(true);
                setShowLoding(false);
            }
        }
    }, [isAuthorized, dbUser]);

    const topInfo = useMemo(() => {
        if (signup) {
            return (
                <div className="topInfo">
                    Already have an account? Switch to{" "}
                    <button className="link reset-css" onClick={() => navigate(ROUTES.LOGIN, navigationOptions)}>
                        Login
                    </button>
                </div>
            );
        } else {
            return (
                <div className="topInfo">
                    Don’t have an account? Switch to{" "}
                    <button className="link reset-css" onClick={() => navigate(ROUTES.SIGN_UP, navigationOptions)}>
                        Sign up
                    </button>
                </div>
            );
        }
    }, [signup]);

    return (
        <>
            <LoadingAnimation show={showLoding} />
            <Wrapper>
                {onboarding ? (
                    <Onboarding onCorrectAuth={onCorrectAuth} />
                ) : (
                    <>
                        {topInfo}
                        <LoginBox>
                            <Top>
                                <div className="logo">Stem Studio</div>
                                <div className="pageName">
                                    {signup ? "Make your first video game online!" : "Login"}
                                </div>
                                <div className="description">
                                    {signup
                                        ? "Join and make your first video game using the no-code Stem Studio"
                                        : "Log in to your Stem Studio account"}
                                </div>
                            </Top>
                            <Bottom>
                                <TextInput
                                    value={email}
                                    setValue={value => setEmail(value)}
                                    height="40px"
                                    placeholder="Email"
                                />
                                <Separator margin="8px 0 0 0" invisible />
                                <TextInput
                                    value={password}
                                    setValue={value => setPassword(value)}
                                    height="40px"
                                    placeholder="Password"
                                />
                                <Separator margin="16px 0 0 0" invisible />
                                <StyledButton width="100%" isBlue={true} disabled>
                                    {signup ? "Sign up" : "Login"}
                                </StyledButton>
                                <Separator margin="12px 0 0 0" invisible />
                                <div className="or">or</div>
                                <Separator margin="12px 0 0 0" invisible />
                                <GoogleButton signup={signup} />
                                <Separator margin="12px 0 0 0" invisible />
                                <DiscordButton signup={signup} />
                            </Bottom>
                        </LoginBox>
                        <div className="terms">
                            By clicking Sign Up and using our platfom, you hereby agree to our{" "}
                            <button
                                className="link reset-css"
                                onClick={() => navigate(ROUTES.TERMS_OF_SERVICE, navigationOptions)}>
                                Terms of Service
                            </button>{" "}
                            and{" "}
                            <button
                                className="link reset-css"
                                onClick={() => navigate(ROUTES.PRIVACY_POLICY, navigationOptions)}>
                                Privacy Policy
                            </button>
                            .
                        </div>
                    </>
                )}
            </Wrapper>
        </>
    );
};
