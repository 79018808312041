import styled from "styled-components";
import {regularFont} from "../../../../../../assets/style";

export const SectionWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 8px;
`;

export const SectionTitle = styled.div`
    ${regularFont("xxs")};
    font-weight: var(--theme-font-medium-plus);
`;
