import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../../assets/style";

export const ColorPickerWrapper = styled.div`
    position: fixed;
    z-index: 1001;
    right: 130px;
    top: 50%;
    transform: translateY(-50%);
    width: 220px;
    background: var(--theme-grey-bg-tertiary);
    ${flexCenter};
    flex-direction: column;
    border-radius: 16px;
    overflow: hidden;

    .react-colorful {
        width: 204px;
        height: 173px;
    }
    .react-colorful__saturation {
        border-radius: 8px;
        border-bottom: none;
    }
    .react-colorful__hue {
        height: 8px;
    }
    .react-colorful__hue-pointer,
    .react-colorful__pointer {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        border: 2px solid #ffffff;
    }
    .react-colorful__last-control {
        border-radius: 2px;
        width: 90%;
        margin: 10px auto 0;
    }
`;

export const Label = styled.div<{$pointer?: boolean}>`
    width: 100%;
    height: 40px;
    ${flexCenter};
    ${({$pointer}) => $pointer && `cursor: pointer`};
    justify-content: space-between;
    padding: 12px;
    ${regularFont("xxs")};
    font-weight: var(--theme-font-medium);
    background: var(--theme-grey-bg-tertiary);
    border-bottom: 1px solid var(--theme-grey-bg);
`;

export const FoldingContainer = styled.div<{$expanded: boolean}>`
    height: ${({$expanded}) => ($expanded ? "250px" : "0")};
    overflow: hidden;
    transition: height 0.3s ease;
`;

export const InputsWrapper = styled.div`
    ${flexCenter};
    margin-top: 17px;
    gap: 12px 8px;
    input {
        background-color: #232323;
        color: var(--theme-font-unselected-secondary-color);
        height: 24px;
        padding: 5px 6px;
        font-size: 10px !important;
    }

    .numericInput {
        width: 41px;
        text-align: center;
    }

    .hexInput {
        width: 62px;
    }

    .singleInputWrapper {
        ${flexCenter};
        flex-direction: column;
        row-gap: 12px;
        font-size: 10px;
    }

    .label {
        color: var(--theme-blue-secondary);
        font-weight: 500;
        font-size: 11px;
    }
`;
