import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import {Object3D, Vector3} from "three"; // Import Vector3 from Three.js
import EventBus from "../../behaviors/event/EventBus";
import {COLLISION_TYPE, OBJECT_TYPES, VOLUME_TYPES} from "../../types/editor";
import {toast} from "react-toastify";
import Toast from "../../ui/window/Toast";
import global from "../../global";
import Application from "../../Application";
import {CollisionType} from "../../editor/assets/v2/types/physics";
import { PhysicsUtil } from "../../physics/PhysicsUtil";

class VolumeBehaviorUpdater implements BehaviorUpdater {
    game?: GameManager;
    target: Object3D;
    volumeType: VOLUME_TYPES;
    physicsEnabled = false;
    removed = false;
    prevPlayerPosition: Vector3 = new Vector3();
    toastId: any = "";
    private physicsRemoved: boolean = false;

    constructor(target: Object3D, volumeType: VOLUME_TYPES) {
        this.target = target;
        this.volumeType = volumeType;
        this.physicsEnabled = this.target.userData.physics && this.target.userData.physics.enabled;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        if (this.volumeType !== VOLUME_TYPES.BLOCKING) {
            this.addCollisionListener();
        }
    }

    onCollision() {
        if (!this.game || !this.game.player || !this.game.scene) return;

        const volumeType = this.volumeType;

        if (volumeType === VOLUME_TYPES.KILL_VOLUME) {
            if (!this.game.initialLives) {
                this.toast("Lives are not set", "warn");
            }
            EventBus.instance.send("game.lives.dec", Number(this.game.initialLives));
            this.removeCollisionAndPhysics();
        }

        if (volumeType === VOLUME_TYPES.DIALOGUE_VOLUME) {
            this.removed = true;
            if (this.toastId === "") {
                this.toastId = toast.info("Hello!", {
                    position: "bottom-left",
                    onClose: () => {
                        this.toastId = "";
                    },
                });
            }
        }

        if (volumeType === VOLUME_TYPES.WIN_VOLUME) {
            if (!this.game.maxScore) {
                this.toast("Max score is not set", "warn");
            }
            EventBus.instance.send("game.score.inc", this.game.maxScore);
            this.removeCollisionAndPhysics();
        }

        if (volumeType === VOLUME_TYPES.LOSE_VOLUME) {
            if (!this.game.initialLives) {
                this.toast("Lives are not set", "warn");
            }
            EventBus.instance.send("game.lives.dec", this.game.initialLives);
            this.removeCollisionAndPhysics();
        }
    }

    private addPhysicsObject() {
        if (this.physicsEnabled && this.physicsRemoved) {
            this.game!.app.addPhysicsObject(this.target);
            this.physicsRemoved = false;
        }
    }

    private addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollision.bind(this),
                useBoundingBoxes: true,
            },
            PhysicsUtil.isDynamicObject(this.target),
        );
    }

    private removeCollisionAndPhysics() {
        this.removePhysicsObject();
        this.removeCollisionListener();
        this.removed = true;
    }

    private removePhysicsObject() {
        if (this.physicsEnabled && !this.physicsRemoved) {
            this.game!.app.removePhysicsObject(this.target);
            this.physicsRemoved = true;
        }
    }

    private removeCollisionListener() {
        this.game!.behaviorManager!.collisionDetector.deleteListener(this.target);
    }


    update(clock: THREE.Clock, delta: number): void {}

    reset() {
        if (this.removed) {
            this.addPhysicsObject();
            this.addCollisionListener();
            this.removed = false;
        }
    }

    toast(message: string, type: "info" | "success" | "error" | "warn") {
        if (global.app instanceof Application) {
            global.app.toast(message, type);
        }
    }
}

export default VolumeBehaviorUpdater;
