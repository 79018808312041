import * as THREE from "three";
import I18n from "i18next";
import global from "../../../global";

let ID = -1;

/**
 * 场景模板基类
 */
class BaseSceneTemplate {
    id: string;
    constructor() {
        this.id = `${this.constructor.name}${ID--}`;
    }

    /**
     * 用于子类初始化场景
     */
    create() {}

    /**
     * 清空场景
     */
    clear() {
        const app = (global as any).app as any;
        const editor = app.editor;

        app.call("clearTools", this);

        editor.history.clear();
        editor.camera.copy(editor.DEFAULT_CAMERA);

        if (
            editor.audioListener &&
            editor.camera.children.findIndex((o: any) => o instanceof THREE.AudioListener) === -1
        ) {
            editor.camera.add(editor.audioListener);
        }

        if (editor.scene.background instanceof THREE.Texture) {
            editor.scene.background = new THREE.Color(0x27272a);
        } else if (editor.scene.background instanceof THREE.Color) {
            editor.scene.background.setHex(0x27272a);
        }

        editor.scene.fog = null;

        editor.deselect();

        // 移除场景物体
        let objects = editor.scene.children;

        while (objects.length > 0) {
            editor.removeObject(objects[0]);
        }

        editor.scripts.length = 0;

        editor.animations.length = 0;

        global.app!.call("enableInfiniteGrid", this, this);

        // 场景信息
        editor.sceneID = null;
        editor.sceneName = "Game Title";
        app.options.sceneType = "Empty";
        document.title = I18n.t("No Name");
        app.editor.camera.userData.control = "OrbitControls";
        let sceneMetadata = {
            name: editor.sceneName,
        };
        const game = {
            uuid: THREE.MathUtils.generateUUID(),
            enabled: true,
            lives: 3,
            maxScore: 500,
        };

        editor.scene.userData = {sceneMetadata, game};
        editor.scene.uuid = THREE.MathUtils.generateUUID();
        app.editor.isMultiplayer = false;
        app.editor.voiceChatEnabled = false;
        app.editor.useAvatar = false;
    }
}

export default BaseSceneTemplate;
