import styled from "styled-components";
import {useNavigate} from "react-router-dom";

import {ROUTES} from "../../../../AppRouter";
import {SEARCH_GAME_QUERY} from "../../types";

import hashtag from "../../../assets/hashtag.svg";
import {flexCenter, regularFont} from "../../../../assets/style";

export const Tag = ({tag}: {tag: string}) => {
    const navigate = useNavigate();
    return (
        <Container onClick={() => navigate(`${ROUTES.SEARCH_RESULTS}?${SEARCH_GAME_QUERY.GAME_TAGS}=${tag}`)}>
            <img src={hashtag} alt="" />
            {tag}
        </Container>
    );
};

const Container = styled.div`
    padding: 8px;
    background-color: var(--theme-homepage-grey-bg-primary);
    ${flexCenter};
    column-gap: 4px;
    ${regularFont("xxs")};
    font-weight: 500;
    border-radius: 8px;
    cursor: pointer;
`;
