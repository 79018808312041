import {flexCenter, regularFont} from "../../../../../assets/style";
import styled from "styled-components";

export const HUDContainer = styled.div<{$bgImg?: string; $isStartMenu?: boolean}>`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000bf;
    padding: 17px 16px 0;

    display: flex;
    flex-direction: column;

    ${({$bgImg, $isStartMenu}) =>
        $bgImg &&
        `
        background-color: #1d1b1b;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url('${$bgImg}');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            ${!$isStartMenu ? "opacity: 0.1;" : "opacity: 1;"}
            z-index: -1;
        }
    `}
`;

export const HUDContainerButtonsLayer = styled.div`
    width: 100%;
    flex-grow: 1;
    position: relative;
    max-height: calc(100% - 55px);
    max-width: 1500px;
    min-width: 1024px;
    margin: 0 auto;

    display: flex;
    flex-wrap: nowrap;

    &::after {
        content: "";
        background: #000000b2;
        opacity: 0.2;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0 0 24px 24px;
    }
`;

export const Menu = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    height: 52px;
    padding: 0 24px;
    background-color: #000;
    border-radius: 12px 12px 0px 0px;
    ${flexCenter};
    justify-content: space-between;
    flex-shrink: 0;

    .screen-selection-btn {
        ${regularFont("xs")};
        color: #5c5c5c;
        font-weight: var(--theme-font-medium);
    }
    .btn-active {
        color: #fff;
    }

    .done-btn {
        ${regularFont("s")};
    }

    .margin-btn {
        margin-right: 12px;
    }

    .options {
        ${flexCenter};
        column-gap: 24px;
    }
`;
