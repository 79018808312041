import * as THREE from "three";
import BaseBehaviorConverter from "./BaseBehaviorConverter";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import FollowBehaviorUpdater from "./FollowBehaviorUpdater";
import {FollowBehaviorInterface, OBJECT_TYPES} from "../../types/editor";

class FollowBehaviorConverter extends BaseBehaviorConverter<FollowBehaviorInterface> {
    public static DEFAULT = new FollowBehaviorConverter(null);

    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: FollowBehaviorInterface): BehaviorUpdater {
        return new FollowBehaviorUpdater(
            target,
            behavior.followTarget,
            behavior.distance,
            behavior.speed,
            behavior.rotate,
        );
    }

    getBehavior(target: THREE.Object3D, id: string): FollowBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as FollowBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have FOLLOW behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): FollowBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.FOLLOW,
            followTarget: "",
            distance: 2,
            speed: 1,
            rotate: true,
            startOnTrigger: false,
        };
    }
}

export default FollowBehaviorConverter;
