import {useEffect, useState} from "react";
import {Wrapper} from "../AddLogo/AddLogo.style";
import {useHUDContext, useHUDStartGameMenuContext} from "../../../../../../../../context";
import {GAME_IMAGE_SIZE} from "../../../../../../../../editorConfig";
import {UploadField} from "../../../../../common/UploadField/UploadField";
import {HUD_TABS, START_MENU_IDS} from "../../../types";
import {FileData} from "../../../../../types/file";
import {backendUrlFromPath} from "../../../../../../../../utils/UrlUtils";

export const AddMenuBg = () => {
    const {popupCallback, popupId, activeScreen} = useHUDContext();
    const {startGameMenuLayout, setStartGameMenuLayout} = useHUDStartGameMenuContext();

    const [uploadedFile, setUploadedFile] = useState<FileData | string | null>(null);

    useEffect(() => {
        let img;
        if (activeScreen === HUD_TABS.GAME_START_MENU && popupId) {
            img = startGameMenuLayout?.[popupId as keyof typeof startGameMenuLayout];
        }
        if (img) {
            setUploadedFile(img as string);
        }
    }, []);

    useEffect(() => {
        if (uploadedFile && popupCallback) {
            popupCallback(backendUrlFromPath(uploadedFile));
        }
    }, [popupCallback]);

    useEffect(() => {
        if (activeScreen === HUD_TABS.GAME_START_MENU) {
            setUploadedFile(startGameMenuLayout?.[START_MENU_IDS.MENU_BG] as string);
        }
    }, [uploadedFile, popupCallback, startGameMenuLayout]);

    const deleteImg = () => {
        if (activeScreen === HUD_TABS.GAME_START_MENU) {
            setStartGameMenuLayout({
                ...startGameMenuLayout,
                [START_MENU_IDS.MENU_BG]: null,
            });
        }
    };

    return (
        <Wrapper>
            <div className="title">Upload Image &#40;512x512&#41;</div>
            <UploadField
                width="100%"
                height="210px"
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                deleteHandler={deleteImg}
                size={GAME_IMAGE_SIZE}
                uploadHandler={arg => popupCallback && popupCallback(backendUrlFromPath(arg))}
            />
        </Wrapper>
    );
};
