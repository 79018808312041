import styled from "styled-components";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import googleicon from "../../assets/google-icon.svg";
import {auth} from "../../../firebase";
import global from "../../../global";
import {regularFont} from "../../../assets/style";

export const GoogleButton = ({signup}: {signup?: boolean}) => {
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();

        signInWithPopup(auth, provider)
            .then(async result => {
                const credential = GoogleAuthProvider.credentialFromResult(result);

                if (!credential) {
                    throw Error("Google Auth error: credential is null");
                }
            })
            .catch(error => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData?.email || "unknown";
                const credential = GoogleAuthProvider.credentialFromError(error);
                (global as any).app.toast("Request failed.", "error");
                console.log(
                    `Error from google sign in. Err code ${errorCode}, message: ${errorMessage}, email: ${email}, credential: ${credential}`,
                );
            });
    };

    return (
        <StyledGoogleButton id="GoogleButton" className="GoogleButton reset-css" onClick={signInWithGoogle}>
            <img src={googleicon} alt="" className="icon" />
            Sign {signup ? "up" : "in"} with Google
        </StyledGoogleButton>
    );
};

const StyledGoogleButton = styled.button`
    background-color: #f4f4f5 !important;
    text-align: center;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;

    ${regularFont("xs")};
    color: #18181b;
    font-weight: 500;
`;
