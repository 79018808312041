import styled from "styled-components";
import searchIconSmall from "../icons/search-icon-small.svg";

const Wrapper = styled.div<{$width?: string}>`
    position: relative;
    box-sizing: border-box;
    > input {
        width: ${({$width}) => ($width ? $width : "170px")};
        height: 32px;
        border: none;
        box-sizing: border-box;
        border-radius: 8px;
        background: var(--theme-grey-bg);
        padding: 0 16px 0 32px;
        font-size: var(--theme-font-size-xs);
        font-weight: var(--theme-font-regular);
        line-height: 120%;
        text-align: left;
        color: var(--theme-font-input-color);
        font-family: "Inter";
        &::placeholder {
            color: var(--theme-font-unselected-secondary-color);
        }
    }
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
`;

type Props = {
    value: string;
    onChange: (value: string) => void;
    className?: string;
    width?: string;
    placeholder?: string;
};

export const SearchInput = ({value, onChange, className, width, placeholder}: Props) => {
    return (
        <Wrapper className={className} $width={width}>
            <IconWrapper>
                <img src={searchIconSmall} alt="search-icon-small" />
            </IconWrapper>
            <input type="text" placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)} />
        </Wrapper>
    );
};
