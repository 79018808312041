export enum EFFECTS_KEYS {
    SMALL = "VFXSmallEffect",
    MEDIUM = "VFXMediumEffect",
    BIG = "VFXBigEffect",
    LASER = "VFXLaserEffect",
    CARTOONY = "VFXCartoonyEffect",
}

export enum EFFECTS_NAMES {
    SMALL = "Small Effect",
    MEDIUM = "Medium Effect",
    BIG = "Big Effect",
    LASER = "Laser Effect",
    CARTOONY = "Cartoony Effect",
}

export const WEAPON_VFX_OPTIONS = [
    {key: "1", value: "none"},
    {key: "2", value: EFFECTS_NAMES.MEDIUM},
    {key: "3", value: EFFECTS_NAMES.BIG},
    {key: "4", value: EFFECTS_NAMES.LASER},
    {key: "5", value: EFFECTS_NAMES.CARTOONY},
];
