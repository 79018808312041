import {useCallback, useEffect, useState} from "react";
import * as THREE from "three";
import I18n from "i18next";
import global from "../../../../../../../global";
import AddObjectCommand from "../../../../../../../command/AddObjectCommand";
import Box from "../../../../../../../object/geometry/Box.js";

import spotLight from "../../../../icons/assetsTab/lights/spot.svg";
import ambientLight from "../../../../icons/assetsTab/lights/ambient.svg";
import pointLight from "../../../../icons/assetsTab/lights/point.svg";
import directionalLight from "../../../../icons/assetsTab/lights/directional.svg";
import boxIcon from "../../../../icons/assetsTab/misc/box.svg";
import rectAreaLight from "../../../../icons/assetsTab/lights/rect-area.svg";
import spawnPoint from "../../../../icons/assetsTab/misc/spawn-point.svg";
import hemisphereLight from "../../../../icons/assetsTab/lights/hemisphere.svg";
import billboardIcon from "../../../../icons/assetsTab/misc/billboard.svg";
import soundIcon from "../../../../icons/assetsTab/new-misc/sound.svg";
import linearFogIcon from "../../../../icons/assetsTab/new-misc/linear-fog.svg";
import exponentialFogIcon from "../../../../icons/assetsTab/new-misc/exponential-fog.svg";
import {IconsFlexContainer} from "../../../../common/IconsFlexContainer";
import {TitleContainer, TopContainer} from "../AssetsTab.style";
import {SearchInput} from "../../../../../../../editor/assets/v2/common/SearchInput";
import {generateUniqueName} from "../../../../../../../v2/pages/services";
import {setBehaviorForObject} from "../../../../../../../editor/assets/v2/RightPanel/behaviors/helpers/setBehaviorForObject";
import {OBJECT_TYPES} from "../../../../../../../types/editor";
import PointMarkerVertexShader from "../../../../../../../object/mark/shader/point_marker_vertex.glsl";
import PointMarkerFragmentShader from "../../../../../../../object/mark/shader/point_marker_fragment.glsl";

export enum LIGHT_NAME {
    SPOT = "Spot Light",
    POINT = "Point Light",
    DIRECTIONAL = "Directional",
    AMBIENT = "Ambient",
    RECT_AREA = "Rect Area Light",
    HEMISPHERE = "Hemisphere Light",
}

export enum NEW_MISC_NAME {
    BILLBOARD = "Billboard",
    VOLUMES = "Scene Volumes",
    SPAWN_POINT = "Spawn Point",
    CHECK_POINT = "Check Point",
    POINT_SOUND = "Point Sound",
    LINEAR_FOG = "Linear Fog",
    EXPONENTIAL_FOG = "Exponential Fog",
}

const LIGHTS_MISC_OPTIONS = [
    {icon: billboardIcon, text: NEW_MISC_NAME.BILLBOARD, name: NEW_MISC_NAME.BILLBOARD},
    {icon: boxIcon, text: "Scene Volumes", name: NEW_MISC_NAME.VOLUMES},
    {icon: spawnPoint, text: "Spawn Point", name: NEW_MISC_NAME.SPAWN_POINT},
    {icon: spawnPoint, text: "Check Point", name: NEW_MISC_NAME.CHECK_POINT},
    {icon: soundIcon, text: "Point Sound", name: NEW_MISC_NAME.POINT_SOUND},
    {icon: linearFogIcon, text: "Linear Fog", name: NEW_MISC_NAME.LINEAR_FOG},
    {
        icon: exponentialFogIcon,
        text: "Exponential </br> Fog",
        name: NEW_MISC_NAME.EXPONENTIAL_FOG,
    },
    {
        icon: directionalLight,
        text: "Directional </br> Light",
        name: LIGHT_NAME.DIRECTIONAL,
    },
    {
        icon: rectAreaLight,
        text: "Rect Area </br> Light",
        name: LIGHT_NAME.RECT_AREA,
    },
    {icon: pointLight, text: "Point Light", name: LIGHT_NAME.POINT},
    {icon: spotLight, text: "Spot Light", name: LIGHT_NAME.SPOT},
    {icon: ambientLight, text: "Ambient </br> Light", name: LIGHT_NAME.AMBIENT},
    {
        icon: hemisphereLight,
        text: "Hemisphere </br> Light",
        name: LIGHT_NAME.HEMISPHERE,
    },
];

const fogColor = "#aaaaaa";
const fogNear = 0.1;
const fogFar = 50;
const fogDensity = 0.05;

export const LightsTab = ({scene}: {scene: any}) => {
    const [search, setSearch] = useState("");
    const [list, setList] = useState<any[]>(LIGHTS_MISC_OPTIONS);
    const app = (global as any).app as any;
    const selected = app.editor?.selected;
    const editor = app.editor;
    const [existingNames, setExistingNames] = useState<Set<string>>(new Set());

    useEffect(() => {
        const names = new Set<string>();
        editor?.scene.children.forEach((child: any) => {
            if (child.name) {
                names.add(child.name);
            }
        });
        setExistingNames(names);
    }, [editor?.scene.children]);

    const handleAddAmbientLight = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const color = 0xaaaaaa;
                const light = new THREE.AmbientLight(color);
                const uniqueName = generateUniqueName(I18n.t("Ambient Light"), prevNames);
                light.name = uniqueName;

                addObjectToSceneCenter(light, new THREE.Vector3(0, 10, 0));
                callback && callback(light);

                const updatedNames = new Set(prevNames);
                updatedNames.add(uniqueName);
                return updatedNames;
            });
        },
        [editor, I18n],
    );

    const handleAddDirectionalLight = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const color = 0xffffff;
                const intensity = 1;

                const light = new THREE.DirectionalLight(color, intensity);
                light.name = generateUniqueName(I18n.t("Directional Light"), prevNames);

                light.castShadow = true;
                light.shadow.radius = 5;
                light.shadow.bias = 0;
                light.shadow.normalBias = 0.2;
                light.shadow.mapSize.x = 2048;
                light.shadow.mapSize.y = 2048;
                light.shadow.camera.left = -100;
                light.shadow.camera.right = 100;
                light.shadow.camera.top = 100;
                light.shadow.camera.bottom = -100;
                light.shadow.camera.near = 0.1;
                light.shadow.camera.far = 1000;

                addObjectToSceneCenter(light, new THREE.Vector3(5, 10, 7.5));
                callback && callback(light);

                const updatedNames = new Set(prevNames);
                updatedNames.add(light.name);
                return updatedNames;
            });
        },
        [editor, I18n],
    );

    const handleAddPointLight = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const uniqueName = generateUniqueName(I18n.t("Point Light"), prevNames);

                const color = 0xffffff;
                const intensity = 1;
                const distance = 0;

                const light = new THREE.PointLight(color, intensity, distance);
                light.name = uniqueName;

                light.castShadow = true;
                light.shadow.bias = 0;
                light.shadow.normalBias = 0.2;
                light.shadow.radius = 5;
                light.shadow.mapSize.x = 512;
                light.shadow.mapSize.y = 512;

                addObjectToSceneInCameraView(light, new THREE.Vector3(0, 5, 0));
                callback && callback(light);
                const updatedNames = new Set(prevNames);
                updatedNames.add(uniqueName);
                return updatedNames;
            });
        },
        [editor, I18n],
    );

    const handleAddSpotLight = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const color = 0xffffff;
                const intensity = 1;
                const distance = 0;
                const angle = Math.PI * 0.1;
                const penumbra = 0;

                const light = new THREE.SpotLight(color, intensity, distance, angle, penumbra);
                light.name = generateUniqueName(I18n.t("Spot Light"), prevNames);
                light.castShadow = true;

                addObjectToSceneInCameraView(light, new THREE.Vector3(0, 5, 0));
                callback && callback(light);
                const updatedNames = new Set(prevNames);
                updatedNames.add(light.name);
                return updatedNames;
            });
        },
        [editor, I18n],
    );

    const handleAddHemisphereLight = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const skyColor = 0x00aaff;
                const groundColor = 0xffaa00;
                const intensity = 1;

                const light = new THREE.HemisphereLight(skyColor, groundColor, intensity);
                const uniqueName = generateUniqueName(I18n.t("Hemisphere Light"), prevNames);
                light.name = uniqueName;

                addObjectToSceneCenter(light, new THREE.Vector3(0, 10, 0));
                callback && callback(light);
                const updatedNames = new Set(prevNames);
                updatedNames.add(uniqueName);
                return updatedNames;
            });
        },
        [editor, I18n],
    );

    const handleAddRectAreaLight = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const color = 0xffffff;
                const intensity = 1;
                const width = 20;
                const height = 10;

                const light = new THREE.RectAreaLight(color, intensity, width, height);
                light.name = generateUniqueName(I18n.t("Rect Area Light"), prevNames);

                addObjectToSceneInCameraView(light, new THREE.Vector3(0, 5, 0));
                callback && callback(light);
                const updatedNames = new Set(prevNames);
                updatedNames.add(light.name);
                return updatedNames;
            });
        },
        [editor, I18n],
    );

    const handleAddVolumes = (callback?: (obj: any) => void) => {
        setExistingNames(prevNames => {
            if (app?.editor?.selected) {
                app.editor.select(null);
            }

            let geometry = new THREE.BoxGeometry(3, 2, 2);
            let material = new THREE.ShaderMaterial({
                vertexShader: PointMarkerVertexShader,
                fragmentShader: PointMarkerFragmentShader,
                uniforms: {
                    width: {
                        value: 1,
                    },
                    height: {
                        value: 1,
                    },
                },
                transparent: true,
                side: THREE.DoubleSide,
            });
            const volume = new THREE.Mesh(geometry, material);

            const uniqueName = generateUniqueName(NEW_MISC_NAME.VOLUMES, prevNames);
            volume.name = uniqueName;
            volume.userData.isSceneVolume = true;
            setBehaviorForObject(OBJECT_TYPES.SCENE_VOLUME, app.editor, volume);

            addObjectToSceneInCameraView(volume);
            callback && callback(volume);
            const updatedNames = new Set(prevNames);
            updatedNames.add(volume.name);
            return updatedNames;
        });
    };

    const handleStartSpawnOrCheckPointTool = useCallback(
        (openCheckPoint: boolean, callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                if (app?.editor?.selected) {
                    app.editor.select(null);
                }

                let geometry = new THREE.PlaneGeometry();
                let material = new THREE.ShaderMaterial({
                    vertexShader: PointMarkerVertexShader,
                    fragmentShader: PointMarkerFragmentShader,
                    uniforms: {
                        width: {
                            value: 1,
                        },
                        height: {
                            value: 1,
                        },
                    },
                    transparent: true,
                    side: THREE.DoubleSide,
                });
                const point = new THREE.Mesh(geometry, material);

                if (openCheckPoint) {
                    const uniqueName = generateUniqueName(NEW_MISC_NAME.CHECK_POINT, prevNames);
                    point.name = uniqueName;
                    point.userData.isCheckPoint = true;
                    setBehaviorForObject(OBJECT_TYPES.CHECK_POINT, app.editor, point);
                } else {
                    const uniqueName = generateUniqueName(NEW_MISC_NAME.SPAWN_POINT, prevNames);
                    point.name = uniqueName;
                    point.userData.isSpawnPoint = true;
                    setBehaviorForObject(OBJECT_TYPES.SPAWN_POINT, app.editor, point);
                }

                addObjectToSceneInCameraView(point);
                callback && callback(point);
                const updatedNames = new Set(prevNames);
                updatedNames.add(point.name);
                return updatedNames;
            });
        },
        [editor],
    );

    const handleAddPointSound = () => {
        console.log("handleAddPointSound");
    };

    const handleAddLinearFog = () => {
        scene.fog = new THREE.Fog(fogColor, fogNear, fogFar);
        app.call(`objectChanged`, undefined, selected);
    };

    const handleAddExponentialFog = () => {
        scene.fog = new THREE.FogExp2(fogColor, fogDensity);
        app.call(`objectChanged`, undefined, selected);
    };

    const handleBillboard = () => {
        handleAddPlane();
    };

    const handleClick = (name: LIGHT_NAME | NEW_MISC_NAME, callback?: (obj: any) => void) => {
        switch (name) {
            case NEW_MISC_NAME.BILLBOARD:
                handleBillboard();
                break;
            case NEW_MISC_NAME.VOLUMES:
                handleAddVolumes(callback);
                break;
            case NEW_MISC_NAME.SPAWN_POINT:
                handleStartSpawnOrCheckPointTool(false, callback);
                break;
            case NEW_MISC_NAME.CHECK_POINT:
                handleStartSpawnOrCheckPointTool(true, callback);
                break;
            case NEW_MISC_NAME.POINT_SOUND:
                handleAddPointSound();
                break;
            case NEW_MISC_NAME.LINEAR_FOG:
                handleAddLinearFog();
                break;
            case NEW_MISC_NAME.EXPONENTIAL_FOG:
                handleAddExponentialFog();
                break;
            case LIGHT_NAME.DIRECTIONAL:
                handleAddDirectionalLight(callback);
                break;
            case LIGHT_NAME.AMBIENT:
                handleAddAmbientLight(callback);
                break;
            case LIGHT_NAME.RECT_AREA:
                handleAddRectAreaLight(callback);
                break;
            case LIGHT_NAME.POINT:
                handleAddPointLight(callback);
                break;
            case LIGHT_NAME.SPOT:
                handleAddSpotLight(callback);
                break;
            case LIGHT_NAME.HEMISPHERE:
                handleAddHemisphereLight(callback);
                break;
            default:
                break;
        }
    };

    const addObjectToSceneInCameraView = (object: THREE.Object3D, offset?: THREE.Vector3) => {
        app.editor.moveObjectToCameraClosestPoint(object);
        addObjectToSceneCenter(object, offset);
    };

    const generateRandomColor = () => {
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);

        const redHex = red.toString(16).padStart(2, "0");
        const greenHex = green.toString(16).padStart(2, "0");
        const blueHex = blue.toString(16).padStart(2, "0");

        const randomColor = `#${redHex}${greenHex}${blueHex}`;

        return randomColor;
    };

    const handleAddPlane = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const material = new THREE.MeshStandardMaterial({
                    color: generateRandomColor(),
                });
                const geometry = new THREE.BoxGeometry(10, 10, 0.001);
                const billboard = new THREE.Mesh(geometry, material);
                const uniqueName = generateUniqueName("Billboard", prevNames);
                billboard.name = uniqueName;
                billboard.userData.isBillboard = true;
                billboard.userData.isPlane === true;
                setBehaviorForObject(OBJECT_TYPES.BILLBOARD, app.editor, billboard);

                addObjectToSceneInCameraView(billboard);
                callback && callback(billboard);
                const updatedNames = new Set(prevNames);
                updatedNames.add(uniqueName);
                return updatedNames;
            });
        },
        [editor],
    );

    const addObjectToSceneCenter = (object: THREE.Object3D, offset?: THREE.Vector3) => {
        if (offset) {
            object.position.add(offset);
        }
        // add command already selects the object
        editor.execute(new (AddObjectCommand as any)(object));
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, name: string) => {
        e.dataTransfer.setData("asset-id", name);
        e.dataTransfer.setData("asset-type", "lights");
    };

    useEffect(() => {
        if (!search) {
            setList(LIGHTS_MISC_OPTIONS);
            return;
        } else {
            setList(
                LIGHTS_MISC_OPTIONS?.filter(n => {
                    return n.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                }),
            );
        }
    }, [search]);

    useEffect(() => {
        app.on(`dragEnd.LightsTab`, (type: string, name: string, position: any) => {
            if (type === "lights") {
                handleClick(name as LIGHT_NAME, (obj: any) => {
                    obj.position.copy(position);
                });
            }
        });
        return () => {
            app.on(`dragEnd.LightsTab`, null);
        };
    }, []);

    return (
        <>
            <TopContainer>
                <SearchInput width="224px" placeholder="Search Misc" onChange={setSearch} value={search} />
            </TopContainer>
            <TitleContainer>Misc</TitleContainer>
            <IconsFlexContainer list={list} onSelectItem={handleClick} draggable onDragStart={handleDragStart} />
        </>
    );
};
