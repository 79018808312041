import React, {useEffect, useRef} from "react";
import * as THREE from "three";
import setCamera from "../helpers/setCamera";
import setLights from "../helpers/setLights";
import resizeWindow from "../helpers/resizeWindow";
import loadModel from "../helpers/loadModel";
import {useModelAnimationCombinerContext} from "../../../../../context";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import global from "../../../../../global";
import Application from "../../../../../Application";
import I18n from "i18next";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {positionCameraForModel} from "../../utils/positionCameraForModel";

interface ModelViewerProps {
    fileExt: string | null;
    model: any;
    handleClose: () => void;
}

export const ModelViewer: React.FC<ModelViewerProps> = ({fileExt, model, handleClose}) => {
    const app = global.app as Application;
    const viewer = useRef<HTMLDivElement>(null);
    const {addMainModel, addAnimationFromMainModel, addMixer, toggleLoading} = useModelAnimationCombinerContext();

    const setupJointMarkers = (jointMarkers: any, scene: THREE.Scene, model: THREE.Object3D) => {
        const box = new THREE.Box3().setFromObject(model);
        const size = new THREE.Vector3();
        box.getSize(size);

        const maxDimension = Math.max(size.x, size.y, size.z);

        const sphereRadius = maxDimension * 0.004;
        model.traverse(child => {
            if (child.type === "Bone") {
                const geometry = new THREE.SphereGeometry(sphereRadius, 16, 16);
                const material = new THREE.MeshBasicMaterial({
                    color: 0x0000ff,
                    depthTest: false, // Disable depth test so spheres are always visible
                    depthWrite: false, // Disable depth writing to not affect depth buffer
                });
                const sphere = new THREE.Mesh(geometry, material);

                // Set render order to a high value to render spheres on top
                sphere.renderOrder = 9999;

                // Position the sphere at the bone's position initially
                sphere.position.copy(child.getWorldPosition(new THREE.Vector3()));
                jointMarkers.push({sphere, bone: child});

                // Add the sphere to the scene
                scene.add(sphere);
            }
        });
    };

    useEffect(() => {
        if (!model || !fileExt || !viewer.current) return;
        const clock = new THREE.Clock();
        const scene = new THREE.Scene();
        const camera = setCamera(viewer.current);
        const controls = new OrbitControls(camera, viewer.current);
        let mixer: THREE.AnimationMixer | null = null;

        const renderer = new THREE.WebGLRenderer();
        resizeWindow(camera, viewer.current, renderer);

        if (viewer.current.children.length) viewer.current.removeChild(viewer.current.lastChild as Node);
        viewer.current.appendChild(renderer.domElement);

        const ground = new THREE.Mesh(
            new THREE.PlaneGeometry(5000, 5000),
            new THREE.MeshPhongMaterial({color: 0x999999, depthWrite: false}),
        );
        ground.rotation.x = -Math.PI / 2;
        ground.receiveShadow = true;
        ground.position.y = 0;
        scene.add(ground);

        const grid = new THREE.GridHelper(5000, 500, 0x29292a, 0x29292a);
        grid.material.opacity = 0.2;
        grid.material.transparent = true;
        grid.position.y = 0;
        scene.add(grid);

        scene.fog = new THREE.Fog(0x29292a, 2000, 3000);

        let url = backendUrlFromPath(model.userData.Url);

        let jointMarkers: any[] = [];

        toggleLoading();
        if (url) {
            loadModel(
                url,
                model.userData,
                (object: any) => {
                    toggleLoading();

                    Object.assign(object._obj.animations, model._obj?.animations || model?.animations);
                    addAnimationFromMainModel(object._obj.animations || object.animations);
                    mixer = new THREE.AnimationMixer(object);
                    addMixer(mixer);

                    scene.add(object);
                    addMainModel(object);
                    positionCameraForModel(object, camera, controls);
                    setupJointMarkers(jointMarkers, scene, object);
                },
                () => {
                    toggleLoading();
                    handleClose();
                },
            );
        } else {
            app.toast(I18n.t("No model URL found in model data"), "error");
            toggleLoading();
            handleClose();
        }

        setLights(scene);
        scene.background = new THREE.Color(0x29292a);
        renderer.shadowMap.enabled = true;
        renderer.render(scene, camera);

        const handleResize = () => resizeWindow(camera, viewer.current as HTMLElement, renderer);
        window.addEventListener("resize", handleResize);
        let lastTime = 0;
        const interval = 1000 / 60;

        const animate = (time: number) => {
            requestAnimationFrame(animate);
            if (mixer) mixer.update(clock.getDelta());

            jointMarkers.forEach(({sphere, bone}) => {
                sphere.position.copy(bone.getWorldPosition(new THREE.Vector3()));
            });

            const deltaTime = time - lastTime;

            if (deltaTime > interval) {
                lastTime = time - (deltaTime % interval);

                renderer.render(scene, camera);
            }
        };
        animate(0);

        return () => {
            window.removeEventListener("resize", handleResize);
            renderer.dispose();
            if (viewer.current) {
                viewer.current.removeChild(renderer.domElement);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, fileExt]);

    return <div style={{height: "100%", width: "100%"}} ref={viewer} />;
};
