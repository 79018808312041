import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";

import {truncateName} from "../../../../../../../v2/pages/services";
import closeIcon from "../../../icons/x.svg";
import copyIcon from "../../../icons/copy.svg";
import checkedIcon from "../../../icons/checked.svg";

import {StyledSwitch} from "../../../../common/StyledSwitch";
import {StyledButton} from "../../../../common/StyledButton";
import {Separator} from "../../Separator";
import {CopyURLContainer, Description, Heading, MainHeading, PostPublishHeading, PublishPanelContainer} from "./style";

interface Props {
    handleSettingsSave: () => void;
    closePanel: () => void;
    firstTimePublish: boolean;
    fullUrl: string;
    isPublic: boolean;
    isCloneable: boolean;
    isPublished: boolean;
    setIsPublic: (value: boolean) => void;
    setIsCloneable: (value: boolean) => void;
    setIsPublished: (value: boolean) => void;
}

export const PublishPanel = ({
    handleSettingsSave,
    firstTimePublish,
    closePanel,
    fullUrl,
    isPublic,
    isCloneable,
    isPublished,
    setIsPublic,
    setIsCloneable,
    setIsPublished,
}: Props) => {
    const panelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (panelRef.current) {
            const handleClickOutside = (event: MouseEvent) => {
                if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
                    closePanel();
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [panelRef, closePanel]);

    return (
        <PublishPanelContainer ref={panelRef}>
            <MainHeading>
                <div className="label">Settings</div>
                <button className="reset-css" onClick={closePanel}>
                    <img src={closeIcon} alt="close" />
                </button>
            </MainHeading>
            <Separator />
            {!firstTimePublish && (
                <>
                    <PostPublishHeading>
                        <img src={checkedIcon} alt="" />
                        <Heading>
                            <div className="label">Your game is live!</div>
                        </Heading>
                    </PostPublishHeading>
                    <Description>
                        Your game is publicaly playable. Copy the direct link below to share with friends!
                    </Description>
                    <CopyURLContainer>
                        <div className="url">{truncateName(fullUrl, 27)}</div>
                        <StyledButton
                            isGrey
                            width="auto"
                            onClick={() => {
                                navigator.clipboard.writeText(fullUrl);
                                toast.success("Copied to clipboard!");
                            }}
                            className="regularWeight">
                            <img src={copyIcon} alt="" />
                        </StyledButton>
                    </CopyURLContainer>
                    <Separator />
                </>
            )}
            <Heading>
                <div className="label">Is published?</div>
                <StyledSwitch checked={isPublished} onChange={() => setIsPublished(!isPublished)} />
            </Heading>
            <Description>Allow anyone to play your game on Mochi</Description>
            <Separator />
            <Heading>
                <div className="label">Is public?</div>
                <StyledSwitch checked={isPublic} onChange={() => setIsPublic(!isPublic)} />
            </Heading>
            <Description>Make your game visible in community games</Description>
            <Separator />
            <Heading>
                <div className="label">Is cloneable?</div>
                <StyledSwitch checked={isCloneable} onChange={() => setIsCloneable(!isCloneable)} />
            </Heading>
            <Description>Allow others to duplicate this project and make it their own</Description>
            <Separator margin="12px auto 8px" />

            <StyledButton isBlue onClick={handleSettingsSave}>
                <span>Save Settings</span>
            </StyledButton>
        </PublishPanelContainer>
    );
};
