import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../assets/style";
import {useAuthorizationContext} from "../../../../context";
import global from "../../../../global";
import Application from "src/Application";
import {useEffect, useState} from "react";

const Container = styled.div`
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 32px;
    padding: 8px;
    ${flexCenter};
    ${regularFont("s")};
    column-gap: 8px;
    border-radius: 12px;
    border: 1px solid #ffffff1a;
    background: var(--theme-container-main-dark);
    font-weight: var(--theme-font-medium);
`;

export const ReadOnlyBadge = () => {
    const app = global.app as Application;
    const {dbUser} = useAuthorizationContext();
    const [projectUserId, setProjectUserId] = useState(app.editor?.projectUserId || "");

    useEffect(() => {
        setProjectUserId(app.editor?.projectUserId || "");
        app.on("clear.ReadOnlyBadge", () => {
            setProjectUserId(app.editor?.projectUserId || "");
        });

        return () => {
            app.on("clear.ReadOnlyBadge", null);
        };
    }, []);

    if (app.editor && (!projectUserId || dbUser?.id === projectUserId)) {
        return null;
    }

    return <Container>You are in Read Only Mode</Container>;
};
