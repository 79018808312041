import {flexCenter} from "../../../../../../assets/style";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    box-sizing: border-box;

    button,
    div,
    span,
    input {
        box-sizing: border-box;
    }

    .assets-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
        justify-content: center;
        row-gap: 8px;
        column-gap: 8px;
        width: 100%;
        padding: 0 6px;
        max-height: calc(100svh - 290px);
    }

    .assets-list > div:nth-child(even) {
        margin-right: auto;
    }

    .assets-list > div:nth-child(odd) {
        margin-left: auto;
    }

    .assets-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        width: fit-content;
        color: var(--theme-font-input-color);
        font-size: var(--theme-font-size-xxs);
        font-weight: var(--theme-font-regular);
        position: relative;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            .assets-item-name {
                color: var(--theme-font-selected-color);
            }
        }
    }

    .assets-item .thumbnail-placeholder {
        width: 108px;
        height: 108px;
        border-radius: 8px;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .assets-item .assets-item-name {
        display: inline-block;
        max-width: 108px;
        text-align: center;
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-font-unselected-color);
    }

    .assets-list .assets-item .delete-button {
        width: 22px;
        height: 22px;
    }

    .assets-list .assets-item:hover {
        color: white;
    }

    .assets-item .sound-image-wrapper,
    .assets-item > img {
        border-radius: 8px;
        width: 108px;
        height: 108px;
    }

    .assets-item .select-border {
        border-radius: 8px;
        width: 81px;
        height: 81px;
        border: 3px solid var(--theme-border-selected);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: none;
    }

    .assets-item .assets-item-menu {
        display: none;
        position: absolute;
        top: 40px;
        left: 25%;
        text-align: center;
        min-width: 84px;
        padding: 0 4px;
        border-radius: 6px;
        box-shadow: 0px 4px 15px 0px #000;
        background-color: var(--theme-container-secondary-dark);
        box-sizing: border-box;
        font-size: var(--theme-font-size-xxs);
    }

    .assets-item .assets-item-menu span {
        transition: 0.3s ease-in-out;
    }

    .assets-item .assets-item-menu span.disabled {
        cursor: not-allowed;
    }

    .assets-item .assets-item-menu.open {
        display: flex;
        flex-direction: column;
        gap: 8px;
        z-index: 10;
    }

    .assets-item .assets-item-menu.open span:hover {
        color: white;
    }

    .assets-item .assets-item-menu.open span.disabled:hover {
        color: var(--theme-font-unselected-color);
    }
`;

export const StyledSoundImageWrapper = styled.div<{$isPlaying: boolean}>`
    background: var(--theme-editor-box-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    &:hover svg path {
        fill: #fff;
    }
    svg path {
        ${props => props.$isPlaying && "fill: var(--theme-container-main-blue) !important"};
    }
`;

export const TabsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding: 12px 8px;
    border-bottom: 1px solid var(--theme-container-divider);
`;

export const TabIconWrapper = styled.div`
    position: relative;

    &.disabled {
        display: none;
    }

    &:hover .tab-tooltip {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const TabIconButton = styled.div<{$isActive: boolean}>`
    width: 32px;
    height: 32px;
    ${flexCenter};
    border-top: 1px solid transparent;
    transition: all 0.2s;
    border-radius: 8px;
    cursor: pointer;
    background: var(--theme-container-divider);

    &:hover {
        background-color: var(--theme-container-main-blue);
        border-top: 1px solid var(--theme-container-selected-border);
        img {
            filter: brightness(2);
        }
    }

    img {
        transform: scale(0.85);
    }

    ${({$isActive}) =>
        $isActive &&
        `
    background-color: var(--theme-container-main-blue);
    border-top: 1px solid var(--theme-container-selected-border);
    img {
      filter: brightness(2);
    }
  `}
`;

export const TopContainer = styled.div`
    ${flexCenter};
    width: 100%;
    padding: 12px 8px;
    border-bottom: 1px solid var(--theme-container-divider);
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    font-weight: var(--theme-font-bold);
    font-size: var(--theme-font-size-xxs);
    color: white;
    height: 48px;
`;

export const TabTooltip = styled.div`
    display: none;
    position: absolute;
    text-align: center;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    min-width: 84px;
    padding: 0 6px;
    height: 17px;
    border-radius: 6px;
    box-shadow: 0px 4px 15px 0px #000;
    background-color: var(--theme-container-secondary-dark);
    box-sizing: border-box;
    font-size: var(--theme-font-size-xxs);
    z-index: 10;
`;

export const TabContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const UploadAssetsButton = styled.img`
    position: absolute;
    top: 2px;
    right: 0;
    cursor: pointer;
`;
