import * as THREE from "three";
import { Object3D } from "three";
import GameManager from "../../behaviors/game/GameManager";
import { IPhysics } from "src/physics/common/types";
import global from "../../global";
import { OBJECT_TYPES, COLLISION_TYPE } from "../../types/editor";
import EventBus from "../../behaviors/event/EventBus";

class WeaponAmmoBehaviorUpdater {
    target: Object3D;
    removed = false;
    gunAimerImageBase64: string | null = null;
    game?: GameManager;
    usingPhysics = false;
    isDynamic = false;
    physics?: IPhysics;
    isDebug: boolean;
    remainingAmmo: number;
    ammoCollisionListenerId: string | undefined;

    private totalAmmoCount: number = 0;
    private countedAmmo: Set<Object3D> = new Set();

    constructor(target: Object3D) {
        this.target = target;
        this.remainingAmmo = 0;
        this.isDebug = (global.app as any)?.storage?.debug;
    }


    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;
        this.addCollisionListener();
    }


    addCollisionListener() {
        this.ammoCollisionListenerId =
            this.game!.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: COLLISION_TYPE.WITH_PLAYER,
                    callback: this.onCollisionWithPlayer.bind(this),
                    useBoundingBoxes: false,
                    distanceThreshold: 1,
                },
                this.isDynamic
            );

    }

    onCollisionWithPlayer() {
        if (!this.game || !this.game.player || !this.game.scene) return;

        const weaponAmmoBehavior = this.target.userData.behaviors.find(
            (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON_AMMO
        );

        if (weaponAmmoBehavior) {
            EventBus.instance.send("game.lives.dec", Number(weaponAmmoBehavior.ammoDamage));
        }

    }

    update(clock: THREE.Clock, delta: number): void {

    }


    reset(): void {

        this.totalAmmoCount = 0;
        this.countedAmmo.clear();
        this.remainingAmmo = 0;

        this.countedAmmo.forEach((ammoObject) => {
            if (ammoObject.parent) {
                ammoObject.parent.remove(ammoObject);
            }
        });

        this.countedAmmo.clear();

    }


}

export default WeaponAmmoBehaviorUpdater;