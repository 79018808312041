import {flexCenter} from "../../../../../../assets/style";
import styled from "styled-components";

export const Grid = styled.div<{fullWidth?: boolean}>`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    justify-items: stretch;
    column-gap: 42px;
    padding: 0;
    position: relative;
    z-index: 2;
    overflow: hidden auto;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ButtonsColumn = styled.div`
    ${flexCenter};
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 24px;
    width: 100%;
    height: 100%;
    padding: 32px 0;

    .marginTop {
        margin-top: auto !important;
    }

    .bigButton {
        margin-bottom: 18px;
    }
`;
