import "./css/Viewport.css";
import global from "../../global";
import VRButton from "../../webvr/VRButton";
import React, {Component, createRef, RefObject} from "react";
import Stats from "stats.js";
import Application from "src/Application";
import * as THREE from "three";
/**
 * 视口
 * @autor tengge / https://github.com/tengge1
 */
interface ViewportProps {}

class Viewport extends Component<ViewportProps> {
    private viewportRef: RefObject<HTMLDivElement>;
    private editorRef: RefObject<HTMLDivElement>;
    private cesiumRef: RefObject<HTMLDivElement>;
    private svgRef: RefObject<HTMLDivElement>;
    private playerRef: RefObject<HTMLDivElement>;
    private vrButton?: HTMLButtonElement;
    private isDebug: boolean;
    private raycaster: THREE.Raycaster;
    private mouse: THREE.Vector2;

    constructor(props: ViewportProps) {
        super(props);

        this.viewportRef = createRef<HTMLDivElement>();
        this.editorRef = createRef<HTMLDivElement>();
        this.cesiumRef = createRef<HTMLDivElement>();
        this.svgRef = createRef<HTMLDivElement>();
        this.playerRef = createRef<HTMLDivElement>();
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();

        this.handleAppStarted = this.handleAppStarted.bind(this);
        this.handleOptionChanged = this.handleOptionChanged.bind(this);
        this.handleEnableVR = this.handleEnableVR.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.isDebug = (global.app as any)?.storage?.debug || false;
    }

    render() {
        return (
            <div className="Viewport" ref={this.viewportRef}>
                <div
                    className="editor"
                    ref={this.editorRef}
                    tabIndex={0}
                    onDrop={this.handleDrop} // Obsługa przeciągania i upuszczania
                    onDragOver={this.handleDragOver}
                />
                <div className="cesium" ref={this.cesiumRef} tabIndex={10} />
                <div className="player" ref={this.playerRef} />
            </div>
        );
    }

    componentDidMount() {
        if (global.app) {
            const app = global.app as Application;
            app.viewportRef = this.viewportRef.current;
            app.editorRef = this.editorRef.current;
            app.cesiumRef = this.cesiumRef.current;
            app.svgRef = this.svgRef.current;
            app.playerRef = this.playerRef.current;
            this.raycaster = new THREE.Raycaster();
            this.mouse = new THREE.Vector2();
            //show frame rate stats
            app.stats = new Stats();
            if (this.isDebug) {
                let showStats = app.storage.showStats;

                if (showStats === undefined) {
                    showStats = false;
                    app.storage.showStats = false;
                }

                Object.assign(app.stats.dom.style, {
                    position: "absolute",
                    top: "62px",
                    left: "8px",
                    zIndex: "100000",
                    display: "none",
                });

                document.body.appendChild(app.stats.dom);
            }

            app.viewport = this.editorRef.current as HTMLElement;

            global.app.on("appStarted", this.handleAppStarted);
            global.app.on("optionChange.Viewport", this.handleOptionChanged);
            global.app.on("enableVR.Viewport", this.handleEnableVR);
        }
    }

    componentWillUnmount() {
        const app = global.app as Application;
        app.on("appStarted", null);
        app.on("optionChange.Viewport", null);
        app.on("enableVR.Viewport", null);
    }

    handleAppStarted() {
        const app = global.app as Application;
        this.handleEnableVR(app.options.enableVR);
    }

    handleOptionChanged(key: string, value: any) {
        if (key !== "sceneType") {
            return;
        }
        if (value === "GIS") {
            // GIS
            if (this.editorRef.current && this.cesiumRef.current) {
                this.editorRef.current.style.display = "none";
                this.cesiumRef.current.style.display = "block";
            }
        } else {
            // Empty
            if (this.editorRef.current && this.cesiumRef.current) {
                this.cesiumRef.current.style.display = "none";
                this.editorRef.current.style.display = "block";
            }
        }
    }

    handleEnableVR(enabled: boolean) {
        const app = global.app as Application;
        const renderer = app.editor?.renderer;

        if (enabled) {
            if (!this.vrButton) {
                this.vrButton = VRButton.createButton(renderer) as HTMLButtonElement;
            }
            if (this.editorRef.current) {
                this.editorRef.current.appendChild(this.vrButton);
            }
        } else {
            if (this.vrButton && this.editorRef.current) {
                this.editorRef.current.removeChild(this.vrButton);
                delete this.vrButton;
            }
        }
    }

    handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const assetId = event.dataTransfer.getData("asset-id");
        const assetType = event.dataTransfer.getData("asset-type");
        const app = global.app as Application;
        if (assetId && app.editor && app.viewport) {
            const rect = app.viewport.getBoundingClientRect();
            if (!rect) return;

            this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

            this.raycaster.setFromCamera(this.mouse, app.editor.camera);

            const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
            const intersectPoint = new THREE.Vector3();

            this.raycaster.ray.intersectPlane(plane, intersectPoint);

            app.call("dragEnd", this, assetType, assetId, intersectPoint);
        }
    };

    handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault(); // Zapobiega domyślnemu zachowaniu przeglądarki
    };
}

export default Viewport;
