import React, {useCallback, useEffect, useState} from "react";
import "./css/IconsFlexContainer.css";
import {LIGHT_NAME, NEW_MISC_NAME} from "../LeftPanel/MainTabs/AssetsTab/SubTabs/LightsTab";
import {PRIMITIVES_NAME} from "../LeftPanel/MainTabs/AssetsTab/SubTabs/PrimitivesTab";
import {debounce} from "lodash";

interface IList {
    icon?: any;
    text: string;
    name: LIGHT_NAME | NEW_MISC_NAME | PRIMITIVES_NAME;
}

interface Props {
    list: IList[];
    onSelectItem: (name: any) => void;
    disableSelection?: boolean;
    draggable?: boolean;
    onDragStart?: (e: React.DragEvent<HTMLDivElement>, name: string) => void;
}

export const IconsFlexContainer = ({list, onSelectItem, disableSelection, draggable, onDragStart}: Props) => {
    const [selected, setSelected] = useState<string[]>([]);

    const handleClick = useCallback(
        debounce((item: IList) => {
            const {name} = item;
            // Allow only 1 fog to have selected state
            if (name === NEW_MISC_NAME.LINEAR_FOG || name === NEW_MISC_NAME.EXPONENTIAL_FOG) {
                const isSelectedLinear = name === NEW_MISC_NAME.LINEAR_FOG;
                const itemToRemove = isSelectedLinear ? NEW_MISC_NAME.EXPONENTIAL_FOG : NEW_MISC_NAME.LINEAR_FOG;
                setSelected(prevSelected => {
                    const updatedSelected = [...prevSelected, name];
                    return updatedSelected.filter(item => item !== itemToRemove);
                });
                onSelectItem(name);
                return;
            }

            setSelected(prev => [...prev, name]);
            onSelectItem(name);
        }, 200),
        [],
    );

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, name: string) => {
        if (draggable) {
            onDragStart && onDragStart(e, name);
        }
    };

    useEffect(() => {
        return () => {
            handleClick.cancel();
        };
    }, [handleClick]);

    return (
        <div className="IconOptionsFlex hidden-scroll">
            {list.map((item, index) => {
                const {text, icon, name} = item;
                const indexOf = selected.indexOf(name);
                const currentIsSelected = indexOf !== -1;
                return (
                    <div
                        className="icon-container"
                        key={text + index}
                        onClick={() => handleClick(item)}
                        draggable={!!draggable}
                        onDragStart={e => handleDragStart(e, name)}>
                        <div className="icon-wrapper">
                            {icon && (
                                <img
                                    className={`${
                                        !disableSelection && currentIsSelected && "icon-img-selected"
                                    } icon-img`}
                                    src={icon as unknown as string}
                                    alt=""
                                />
                            )}
                        </div>
                        <div
                            className={`${!disableSelection && currentIsSelected && "icon-text-selected"} icon-text`}
                            dangerouslySetInnerHTML={{__html: text}}
                        />
                    </div>
                );
            })}
        </div>
    );
};
