import {Object3D} from "three";
import {ConsumableBehaviorInterface} from "../../types/editor";
import CollectableBehaviorUpdaterBase from "./CollectableBehaviorUpdaterBase";

export default class ConsumableBehaviorUpdater extends CollectableBehaviorUpdaterBase {
    constructor(target: Object3D, behavior: ConsumableBehaviorInterface) {
        super(target, behavior);
        this.target = target;
    }
}
