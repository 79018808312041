import styled, {css} from "styled-components";
import {regularFont} from "../../../../../../../assets/style";

export const List = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    transform: translate(-124%, 40%);

    display: flex;
    flex-direction: column;
    gap: 0;
    width: 216px;
    height: 304px;
    padding: 8px;
    overflow: hidden auto;

    background: var(--theme-container-minor-dark);
    box-shadow: 0px 4px 15px 0px #000000;
    border: 1px solid #ffffff1a;
    border-radius: 16px;
    z-index: 100;
`;

export const ListItem = styled.div<{$inactive?: boolean}>`
    padding: 8px;
    width: 100%;
    ${regularFont("xxs")};
    color: var(--theme-font-unselected-color);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #262626;
        color: #fff;
        font-weight: var(--theme-font-medium-plus);
    }
    ${({$inactive}) =>
        $inactive &&
        css`
            opacity: 0.6;
            pointer-events: none;
        `}
`;
