import {useEffect, useState} from "react";
import {TriggerBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {StyledButton} from "../../common/StyledButton";
import {SelectRow} from "../common/SelectRow";
import {fetchModels} from "../../../../../v2/pages/services";
import {Label} from "../RightPanel.style";
import {SelectionOfButtons} from "../common/SelectionOfButtons";

type Props = {
    behavior: TriggerBehaviorInterface;
};

interface IOptions {
    key: string;
    value: string;
}
type InputChangeType = (value: any, name: keyof TriggerBehaviorInterface) => void;

export const TriggerBehaviors = ({behavior}: Props) => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor?.selected;
    const [objectOptions, setObjectOptions] = useState<IOptions[]>([{key: "0", value: "none"}]);
    const [currentBehavior, setCurrentBehavior] = useState<TriggerBehaviorInterface>(behavior);

    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            const objBehavior = obj.userData.behaviors?.find((userBehavior: any) => userBehavior.id === behavior.id);

            return objBehavior as TriggerBehaviorInterface;
        }
        return null;
    };

    const handleInputChange: InputChangeType = (value, name) => {
        if (!app || !selected) return;

        const objBehavior = getObjectBehavior();

        if (objBehavior) {
            (objBehavior as any)[name] = value;
            const newBehavior = {...objBehavior, [name]: value};
            setCurrentBehavior(newBehavior);

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    useEffect(() => {
        const getObjects = async () => {
            const options = ["none"];
            const res = await fetchModels();
            res?.forEach(object => {
                options.push(object.Name);
            });
            setObjectOptions(
                options.map((option, index) => {
                    return {
                        key: `${index + 1}`,
                        value: option,
                    };
                }),
            );
        };
        getObjects();
    }, []);

    return (
        <>
            <Label $regular>If:</Label>
            <SelectionOfButtons>
                <StyledButton
                    width="109px"
                    isBlue={currentBehavior.player_touches}
                    isActive={!currentBehavior.player_touches}
                    onClick={() => {
                        handleInputChange(true, "player_touches");
                        handleInputChange(false, "object_touches");
                    }}>
                    <span>Player Touches</span>
                </StyledButton>
                <StyledButton
                    width="109px"
                    isBlue={currentBehavior.object_touches}
                    isActive={!currentBehavior.object_touches}
                    onClick={() => {
                        handleInputChange(true, "object_touches");
                        handleInputChange(false, "player_touches");
                    }}>
                    <span>Object Touches</span>
                </StyledButton>
                <Separator invisible margin="12px 0 0" />
            </SelectionOfButtons>
            <Label $regular>Then:</Label>
            <CommonFields
                objectOptions={objectOptions}
                handleInputChange={handleInputChange}
                behavior={currentBehavior}
            />
            <PanelCheckbox
                text="Else:"
                white
                checked={!!currentBehavior.else_condition}
                onChange={() => handleInputChange(!behavior.else_condition, "else_condition")}
            />
            {!currentBehavior.else_condition && <Separator margin="12px 0 0" />}
            {currentBehavior.else_condition && (
                <CommonFields
                    objectOptions={objectOptions}
                    handleInputChange={handleInputChange}
                    behavior={currentBehavior}
                    elseSelected
                />
            )}
        </>
    );
};

interface CommonFieldsProps {
    objectOptions: IOptions[];
    handleInputChange: InputChangeType;
    behavior: TriggerBehaviorInterface;
    elseSelected?: boolean;
}
const CommonFields = ({objectOptions, handleInputChange, behavior, elseSelected}: CommonFieldsProps) => {
    const behaviorActivate = elseSelected ? behavior.else_activate : behavior.then_activate;
    const behaviorObject = elseSelected ? behavior.else_object : behavior.then_object;
    const behaviorAnimation = elseSelected ? behavior.else_animation : behavior.then_animation;
    const behaviorGenericSound = elseSelected ? behavior.else_generic_sound : behavior.then_generic_sound;
    const behaviorEnemy = elseSelected ? behavior.else_enemy : behavior.then_enemy;
    return (
        <>
            <SelectionOfButtons>
                <StyledButton
                    width="109px"
                    isBlue={behaviorActivate}
                    isActive={!behaviorActivate}
                    onClick={() =>
                        handleInputChange(!behaviorActivate, elseSelected ? "else_activate" : "then_activate")
                    }>
                    <span>Activate</span>
                </StyledButton>
                <StyledButton
                    width="109px"
                    isBlue={!behaviorActivate}
                    isActive={behaviorActivate}
                    onClick={() =>
                        handleInputChange(!behaviorActivate, elseSelected ? "else_activate" : "then_activate")
                    }>
                    <span>De-Activate</span>
                </StyledButton>
            </SelectionOfButtons>
            <SelectRow
                $margin={"12px 0"}
                label="This Object"
                value={objectOptions.find(el => el.value === behaviorObject)}
                onChange={item => handleInputChange(item.value, elseSelected ? "else_object" : "then_object")}
                data={objectOptions}
            />

            <PanelCheckbox
                text="Animation"
                checked={!!behaviorAnimation}
                onChange={() =>
                    handleInputChange(!behaviorAnimation, elseSelected ? "else_animation" : "then_animation")
                }
            />
            <Separator invisible margin="12px 0 0" />
            <PanelCheckbox
                text="Generic Sound"
                checked={!!behaviorGenericSound}
                onChange={() =>
                    handleInputChange(!behaviorGenericSound, elseSelected ? "else_generic_sound" : "then_generic_sound")
                }
            />
            <Separator invisible margin="12px 0 0" />
            <PanelCheckbox
                text="Enemy"
                checked={!!behaviorEnemy}
                onChange={() => handleInputChange(!behaviorEnemy, elseSelected ? "else_enemy" : "then_enemy")}
            />
            <Separator invisible margin="12px 0 0" />
        </>
    );
};
