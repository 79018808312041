import React, {useEffect, useState} from "react";
import {
    FONT_FAMILIES,
    IN_GAME_MENU_IDS,
    InGameMenuDataType,
    START_MENU_BUTTON_TYPES,
} from "../editor/assets/v2/HUD/HUDEditView/types";
// import logoExample from "../editor/assets/v2/HUD/HUDEditView/images/game-logo-example.png";
// import {drawLogoFromSceneName} from "./utils/drawLogoFromScene";
import global from "../global";

type HUDInGameMenuContextValue = {
    inGameMenuLayout: InGameMenuDataType | undefined;
    setInGameMenuLayout: React.Dispatch<React.SetStateAction<InGameMenuDataType | undefined>>;
    isReady: boolean;
    useInitialSetup: () => void;
};

export const HUDInGameMenuContext = React.createContext<HUDInGameMenuContextValue>(null!);

export interface HUDInGameMenuContextProviderProps {
    children: React.ReactNode;
    isReady: boolean;
}

const placeholder = {
    [IN_GAME_MENU_IDS.GAME_BUTTON_MID_1]: {
        UIButtonType: START_MENU_BUTTON_TYPES.START_GAME,
        radius: 8,
        buttonColor: "white",
        fontColor: "black",
        fontFamily: FONT_FAMILIES.ROBOTO,
        fontSize: 18,
    },
};

const HUDInGameMenuContextProvider: React.FC<HUDInGameMenuContextProviderProps> = ({children, isReady}) => {
    const [inGameMenuLayout, setInGameMenuLayout] = useState<InGameMenuDataType>();
    const app = global.app as any;
    const sceneId = app?.editor?.scene.uuid;

    useEffect(() => {
        if (app?.editor && isReady && sceneId) {
            const gameHUD = app?.editor.scene.userData?.gameUI?.inGameMenu;

            if (!gameHUD || Object.keys(gameHUD).length === 0) {
                // const logo = drawLogoFromSceneName();
                // const newLogo = logo || logoExample;
                setInGameMenuLayout({
                    ...placeholder,
                    // [IN_GAME_MENU_IDS.LOGO_MID]: newLogo,
                });
                if (app?.editor.scene.userData) {
                    app.editor.scene.userData.gameUI = {
                        ...app?.editor.scene.userData.gameUI,
                        inGameMenu: {
                            ...placeholder,
                            // [IN_GAME_MENU_IDS.LOGO_MID]: newLogo,
                        },
                    };
                    app.editor.scene.userData.isStartGameMenuDefaultBanner = true;
                }
            } else {
                setInGameMenuLayout(app?.editor.scene.userData?.gameUI?.inGameMenu);
            }
        }
    }, [isReady, sceneId]);

    const useInitialSetup = () => {
        if (app?.editor && isReady && sceneId && !inGameMenuLayout) {
            setInGameMenuLayout(app?.editor.scene.userData?.gameUI?.inGameMenu);
        }
    };

    useEffect(() => {
        app.on("clear.HUDInGameMenuContext", () => {
            if (app?.editor?.scene.userData?.isInGameMenuDefaultBanner) {
                setInGameMenuLayout({
                    ...app?.editor.scene.userData.gameUI.inGameMenu,
                });

                app.editor.scene.userData.gameUI = {
                    ...app?.editor.scene.userData.gameUI,
                    inGameMenu: {
                        ...app?.editor.scene.userData.gameUI.inGameMenu,
                    },
                };
            }
        });
    }, []);

    return (
        <HUDInGameMenuContext.Provider
            value={{
                inGameMenuLayout,
                setInGameMenuLayout,
                isReady,
                useInitialSetup,
            }}>
            {children}
        </HUDInGameMenuContext.Provider>
    );
};

export default HUDInGameMenuContextProvider;
