import { CheckboxWrapper, DefaultWrapper } from "../styles/Behaviors.style";
import { Checkbox } from "../../../../../ui/common/Checkbox";
import { NumericInput } from "../../common/NumericInput";
import { ThirdPersonOptions } from "./character/ThirdPersonOptions";
import { SideScrollerOptions } from "./character/SideScrollerOptions";
import { useState } from "react";
import { VehicleOptions } from "./character/VehicleOptions";
import global from "../../../../../global";
import { CHARACTER_TYPES, CharacterBehaviorInterface, OBJECT_TYPES } from "../../../../../types/editor";
import { PanelCheckbox } from "../common/PanelCheckbox";
import { NumericInputRow } from "../common/NumericInputRow";

type Props = {
    behavior: CharacterBehaviorInterface;
};

export const CharacterBehaviors = ({ behavior }: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behaviorState, setBehaviorState] = useState(behavior);

    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            const objBehavior = obj.userData.behaviors?.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.CHARACTER,
            );

            return objBehavior;
        }
        return null;
    };

    const handleInputChange = (value: any, name: string) => {
        if (selected) {
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
                objBehavior[name] = value; // Update object's behavior directly
            }
            setBehaviorState({ ...objBehavior, [name]: value }); // Update the component state

            app.call('objectChanged', app.editor, app.editor.selected); // Trigger object change
            app.call('objectUpdated', app.editor, app.editor.selected); // Trigger object update
        }
    };

    const handleCharacterTypeChange = (type: CHARACTER_TYPES) => {
        const objBehavior = getObjectBehavior();
        if (objBehavior) {
            objBehavior.control = type; // Update object's control type
            setBehaviorState({ ...objBehavior }); // Update the component state

            app.call('objectChanged', app.editor, app.editor.selected); // Trigger object change
            app.call('objectUpdated', app.editor, app.editor.selected); // Trigger object update
        }
    };

    return (
    
        <>
            {Object.keys(CHARACTER_TYPES).map(key => {
                const type = CHARACTER_TYPES[key as unknown as keyof typeof CHARACTER_TYPES];

                return (
                    <CheckboxWrapper key={key} style={{ margin: 0 }}>
                        <Checkbox
                            customId={type}
                            checked={behaviorState.control === type}
                            onChange={() => handleCharacterTypeChange(type)}
                        />
                        <span>{type}</span>
                    </CheckboxWrapper>
                );
            })}
           
            <DefaultWrapper>
                    Health
                    <NumericInputRow
                        label="Game Health"
                        value={behaviorState.health}
                        setValue={value => handleInputChange(value, "health")}
                    />
            </DefaultWrapper>
            <DefaultWrapper>
                Weapons
                <PanelCheckbox
                    text="Pick Up Weapons"
                    checked={!!behaviorState.pickUpWeapons}
                    onChange={() => handleInputChange(!behaviorState.pickUpWeapons, "pickUpWeapons")}
                />
            </DefaultWrapper>

            {behaviorState.control === CHARACTER_TYPES.FIRST_PERSON && <ThirdPersonOptions />}
            {behaviorState.control === CHARACTER_TYPES.THIRD_PERSON && <ThirdPersonOptions />}
            {behaviorState.control === CHARACTER_TYPES.FORTNITE && <ThirdPersonOptions />}
            {behaviorState.control === CHARACTER_TYPES.VEHICLE && <VehicleOptions />}
            {behaviorState.control === CHARACTER_TYPES.SIDE_SCROLLER && <SideScrollerOptions />}
        </>
    );
};
