import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import CameraBehaviorUpdater from "./CameraBehaviorUpdater";
import {
    CAMERA_EFFECTS,
    CAMERA_OBJECT_INTERACTION,
    CAMERA_TYPES,
    CameraBehaviorInterface,
    OBJECT_TYPES,
} from "../../types/editor";

class CameraBehaviorConverter extends BaseBehaviorConverter<CameraBehaviorInterface> {
    public static DEFAULT = new CameraBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: CameraBehaviorInterface): BehaviorUpdater {
        return new CameraBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, id: string): CameraBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as CameraBehaviorInterface;
        const isCameraBehaviorInterface = "objectInteraction" in behavior && "enabled" in behavior;
        if (!behavior || !isCameraBehaviorInterface) {
            console.warn("Object doesn't have CAMERA behavior: ", target);
            return this.getDefaultBehavior(id);
        }

        return behavior;
    }

    getDefaultBehavior(id: string): CameraBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.CAMERA,
            objectInteraction: CAMERA_OBJECT_INTERACTION.TRANSPARENT,
            playerCollisionBox: 2,
            cameraHeadHigh: 1,
            cameraEffect: CAMERA_EFFECTS.NONE,
            cameraType: CAMERA_TYPES.FIRST_PERSON,
        };
    }
}

export default CameraBehaviorConverter;
