import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {CharacterSoundsBehaviorInterface, OBJECT_TYPES} from "../../types/editor";

class CharacterSoundsBehaviorConverter extends BaseBehaviorConverter<CharacterSoundsBehaviorInterface> {
    public static DEFAULT = new CharacterSoundsBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: any): any {}
    getBehavior(target: THREE.Object3D, behaviorId: string): CharacterSoundsBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, behaviorId) as CharacterSoundsBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have CHARACTER_SOUNDS behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): CharacterSoundsBehaviorInterface {
        return {
            type: OBJECT_TYPES.CHARACTER_SOUNDS,
            jumping: {id: "", name: "", url: ""},
            walking: {id: "", name: "", url: ""},
            running: {id: "", name: "", url: ""},
            dying: {id: "", name: "", url: ""},
            volume: 50,
            enabled: true,
            id,
        };
    }
}

export default CharacterSoundsBehaviorConverter;
