import {useState} from "react";
import {
    CAMERA_EFFECTS,
    CAMERA_OBJECT_INTERACTION,
    CAMERA_TYPES,
    CameraBehaviorInterface,
} from "../../../../../types/editor";
import global from "../../../../../global";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {Separator} from "../common/Separator";
import {SelectRow} from "../common/SelectRow";
import {NumericInputRow} from "../common/NumericInputRow";
import {StyledButton} from "../../common/StyledButton";
import {Heading} from "../common/Heading";
import CameraBehaviorConverter from "../../../../../serialization/behaviours/CameraBehaviorConverter";

const CAMERA_EFFECTS_OPTIONS = [
    {value: CAMERA_EFFECTS.PIXEL, key: "pixel"},
    {value: CAMERA_EFFECTS.BOKEH, key: "bokeh"},
    {value: CAMERA_EFFECTS.RGB, key: "rgb"},
    {value: CAMERA_EFFECTS.NONE, key: "none"},
];

type Props = {
    behavior: CameraBehaviorInterface;
};

export const CameraBehaviors = ({behavior}: Props) => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor?.selected;
    const [behaviorState, setBehaviorState] = useState(behavior);
    const targetBehavior = CameraBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleInputChange = (value: any, name: keyof CameraBehaviorInterface) => {
        if (!app || !selected || !targetBehavior) return;

        (targetBehavior as any)[name] = value;
        setBehaviorState({...targetBehavior, [name]: value});

        app.call(`objectChanged`, app.editor, app.editor.selected);
        app.call(`objectUpdated`, app.editor, app.editor.selected);
    };

    return (
        <>
            <SelectionOfButtons>
                {Object.keys(CAMERA_TYPES).map(key => {
                    const type = CAMERA_TYPES[key as unknown as keyof typeof CAMERA_TYPES];
                    const isSelected =
                        behaviorState.cameraType === type ||
                        (!behaviorState.cameraType && type === CAMERA_TYPES.FIRST_PERSON);
                    return (
                        <StyledButton
                            width="100px"
                            isBlue={isSelected}
                            isActive={behaviorState.cameraType !== type}
                            onClick={() => handleInputChange(type, "cameraType")}
                            key={key}>
                            <span>{type}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
            <Separator />
            <SelectRow
                $margin={"0 0 12px 0"}
                label="Camera Effect"
                value={CAMERA_EFFECTS_OPTIONS.find(el => el.value === behaviorState.cameraEffect)}
                onChange={item => handleInputChange(item.value, "cameraEffect")}
                data={CAMERA_EFFECTS_OPTIONS}
            />
            {behaviorState.cameraType === CAMERA_TYPES.FIRST_PERSON && (
                <>
                    <NumericInputRow
                        width="75px"
                        label="Camera Head Height"
                        value={behaviorState.cameraHeadHigh || 0}
                        setValue={value => handleInputChange(value, "cameraHeadHigh")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Player Collision Box"
                        value={behaviorState.playerCollisionBox || 0}
                        setValue={value => handleInputChange(value, "playerCollisionBox")}
                    />
                </>
            )}
            {(behaviorState.cameraType === CAMERA_TYPES.THIRD_PERSON ||
                behaviorState.cameraType === CAMERA_TYPES.TOP_DOWN ||
                behaviorState.cameraType === CAMERA_TYPES.SIDE_SCROLLER) && (
                <>
                    <NumericInputRow
                        width="75px"
                        label="Camera Min Distance"
                        value={behaviorState.cameraMINDistance || 0}
                        setValue={value => handleInputChange(value, "cameraMINDistance")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Camera Max Distance"
                        value={behaviorState.cameraMaxDistance || 0}
                        setValue={value => handleInputChange(value, "cameraMaxDistance")}
                    />
                    {behaviorState.cameraType === CAMERA_TYPES.SIDE_SCROLLER ? (
                        <NumericInputRow
                            width="75px"
                            label="Camera Axis"
                            value={behaviorState.cameraAxis || 0}
                            setValue={value => handleInputChange(value, "cameraAxis")}
                        />
                    ) : (
                        <NumericInputRow
                            width="75px"
                            label="Camera Angle"
                            value={behaviorState.cameraAngle || 0}
                            setValue={value => handleInputChange(value, "cameraAngle")}
                        />
                    )}
                </>
            )}
            {behaviorState.cameraType === CAMERA_TYPES.THIRD_PERSON && (
                <>
                    <NumericInputRow
                        width="75px"
                        label="Camera Min Height"
                        value={behaviorState.cameraMinHeight || 0}
                        setValue={value => handleInputChange(value, "cameraMinHeight")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Camera Max Height"
                        value={behaviorState.cameraMaxHeight || 0}
                        setValue={value => handleInputChange(value, "cameraMaxHeight")}
                    />
                </>
            )}
            <Heading margin="4px 0 0">Object Interaction</Heading>
            <SelectionOfButtons margin="12px auto 0">
                <StyledButton
                    width="109px"
                    isBlueSecondary={behaviorState.objectInteraction === CAMERA_OBJECT_INTERACTION.TRANSPARENT}
                    isActive={behaviorState.objectInteraction !== CAMERA_OBJECT_INTERACTION.TRANSPARENT}
                    onClick={() => handleInputChange(CAMERA_OBJECT_INTERACTION.TRANSPARENT, "objectInteraction")}>
                    <span>Transparent</span>
                </StyledButton>
                <StyledButton
                    width="109px"
                    isBlueSecondary={
                        behaviorState.objectInteraction === CAMERA_OBJECT_INTERACTION.ZOOM ||
                        !behaviorState.objectInteraction
                    }
                    isActive={behaviorState.objectInteraction !== CAMERA_OBJECT_INTERACTION.ZOOM}
                    onClick={() => handleInputChange(CAMERA_OBJECT_INTERACTION.ZOOM, "objectInteraction")}>
                    <span>Zoom</span>
                </StyledButton>
            </SelectionOfButtons>
        </>
    );
};
