import {useEffect, useState} from "react"; // Import useState
import {OBJECT_TYPES, WeaponAmmoBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import {Separator} from "../common/Separator";
import {NumericInputRow} from "../common/NumericInputRow";
import {getDefaultWeaponAmmoBehavior} from "./helpers/getDefaultWeaponAmmoBehavior";

type Props = {
    behavior?: WeaponAmmoBehaviorInterface;
};

export const WeaponAmmoBehaviors = ({behavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;

    const [currentBehavior, setCurrentBehavior] = useState<WeaponAmmoBehaviorInterface>(getDefaultWeaponAmmoBehavior());

    useEffect(() => {
        if (selected) {
            const defaultBehavior = getDefaultWeaponAmmoBehavior();
            const mergedBehavior = {
                ...defaultBehavior,
                ...behavior,
            };
            setCurrentBehavior(mergedBehavior);
            Object.keys(defaultBehavior).forEach(key => {
                handleInputChange(
                    mergedBehavior[key as keyof WeaponAmmoBehaviorInterface],
                    key as keyof WeaponAmmoBehaviorInterface,
                );
            });
        }
    }, [selected, behavior]);

    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            const objBehavior = obj.userData.behaviors?.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON_AMMO,
            );
            return objBehavior;
        }
        return null;
    };

    const handleInputChange = (value: any, name: keyof WeaponAmmoBehaviorInterface) => {
        if (selected) {
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
                objBehavior[name] = value;
            }

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <NumericInputRow
                width="75px"
                label="Ammo Damage"
                value={currentBehavior.ammoDamage}
                setValue={value => handleInputChange(value, "ammoDamage")}
            />
            <NumericInputRow
                width="75px"
                label="Time Ammo Shows"
                value={currentBehavior.ammoLife}
                setValue={value => handleInputChange(value, "ammoLife")}
            />
            <NumericInputRow
                width="75px"
                label="Ammo Mass"
                value={currentBehavior.ammoMass}
                setValue={value => handleInputChange(value, "ammoMass")}
            />
            <NumericInputRow
                width="75px"
                label="Ammo Speed"
                value={currentBehavior.ammoSpeed}
                setValue={value => handleInputChange(value, "ammoSpeed")}
            />
            <NumericInputRow
                width="75px"
                label="Friction"
                value={currentBehavior.ammoFriction}
                setValue={value => handleInputChange(value, "ammoFriction")}
            />
            <NumericInputRow
                width="75px"
                label="Inertia"
                value={currentBehavior.ammoInertia}
                setValue={value => handleInputChange(value, "ammoInertia")}
            />
            <NumericInputRow
                width="75px"
                label="Restitution"
                value={currentBehavior.ammoRestitution}
                setValue={value => handleInputChange(value, "ammoRestitution")}
            />
            <NumericInputRow
                width="75px"
                label="Bounce Effect"
                value={currentBehavior.ammoBounceEffect}
                setValue={value => handleInputChange(value, "ammoBounceEffect")}
            />

            <Separator margin="4px 0" />
        </>
    );
};
