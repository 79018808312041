import React, {useEffect, useState} from "react";
import global from "../global";

interface AnimationContextValue {
    animationsState: any;
    setAnimationsState: React.Dispatch<React.SetStateAction<any>>;
}

export const AnimationContext = React.createContext<AnimationContextValue>(
    null!,
);

export interface AnimationContextProviderProps {
    children: React.ReactNode;
    isReady: boolean;
}

const AnimationContextProvider: React.FC<AnimationContextProviderProps> = ({
    children,
    isReady,
}) => {
    const app = (global as any).app as any;
    const [animationsState, setAnimationsState] = useState({
        animations: app.editor?.animations || [],
        selectedAnimation: app.editor?.selectedAnimation || null,
    });
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (!isInitialized && isReady) {
            setAnimationsState({
                animations: app.editor?.animations,
                selectedAnimation: app.editor?.selectedAnimation || null,
            });
            setIsInitialized(true);
        }
    }, [isInitialized, isReady]);

    useEffect(() => {
        if (animationsState && isReady) {
            app.editor.animations = animationsState.animations;
            app.editor.selectedAnimation = animationsState.selectedAnimation;
        }
    }, [animationsState, isReady]);

    return (
        <AnimationContext.Provider
            value={{
                animationsState,
                setAnimationsState,
            }}>
            {children}
        </AnimationContext.Provider>
    );
};

export default AnimationContextProvider;
