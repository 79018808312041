import React, {useEffect} from "react";
import classNames from "classnames";
import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../assets/style";
import {debounce} from "lodash";

type Props = {
    children?: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    style?: React.CSSProperties;
    className?: string;
    id?: string;
    disabled?: boolean;
    ref?: React.Ref<HTMLButtonElement>;
    isActive?: boolean;
    isBlue?: boolean;
    isGrey?: boolean;
    isGreySecondary?: boolean;
    isGreyTertiary?: boolean;
    isBlueSecondary?: boolean;
    isGreyBlue?: boolean;
    width?: string;
    height?: string;
    margin?: string;
};

export const StyledButton = ({
    children,
    onClick,
    style,
    className,
    disabled,
    ref,
    id,
    isActive,
    isBlue,
    isBlueSecondary,
    isGrey,
    isGreySecondary,
    isGreyTertiary,
    isGreyBlue,
    width,
    height,
    margin,
}: Props) => {
    const debouncedOnClick = onClick ? debounce(onClick, 200) : undefined;

    useEffect(() => {
        return () => {
            debouncedOnClick?.cancel();
        };
    }, [debouncedOnClick]);

    return (
        <CommonButton
            ref={ref}
            className={classNames("StyledButton", className)}
            onClick={debouncedOnClick}
            style={style}
            disabled={disabled}
            id={id}
            $isActive={isActive}
            $isBlue={isBlue}
            $isBlueSecondary={isBlueSecondary}
            $isGrey={isGrey}
            $isGreySecondary={isGreySecondary}
            $isGreyTertiary={isGreyTertiary}
            $isGreyBlue={isGreyBlue}
            $width={width}
            $height={height}
            $margin={margin}>
            {children}
        </CommonButton>
    );
};

export const CommonButton = styled.button<{
    $isActive?: boolean;
    $isBlue?: boolean;
    $isBlueSecondary?: boolean;
    $isGrey?: boolean;
    $isGreySecondary?: boolean;
    $isGreyTertiary?: boolean;
    $isGreyBlue?: boolean;
    $width?: string;
    $margin?: string;
    disabled?: boolean;
    $height?: string;
}>`
    margin: ${({$margin}) => ($margin ? `${$margin}` : 0)};
    background: transparent;
    border: none;
    width: ${({$width}) => $width || "100%"};
    height: ${({$height}) => $height || "32px"};
    padding: 8px;
    border-radius: 8px;
    ${flexCenter};
    column-gap: 4px;
    transition: all 0.2s;
    cursor: pointer;
    ${regularFont("xxs")};
    font-weight: var(--theme-font-medium);
    color: white;
    pointer-events: all;

    &:disabled {
        cursor: not-allowed !important;
    }

    &:hover {
        background: var(--theme-container-divider);
    }

    ${({$isActive}) =>
        $isActive &&
        `
    background: var(--theme-grey-bg);
  `}
    ${({$isBlue}) =>
        $isBlue &&
        `
    background: var(--theme-container-main-blue);
    border-top: 1px solid var(--theme-container-main-blue-border);
    &:hover {
      background: var(--theme-container-hover-blue);
      border-top: 1px solid var(--theme-container-hover-blue-border);
    }
    &:active {
      background: var(--theme-container-active-blue);
      border-top: 1px solid var(--theme-container-active-blue-border);
    }
  `}
  ${({$isBlueSecondary}) =>
        $isBlueSecondary &&
        `
  background: #435A70;
  border-top: none;
  &:hover {
      background: #435A70;
    }
    `}
    
    ${({$isGrey}) =>
        $isGrey &&
        `
    background: var(--theme-grey-bg-button);
    border-top: none;
    &:hover {
        background-color: var(--theme-grey-bg-button-hover) !important;
    }
    &:active {
        background-color: var(--theme-grey-bg-button-active) !important;
    }
  `}

  ${({$isGreySecondary}) =>
        $isGreySecondary &&
        `
    background: var(--theme-grey-bg-secondary-button);
     border-top: 1px solid #52525B;
    &:hover {
        background-color: var(--theme-grey-bg-secondary-button-hover) !important;
    }
    &:active {
        background-color: var(--theme-grey-bg-secondary-button-active) !important;
    }
  `}
  ${({$isGreyTertiary}) =>
        $isGreyTertiary &&
        `
    background: var(--theme-grey-bg-tertiary-button);
     border-top: none;
    &:hover {
        background-color: var(--theme-grey-bg-tertiary-button-hover) !important;
    }
    &:active {
        background-color: var(--theme-grey-bg-tertiary-button-active) !important;
    }
  `}


    ${({$isGreyBlue}) =>
        $isGreyBlue &&
        `
    background: var(--theme-grey-bg);
    border-top: none;
    color: #AEAEAE;
    &:hover {
        background: var(--theme-container-main-blue) !important;
        color: white;
    }
    &:active {
        background: var(--theme-container-main-blue) !important;
        color: white;
    }
    `}

  ${({disabled}) =>
        disabled &&
        `
    cursor: not-allowed;
    opacity: 0.7;
  `}
`;
