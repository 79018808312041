import {useEffect, useState} from "react";
import styled from "styled-components";

import successIcon from "./images/success.svg";
import errorIcon from "./images/error.svg";
import {regularFont} from "../../../assets/style";

import {useAuthorizationContext} from "../../../context";
import {TextInput} from "../../../editor/assets/v2/common/TextInput";
import {StyledButton} from "../../../editor/assets/v2/common/StyledButton";
import {Separator} from "../../../editor/assets/v2/RightPanel/common/Separator";
import {Bottom, LoginBox, Top} from "./LoginPage.style";

interface Props {
    onCorrectAuth: () => void;
}

export const Onboarding = ({onCorrectAuth}: Props) => {
    const {validateUsername, saveUsernameInFirebase} = useAuthorizationContext();
    const [username, setUsername] = useState("");
    const [isUsernameValid, setIsUsernameValid] = useState(false);
    const [showValidationBorder, setShowValidationBorder] = useState(false);

    useEffect(() => {
        const handlevalidateUsername = async () => {
            const usernameAvailable = await validateUsername(username);
            setIsUsernameValid(usernameAvailable);
            setShowValidationBorder(true);
        };
        if (username.length >= 3) {
            handlevalidateUsername();
        } else {
            setIsUsernameValid(false);
        }
    }, [username]);

    const saveUsername = async () => {
        const success = await saveUsernameInFirebase(username);
        success && onCorrectAuth();
    };

    return (
        <LoginBox>
            <Top>
                <div className="logo">Welcome to Erth.ai</div>
                <div className="pageName">What should we call you</div>
                <div className="description">Set your username below</div>
            </Top>
            <Bottom>
                <InputWrapper>
                    <UsernameInput
                        value={username}
                        setValue={value => setUsername(value)}
                        height="40px"
                        placeholder="Username"
                        $valueCorrect={showValidationBorder ? isUsernameValid : undefined}
                    />
                    {showValidationBorder && (
                        <img
                            className="icon"
                            src={isUsernameValid ? successIcon : errorIcon}
                            alt={isUsernameValid ? "username is available" : "username is not available"}
                        />
                    )}
                </InputWrapper>
                {!isUsernameValid && showValidationBorder && (
                    <ErrorMessage>Sorry! That username is already taken.</ErrorMessage>
                )}
                <Separator margin="16px 0 0 0" invisible />
                <StyledButton width="100%" isBlue={true} disabled={!isUsernameValid} onClick={saveUsername}>
                    Next
                </StyledButton>
            </Bottom>
        </LoginBox>
    );
};

const InputWrapper = styled.div`
    position: relative;
    .icon {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const ErrorMessage = styled.div`
    ${regularFont("xxs")};
    color: var(--theme-font-unselected-secondary-color);
    margin-top: 8px;
`;

const UsernameInput = styled(TextInput)<{$valueCorrect?: boolean}>`
    font-size: var(--theme-font-size-s) !important;
    font-weight: var(--theme-font-bold);
    color: var(--theme-font-main-selected-color);
    &::placeholder {
        font-weight: var(--theme-font-medium);
    }

    ${({$valueCorrect}) => $valueCorrect && "border: 2px solid #34D399;"};
    ${({$valueCorrect}) => $valueCorrect === false && "border: 2px solid #FB7185;"};
`;
