import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {AppRouter} from "./AppRouter.js";
import AuthorizationContextProvider from "./context/AuthorizationContext.js";
import HUDContextProvider from "./context/HUDContext.js";
import HomepageContextProvider from "./context/HomepageContext.js";
import AppGlobalContextProvider from "./context/AppGlobalContext.js";

export const AppContainer = () => {
    return (
        <AuthorizationContextProvider>
            <HUDContextProvider>
                <HomepageContextProvider>
                    <AppGlobalContextProvider>
                        <AppRouter />
                        <ToastContainer position="top-center" theme="dark" />
                    </AppGlobalContextProvider>
                </HomepageContextProvider>
            </HUDContextProvider>
        </AuthorizationContextProvider>
    );
};
