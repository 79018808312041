import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import PlatformBehaviorUpdater from "./PlatformBehaviorUpdater";
import {OBJECT_TYPES, PLATFORM_RESPAWN_TYPES, PlatformBehaviorInterface} from "../../types/editor";
import BasePlatformBehaviorConverter from "./BasePlatformBehaviorConverter";

class PlatformBehaviorConverter extends BasePlatformBehaviorConverter {
    public static DEFAULT = new PlatformBehaviorConverter(null);
    static readonly PLATFORM_ANIMATION_LAYER_ID = "9b08333c-7cf7-42f0-8c31-2df838780725";
    static readonly PLATFORM_ANIMATION_LAYER_NAME = "PLATFORMS";

    constructor(obj: any) {
        super(obj);
    }

    clearAnimationLayer(target: THREE.Object3D) {
        const animationLayer = super.getAnimationLayer(
            PlatformBehaviorConverter.PLATFORM_ANIMATION_LAYER_ID,
            PlatformBehaviorConverter.PLATFORM_ANIMATION_LAYER_NAME,
        );

        if (animationLayer?.animations) {
            animationLayer.animations = animationLayer.animations.filter((a: any) => a.target !== target.uuid);
        }
    }

    convert(target: THREE.Object3D, behavior: PlatformBehaviorInterface): BehaviorUpdater {
        const animationLayer = super.getAnimationLayer(
            PlatformBehaviorConverter.PLATFORM_ANIMATION_LAYER_ID,
            PlatformBehaviorConverter.PLATFORM_ANIMATION_LAYER_NAME,
        );

        //delete existing animation for this object
        animationLayer.animations = animationLayer.animations.filter((a: any) => a.target !== target.uuid);

        if (behavior.speed <= 0) {
            return new PlatformBehaviorUpdater(target);
        }

        const physics = target.userData.physics;

        if (physics.ctype !== "Kinematic") {
            // Need for new UI property to enable movement
            physics.mass = 0;
            physics.restitution = 0;
            physics.ctype = "Kinematic";
        }

        const normalDuration = 10; // Normal duration is 10 seconds for speed = 1
        const beginTime = 0;
        const endTime = beginTime + normalDuration / behavior.speed;
        const loop = behavior.respawn !== PLATFORM_RESPAWN_TYPES.PLAY_ONCE;
        const loopType = behavior.respawn === PLATFORM_RESPAWN_TYPES.LOOP ? "reflect" : "restart";

        const displacement = new THREE.Vector3(behavior.horizontalX, behavior.vertical, behavior.horizontalZ);
        displacement.applyQuaternion(target.quaternion);

        const endPositionX = target.position.x + displacement.x;
        const endPositionY = target.position.y + displacement.y;
        const endPositionZ = target.position.z + displacement.z;

        animationLayer.animations.push({
            id: null,
            uuid: THREE.MathUtils.generateUUID(),
            name: "Platform Animation " + target.name,
            target: target.uuid,
            type: "Tween",
            beginTime,
            endTime,
            loop: loop,
            loopType: loopType, //reflect, restart
            data: {
                beginStatus: "Custom",
                beginPositionX: target.position.x,
                beginPositionY: target.position.y,
                beginPositionZ: target.position.z,
                beginRotationX: target.rotation.x,
                beginRotationY: target.rotation.y,
                beginRotationZ: target.rotation.z,
                beginScaleLock: true,
                beginScaleX: target.scale.x,
                beginScaleY: target.scale.y,
                beginScaleZ: target.scale.z,
                ease: "linear", //TODO: make enum, linear, quadIn, quadOut, quadInOut, cubicIn, cubicOut, cubicInOut, quartIn, quartOut, quartInOut, quintIn, quintOut, quintInOut, sineIn, sineOut, sineInOut, backIn, backOut, backInOut, circIn, circOut, circInOut, bounceIn, bounceOut, bounceInOut, elasticIn, elasticOut, elasticInOut
                endStatus: "Custom",
                endPositionX: endPositionX,
                endPositionY: endPositionY,
                endPositionZ: endPositionZ,
                endRotationX: target.rotation.x,
                endRotationY: target.rotation.y,
                endRotationZ: target.rotation.z,
                endScaleLock: true,
                endScaleX: target.scale.x,
                endScaleY: target.scale.y,
                endScaleZ: target.scale.z,
            },
        });

        return new PlatformBehaviorUpdater(target);
    }

    getBehavior(target: THREE.Object3D, id: string): PlatformBehaviorInterface | null {
        if (!target) return null;

        const behavior = super.findBehavior(target, id) as PlatformBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have PLATFORM behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): PlatformBehaviorInterface {
        return {
            type: OBJECT_TYPES.PLATFORM,
            vertical: 3,
            horizontalX: 0,
            horizontalZ: 0,
            respawn: PLATFORM_RESPAWN_TYPES.LOOP,
            speed: 5,
            enabled: true,
            id,
            startOnTrigger: false,
        };
    }
}

export default PlatformBehaviorConverter;
