import BaseHelper from "./BaseHelper";
import global from "../global";
import * as THREE from "three";

/**
 * 网格帮助器
 * @author tengge / https://github.com/tengge1
 */
class GridHelper extends BaseHelper {
    constructor() {
        super();
        this.infiniteGridPlane = null;
        global.app.on("enableInfiniteGrid.GridHelper", this.enableInfiniteGrid.bind(this));
        global.app.on("disableInfiniteGrid.GridHelper", this.dispose.bind(this));
    }

    start() {
        global.app.on(`storageChanged.${this.id}`, this.onStorageChanged.bind(this));
        this.update();
    }

    stop() {
        global.app.on(`appStarted.${this.id}`, null);

        if (this.helper) {
            var scene = global.app.editor.sceneHelpers;
            scene.remove(this.helper);
            delete this.helper;
        }
    }

    enableInfiniteGrid() {
        if (!global.app || !global.app.editor || !global.app.editor.camera) {
            return;
        }

        try {
            global.app.editor.camera.near = 1;
            global.app.editor.camera.far = 10000;
            global.app.editor.camera.updateProjectionMatrix();
        } catch (error) {
            console.error("Error updating camera properties:", error);
        }
    }

    update() {
        var showGrid = global.app.storage.showGrid;

        var scene = global.app.editor.sceneHelpers;
        if (showGrid && this.infiniteGridPlane === null) {
            const gridColor =
                scene.userData.infiniteGridColor !== undefined
                    ? new THREE.Color(scene.userData.infiniteGridColor)
                    : new THREE.Color("#A1A1AA");
            this.infiniteGrid(scene, 1, 5, gridColor, 8000, "xzy");
            scene.add(this.infiniteGridPlane);
        } else if (!showGrid && this.infiniteGridPlane !== null) {
            scene.remove(this.infiniteGridPlane);
            this.infiniteGridPlane = null;
        }
    }

    infiniteGrid(scene, size1 = 1, size2 = 5, color = new THREE.Color("white"), distance = 8000, axes = "xzy") {
        const planeAxes = axes.slice(0, 2);

        const geometry = new THREE.PlaneGeometry(1, 1, 1500, 1500);

        const material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms: {
                uSize1: {value: size1},
                uSize2: {value: size2},
                uColor: {value: color},
                uDistance: {value: distance},
            },
            transparent: true,
            vertexShader: `
                varying vec3 worldPosition;
                uniform float uDistance;
                void main() {
                    vec3 pos = position.${axes} * uDistance;
                    pos.${planeAxes} += cameraPosition.${planeAxes};
                    worldPosition = pos;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
                }
            `,
            fragmentShader: `
                precision highp float; 
                varying vec3 worldPosition;
                uniform float uSize1;
                uniform float uSize2;
                uniform vec3 uColor;
                uniform float uDistance;
                float getGrid(float size) {
                    vec2 r = worldPosition.${planeAxes} / size;
                    vec2 grid = abs(fract(r - 0.5) - 0.5) / fwidth(r);
                    float line = min(grid.x, grid.y);
                    return 1.0 - min(line, 1.0);
                }
                void main() {
                    float d = 1.0 - min(distance(cameraPosition.${planeAxes}, worldPosition.${planeAxes}) / uDistance, 1.0);
                    float g1 = getGrid(uSize1);
                    float g2 = getGrid(uSize2);
                    float lineMix = smoothstep(0.0, 1.0, mix(g2, g1, g1));
                    gl_FragColor = vec4(uColor.rgb, lineMix * pow(d, 3.0));
                    gl_FragColor.a = mix(0.5 * gl_FragColor.a, gl_FragColor.a, g2);
                    if (gl_FragColor.a <= 0.0) discard;
                }
            `,
            extensions: {
                derivatives: true,
            },
            defines: {
                USE_FXAA: 1,
            },
        });

        this.infiniteGridPlane = new THREE.Mesh(geometry, material);
        this.infiniteGridPlane.userData.isInfiniteGrid = true;
        scene.userData.infiniteGrid = this.infiniteGridPlane;
        this.infiniteGridPlane.position.y = 0.035;
    }

    onStorageChanged(key) {
        if (key === "showGrid") {
            this.update();
        }
    }

    dispose() {
        const scene = global.app.editor.scene;
        scene.traverse(child => {
            if (child.userData && child.userData.isInfiniteGrid) {
                if (child.material) {
                    child.material.dispose();
                }
                scene.remove(child);
            }
        });
    }
}

export default GridHelper;
