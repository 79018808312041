import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import ThrowableBehaviorUpdater from "./ThrowableBehaviorUpdater";
import {OBJECT_TYPES, ThrowableBehaviorInterface} from "../../types/editor";
import global from "../../global";
import Application from "../../Application";
import Player from "../../player/Player";

class ThrowableBehaviorConverter extends BaseBehaviorConverter<ThrowableBehaviorInterface> {
    public static DEFAULT = new ThrowableBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: ThrowableBehaviorInterface): BehaviorUpdater {
        let isConsumable = !!super.findBehavior(target, behavior.id);

        if ((global.app as Application)?.player?.isPlaying || (global.app as Player)?.isPlaying) {
            target.visible = isConsumable && target.visible;
            if (target.userData.physics) {
                target.userData.physics.enabled = false;
            }
        }

        return new ThrowableBehaviorUpdater(target, behavior.throwablePointAmount);
    }

    getBehavior(target: THREE.Object3D, id: string): ThrowableBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as ThrowableBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have THROWABLE behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): ThrowableBehaviorInterface {
        return {
            throwableStarting: false,
            type: OBJECT_TYPES.THROWABLES,
            model: {id: "", name: "", url: ""},
            powerLevel: 1,
            bounceEffect: 10,
            aimer: false,
            aimerGuide: false,
            throwableVisible: true,
            throwableMass: 2,
            throwableSpeed: 50,
            throwableColor: "#ff0000",
            throwableLife: 2,
            throwableFriction: 0.25,
            throwableRestitution: 0.25,
            throwableInertia: 0.25,
            throwablePointAmount: 0,
            throwableDamage: 50,
            throwableAmount: 1,
            muzzle_flash: false, //TODO move this to effect behavior
            laser_effect: false, //TODO move this to effect behavior
            enabled: true,
            id,
        };
    }
}

export default ThrowableBehaviorConverter;
