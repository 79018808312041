import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import EventBus from "../../behaviors/event/EventBus";
import {Object3D, AnimationMixer} from "three";
import {
    COLLISION_TYPE,
    EnemyBehaviorInterface,
    OBJECT_TYPES,
} from "../../types/editor";
import global from "../../global";

declare module "three" {
    interface Object3D {
        _obj?: any;
    }
}

class EnemyBehaviorUpdater implements BehaviorUpdater {
    clock: THREE.Clock | null;
    game?: GameManager | null;
    target: Object3D;
    behavior: EnemyBehaviorInterface;
    prevPlayerPosition: THREE.Vector3;
    lastPlayerMoveTime: number;
    private mixers: AnimationMixer[] = [];
    enemyEnabled: boolean;

    // Enemy lives
    lives = 3;
    deathAnimationStarted = false;
    removed = false;

    // Collision listeners
    playerCollisionListenerId: string | undefined;
    bulletCollisionListenerId: string | undefined;
    requestAnimationFrameId: number;

    constructor(target: Object3D, behavior: EnemyBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.clock = new THREE.Clock();
        this.prevPlayerPosition = new THREE.Vector3();
        this.lastPlayerMoveTime = Date.now();
        this.enemyEnabled = true;
        this.requestAnimationFrameId = -1;
    }

    reset(): void {}

    isDead() {
        return this.lives <= 0;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.addCollisionListeners(true, true);
        this.initEnemies();
    }

    initEnemies() {
        if (!this.game || !this.game.player || !this.game.scene) return;
        const scene = this.game.scene;
        const circleMaterial = new THREE.MeshBasicMaterial({
            color: 0x00ff00,
            transparent: true,
            opacity: 0.5,
            side: THREE.DoubleSide,
        });

        const enemyObject = scene.getObjectById(this.target.id);

        if (
            enemyObject &&
            enemyObject.userData &&
            enemyObject.userData.behaviors
        ) {
            const enemyBehavior = enemyObject.userData.behaviors.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.ENEMY,
            );
            if (
                enemyBehavior &&
                enemyBehavior.enemyEnabled !== undefined &&
                !isNaN(enemyBehavior.enemyEnabled) &&
                enemyBehavior.health !== undefined &&
                !isNaN(enemyBehavior.health) &&
                enemyBehavior.movementSpeed !== undefined &&
                !isNaN(enemyBehavior.movementSpeed) &&
                enemyBehavior.attackDamage !== undefined &&
                !isNaN(enemyBehavior.attackDamage) &&
                enemyBehavior.attackDistance !== undefined &&
                !isNaN(enemyBehavior.attackDistance) &&
                enemyBehavior.attackSpeed !== undefined &&
                !isNaN(enemyBehavior.attackSpeed) &&
                enemyBehavior.respawnAmount !== undefined &&
                !isNaN(enemyBehavior.respawnAmount) &&
                enemyBehavior.roamDistance !== undefined &&
                !isNaN(enemyBehavior.roamDistance) &&
                enemyBehavior.showRoamArea !== undefined &&
                !isNaN(enemyBehavior.showRoamArea) &&
                enemyBehavior.rotationSpeed !== undefined &&
                !isNaN(enemyBehavior.rotationSpeed) &&
                enemyBehavior.fightDistance !== undefined &&
                !isNaN(enemyBehavior.fightDistance) &&
                enemyBehavior.directionDuration !== undefined &&
                !isNaN(enemyBehavior.directionDuration)
            ) {
                const animations: THREE.AnimationClip[] = [];
                const childAnimations = enemyObject._obj
                    ? (enemyObject._obj.animations as THREE.AnimationClip[])
                    : [];

                if (childAnimations && childAnimations.length > 0) {
                    animations.push(...childAnimations);
                }

                this.enemyEnabled = enemyBehavior.enemyEnabled;

                if (animations.length > 0) {
                    const mixer = new THREE.AnimationMixer(enemyObject);
                    this.mixers.push(mixer);
                    const walkAnimationName = enemyBehavior.walkAnimation;
                    if (walkAnimationName) {
                        const walkAnimation = animations.find(
                            (clip: THREE.AnimationClip) =>
                                clip.name === walkAnimationName,
                        );
                        if (walkAnimation) {
                            mixer.clipAction(walkAnimation).play();
                            enemyObject.userData.currentAnimation =
                                walkAnimation;
                        } else {
                            console.warn(
                                `Walk animation clip named "${walkAnimationName}" not found.`,
                            );
                        }
                    } else {
                        console.warn(`No walk animation specified for enemy.`);
                    }

                    enemyObject.userData.mixer = mixer;
                    enemyObject.userData.mixerKey = enemyObject.id;
                    enemyObject.userData.animations = animations;
                } else {
                    console.log("No animations found in the model.");
                }

                Object.assign(enemyObject, {
                    enemyBehavior: {
                        enemyEnabled: enemyBehavior.enemyEnabled,
                        health: enemyBehavior.health,
                        movementSpeed: enemyBehavior.movementSpeed,
                        attackDamage: enemyBehavior.attackDamage,
                        attackDistance: enemyBehavior.attackDistance,
                        attackSpeed: enemyBehavior.attackSpeed,
                        respawnAmount: enemyBehavior.respawnAmount,
                        roamDistance: enemyBehavior.roamDistance,
                        showRoamArea: enemyBehavior.showRoamArea,
                        rotationSpeed: enemyBehavior.rotationSpeed,
                        fightDistance: enemyBehavior.fightDistance,
                        directionDuration: enemyBehavior.directionDuration,
                        idleAnimation: enemyBehavior.idleAnimation,
                        attackAnimation: enemyBehavior.attackAnimation,
                        walkAnimation: enemyBehavior.walkAnimation,
                        fallAnimation: enemyBehavior.fallAnimation,
                        jumpAnimation: enemyBehavior.jumpAnimation,
                        dieAnimation: enemyBehavior.dieAnimation,
                    },
                });

                enemyObject.userData.originalPosition =
                    enemyObject.position.clone();

                if (enemyBehavior.enemyEnabled) {
                    if (enemyBehavior.showRoamArea) {
                        const roamDistance = enemyBehavior.roamDistance;
                        const bbox = new THREE.Box3().setFromObject(
                            enemyObject,
                        );
                        const center = new THREE.Vector3();
                        bbox.getCenter(center);
                        const geometry = new THREE.CircleGeometry(
                            roamDistance,
                            32,
                        );
                        const circle = new THREE.Mesh(geometry, circleMaterial);
                        circle.position.set(center.x, center.y, center.z);
                        circle.rotation.x = -Math.PI / 2;
                        scene.add(circle); // TODO: Enhancement - this should be visible in editor and game
                    }
                }
            }
        }
    }

    addCollisionListeners(withPlayer: boolean, withBullet: boolean) {
        if (withPlayer) {
            this.playerCollisionListenerId =
                this.game?.behaviorManager?.collisionDetector.addListener(
                    this.target,
                    {
                        type: COLLISION_TYPE.WITH_PLAYER,
                        callback: this.onCollisionWithPlayer.bind(this),
                        useBoundingBoxes: false,
                        distanceThreshold: 2.0,
                    },
                    this.target.userData.physics &&
                        this.target.userData.physics.enabled,
                );
        }
        if (withBullet) {
            this.bulletCollisionListenerId =
                this.game?.behaviorManager?.collisionDetector.addListener(
                    this.target,
                    {
                        type: COLLISION_TYPE.WITH_COLLIDABLE_OBJECTS,
                        callback: this.onCollisionWithThrowable.bind(this),
                        useBoundingBoxes: false,
                        distanceThreshold: 2.0,
                    },
                    this.target.userData.physics &&
                        this.target.userData.physics.enabled,
                );
        }
    }

    onCollisionWithThrowable() {
        if (this.lives > 0) {
            this.lives--;
            if (this.isDead()) {
                this.game!.behaviorManager!.collisionDetector.deleteListener(
                    this.target,
                );
            } else {
                this.game!.behaviorManager!.collisionDetector.deleteListener(
                    this.target,
                    this.bulletCollisionListenerId,
                );
                setTimeout(() => {
                    this.addCollisionListeners(false, true);
                }, 500);
            }
        }
    }

    onCollisionWithPlayer() {
        const enemyBehavior = this.target.userData.behaviors.find(
            (behavior: any) => behavior.type === "Enemy",
        );
        if (enemyBehavior && this.enemyEnabled) {
            (global as any).app.call("playerFallBack", this, this);
            let attackDamage = parseFloat(enemyBehavior.attackDamage);
            EventBus.instance.send("game.lives.dec", attackDamage);
            this.game!.behaviorManager!.collisionDetector.deleteListener(
                this.target,
                this.playerCollisionListenerId,
            );
            setTimeout(() => {
                this.addCollisionListeners(true, false);
            }, 5000);
        }
    }

    playAnimation(
        enemy: THREE.Object3D,
        objectAnimations: THREE.AnimationClip[],
        animationName: string,
        forceRestart = false,
        finishCallback: any = null,
    ) {
        const animations = objectAnimations;

        if (
            animations &&
            animations.length > 0 &&
            (enemy.userData.currentAnimation !== animationName || forceRestart)
        ) {
            let mixer = enemy.userData.mixer as THREE.AnimationMixer;
            if (!mixer) {
                mixer = new THREE.AnimationMixer(enemy);
                this.mixers.push(mixer);
                enemy.userData.mixer = mixer;
            }
            mixer.stopAllAction();
            const animationClip = animations.find(
                (clip: THREE.AnimationClip) => clip.name === animationName,
            );
            if (animationClip) {
                mixer.addEventListener("finished", () => {
                    if (finishCallback) finishCallback();
                });
                const action = mixer.clipAction(animationClip);
                action.setEffectiveTimeScale(1);
                action.fadeIn(0.5);
                if (this.isDead()) {
                    action.setLoop(THREE.LoopOnce, 1);
                    action.clampWhenFinished = true;
                    action.play();
                } else {
                    action.play();
                }
                enemy.userData.currentAnimation = animationName;
            } else {
                console.warn(
                    `Animation clip named "${animationName}" not found.`,
                );
                if (finishCallback) finishCallback();
            }
        }
    }


    update(clock: THREE.Clock, delta: number): void {
        if (
            !this.game ||
            !this.game.player ||
            !this.game.scene ||
            this.removed
        ) {
            this.stopAnimation();
            return;
        }

        const player = this.game.player;
        const playerPosition = new THREE.Vector3();
        player.getWorldPosition(playerPosition);
        const deltaTime = this.clock!.getDelta();

        const playerMoved =
            playerPosition.distanceTo(this.prevPlayerPosition) > 0;
        if (playerMoved) {
            this.prevPlayerPosition.copy(playerPosition);
            this.lastPlayerMoveTime = Date.now();
        }

        const enemyObject = this.target as THREE.Object3D & {
            enemyBehavior: any;
        };

        if (this.target && !this.isDead() && this.enemyEnabled) {
            const movementSpeed = parseFloat(enemyObject.enemyBehavior.movementSpeed);
            const attackDistance = parseFloat(enemyObject.enemyBehavior.attackDistance);
            const attackSpeed = parseFloat(enemyObject.enemyBehavior.attackSpeed);
            const roamDistance = parseFloat(enemyObject.enemyBehavior.roamDistance);
            const rotationSpeed = parseFloat(enemyObject.enemyBehavior.rotationSpeed);
            const fightDistance = parseFloat(enemyObject.enemyBehavior.fightDistance);
            const directionDuration = parseFloat(enemyObject.enemyBehavior.directionDuration);

            const distanceToPlayer = enemyObject.position.distanceTo(playerPosition);
            const originalPosition = enemyObject.userData.originalPosition;
            const distanceToOriginalPosition = enemyObject.position.distanceTo(originalPosition);

            enemyObject.userData.state =
                enemyObject.userData.state || "standing";
            enemyObject.userData.stateTimer =
                enemyObject.userData.stateTimer || 0;
            enemyObject.userData.standingDuration =
                enemyObject.userData.standingDuration || Math.random() * 3 + 2;

            enemyObject.userData.stateTimer += deltaTime;

            if (
                enemyObject.userData.state === "standing" &&
                enemyObject.userData.stateTimer >=
                enemyObject.userData.standingDuration
            ) {
                enemyObject.userData.state = "approaching";
                enemyObject.userData.stateTimer = 0;
            } else if (
                enemyObject.userData.state === "approaching" &&
                enemyObject.userData.stateTimer >= 4
            ) {
                enemyObject.userData.state = "retreating";
                enemyObject.userData.stateTimer = 0;
            } else if (
                enemyObject.userData.state === "retreating" &&
                enemyObject.userData.stateTimer >= 2
            ) {
                enemyObject.userData.state = "standing";
                enemyObject.userData.stateTimer = 0;
                enemyObject.userData.standingDuration = Math.random() * 3 + 2; // Random duration between 2 and 5 seconds
            }

            if (distanceToPlayer <= attackDistance) {
                enemyObject.userData.state = "attacking";
            }

            if (enemyObject.userData.state === "standing") {
                this.playAnimation(
                    enemyObject,
                    enemyObject.userData.animations,
                    enemyObject.enemyBehavior.idleAnimation,
                );
            } else if (enemyObject.userData.state === "approaching") {
                if (distanceToPlayer > attackDistance) {
                    if (
                        !enemyObject.userData.randomMoveTimer ||
                        enemyObject.userData.randomMoveTimer <= 0
                    ) {
                        enemyObject.userData.randomMoveTimer =
                            Math.random() * directionDuration +
                            directionDuration;
                        enemyObject.userData.randomDirection =
                            new THREE.Vector3(
                                Math.random() * 2 - 1,
                                0,
                                Math.random() * 2 - 1,
                            ).normalize();
                    }

                    this.playAnimation(
                        enemyObject,
                        enemyObject.userData.animations,
                        enemyObject.enemyBehavior.walkAnimation,
                    );


                    const targetRotation = Math.atan2(
                        enemyObject.userData.randomDirection.x,
                        enemyObject.userData.randomDirection.z,
                    );
                    enemyObject.rotation.y = THREE.MathUtils.lerp(
                        enemyObject.rotation.y,
                        targetRotation,
                        rotationSpeed * deltaTime,
                    );

                    enemyObject.position.add(
                        enemyObject.userData.randomDirection
                            .clone()
                            .multiplyScalar(movementSpeed),
                    );
                    enemyObject.userData.randomMoveTimer -= deltaTime;


                    if (distanceToOriginalPosition > roamDistance) {
                        const directionToOriginal = originalPosition
                            .clone()
                            .sub(enemyObject.position)
                            .normalize();
                        const targetRotation = Math.atan2(
                            directionToOriginal.x,
                            directionToOriginal.z,
                        );
                        enemyObject.rotation.y = THREE.MathUtils.lerp(
                            enemyObject.rotation.y,
                            targetRotation,
                            rotationSpeed * deltaTime,
                        );
                        enemyObject.position.add(
                            directionToOriginal.multiplyScalar(movementSpeed),
                        );
                    }
                } else {
                    this.playAnimation(
                        enemyObject,
                        enemyObject.userData.animations,
                        enemyObject.enemyBehavior.attackAnimation,
                    );

                    const direction = new THREE.Vector3(
                        playerPosition.x - enemyObject.position.x,
                        0,
                        playerPosition.z - enemyObject.position.z,
                    ).normalize();

                    const targetRotation = Math.atan2(direction.x, direction.z);
                    enemyObject.rotation.y = THREE.MathUtils.lerp(
                        enemyObject.rotation.y,
                        targetRotation,
                        rotationSpeed * deltaTime,
                    );

                    if (distanceToPlayer > fightDistance) {
                        enemyObject.position.add(
                            direction.multiplyScalar(attackSpeed),
                        );
                    } else {
                        enemyObject.position.copy(
                            enemyObject.userData.previousPosition ||
                            enemyObject.position,
                        );
                    }

                    enemyObject.userData.previousPosition =
                        enemyObject.position.clone();
                }
            } else if (enemyObject.userData.state === "retreating") {
                this.playAnimation(
                    enemyObject,
                    enemyObject.userData.animations,
                    enemyObject.enemyBehavior.walkAnimation,
                );

                const direction = new THREE.Vector3(
                    enemyObject.position.x - playerPosition.x,
                    0,
                    enemyObject.position.z - playerPosition.z,
                ).normalize();

                const targetRotation = Math.atan2(direction.x, direction.z);
                enemyObject.rotation.y = THREE.MathUtils.lerp(
                    enemyObject.rotation.y,
                    targetRotation,
                    rotationSpeed * deltaTime,
                );

                enemyObject.position.add(
                    direction.multiplyScalar(movementSpeed),
                );
            } else if (enemyObject.userData.state === "attacking") {
                this.playAnimation(
                    enemyObject,
                    enemyObject.userData.animations,
                    enemyObject.enemyBehavior.attackAnimation,
                );

                const direction = new THREE.Vector3(
                    playerPosition.x - enemyObject.position.x,
                    0,
                    playerPosition.z - enemyObject.position.z,
                ).normalize();

                const targetRotation = Math.atan2(direction.x, direction.z);
                enemyObject.rotation.y = THREE.MathUtils.lerp(
                    enemyObject.rotation.y,
                    targetRotation,
                    rotationSpeed * deltaTime,
                );

                if (distanceToPlayer > fightDistance) {
                    enemyObject.position.add(
                        direction.multiplyScalar(attackSpeed),
                    );
                } else {
                    enemyObject.position.copy(
                        enemyObject.userData.previousPosition ||
                        enemyObject.position,
                    );
                }

                enemyObject.userData.previousPosition =
                    enemyObject.position.clone();

                if (distanceToPlayer > attackDistance) {
                    enemyObject.userData.state = "standing";
                    enemyObject.userData.stateTimer = 0;
                    enemyObject.userData.standingDuration =
                        Math.random() * 3 + 2;
                }
            }


            if (
                enemyObject.userData.state === "approaching" ||
                enemyObject.userData.state === "retreating"
            ) {
                const movementDirection =
                    enemyObject.userData.randomDirection ||
                    playerPosition
                        .clone()
                        .sub(enemyObject.position)
                        .normalize();
                const targetRotation = Math.atan2(
                    movementDirection.x,
                    movementDirection.z,
                );
                enemyObject.rotation.y = THREE.MathUtils.lerp(
                    enemyObject.rotation.y,
                    targetRotation,
                    rotationSpeed * deltaTime,
                );
            }
        }

        if (this.isDead() && !this.deathAnimationStarted) {
            this.playAnimation(
                enemyObject,
                enemyObject.userData.animations,
                enemyObject.enemyBehavior.dieAnimation,
                true,
                () => {
                    if (
                        this.target instanceof THREE.Object3D &&
                        this.target.parent !== null
                    ) {
                        this.game!.app.removePhysicsObject(this.target);
                        this.removed = true;
                    }
                },
            );
            this.deathAnimationStarted = true;
        }

        if (this.mixers && this.mixers.length > 0) {
            for (const mixer of this.mixers) {
                mixer.update(deltaTime);
            }
        }
    }



    stopAnimation = () => {
        cancelAnimationFrame(this.requestAnimationFrameId);
        this.requestAnimationFrameId = -1;
    };

    dispose = () => {
        this.stopAnimation();

        this.mixers.forEach(mixer => {
            mixer.stopAllAction();
            mixer.uncacheRoot(mixer.getRoot());
        });
        this.mixers.length = 0;

        this.clock = null;
        this.requestAnimationFrameId = -1;
    };
}

export default EnemyBehaviorUpdater;
