import styled from "styled-components";

import global from "../../../../../../global";
import Application from "../../../../../../Application";
import {OBJECT_TYPES} from "../../../../../../types/editor";
import goBackIcon from "../../icons/go-back.svg";
import {Container, Header} from "../../styles/Behaviors.style";

import {CharacterBehaviors} from "../../behaviors/CharacterBehaviors";
import {AnimationBehaviors} from "../../behaviors/AnimationBehaviors";
import {JumpPadBehaviors} from "../../behaviors/JumpPadBehaviors";
import {TeleportBehaviors} from "../../behaviors/TeleportBehaviors";
import {FollowBehaviors} from "../../behaviors/FollowBehaviors";
import {VolumeBehaviors} from "../../behaviors/VolumeBehaviors";
import {GenericSoundBehaviors} from "../../behaviors/GenericSoundBehaviors";
import {ConsumableBehaviors} from "../../behaviors/ConsumableBehaviors";
import {EnemyBehaviors} from "../../behaviors/EnemyBehaviors";
import {PlatformBehaviors} from "../../behaviors/PlatformBehaviors";
import {SpawnPointBehaviors} from "../../behaviors/SpawnPointBehaviors";
import {CharacterSoundsBehavior} from "../../behaviors/CharacterSoundsBehavior";
import {ThrowableBehaviors} from "../../behaviors/ThrowableBehaviors";
import {WeaponBehaviors} from "../../behaviors/WeaponBehavior/WeaponBehaviors";
import {CameraBehaviors} from "../../behaviors/CameraBehaviors";
import {ScriptBehaviors} from "../../behaviors/ScriptBehaviors";
import {WeaponAmmoBehaviors} from "../../behaviors/WeaponAmmoBehaviors";
import {TriggerBehaviors} from "../../behaviors/TriggerBehaviors";
import {ObjectInteractionBehaviors} from "../../behaviors/ObjectInteractionBebahiors";
import {PropAnimationBehaviors} from "../../behaviors/PropAnimationBehaviors";
import {AiNPCBehaviors} from "../../behaviors/AiNpcBehaviors";
import deleteIcon from "../../../icons/delete-icon.svg";

interface Props {
    selectedBehavior: any;
    setSelectedBehavior: React.Dispatch<any>;
}

export const BehaviorDetailsView = ({selectedBehavior, setSelectedBehavior}: Props) => {
    const app = global.app as Application;

    const handleDelete = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedBehavior(null);
        app?.editor?.removeBehavior(selectedBehavior.id);
    };
    return (
        <BehaviorDetailsWrapper className="hidden-scroll">
            <Container>
                <Header onClick={() => setSelectedBehavior(null)}>
                    <img src={goBackIcon} alt="go back" className="icon" />
                    {selectedBehavior.type}
                    <img src={deleteIcon} className="delete-icon" onClick={handleDelete} />
                </Header>
                {selectedBehavior?.type === OBJECT_TYPES.CHARACTER && (
                    <CharacterBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.CAMERA && <CameraBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.ANIMATION && (
                    <AnimationBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.PROP_ANIMATION && (
                    <PropAnimationBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.VOLUME && <VolumeBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.GENERIC_SOUND && (
                    <GenericSoundBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.CONSUMABLE && (
                    <ConsumableBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.ENEMY && <EnemyBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.PLATFORM && <PlatformBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.SPAWNPOINT && (
                    <SpawnPointBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.JUMPPAD && <JumpPadBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.TELEPORT && <TeleportBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.FOLLOW && <FollowBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.CHARACTER_SOUNDS && (
                    <CharacterSoundsBehavior behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.THROWABLES && (
                    <ThrowableBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.WEAPON && <WeaponBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.WEAPON_AMMO && (
                    <WeaponAmmoBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.SCRIPT && <ScriptBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.TRIGGER && <TriggerBehaviors behavior={selectedBehavior} />}
                {selectedBehavior?.type === OBJECT_TYPES.OBJECT_INTERACTIONS && (
                    <ObjectInteractionBehaviors behavior={selectedBehavior} />
                )}
                {selectedBehavior?.type === OBJECT_TYPES.AI_NPC && <AiNPCBehaviors behavior={selectedBehavior} />}
            </Container>
        </BehaviorDetailsWrapper>
    );
};

const BehaviorDetailsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 11px;
    box-sizing: border-box;
    padding: 0;
`;
