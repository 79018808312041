//behaviors
export enum OBJECT_TYPES {
    VOLUME = "Volume",
    CONSUMABLE = "Consumable",
    ENEMY = "Enemy",
    CHARACTER = "Character",
    CAMERA = "Camera",
    PLATFORM = "Platform",
    SPAWNPOINT = "SpawnPoint",
    JUMPPAD = "JumpPad",
    TELEPORT = "Teleport",
    ANIMATION = "Animation",
    PROP_ANIMATION = "Prop Animation",
    FOLLOW = "Follow",
    WEAPON = "Weapon",
    WEAPON_AMMO = "Weapon Ammo",
    THROWABLES = "Throwables",
    GENERIC_SOUND = "Generic Sound",
    CHARACTER_SOUNDS = "Character Sounds",
    SCRIPT = "Script",
    TRIGGER = "Trigger",
    OBJECT_INTERACTIONS = "Object Interactions",
    AI_NPC = "AI Agent",
    CONTROLS = "Controls",
    MAKE_CHILD = "MakeChild",
    OTHER = "Other",
    LIGHTS = "Lights",
    MATH_FUNCTIONS = "Math Functions",
    PARTICLE_EFFECTS = "Particle Effects",
    SWITCH = "Switch",
    CHECK_POINT = "Check Point", // not available in behaviors list, added in a different way, from left panel assets
    SPAWN_POINT = "Spawn Point", // not available in behaviors list, added in a different way, from left panel assets
    SCENE_VOLUME = "Scene Volume", // not available in behaviors list, added in a different way, from left panel assets
    BILLBOARD = "Billboard", // not available in behaviors list, added in a different way, from left panel assets
    TIMER = "Timer",
    GAME_OVER = "Game Over",
    COUNTER = "Counter",
    TEXT_PROMPT = "Text Prompt",
    CONDITIONS = "Conditions",
    PANNING_TEXTURE = "Panning Texture",
    ON_CLICK = "On Click",
    VEHICLE_SOUND = "Vehicle Sound",
    PROGRESSION = "Progression",
}

export const OBJECT_TYPES_WHITE_LIST = [
    OBJECT_TYPES.VOLUME,
    OBJECT_TYPES.CONSUMABLE,
    OBJECT_TYPES.ENEMY,
    OBJECT_TYPES.CHARACTER,
    OBJECT_TYPES.CHARACTER_SOUNDS,
    OBJECT_TYPES.GENERIC_SOUND,
    OBJECT_TYPES.CAMERA,
    OBJECT_TYPES.PLATFORM,
    OBJECT_TYPES.SPAWNPOINT,
    OBJECT_TYPES.SPAWN_POINT,
    OBJECT_TYPES.JUMPPAD,
    OBJECT_TYPES.TELEPORT,
    OBJECT_TYPES.ANIMATION,
    OBJECT_TYPES.PROP_ANIMATION,
    OBJECT_TYPES.FOLLOW,
    OBJECT_TYPES.THROWABLES,
    OBJECT_TYPES.WEAPON,
    OBJECT_TYPES.WEAPON_AMMO,
    OBJECT_TYPES.SCRIPT,
    OBJECT_TYPES.TRIGGER,
    OBJECT_TYPES.OBJECT_INTERACTIONS,
    OBJECT_TYPES.AI_NPC,
];

export const OBJECT_TYPES_ASSETS_LIST = [
    OBJECT_TYPES.CHECK_POINT,
    OBJECT_TYPES.SPAWN_POINT,
    OBJECT_TYPES.SCENE_VOLUME,
    OBJECT_TYPES.BILLBOARD,
];

export enum OBJECT_INTERACTION_OPTIONS {
    PICKUP_DROP = "Pickup and Drop",
    PUSH_PULL = "Push and Pull",
}

export enum CHARACTER_TYPES {
    FIRST_PERSON = "First Person",
    THIRD_PERSON = "3rd Person",
    FORTNITE = "FortNite",
    SIDE_SCROLLER = "Side Scroller",
    VEHICLE = "Vehicle",
}

export enum ANIMATION_TYPES {
    REPEAT = "Repeat",
    LOOP = "Loop",
    PLAY_ONCE = "Play Once",
}
export enum PROP_ANIMATION_TYPES {
    LOOP = "Loop",
    PLAY_ONCE = "Play Once",
}

export enum CHARACTER_VERTICAL_STATUS_TYPES {
    JUMPING_UP = "Jumping Up",
    FALLING_DOWN = "Falling Down",
    STATIONARY = "Vertical Stationary",
    LANDED = "Landed",
    HANG_TIME = "Hang Time",
}

export enum EASE_TYPES {
    LINEAR = "linear",
    QUAD_IN = "quadIn",
    QUAD_OUT = "quadOut",
    QUAD_IN_OUT = "quadInOut",
    CUBIC_IN = "cubicIn",
    CUBIC_OUT = "cubicOut",
    CUBIC_IN_OUT = "cubicInOut",
    QUART_IN = "quartIn",
    QUART_OUT = "quartOut",
    QUART_IN_OUT = "quartInOut",
    QUINT_IN = "quintIn",
    QUINT_OUT = "quintOut",
    QUINT_IN_OUT = "quintInOut",
    SINE_IN = "sineIn",
    SINE_OUT = "sineOut",
    SINE_IN_OUT = "sineInOut",
    BACK_IN = "backIn",
    BACK_OUT = "backOut",
    BACK_IN_OUT = "backInOut",
    CIRC_IN = "circIn",
    CIRC_OUT = "circOut",
    CIRC_IN_OUT = "circInOut",
    BOUNCE_IN = "bounceIn",
    BOUNCE_OUT = "bounceOut",
    BOUNCE_IN_OUT = "bounceInOut",
    ELASTIC_IN = "elasticIn",
    ELASTIC_OUT = "elasticOut",
    ELASTIC_IN_OUT = "elasticInOut",
}

export enum VOLUME_TYPES {
    BLOCKING = "Blocking",
    KILL_VOLUME = "Kill Volume",
    DIALOGUE_VOLUME = "Dialogue Volume",
    LOSE_VOLUME = "Lose Volume",
    WIN_VOLUME = "Win Volume",
    CUSTOM = "Custom",
    TRIGGER_VOLUME = "Trigger Volume",
}

export enum CUSTOM_BLOCK_VOLUME_TYPES {
    BLOCK_ENEMIES = "Block Enemies",
    BLOCK_THROWABLE = "Block Throwables",
    BLOCK_CHARACTERS = "Block Characters",
}

export enum ENEMY_TYPES {
    AGGRESIVE = "Aggressive",
    DEFENSIVE = "Defensive",
    PATROLS = "Patrols",
    CUSTOM = "Custom",
}

export enum PLATFORM_RESPAWN_TYPES {
    LOOP = "Loop",
    REPEAT = "Respawn",
    PLAY_ONCE = "Play Once",
}

export enum SPAWNPOINT_TYPES {
    CLONE = "Clone",
    MOVE = "Move",
}

export enum WEAPON_TYPES {
    MACHINE_GUN = "Machine Gun",
    SUB_MACHINE_GUN = "Sub Machine Gun",
    RIFLE = "Rifle",
    SNIPER_RIFLE = "Sniper Rifle",
    SCIFI_SNIPER_RIFLE = "SciFi Sniper Rifle",
    SHOT_GUN = "Shot Gun",
    PISTOL = "Pistol",
    BOW = "Bow",
    HANDS = "Hands",
    SWORD = "Sword",
    KNIFE = "Knife",
    STAFF = "Staff",
    GRENADE = "Grenade",
}

export type IBehavior =
    | CharacterBehaviorInterface
    | CameraBehaviorInterface
    | AnimationBehaviorInterface
    | PropAnimationBehaviorInterface
    | WeaponBehaviorInterface
    | WeaponAmmoBehaviorInterface
    | EnemyBehaviorInterface
    | PlatformBehaviorInterface
    | SpawnPointBehaviorInterface
    | JumpPadBehaviorInterface
    | TeleportBehaviorInterface
    | VolumeBehaviorInterface
    | ThrowableBehaviorInterface
    | ConsumableBehaviorInterface
    | TriggerBehaviorInterface
    | SpawnPointAssetBehaviorInterface
    | CheckPointAssetBehaviorInterface
    | ObjectInteractionBehaviorInterface
    | AiNPCBehaviorInterface;

export interface BehaviorInterface {
    enabled: boolean;
    id: string;
    type: OBJECT_TYPES;
}

export interface ThirdPersonOptionsInterface {
    sceneModels: any;
    selectedModelUUID: string;
    selectedModel: any;
    animationNames: any;
    walkAnimation: string;
    runAnimation: string;
    jumpAnimation: string;
    idleAnimation: string;
    fallDelay: number;
    fallAnimation: string;
    dieAnimation: string;
    leftDirectionAnimation: string;
    rightDirectionAnimation: string;
    reverseDirectionAnimation: string;
    shootAnimation: string;
    reloadAnimation: string;
    swordAnimation: string;
    punchAnimation: string;
    throwAnimation: string;
    pickUpAnimation: string;
    carryAnimation: string;
    initialXRotation: string;
    walkSpeed: number;
    runSpeed: number;
    turnSpeed: number;
    jumpHeight: number;
    playerGravity: number;
    cameraMINDistance: number;
    cameraMAXDistance: number;
    overTheShoulder: boolean;
    otsRightShoulderCamera: boolean;
    slopeTolerance: number;
}

export enum ANIMATION_TYPES {
    IDLE = "idle",
    STAND = "stand",
    WAIT = "wait",
    WALK = "walk",
    RUN = "run",
    JOG = "jog",
    JUMP = "jump",
    HOP = "hop",
    SHOOT = "shoot",
    RELOAD = "reload",
    SWORD = "sword",
    SWING = "swing",
    PUNCH = "punch",
    KICK = "kick",
    THROW = "throw",
    PICK_UP = "pick",
    CARRY = "carry",
    LEFT_DIRECTION = "walk",
    RIGHT_DIRECTION = "walk",
    REVERSE_DIRECTION = "walk",
    FALL = "fall",
    SKYDIVE = "sky dive",
    DIE = "die",
}

export interface SideScrollerOptionsInterface {
    sceneModels: any;
    selectedModelUUID: string;
    selectedModel: any;
    animationNames: any;
    walkAnimation: string;
    runAnimation: string;
    jumpAnimation: string;
    idleAnimation: string;
    fallDelay: number;
    fallAnimation: string;
    dieAnimation: string;
    leftDirectionAnimation: string;
    rightDirectionAnimation: string;
    reverseDirectionAnimation: string;
    shootAnimation: string;
    reloadAnimation: string;
    swordAnimation: string;
    punchAnimation: string;
    throwAnimation: string;
    pickUpAnimation: string;
    carryAnimation: string;
    initialXRotation: string;
    walkSpeed: number;
    runSpeed: number;
    turnSpeed: number;
    jumpHeight: number;
    playerGravity: number;
    cameraMINDistance: number;
    cameraMAXDistance: number;
}

export interface SoundPropInterface {
    id: string;
    name: string;
    url: string;
}

export interface ModelPropInterface {
    id: string;
    name: string;
    url: string;
}

export interface CharacterBehaviorInterface extends BehaviorInterface {
    id: string;
    type: OBJECT_TYPES.CHARACTER; // Value is a string "Character"
    control: CHARACTER_TYPES; // possible options here: "First Person", "3rd Person", "Side Scroller", "Vehicle"
    health: number;
    pickUpWeapons: boolean;
    movementSpeed: number;
    useDemoWeapon: boolean;
    autoForward: boolean;
    shield: number;
    walkSpeed: number;
    runSpeed: number;
    lookSpeed: number;
    jumpStrength: number;
    age: number;
    overTheShoulder: boolean;
    otsRightShoulderCamera: boolean;
}

export enum CAMERA_OBJECT_INTERACTION {
    TRANSPARENT = "Transparent",
    ZOOM = "Zoom",
}
export interface CameraBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.CAMERA; // Value is a string "Camera"
    cameraType: CAMERA_TYPES; // Possible values here: "First Person", "3rd Person", "Top Down", "Side Scroller"
    cameraEffect: CAMERA_EFFECTS; // Possible values here: Pixel, Bokeh, RGB, None
    cameraHeadHigh?: number; // this option can only be valid if cameraType is set to "First Person"
    playerCollisionBox?: number; // this option can only be valid if cameraType is set to "First Person"
    cameraMINDistance?: number; // this option can only be valid if cameraType is set to "3rd Person" or "Top Down"
    cameraMaxDistance?: number; // this option can only be valid if cameraType is set to "3rd Person" or "Top Down"
    cameraAngle?: number; // this option can only be valid if cameraType is set to "3rd Person" or "Top Down"
    cameraMinHeight?: number; // this option can only be valid if cameraType is set to "3rd Person"
    cameraMaxHeight?: number; // this option can only be valid if cameraType is set to "3rd Person"
    cameraAxis?: number; // this option can only be valid if cameraType is set to "Side Scroller"
    objectInteraction: CAMERA_OBJECT_INTERACTION; // Possible values here: "Transparent" or "Zoom"
}

export interface AnimationBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.ANIMATION; // value here is a string "Animation"
    animationType: ANIMATION_TYPES; // possible options here: "Repeat", "Loop", "Play Once"
    horizontalX: number;
    horizontalZ: number;
    vertical: number;
    rotationX: number;
    rotationY: number;
    rotationZ: number;
    scaleX: number;
    scaleY: number;
    scaleZ: number;
    easeType: EASE_TYPES;
    speed: number;
    startOnTrigger: boolean;
}
export interface PropAnimationBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.PROP_ANIMATION; // value here is a string "Prop Animation"
    animationType: PROP_ANIMATION_TYPES; // possible options here: "Loop", "Play Once"
    propAnimation: string;
    animationSpeed: number;
    startOnTrigger: boolean;
}

export interface FollowBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.FOLLOW;
    followTarget: string;
    distance: number;
    speed: number;
    rotate: boolean;
    startOnTrigger: boolean;
}

export interface ICustomVolumeOptions {
    damageAmount: number; // percentage
    losePoints: number;
    loseTime: number;
}
export interface VolumeBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.VOLUME; // value here is a string "Volume"
    volumeType: VOLUME_TYPES; // possible options here: "Blocking", "Kill Volume", "Dialogue Volume", "Lose Volume", "Win Volume", "Custom", "Trigger Volume"
    customBlockVolumeType: CUSTOM_BLOCK_VOLUME_TYPES[]; // // possible options here: "Block Enemies", "Block Throwables", "Block Characters"
    customVolumeOptions: ICustomVolumeOptions; // this is an object with keys: damageAmount, losePoints, loseTime, all of them are of type number
    startOnTrigger: boolean;
}

export interface SpawnPointAssetBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.SPAWN_POINT; // value here is a string "Spawn"
    position: ITransformValue;
}
export interface CheckPointAssetBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.CHECK_POINT; // value here is a string "Check Point"
    position: ITransformValue;
}

export interface ObjectInteractionBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.OBJECT_INTERACTIONS; // value here is a string "Object Interactions"
    interaction: OBJECT_INTERACTION_OPTIONS; // options: "Pickup and Drop" or "Push and Pull"
}

export interface ScriptBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.SCRIPT; // value here is a string "Script"
    name: string; // name of the script
    scriptType: string; // example: 'javascript'
    source: string; // code of script
}

export interface GenericSoundBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.GENERIC_SOUND; // value here is a string "Generic Sound"
    sound: SoundPropInterface; // this is an object with keys: id, name, url - all are type of string
    soundDistance: number;
    volume: number;
    loop: boolean;
    startOnTrigger: boolean;
}

export interface TriggerBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.TRIGGER; // value here is a string "Trigger"
    player_touches: boolean;
    object_touches: boolean;
    else_condition: boolean;
    then_activate: boolean;
    else_activate: boolean;
    then_object: string;
    else_object: string;
    then_animation: boolean;
    else_animation: boolean;
    then_generic_sound: boolean;
    else_generic_sound: boolean;
    then_enemy: boolean;
    else_enemy: boolean;
}

export type ConsumableType = "Instant" | "Button Press";
export interface ConsumableBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.CONSUMABLE;
    pointAmount: number;
    timeAmount: number;
    healthAmount: number;
    ammoAmount: number;
    shieldAmount: number;
    moneyAmount: number;
    scaleAmount: number;
    speedAmount: number;
    jumpAmount: number;
    timeForEffect_jump: number;
    timeForEffect_speed: number;
    collisionSettings: {
        disposable: boolean;
        playerCollision: boolean;
        enemyCollision: boolean;
        throwableCollision: boolean;
        canReappear: boolean;
    };
    timeToShowAgain?: number;
    useBoundingBoxes?: boolean;
    consumableType: ConsumableType; // Possible values are: "Instant", "Button Press"
    uiImage?: string;
}

export interface EnemyBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.ENEMY; // Value is a string "Enemy"
    enemyType: ENEMY_TYPES; // possible options here: "Aggressive", "Defensive", "Patrols"
    enemyEnabled: boolean;
    health: number;
    movementSpeed: number;
    attackDamage: number;
    attackDistance: number;
    attackSpeed: number;
    respawnAmount: number;
    roamDistance: number;
    showRoamArea: boolean;
    rotationSpeed: number;
    fightDistance: number;
    directionDuration: number;
    animationClipName: string;
    fallDelay: number;
    weapon: WEAPON_TYPES;
}

export interface PlatformBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.PLATFORM; // Value is a string "Platform"
    vertical: number;
    horizontalX: number;
    horizontalZ: number;
    respawn: PLATFORM_RESPAWN_TYPES; // possible options here: "Respawn", "Loop", "Play Once"
    speed: number;
    startOnTrigger: boolean;
}

export interface JumpPadBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.JUMPPAD; // Value is a string "Platform"
    startOnTrigger: boolean;
    jumpPadStrength: number;
    jumpPadAngle: number;
}

export interface TeleportBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.TELEPORT; // Value is a string "Platform"
    startOnTrigger: boolean;
    teleporterExitObject: string;
}

export interface SpawnPointBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.SPAWNPOINT;
    startOnTrigger: boolean;
    objectNameToSpawn: string;
    spawnType: SPAWNPOINT_TYPES;
}

export interface CharacterSoundsBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.CHARACTER_SOUNDS;
    jumping: SoundPropInterface;
    walking: SoundPropInterface;
    running: SoundPropInterface;
    dying: SoundPropInterface;
    volume: number;
}

export interface ThrowableBehaviorInterface extends BehaviorInterface {
    throwableStarting: boolean;
    type: OBJECT_TYPES.THROWABLES;
    model: ModelPropInterface;
    powerLevel: number;
    bounceEffect: number;
    aimer: boolean;
    aimerGuide: boolean;
    throwableVisible: boolean;
    throwableUIImage?: string;
    throwableMass: number;
    throwableSpeed: number;
    throwableColor: string;
    throwableLife: number;
    throwablePointAmount: number;
    throwableFriction: number;
    throwableRestitution: number;
    throwableInertia: number;
    throwableDamage: number;
    throwableAmount: number;
    muzzle_flash: boolean; //TODO move to effects behavior
    laser_effect: boolean; //TODO move to effects behavior
}

export interface WeaponAmmoBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.WEAPON_AMMO;
    ammoDamage: number;
    ammoBounceEffect: number;
    ammoFireRate: number;
    ammoMass: number;
    ammoLife: number;
    ammoSpeed: number;
    ammoFriction: number;
    ammoInertia: number;
    ammoRestitution: number;
}

export enum COLLISION_TYPE {
    UNKNOWN = -1,
    WITH_PLAYER,
    WITH_COLLIDABLE_OBJECTS,
    WITH_ENEMY,
}

export interface ICollisionSettings {
    disposable: boolean;
    playerCollision: boolean;
    enemyCollision: boolean;
    throwableCollision: boolean;
    canReappear: boolean;
}

export interface ITransformValue {
    x: number;
    y: number;
    z: number;
}

export enum TRANSFORMATION_OPTIONS {
    POSITION,
    ROTATION,
    SCALE,
}

export enum TRANSFORM_CONTROLS_MODE {
    TRANSLATE = "translate",
    ROTATE = "rotate",
    SCALE = "scale",
}

export enum WEAPON_AIMERS {
    AIMER_SCREEN_ZINDEX = 998,
}

export interface WeaponBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.WEAPON; // Value is a string "Weapon"
    weaponName: string;
    ui_tag: string;
    weaponStarting: boolean;
    weaponHUDImage: string;
    weaponType: WEAPON_TYPES;
    weaponDamage: number;
    weaponAimerZindex: number;
    weaponClipAmount: number;
    weaponFireSpeed: number;
    weaponReloadSpeed: number;
    weaponScopeZoom: number;
    weaponHUDAimerImage: string;
    weaponHUDAimerImageName: string;
    weaponShowHUDAimerInGame: boolean;
    weaponPreviewHUDAimer: boolean;
    weaponHUDAimerSize: number;
    VFXSmallEffect: boolean;
    VFXMediumEffect: boolean;
    VFXBigEffect: boolean;
    VFXLaserEffect: boolean;
    VFXCartoonyEffect: boolean;
    weaponAutoReload: boolean;
    position_x: number;
    position_y: number;
    position_z: number;
    rotation_x: number;
    rotation_y: number;
    rotation_z: number;
    selectedWeaponAmmoName: string;
    weaponAmmoVisible: boolean;
    weaponSelectedCharacterBone: string;
    weaponScale: number;
    weaponMuzzleFlashBrightness: number;
    weaponMuzzleSmokeDensity: number;
    weaponMuzzleSmokeSize: number;
    weaponMuzzleSmokeLife: number;
    weaponMuzzleSmokeOpacity: number;
}

export interface AiNPCBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.AI_NPC; // Value is a string "AI NPC"
    name: string;
    description: string;
    voice_id: string;
    range: number;
    additional_prompt: string;
    active_in_voice_chat: boolean;
}

export interface SceneVolumeAssetBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.SCENE_VOLUME; // Value is a string "Scene Volume"
    volumeType: SCENE_VOLUME_TYPES; // type of the scene volume
    options: WaterVolumeOptionsInterface | GravityVolumeOptionsInterface | CustomVolumeOptionsInterface | null;
}

export interface BillboardBehaviorInterface {
    id: string;
    type: OBJECT_TYPES.BILLBOARD; // Value is a string "Billboard"
    billboardMode: BILLBOARD_TYPES; // type of the billboard: video or file, values: "Webpage", "Image" or "YouTube Video"
    loop?: boolean;
    twoSided?: boolean;
    faceCamera?: boolean;
    assetFile?: string; // uploaded asset url
    urlLink?: string; // video or webpage url
}

export interface WaterVolumeOptionsInterface {
    swimSpeed: number; // swimming speed values between 1 and 5
    dps: number; // damage per second
}

export interface GravityVolumeOptionsInterface {
    strength: number; // gravity strength in procentage
}

export interface CustomVolumeOptionsInterface {
    type: CUSTOM_VOLUME_TYPES; // possible options here: "Level Changer"
    loseTime: number; // time in seconds to lose
    damageAmount: number; // damage in procentage
    losePoints: number; // points to lose
}

export enum CUSTOM_VOLUME_TYPES {
    LEVEL_CHANGER = "Level Changer",
    CUSTOM = "Custom",
}

export enum BILLBOARD_TYPES {
    WEB = "Webpage",
    YT_VIDEO = "YouTube Video",
    IMAGE = "Image",
}

export enum SCENE_VOLUME_TYPES {
    BLOCKING = "Blocking",
    KILL_VOLUME = "Kill",
    LOSE_VOLUME = "Lose",
    WIN_VOLUME = "Win",
    WATER = "Water",
    GRAVITY = "Gravity",
    CUSTOM = "Custom",
}

export enum SHADER_EFFECTS {
    BOKEH = "Bokeh Effect",
    PIXEL = "Pixel Effect",
    RGB = "RGB",
    NONE = "None",
}

export enum CAMERA_EFFECTS {
    BOKEH = "Bokeh",
    PIXEL = "Pixel",
    RGB = "RGB",
    NONE = "None",
}

export enum CAMERA_TYPES {
    FIRST_PERSON = "First Person",
    THIRD_PERSON = "Third Person",
    FORTNITE = "FortNite",
    TOP_DOWN = "Top Down",
    SIDE_SCROLLER = "Side Scroller",
    VEHICLE = "Vehicle",
}

export enum WEAPON_EFFECTS {
    GUN_MUZZLE_FLASH_PLANE_NAME = "gun_muzzle_flash_plane",
    WEAPON_HUD_AIMER_IMG_NAME = "weapon-hud-aimer-",
}

export enum MATERIAL_TYPES {
    MESH_BASIC = "MeshBasicMaterial",
    MESH_STANDARD = "MeshStandardMaterial",
    MESH_PHONG = "MeshPhongMaterial",
    MESH_TOON = "MeshToonMaterial",
    MESH_PHYSICAL = "MeshPhysicalMaterial",
}

export enum SHADER_EFFECTS_PROPS {
    BOKEH = "bokeh",
    PIXEL = "pixel",
    RGB = "rgbShift",
    NONE = "none",
}

//physics
enum CollisionType {
    Dynamic = "Dynamic",
    Kinematic = "Kinematic",
    Static = "Static",
}

enum Shape {
    btBoxShape = "BoxShape",
    btSphereShape = "SphereShape",
    btConvexHullShape = "ConvexHullShape",
    btCapsuleShape = "CapsuleShape",
}

interface IPhysics {
    enabled: boolean;
    shape: keyof Shape;
    mass: number;
    inertia: {
        x: number;
        y: number;
        z: number;
    };
    ctype: CollisionType;
    position: {
        x: number;
        y: number;
        z: number;
    };
    scale: {
        x: number;
        y: number;
        z: number;
    };
    rotation: {
        x: number;
        y: number;
        z: number;
    };
    enable_preview: boolean;
}

export enum COLLISION_MATERIAL_TYPE {
    METAL = "Metal",
    DIRT = "Dirt",
    GROUND = "Ground",
    PLASTIC = "Plastic",
    SNOW = "Snow",
    WOOD = "Wood",
    CONCRETE = "Concrete",
    MUD = "Mud",
    ICE = "Ice",
    SLIME = "Slime",
    WATER = "Water",
    SLIPPERY_GROUND = "Slippery ground",
    RUBBER = "Rubber",
    SAND = "Sand",
}

export interface ILightState {
    label?: string;
    show: boolean;
    showColor?: boolean;
    color?: string;
    showIntensity?: boolean;
    intensity?: number;
    showDistance?: boolean;
    showDecay?: boolean;
    distance?: number;
    decay?: number;
    showAngle?: boolean;
    showPenumbra?: boolean;
    angle?: number;
    penumbra?: number;
    showSkyColor?: boolean;
    showGroundColor?: boolean;
    skyColor?: string;
    groundColor?: string;
    showWidth?: boolean;
    showHeight?: boolean;
    showCastShadow?: boolean;
    castShadow?: boolean;
    width?: number;
    height?: number;
}

export interface VehicleOptionsInterface {
    acceleration: number;
    maxSpeed: number;
    leftFrontWheel: string;
    rightFrontWheel: string;
    leftRearWheel: string;
    rightRearWheel: string;
    steeringWheel: string;
    trackModel: string;
    trackSurface: string;
    trackBoundary: string;
    engineHorsepower: number;
    tireFriction: number;
    brakeForce: number;
    cameraMINDistance: number;
    cameraMAXDistance: number;
}

//AI responses

export interface IAiTransformResponse {
    scale: ITransformMessageData[];
    rotation: ITransformMessageData[];
    position: ITransformMessageData[];
}

export interface IAiPhysicsResponse {
    attach: IPhysicsAttachMessageData[];
    detach: IPhysicsDetachMessageData[];
    update: IPhysicsUpdateMessageData[];
}

export interface IAiBehaviorsResponse {
    attach: (Omit<IBehaviorsAttachMessageData, "types"> | Omit<IBehaviorsAttachMessageData, "behaviors">)[];
    detach: IBehaviorsDetachMessageData[];
    update: IBehaviorsUpdateMessageData[];
}

export interface IAiResponse {
    transform: IAiTransformResponse;
    physics: IAiPhysicsResponse;
    behaviors: IAiBehaviorsResponse;
}

// messages

export interface ITransformMessageData {
    objectId: string;
    values: {x: number; y: number; z: number};
}

export interface IPhysicsAttachMessageData {
    objectId: string;
    physics?: IPhysics; // pass physics to attach custom physics, else default physics will be used
}

export interface IPhysicsDetachMessageData {
    objectId: string;
}

export interface IPhysicsUpdateMessageData {
    objectId: string;
    physics: Partial<IPhysics>; // pass partial physics to update existing physics;
}

export type IPhysicsMessageData = IPhysicsAttachMessageData | IPhysicsDetachMessageData | IPhysicsUpdateMessageData;

export interface IBehaviorsAttachMessageData {
    objectId: string;
    types: OBJECT_TYPES[]; // provide types to add default behaviors or detach
    behaviors: IBehavior[]; // provide behaviors to create custom behaviors or update existing behaviors with partial data
}

export interface IBehaviorsDetachMessageData {
    objectId: string;
    types: OBJECT_TYPES[]; // provide types to remove behaviors
}

export interface IBehaviorsUpdateMessageData {
    objectId: string;
    behaviors: (Partial<IBehavior> & Pick<IBehavior, "type">)[]; // update existing behaviors with partial data
}

export type IBehaviorsMessageData = IPhysicsAttachMessageData | IPhysicsDetachMessageData | IPhysicsUpdateMessageData;

// Example response
const apiResponse = {
    transform: {
        scale: [
            {
                objectId: "obj1",
                values: {x: 2, y: 2, z: 2},
            },
            {
                objectId: "obj2",
                values: {x: 3, y: 3, z: 4},
            },
            {
                objectId: "obj3",
                values: {x: 10, y: 10, z: 10},
            },
        ],
        rotation: [
            {
                objectId: "obj1",
                values: {x: 90, y: 90, z: 90},
            },
            {
                objectId: "obj2",
                values: {x: 120, y: 100, z: 45},
            },
            {
                objectId: "obj3",
                values: {x: 45, y: 45, z: 45},
            },
        ],
        position: [
            {
                objectId: "obj1",
                values: {x: 10, y: 20, z: 30},
            },
            {
                objectId: "obj2",
                values: {x: 15, y: 25, z: 35},
            },
            {
                objectId: "obj3",
                values: {x: 20, y: 30, z: 40},
            },
        ],
    },
    physics: {
        attach: [
            {
                objectId: "obj1",
                physics: {
                    enabled: true,
                    shape: "btBoxShape",
                    mass: 0,
                    inertia: {x: 0, y: 0, z: 0},
                    restitution: 0.5,
                    ctype: "Static",
                    position: {x: 10, y: 20, z: 30},
                    scale: {x: 1, y: 1, z: 1},
                    rotation: {x: 0, y: 0, z: 0},
                    enable_preview: true,
                    friction: 0.5,
                },
            },
            {
                objectId: "obj2",
                physics: {
                    enabled: true,
                    shape: "btSphereShape",
                    mass: 20,
                    inertia: {x: 0, y: 0, z: 0},
                    restitution: 0.6,
                    ctype: "Dynamic",
                    position: {x: 15, y: 25, z: 35},
                    scale: {x: 1, y: 1, z: 1},
                    rotation: {x: 0, y: 0, z: 0},
                    enable_preview: true,
                    friction: 0.5,
                },
            },
            {
                objectId: "obj3",
            },
        ],
        detach: [
            {
                objectId: "obj4",
            },
        ],
        update: [
            {
                objectId: "obj5",
                physics: {
                    enabled: true,
                    mass: 60,
                },
            },
        ],
    },
    behaviors: {
        attach: [
            {
                objectId: "obj1",
                behaviors: [
                    {
                        type: "Platform",
                        enablePlatform: true,
                        vertical: 10,
                        horizontalX: 5,
                        horizontalZ: 5,
                        respawn: "Respawn",
                        speed: 2,
                        enableJumpPad: true,
                        jumpPadStrength: 15,
                        jumpPadAngle: 45,
                        isTeleporter: false,
                        teleporterExitObject: "",
                        startOnTrigger: false,
                    },
                ],
            },
            {
                objectId: "obj2",
                types: ["Character"],
            },
            {
                objectId: "obj3",
                behaviors: [
                    {
                        type: "Enemy",
                        enemyType: "Aggressive",
                        enemyEnabled: true,
                        health: 50,
                        movementSpeed: 3,
                        attackDamage: 10,
                        attackDistance: 5,
                        attackSpeed: 1.5,
                        respawnAmount: 3,
                        roamDistance: 10,
                        showRoamArea: false,
                        rotationSpeed: 2,
                        fightDistance: 2,
                        directionDuration: 1,
                        animationClipName: "attack",
                    },
                ],
            },
        ],
        detach: [
            {
                objectId: "obj4",
                types: ["Enemy", "Character"],
            },
        ],
        update: [
            {
                objectId: "obj6",
                behaviors: [
                    {
                        type: "Enemy",
                        enemyType: "Aggressive",
                        enemyEnabled: true,
                        health: 50,
                    },
                ],
            },
        ],
    },
};

export interface ISoundSettings {
    id: string;
    url: string;
    loop: boolean;
    volume: number;
    soundType: "play-now" | "menu-background" | "";
}

export enum IFRAME_MESSAGES {
    GAME_STARTED = "gameStarted",
    GAME_RESUMED = "gameResumed",
    GAME_PAUSED = "gamePaused",
    GAME_ENDED = "gameEnded",
    GAME_CLOSED = "gameClosed",
    GAME_CREATED = "gameCreated",
    GAME_PLAYER_ERROR = "gamePlayerError",
    GAME_MULTIPLAYER_ERROR = "gameMultiplayerError",
    PLAYER_ADDED_LISTENER = "playerAddedListener",
}
