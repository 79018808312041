import global from "../../../../../global";
import {AiNPCBehaviorInterface} from "../../../../../types/editor";
import {useEffect, useState} from "react";
import AiNPCBehaviorConverter from "../../../../../serialization/behaviours/AiNpcBehaviorsConverter";
import {TextInputRow} from "../common/TextInputRow";
import {SelectRow} from "../common/SelectRow";
import Ajax from "../../../../../utils/Ajax";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import {NumericInputRow} from "../common/NumericInputRow";
import {PanelSectionTitle} from "../RightPanel.style";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";

type Props = {
    behavior: AiNPCBehaviorInterface;
};

type Voice = {
    key: string;
    value: string;
};

export const AiNPCBehaviors = ({behavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behaviorState, setBehaviorState] = useState(behavior);
    const [voices, setVoices] = useState<Voice[]>([
        {
            key: "9BWtsMINqrJLrRacOk9x",
            value: "Aria",
        },
    ]);

    const targetBehavior = AiNPCBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleInputChange = (value: any, name: keyof AiNPCBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehaviorState({...targetBehavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await Ajax.get({
                    url: backendUrlFromPath("/api/AI/Voices") || "",
                    needAuthorization: false,
                });
                if (response?.data) {
                    setVoices(response.data.voices.map((voice: any) => ({key: voice.voice_id, value: voice.name})));
                    console.log(response.data);
                }
                //setVoices(response.data);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    return (
        <>
            <PanelSectionTitle>Agent Details</PanelSectionTitle>
            <Separator invisible margin="6px" />
            <TextInputRow
                isColumn
                label="Name"
                width="100%"
                value={behaviorState.name}
                setValue={value => handleInputChange(value, "name")}
                color="#FAFAFA"
            />
            <TextInputRow
                type="textarea"
                isColumn
                placeholder="Describe your agent.."
                label="Persona Description"
                width="100%"
                value={behaviorState.description}
                setValue={value => handleInputChange(value, "description")}
                height="120px"
            />
            <Separator margin="6px 0" />
            <PanelCheckbox
                text="Active in Voice Chat"
                checked={!!behaviorState.active_in_voice_chat}
                onChange={() => handleInputChange(!behaviorState.active_in_voice_chat, "activeInVoiceChat")}
            />
            <Separator invisible margin="4px" />
            <SelectRow
                label="Voice"
                value={voices.find(el => el.key === behaviorState.voice_id)}
                onChange={item => handleInputChange(item.key, "voice_id")}
                data={voices}
            />
            <NumericInputRow
                label="Conversation range"
                value={behaviorState.range}
                setValue={value => handleInputChange(value, "range")}
                width="75px"
                min={0}
            />
            <TextInputRow
                placeholder="Write a description..."
                type="textarea"
                isColumn
                label="Additional Prompt"
                width="100%"
                value={behaviorState.additional_prompt}
                setValue={value => handleInputChange(value, "additional_prompt")}
                height="120px"
            />
        </>
    );
};
