import * as THREE from "three";
import "../css/Section.css";
import {ILightState} from "../../../../../types/editor";
import {NumericInput} from "../../common/NumericInput";
import StyledColorPicker from "../../common/StyledColorPicker/StyledColorPicker";
import {useLightingContext} from "../../../../../context";
import global from "../../../../../global";
import {PanelSectionTitleSecondary} from "../RightPanel.style";
import {Checkbox} from "../../../../../ui/common/Checkbox";
import {InputSymbol} from "../../common/InputSymbol";

type AxisType = "x" | "y" | "z";

const AxisArray: AxisType[] = ["x", "y", "z"];
interface Props {
    lightState: ILightState;
    isLocked?: boolean;
    selectedObj: any;
}

export const LightingSection = ({lightState, isLocked, selectedObj}: Props) => {
    const {
        showColor,
        color,
        showSkyColor,
        skyColor,
        showGroundColor,
        groundColor,
        showIntensity,
        intensity,
        showDecay,
        decay,
        showDistance,
        distance,
        showAngle,
        angle,
        showPenumbra,
        penumbra,
        showWidth,
        width,
        showHeight,
        height,
        showCastShadow,
        castShadow,
        showTarget,
        target,
    } = lightState;

    const {
        setColorChangeActivated,
        colorChangeActivated,
        setSkyColorChangeActivated,
        skyColorChangeActivated,
        setGroundColorChangeActivated,
        groundColorChangeActivated,
    } = useLightingContext();

    const app = (global as any).app;

    const handleValueChange = (value: any, key: string) => {
        const selected = app.editor.objectByUuid(selectedObj.uuid);
        if (selected) {
            selected[key] = value;
            app.call(`objectChanged`, selected, selected);
        }
    };

    const handleColorChange = (value: string) => {
        const selected = app.editor.objectByUuid(selectedObj.uuid);
        if (selected && showColor && value !== undefined) {
            selected.color = new THREE.Color(value);

            let helper = selected.children.filter((n: any) => n.userData.type === "helper")[0];

            if (helper) {
                helper.material.color = selected.color;
            }
            app.call(`objectChanged`, selected, selected);
        }
    };

    const handleSkyColorChange = (value: string) => {
        const selected = app.editor.objectByUuid(selectedObj.uuid);
        if (selected && showSkyColor && value !== undefined) {
            selected.color = new THREE.Color(value);

            let sky = selected.children.filter((n: any) => n.userData.type === "sky")[0];

            if (sky) {
                sky.material.uniforms.topColor.value = selected.color;
            }
            app.call(`objectChanged`, selected, selected);
        }
    };

    const handleGroundColorChange = (value: string) => {
        const selected = app.editor.objectByUuid(selectedObj.uuid);
        if (selected && showGroundColor && value !== undefined) {
            selected.groundColor = new THREE.Color(value);

            let ground = selected.children.filter((n: any) => n.userData.type === "sky")[0];

            if (ground) {
                ground.material.uniforms.bottomColor.value = selected.groundColor;
            }

            app.call(`objectChanged`, selected, selected);
        }
    };

    const handleTargetChange = (value: number, toUpdate: AxisType) => {
        const selected = app.editor.objectByUuid(selectedObj.uuid);
        if (selected && showTarget && value !== undefined) {
            const targetObject = new THREE.Object3D();
            const target = selected.target as THREE.Object3D;

            switch (toUpdate) {
                case "x":
                    targetObject.position.set(value, target.position.y, target.position.z);
                    break;
                case "y":
                    targetObject.position.set(target.position.x, value, target.position.z);
                    break;
                case "z":
                    targetObject.position.set(target.position.x, target.position.y, value);
                    break;
            }

            selected.target = targetObject;
            selected.target.updateMatrixWorld(true);

            app.call(`objectChanged`, selected, selected);
        }
    };

    return (
        <div className="Section">
            {showColor && (
                <div className="box">
                    <PanelSectionTitleSecondary>Color</PanelSectionTitleSecondary>
                    <div
                        className="color-box"
                        style={
                            !isLocked
                                ? {backgroundColor: color}
                                : {
                                      backgroundColor: color,
                                      pointerEvents: "none",
                                  }
                        }
                        onClick={() => setColorChangeActivated(true)}
                    />
                    {colorChangeActivated && (
                        <StyledColorPicker
                            color={color || "#ffffff"}
                            setColor={value => handleColorChange(value)}
                            hide={() => setColorChangeActivated(false)}
                        />
                    )}
                </div>
            )}
            {showSkyColor && (
                <div className="box">
                    <PanelSectionTitleSecondary>Sky Color</PanelSectionTitleSecondary>
                    <div
                        className="color-box"
                        style={
                            !isLocked
                                ? {backgroundColor: skyColor}
                                : {
                                      backgroundColor: skyColor,
                                      pointerEvents: "none",
                                  }
                        }
                        onClick={() => setSkyColorChangeActivated(true)}
                    />
                    {skyColorChangeActivated && (
                        <StyledColorPicker
                            color={skyColor || "#ffffff"}
                            setColor={(value: string) => handleSkyColorChange(value)}
                            hide={() => setSkyColorChangeActivated(false)}
                        />
                    )}
                </div>
            )}
            {showGroundColor && (
                <div className="box">
                    <PanelSectionTitleSecondary>Ground Color</PanelSectionTitleSecondary>
                    <div
                        className="color-box"
                        style={
                            !isLocked
                                ? {backgroundColor: groundColor}
                                : {
                                      backgroundColor: groundColor,
                                      pointerEvents: "none",
                                  }
                        }
                        onClick={() => setGroundColorChangeActivated(true)}
                    />
                    {groundColorChangeActivated && (
                        <StyledColorPicker
                            color={groundColor || "#ffffff"}
                            setColor={(value: string) => handleGroundColorChange(value)}
                            hide={() => setGroundColorChangeActivated(false)}
                        />
                    )}
                </div>
            )}
            {showIntensity && (
                <div className="box">
                    <PanelSectionTitleSecondary>Intensity</PanelSectionTitleSecondary>
                    <NumericInput
                        className={"numeric-input"}
                        value={intensity || 0}
                        setValue={value => handleValueChange(value, "intensity")}
                        disabled={isLocked}
                    />
                </div>
            )}
            {showDistance && (
                <div className="box">
                    <PanelSectionTitleSecondary>Distance</PanelSectionTitleSecondary>
                    <NumericInput
                        className={"numeric-input"}
                        value={distance || 0}
                        setValue={value => handleValueChange(value, "distance")}
                        disabled={isLocked}
                    />
                </div>
            )}
            {showDecay && (
                <div className="box">
                    <PanelSectionTitleSecondary>Decay</PanelSectionTitleSecondary>
                    <NumericInput
                        className={"numeric-input"}
                        value={decay || 0}
                        setValue={value => handleValueChange(value, "decay")}
                        disabled={isLocked}
                    />
                </div>
            )}
            {showAngle && (
                <div className="box">
                    <PanelSectionTitleSecondary>Angle</PanelSectionTitleSecondary>
                    <NumericInput
                        className={"numeric-input"}
                        value={angle || 0}
                        setValue={value => handleValueChange(value, "angle")}
                        disabled={isLocked}
                    />
                </div>
            )}
            {showPenumbra && (
                <div className="box">
                    <PanelSectionTitleSecondary>Penumbra</PanelSectionTitleSecondary>
                    <NumericInput
                        className={"numeric-input"}
                        value={penumbra || 0}
                        setValue={value => handleValueChange(value, "penumbra")}
                        disabled={isLocked}
                    />
                </div>
            )}
            {showWidth && (
                <div className="box">
                    <PanelSectionTitleSecondary>Width</PanelSectionTitleSecondary>
                    <NumericInput
                        className={"numeric-input"}
                        value={width || 0}
                        setValue={value => handleValueChange(value, "width")}
                        disabled={isLocked}
                    />
                </div>
            )}
            {showHeight && (
                <div className="box">
                    <PanelSectionTitleSecondary>Height</PanelSectionTitleSecondary>
                    <NumericInput
                        className={"numeric-input"}
                        value={height || 0}
                        setValue={value => handleValueChange(value, "height")}
                        disabled={isLocked}
                    />
                </div>
            )}

            {showCastShadow && (
                <div className="box">
                    <PanelSectionTitleSecondary>Cast Shadow</PanelSectionTitleSecondary>
                    <Checkbox
                        customId="castShadow"
                        checked={!!castShadow}
                        onChange={e => handleValueChange(e.target.checked, "castShadow")}
                    />
                </div>
            )}
            {showTarget && (
                <div className="box column" style={{gap: 8}}>
                    <PanelSectionTitleSecondary>Target Position</PanelSectionTitleSecondary>
                    <div className="box" style={{gap: 8}}>
                        {AxisArray.map((axis: AxisType) => {
                            return (
                                <div className="inputWrapper" key={axis}>
                                    <InputSymbol
                                        symbol={axis.toUpperCase()}
                                        value={+(target?.position[axis as keyof THREE.Vector3] || 0)}
                                        setValue={value => handleTargetChange(value, axis)}
                                        isLocked={false}
                                    />
                                    <NumericInput
                                        id={"light target" + axis}
                                        value={+(target?.position[axis as keyof THREE.Vector3] || 0)}
                                        setValue={value => {
                                            return null;
                                        }}
                                        className="dark-input"
                                        onBlur={value => handleTargetChange(value, axis)}
                                        onDragValueChange={value => handleTargetChange(value, axis)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
