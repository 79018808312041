import styled from "styled-components";
import {ReactNode} from "react";
import {flexCenter, regularFont} from "../../../../assets/style";

export const EmptyBox = ({text, children}: {text: string; children?: ReactNode}) => {
    return (
        <StyledBox>
            <div>{text}</div>
            {children}
        </StyledBox>
    );
};

const StyledBox = styled.div`
    width: 100%;
    height: 220px;
    ${flexCenter};
    flex-direction: column;
    row-gap: 8px;
    background: var(--theme-homepage-grey-bg-primary);
    border: 1px solid var(--theme-homepage-grey-bg-secondary);
    border-radius: 16px;
    ${regularFont("l")};
    font-weight: var(--theme-font-bold);
    color: var(--theme-homepage-placeholder-color);
`;
