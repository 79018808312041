import React from "react";
import {LightingContext} from "./LightingContext";
import {AuthorizationContext} from "./AuthorizationContext";
import {AnimationContext} from "./AnimationContext";
import {HUDContext} from "./HUDContext";
import {HUDStartGameMenuContext} from "./HUDStartGameMenuContext";
import {HUDGameContext} from "./HUDGameContext";
import {HUDInGameMenuContext} from "./HUDInGameMenuContext";
import {HomepageContext} from "./HomepageContext";
import {ModelAnimationCombinerContext} from "./ModelAnimationCombinerContext";
import {AppGlobalContext} from "./AppGlobalContext";

export const useLightingContext = () => {
    return React.useContext(LightingContext);
};
export const useAnimationContext = () => {
    return React.useContext(AnimationContext);
};
export const useAuthorizationContext = () => {
    return React.useContext(AuthorizationContext);
};
export const useHUDContext = () => {
    return React.useContext(HUDContext);
};

export const useHUDStartGameMenuContext = () => {
    return React.useContext(HUDStartGameMenuContext);
};

export const useHUDInGameMenuContext = () => {
    return React.useContext(HUDInGameMenuContext);
};

export const useHUDGameContext = () => {
    return React.useContext(HUDGameContext);
};

export const useHomepageContext = () => {
    return React.useContext(HomepageContext);
};
export const useModelAnimationCombinerContext = () => React.useContext(ModelAnimationCombinerContext);

export const useAppGlobalContext = () => React.useContext(AppGlobalContext);
