import {FormEvent, useCallback, useEffect, useState} from "react";
import * as THREE from "three";
import {ContentItem, ExpandButton, TabContent, Tag, Wrapper} from "./GameSettings.style";
import {useAppGlobalContext} from "../../../../../../context";
import {NumericInput} from "../../../common/NumericInput";
import {UploadField} from "../../../common/UploadField/UploadField";
import global from "../../../../../../global";
import {PanelCheckbox} from "../../common/PanelCheckbox";
import {PanelSectionTitle, PanelSectionTitleSecondary} from "../../RightPanel.style";
import {GAME_IMAGE_SIZE} from "../../../../../../editorConfig";
import {StyledButton} from "../../../common/StyledButton";
import {debounce} from "lodash";
import {backendUrlFromPath} from "../../../../../../utils/UrlUtils";
import Ajax from "../../../../../../utils/Ajax";
import {TextInput} from "../../../common/TextInput";
import {StyledTextarea} from "../../../common/StyledTextarea";
import deleteIcon from "../../icons/x-small.svg";
import {Separator} from "../../common/Separator";
import {Tooltip} from "../../../common/Tooltip";
import expandArrow from "../../icons/expand-arrow.svg";

/*const SHADER_DEFAULT_OPTIONS = {
    bokeh: {
        focus: 50,
        aperture: 2.8,
        maxBlur: 1,
    },
    pixel: {
        pixelSize: 8,
    },
    rgbShift: {
        amount: 0.1,
    },
};*/
const initialGridColor = "#A1A1AA";
const initialBgColor = "#27272A";

export const GameSettings = ({openUIPanel}: {openUIPanel: () => void}) => {
    const app = (global as any).app as any;
    const [useAvatar, setUseAvatar] = useState(!!app.editor?.useAvatar);
    const [isMultiplayer, setIsMultiplayer] = useState(!!app.editor?.isMultiplayer);
    const [voiceChatEnabled, setVoiceChatEnabled] = useState(!!app.editor?.voiceChatEnabled);
    const [game, setGame] = useState({
        uuid: app.editor?.scene?.userData?.game?.uuid || "", //FIXME: .game is NOT uuid
        enabled: !!app.editor?.isGame(),
        lives: 3,
        maxScore: 500,
        timer: 200,
        bannerImage: "",
    });
    const [name, setName] = useState(app.editor?.sceneName || "");
    const [description, setDescription] = useState(app.editor?.description || "");
    const [newTag, setNewTag] = useState("");
    const [tags, setTags] = useState<string[]>(app.editor?.tags || []);
    const [useWebGPU, setUseWebGPU] = useState(!!app.editor?.useWebGPU);
    const [isFirstLevel, setIsFirstLevel] = useState(true);
    const [gameDetailsExpanded, setGameDetailsExpanded] = useState(true);

    const {
        isGameSettingsPanelOpen,
        setIsGameSettingsPanelOpen,
        isCheckPointPanelOpen,
        isSpawnPointPanelOpen,
        isVolumePanelOpen,
    } = useAppGlobalContext();

    const handleUseWebGPUChange = (useWebGPU: boolean) => {
        if (app.editor) {
            app.editor.useWebGPU = useWebGPU;
            setUseWebGPU(useWebGPU);
        }
    };

    const debouncedSceneChange = useCallback(
        debounce(data => {
            Ajax.post({
                url: backendUrlFromPath(`/api/Scene/Edit`),
                data: {
                    Name: data.name,
                    ID: app.editor?.sceneID,
                    Description: data.description,
                    Tags: JSON.stringify(data.tags),
                },
            }).then(response => {
                if (response?.data.Code === 200 && app.editor) {
                    app.editor.sceneName = data.name;
                    app.editor.description = data.description;
                    app.editor.tags = data.tags;
                    app.call(`clear`, app.editor, app.editor);
                }
            });
        }, 500),
        [app],
    );

    const handleNameChange = (value: string) => {
        setName(value);
        app.editor.sceneName = value;
        app.call("sceneNameUpdated");
        debouncedSceneChange({name: value, description, tags});
    };

    const handleDescriptionChange = (value: string) => {
        setDescription(value);
        app.editor.description = value;
        debouncedSceneChange({name, description: value, tags});
    };

    const handleAddTag = (e: FormEvent) => {
        e.preventDefault();
        const tagsValue = [...tags, newTag];
        setTags(tagsValue);
        setNewTag("");
        app.editor.tags = tagsValue;
        debouncedSceneChange({name, description, tags: tagsValue});
    };

    const handleRemoveTag = (index: number) => {
        const tagsValue = [...tags.slice(0, index), ...tags.slice(index + 1)];
        setTags(tagsValue);
        app.editor.tags = tagsValue;
        debouncedSceneChange({name, description, tags: tagsValue});
    };

    /* maybe will be used in future */
    /*const handleShaderEffectChange = (obj: {key: string; value: string}) => {
        if (app.editor) {
            let postProcessing = app.editor?.scene.userData.postProcessing || {};

            Object.values(SHADER_EFFECTS_PROPS).forEach(value => {
                if (postProcessing && postProcessing[value]) {
                    postProcessing[value] = {
                        ...postProcessing[value],
                        enabled: false,
                    };
                }
            });

            if (obj.value !== SHADER_EFFECTS.NONE) {
                postProcessing = {
                    ...postProcessing,
                    [SHADER_EFFECTS_PROPS[obj.key as keyof typeof SHADER_EFFECTS_PROPS]]: {
                        ...postProcessing[SHADER_EFFECTS_PROPS[obj.key as keyof typeof SHADER_EFFECTS_PROPS]],
                        ...SHADER_DEFAULT_OPTIONS[
                            SHADER_EFFECTS_PROPS[
                                obj.key as keyof typeof SHADER_EFFECTS_PROPS
                            ] as keyof typeof SHADER_DEFAULT_OPTIONS
                        ],
                        enabled: true,
                    },
                };
            }
            app.editor.scene.userData.postProcessing = postProcessing;

            setShaderEffect(obj.key);
            app.call(`objectChanged`, app.editor, app.editor.scene);
            app.call(`postProcessingChanged`, app.editor);
        }
    };*/

    /*const handleColorChange = (color: string) => {
        setGridColor(color);

        const editor = app.editor;
        const sceneHelpers = editor?.sceneHelpers;

        if (sceneHelpers) {
            sceneHelpers.userData.infiniteGridColor = color;
            sceneHelpers.traverse((child: any) => {
                if (child.userData.isInfiniteGrid) {
                    child.material.uniforms.uColor.value.set(color);
                }
            });
        }
    };

    const handleBackgroundColorChange = (color: string) => {
        setBackgroundColor(color);

        const editor = app.editor;
        if (editor) {
            const scene = editor.scene;

            scene.background = new THREE.Color(color);
            scene.userData.backgroundColor = color;
        }
    };*/

    const removeSelected = () => {
        if (app?.editor?.selected && app.editor.selected.type !== "Scene") {
            setIsGameSettingsPanelOpen(false);
        }
    };

    const handleInputChange = (value: any, name: string) => {
        setGame(prevState => {
            let newState = {
                ...prevState,
                [name]: value,
            };
            app.editor.scene.userData.game = newState;
            return newState;
        });
    };

    const updateGame = () => {
        const gameData = app.editor?.scene?.userData?.game;
        if (gameData) {
            setGame(gameData);
        }
    };

    const handleAvatarCheckboxChange = (checked: boolean) => {
        app.editor.useAvatar = checked;
        setUseAvatar(checked);
    };

    const handleMultiplayerCheckboxChange = (checked: boolean) => {
        app.editor.isMultiplayer = checked;
        setIsMultiplayer(checked);
    };

    const handleVoiceChatCheckboxChange = (checked: boolean) => {
        app.editor.voiceChatEnabled = checked;
        setVoiceChatEnabled(checked);
    };

    const update = () => {
        if (isGameSettingsPanelOpen) {
            app.editor.scene.userData.game = app.editor.scene.userData.game || {
                uuid: THREE.MathUtils.generateUUID(),
                enabled: true,
                lives: 3,
                maxScore: 500,
            };
            setGame(app.editor.scene.userData.game);
            setUseAvatar(!!app.editor?.useAvatar);
            setIsMultiplayer(!!app.editor?.isMultiplayer);
            setVoiceChatEnabled(!!app.editor?.voiceChatEnabled);
            setName(app.editor.sceneName || "");
            setDescription(app.editor.description || "");
            setTags(app.editor.tags || []);
        }
    };

    useEffect(() => {
        app.on(`objectSelected.GameSettings`, removeSelected);
        app.on("sceneSaved.GameSettings", updateGame);
        app.on("sceneLoaded.GameSettings", () => {
            updateGame();
            update();
        });
        app.on("clear.GameSettings", update);

        return () => {
            app.on(`objectSelected.GameSettings`, null);
            app.on("sceneSaved.GameSettings", null);
            app.on("sceneLoaded.GameSettings", null);
            app.on("clear.GameSettings", null);
        };
    }, [app.editor]);

    useEffect(() => {
        update();
    }, [isGameSettingsPanelOpen]);

    return isGameSettingsPanelOpen && !isCheckPointPanelOpen && !isSpawnPointPanelOpen && !isVolumePanelOpen ? (
        <>
            <TabContent>
                <ContentItem $padding={"4px 0 8px"}>
                    <PanelSectionTitle>Game Details</PanelSectionTitle>
                    <ExpandButton $expanded={gameDetailsExpanded}>
                        <img src={expandArrow} onClick={() => setGameDetailsExpanded(!gameDetailsExpanded)} />
                    </ExpandButton>
                </ContentItem>
                {gameDetailsExpanded && (
                    <>
                        <ContentItem>
                            <PanelSectionTitleSecondary>Name</PanelSectionTitleSecondary>
                            <TextInput
                                value={name}
                                setValue={value => handleNameChange(value)}
                                height="24px"
                                width="100%"
                            />
                        </ContentItem>
                        <ContentItem>
                            <PanelSectionTitleSecondary>Description</PanelSectionTitleSecondary>
                            <StyledTextarea
                                value={description}
                                setValue={value => handleDescriptionChange(value)}
                                height="64px"
                                width="100%"
                                placeholder="Write a description..."
                            />
                        </ContentItem>
                        <ContentItem>
                            <PanelSectionTitleSecondary>Image</PanelSectionTitleSecondary>
                            <UploadField
                                width="100%"
                                height="112px"
                                style={{backgroundColor: "#27272A"}}
                                withButton
                                uploadedFile={game.bannerImage}
                                setUploadedFile={imageUrl => handleInputChange(imageUrl, "bannerImage")}
                                size={GAME_IMAGE_SIZE}
                            />
                        </ContentItem>
                        <ContentItem>
                            <PanelSectionTitleSecondary>Tags</PanelSectionTitleSecondary>
                            <form name="tags" onSubmit={e => handleAddTag(e)} style={{width: "100%"}}>
                                <TextInput
                                    placeholder="Add tags..."
                                    value={newTag}
                                    setValue={value => setNewTag(value)}
                                    height="24px"
                                    width="100%"
                                />
                            </form>
                        </ContentItem>
                        {tags.length > 0 && (
                            <ContentItem
                                $flexDirection="row"
                                $alignItems="flex-start"
                                $justifyContent="flex-start"
                                $columnGap="8px">
                                {tags.map((tag, index) => (
                                    <Tag key={index}>
                                        {tag} <img src={deleteIcon} onClick={() => handleRemoveTag(index)} />
                                    </Tag>
                                ))}
                            </ContentItem>
                        )}
                    </>
                )}

                <Separator margin="4px 0" />
                <ContentItem>
                    <PanelSectionTitle>Game Settings</PanelSectionTitle>
                </ContentItem>
                <ContentItem>
                    <PanelCheckbox
                        v2
                        text="Is a Game"
                        checked={!!game.enabled}
                        isGray
                        regular
                        onChange={() => handleInputChange(!game.enabled, "enabled")}
                    />
                    <PanelCheckbox
                        v2
                        text="Is Multiplayer"
                        checked={isMultiplayer}
                        isGray
                        regular
                        onChange={() => handleMultiplayerCheckboxChange(!isMultiplayer)}
                    />
                    <PanelCheckbox
                        v2
                        text="Enable Voice Chat"
                        checked={voiceChatEnabled}
                        isGray
                        regular
                        onChange={() => handleVoiceChatCheckboxChange(!voiceChatEnabled)}
                    />
                    <PanelCheckbox
                        v2
                        text="Enable WebGPU"
                        checked={!!useWebGPU}
                        isGray
                        regular
                        onChange={() => handleUseWebGPUChange(!useWebGPU)}
                    />
                    <PanelCheckbox
                        v2
                        text="Use Player Avatar as Character"
                        checked={useAvatar}
                        isGray
                        regular
                        onChange={() => handleAvatarCheckboxChange(!useAvatar)}
                    />
                </ContentItem>

                <Separator margin="4px 0" />
                <ContentItem>
                    <PanelSectionTitle>Game UI</PanelSectionTitle>
                    <StyledButton
                        style={{fontSize: "var(--theme-font-size-xs)"}}
                        isGreySecondary
                        onClick={openUIPanel}
                        disabled={!game.enabled}>
                        Edit Game UI
                    </StyledButton>
                </ContentItem>
                <Separator margin="4px 0" />
                <ContentItem>
                    <PanelSectionTitle>Level Details</PanelSectionTitle>
                </ContentItem>
                <ContentItem>
                    <PanelSectionTitleSecondary>Name</PanelSectionTitleSecondary>
                    <TextInput value={name} setValue={value => handleNameChange(value)} height="24px" width="100%" />
                    <PanelCheckbox
                        v2
                        text="Is first level"
                        checked={isFirstLevel}
                        isGray
                        regular
                        onChange={() => setIsFirstLevel(!isFirstLevel)}
                    />
                </ContentItem>
                <Separator margin="4px 0" />
                <ContentItem>
                    <PanelSectionTitle>Level Rules</PanelSectionTitle>
                </ContentItem>
                <ContentItem
                    $flexDirection="row"
                    $justifyContent="space-between"
                    $alignItems="center"
                    $padding="0 0 8px">
                    <PanelSectionTitleSecondary>Max Score</PanelSectionTitleSecondary>
                    <NumericInput
                        value={game.maxScore}
                        setValue={value => handleInputChange(value, "maxScore")}
                        rightAlign
                    />
                </ContentItem>
                <ContentItem
                    $flexDirection="row"
                    $justifyContent="space-between"
                    $alignItems="center"
                    $padding="0 0 8px">
                    <PanelSectionTitleSecondary>Max Lives</PanelSectionTitleSecondary>
                    <NumericInput value={game.lives} setValue={value => handleInputChange(value, "lives")} rightAlign />
                </ContentItem>
                <ContentItem
                    $flexDirection="row"
                    $justifyContent="space-between"
                    $alignItems="center"
                    $padding="0 0 8px">
                    <PanelSectionTitleSecondary>Timer</PanelSectionTitleSecondary>
                    <Wrapper>
                        <Tooltip text="Set timer to 0 for infinite time limit." width="100px" />
                        <NumericInput
                            padding="6px 16px 6px 14px"
                            value={game.timer}
                            setValue={value => handleInputChange(value, "timer")}
                            unit={"s"}
                            rightAlign
                        />
                    </Wrapper>
                </ContentItem>
            </TabContent>
        </>
    ) : null;
};
