import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import WeaponAmmoBehaviorUpdater from "./WeaponAmmoBehaviorUpdater";
import { WeaponAmmoBehaviorInterface, OBJECT_TYPES } from "../../types/editor";

class WeaponAmmoBehaviorConverter extends BaseBehaviorConverter<WeaponAmmoBehaviorInterface> {
    public static DEFAULT = new WeaponAmmoBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: WeaponAmmoBehaviorInterface): BehaviorUpdater {
        return new WeaponAmmoBehaviorUpdater(target);
    }


    reset(): void { }

    getBehavior(target: THREE.Object3D, id: string): WeaponAmmoBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as WeaponAmmoBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have wEAPON AMMO behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): WeaponAmmoBehaviorInterface {
        return {
            type: OBJECT_TYPES.WEAPON_AMMO,
            enabled: true,
            id,
        } as unknown as WeaponAmmoBehaviorInterface;
    }

}

export default WeaponAmmoBehaviorConverter;