import styled from "styled-components";
import {ITemplate} from "./constants/templates";
import {SingleTemplate} from "./SingleTemplate";

export type TemplateProps = {
    data: ITemplate[];
    selectedItemsIds?: string[];
    onClick: (id: string) => void;
    onDoubleClick?: (id: string, newTab?: boolean) => void;
};

export const TemplateList = ({data, selectedItemsIds, onClick, onDoubleClick}: TemplateProps) => {
    return (
        <StyledList>
            {data.map((item, index) => (
                <SingleTemplate
                    key={item.ID + index}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                    selectedItemsIds={selectedItemsIds}
                    item={item}
                />
            ))}
        </StyledList>
    );
};

const StyledList = styled.div`
    width: 100%;
    margin: 0 auto;
    display: inline-grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: start;
    justify-content: start;
    grid-gap: 12px;
    padding: 12px;
    overflow-y: auto;
    max-width: 100%;
`;
