import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import { IPhysics } from "src/physics/common/types";
import { SPAWNPOINT_TYPES } from "../../types/editor";
import { PhysicsUtil } from "../../physics/PhysicsUtil";

class SpawnPointBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;
    physics?: IPhysics;
    objectNameToSpawn?: string;
    spawnType?: SPAWNPOINT_TYPES;
    spawnedObject?: THREE.Object3D;

    constructor(target: THREE.Object3D, objectNameToSpawn: string, spawnType: SPAWNPOINT_TYPES) {
        this.target = target;
        this.objectNameToSpawn = objectNameToSpawn;
        this.spawnType = spawnType;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;

        if (!this.objectNameToSpawn || this.objectNameToSpawn === "") {
            return;
        }

        const objectToSpawn = this.game?.scene?.getObjectByName(this.objectNameToSpawn);

        if (!objectToSpawn) {
            return;
        }

        if (this.spawnType === SPAWNPOINT_TYPES.CLONE) {
            this.spawnedObject = this.cloneObject(objectToSpawn);
        } else {
            this.spawnedObject = objectToSpawn;
        }

        this.moveObjectToSpawn(this.spawnedObject);
    }

    private moveObjectToSpawn(object: THREE.Object3D) {
        object.position.copy(this.target.position);
        object.quaternion.copy(this.target.quaternion);

        if (object.userData.physics?.enabled) {
            const targetPosition = PhysicsUtil.calculatePhysicsPositionFromObject(
                object.position,
                object.quaternion,
                object.userData.physics?.anchorOffset
            );
            this.physics.setOrigin(object.uuid, targetPosition);
            this.physics.setRotation(object.uuid, object.quaternion);
        }
    }

    private cloneObject(object: THREE.Object3D): THREE.Object3D {
        return object; // TODO: Implement cloning
    }

    update(clock: THREE.Clock, delta: number): void {}

    reset() {}
}

export default SpawnPointBehaviorUpdater
