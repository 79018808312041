import React from "react";
import {UploadButton} from "./UploadButton";
import styled from "styled-components";
import {regularFont} from "../../../../../assets/style";

const Section = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px;
    gap: 8px;
    box-sizing: border-box;
`;

const Title = styled.div`
    ${regularFont("xxs")};
    color: #aeaeae;
    white-space: nowrap;
`;

interface UploadSectionProps {
    onAnimationUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UploadSection: React.FC<UploadSectionProps> = ({onAnimationUpload}) => {
    const [fileName, setFileName] = React.useState("");

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        onAnimationUpload(event);
        setFileName(event.target.files![0].name);
    };

    return (
        <Section>
            <Title>Upload Animation</Title>

            <UploadButton fileName={fileName} onUpload={handleUpload} multiple accept=".fbx" />
        </Section>
    );
};
