import {OBJECT_TYPES, WeaponAmmoBehaviorInterface} from "../../../../../../types/editor";
import global from "../../../../../../global";

export const getDefaultWeaponAmmoBehavior = (): WeaponAmmoBehaviorInterface => {
    return {
        type: OBJECT_TYPES.WEAPON_AMMO,
        ammoDamage: 1,
        ammoBounceEffect: 0,
        ammoMass: 1,
        ammoLife: 3,
        ammoSpeed: 100,
        ammoFriction: 0,
        ammoInertia: 0,
        ammoRestitution: 0
    };
};
