import styled from "styled-components";
import {flexCenter} from "../../../../../assets/style";

interface Props {
    children: React.ReactNode;
    margin?: string;
    nowrap?: boolean;
    justifyContent?: string;
}

export const SelectionOfButtons = ({children, margin, nowrap, justifyContent}: Props) => {
    return (
        <StyledSelectionOfButtons $margin={margin} $nowrap={nowrap} $justifyContent={justifyContent}>
            {children}
        </StyledSelectionOfButtons>
    );
};

const StyledSelectionOfButtons = styled.div<{
    $margin?: string;
    $nowrap?: boolean;
    $justifyContent?: string;
}>`
    ${({$margin}) => ($margin ? `margin: ${$margin}` : `margin: 8px auto 4px;`)};
    ${flexCenter};
    gap: 8px 6px;
    ${({$justifyContent}) => $justifyContent && `justify-content: ${$justifyContent};`};
    flex-wrap: ${({$nowrap}) =>
        $nowrap
            ? `nowrap`
            : `wrap
`};
    button {
        height: 24px;
        padding: 4px;
        font-weight: var(--theme-font-regular);
    }
`;
