import * as THREE from "three";
import {ISoundSettings} from "../../types/editor";

class SoundManager {
    scene: any = null;
    audioListener: any = null;
    loadedSounds: any = {};
    totalSounds: number = -1;

    constructor(scene: any) {
        this.scene = scene;
        this.audioListener = null;
        this.loadedSounds = {};
        this.clearLoadedSounds();
        this.audioListener = new THREE.AudioListener();
    }

    // Method to clear loaded sounds
    clearLoadedSounds() {
        for (const id in this.loadedSounds) {
            if (this.loadedSounds.hasOwnProperty(id)) {
                this.loadedSounds[id].stop(); // Stop the sound
            }
        }
    }

    stopSound(id: string) {
        const sound = this.loadedSounds[id];
        if (sound) {
            sound.stop(); // Stop the sound
        } else {
            console.error(`Sound with ID "${id}" not found.`);
        }
    }

    loadSounds(soundSettings: ISoundSettings[]) {
        const audioLoader = new THREE.AudioLoader();

        soundSettings.forEach((setting: any) => {
            audioLoader.load(
                setting.url,
                buffer => {
                    const sound = new THREE.Audio(this.audioListener);
                    sound.setBuffer(buffer);

                    sound.setLoop(setting.loop);
                    sound.setVolume(setting.volume * 0.5);

                    this.loadedSounds[setting.id] = sound;
                    console.log(`Loaded sound with ID "${setting.id}"`);

                    if (Object.keys(this.loadedSounds).length === this.totalSounds) {
                        this.onAllSoundsLoaded();
                    }

                    // Play the "background" sound once it's loaded
                    if (setting.soundType === "play-now" || setting.soundType === "menu-background") {
                        this.playSound(setting.id, setting.soundType);
                    }
                },
                xhr => {
                    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
                },
                error => {
                    console.error("Failed to load audio file:", error);
                },
            );
        });
    }

    onAllSoundsLoaded() {
        console.log("All sounds have been loaded.");
    }

    playSound(id: string, type?: string) {
        const sound = this.loadedSounds[id];
        if (sound) {
            if (type == "play-now" || type == "jump" || type == "menu-background") {
                sound.setVolume(0.5);
            } else {
                sound.stop();
                sound.setVolume(1.5);
            }

            sound.play();
        } else {
            console.error(`Sound with ID "${id}" not found.`);
        }
    }

    setVolume(id: string, volume: number) {
        const sound = this.loadedSounds[id];
        if (sound) {
            sound.setVolume(volume); // Set the volume of the sound
        } else {
            console.error(`Sound with ID "${id}" not found.`);
        }
    }

    muteAllSounds() {
        for (const id in this.loadedSounds) {
            if (this.loadedSounds.hasOwnProperty(id)) {
                this.loadedSounds[id].setVolume(0);
            }
        }
    }
}

export {SoundManager};
