import styled from "styled-components";
import {Container} from "../Home/Home.style";
import {flexCenter, regularFont} from "../../../assets/style";

export const SearchContainer = styled(Container)`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .norResults {
        ${regularFont("s")};
    }
`;

export const TitleRow = styled.div`
    font-size: 48px;
    font-weight: 700;
    color: #fff;
    ${flexCenter};
    justify-content: space-between;
`;

export const InnerPadding = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex-grow: 1;
`;
