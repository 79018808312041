import {useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {toast} from "react-toastify";

import {useAuthorizationContext} from "../../../../context";
import {generateProjectLink, getGameUrl} from "../../../../v2/pages/services";
import global from "../../../../global";
import Application from "../../../../Application";
import Converter from "../../../../serialization/Converter";
import {StopButton} from "../HUD/HUDView/StopButton";
import {IFRAME_MESSAGES} from "../../../../types/editor";

export const GameIframe = () => {
    const app = global.app as Application | null;
    const {authToken, dbUser} = useAuthorizationContext();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [renderStopButton, setRenderStopButton] = useState(true);

    useEffect(() => {
        const iframe = iframeRef.current;
        const postAuthInfo = () => {
            if (iframe && iframe.contentWindow && dbUser) {
                const editor = app?.editor;
                const scene = app?.editor?.scene;
                if (!scene || !editor) return toast.error("Cannot find scene data.");
                const ORIGIN = `${process.env.REACT_APP_ORIGIN}${generateProjectLink(editor.sceneID || undefined)}`;
                const serializableScene = JSON.parse(JSON.stringify(scene));
                const projectData = JSON.parse(
                    JSON.stringify({
                        IsMultiplayer: editor.isMultiplayer,
                        UseWebGPU: editor.useWebGPU,
                        UseAvatar: editor.useAvatar,
                        IsPublished: editor.isPublished,
                        ProjectUserId: editor.projectUserId,
                        VoiceChatEnabled: editor.voiceChatEnabled,
                    }),
                );
                const jsons = new (Converter as any)().toJSON({
                    options: app.options,
                    scene: editor.scene,
                    camera: editor.camera,
                    renderer: editor.renderer,
                    scripts: editor.scripts,
                    animations: editor.animations,
                });
                const serializableJsons = JSON.parse(JSON.stringify(jsons));
                iframe.contentWindow?.postMessage(
                    {
                        token: authToken,
                        userId: dbUser.id,
                        username: dbUser.username || dbUser.name,
                        sceneData: serializableScene,
                        projectData,
                        jsons: serializableJsons,
                    },
                    ORIGIN,
                );
            } else {
                console.error("No access to iframe or user info.");
            }
        };

        const handleMessage = (event: any) => {
            const message = event.data;
            if (message === IFRAME_MESSAGES.GAME_CREATED) {
                setRenderStopButton(false);
            } else if (message === IFRAME_MESSAGES.PLAYER_ADDED_LISTENER) {
                postAuthInfo();
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
            if (iframe) {
                iframe.src = "about:blank";
                iframeRef.current = null;
            }
        };
    }, []);

    if (!app?.editor) {
        console.error("No editor context");
        toast.error("Cannot run Player.");
        return;
    } else {
        return ReactDOM.createPortal(
            <IframeWrapper>
                {renderStopButton && <StopButton />}
                <iframe
                    ref={iframeRef}
                    src={
                        getGameUrl(app.editor.sceneID || "") +
                        "&isEditorMode=true" +
                        `&isDebugMode=${app.storage.debug}`
                    }
                />
            </IframeWrapper>,
            document.body,
        );
    }
};

const IframeWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999999999999;
    iframe {
        width: 100vw;
        height: 100vh;
    }
    .stop-btn {
        position: absolute;
        top: 55px;
        left: 50%;
        transform: translateX(-50%);
    }
`;
