import {HemisphereLight, DirectionalLight, Scene} from "three";

export default function setLights(scene: Scene): void {
    let light = new HemisphereLight(0xffffff, 0x444444);
    light.position.set(100, 200, 400);
    scene.add(light);

    const dirLight = new DirectionalLight(0xffffff);
    dirLight.position.set(100, 200, 100);
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 180;
    dirLight.shadow.camera.bottom = -100;
    dirLight.shadow.camera.left = -120;
    dirLight.shadow.camera.right = 120;
    scene.add(dirLight);
}
