import styled, {CSSProperties} from "styled-components";
import EventBus from "../../../../../behaviors/event/EventBus";
import stopIcon from "../../icons/stop-icon.svg";
import global from "../../../../../global";
import {IFRAME_MESSAGES} from "../../../../../types/editor";

const handleStop = (e?: any) => {
    e?.preventDefault();
    if (!e.clientX || !global || !global.app) {
        return;
    }
    (global?.app as any)?.player?.stop();
    EventBus.instance.send("game.stop");
    window.parent.postMessage(IFRAME_MESSAGES.GAME_CLOSED, "*");
};

export const StopButton = ({style}: {style?: CSSProperties}) => {
    return (
        <StyledButton onClick={handleStop} style={style} className="stop-btn">
            <img src={stopIcon} alt="stop game" />
        </StyledButton>
    );
};

const StyledButton = styled.button`
    cursor: pointer;
    width: 48px;
    height: 48px;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    pointer-events: all;
`;
