import {useEffect, useState} from "react";
import {BehaviorsWrapper, Container, PlusIcon} from "./SelectedBehaviorsList.style";
import {SingleBehavior} from "./SingleBehavior";
import global from "../../../../../../../global";
import {StyledButton} from "../../../../../../../editor/assets/v2/common/StyledButton";

type Props = {
    setIsBehaviorsListDisplayed: (value: boolean) => void;
    selectedBehavior: any;
    setSelectedBehavior: (behavior: any) => void;
    copyAllBehaviors: () => void;
    pasteBehavior: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const SelectedBehaviorsList = ({
    setIsBehaviorsListDisplayed,
    setSelectedBehavior,
    selectedBehavior,
    copyAllBehaviors,
    pasteBehavior,
}: Props) => {
    const [data, setData] = useState<any>({});

    const app = global.app;
    const editor = app?.editor;

    const updateUI = () => {
        const selected = editor.selected;

        setData(selected ? {[selected.uuid]: selected} : {});
    };

    useEffect(() => {
        if (!app) return;

        updateUI();
        app.on(`objectChanged.ObjectBehaviorsTypeSection`, updateUI);
        app.on(`objectRemoved.ObjectBehaviorsTypeSection`, updateUI);
        app.on(`objectSelected.ObjectBehaviorsTypeSection`, handleObjectSelected);
    }, []);

    const handleObjectSelected = () => {
        updateUI();
        setSelectedBehavior(null);
    };

    const handleRemoveBehaviorById = (id: string) => {
        if (!app) return;
        const selected = editor.selected;
        if (!selected) return;

        if (selectedBehavior?.id === id) {
            setSelectedBehavior(null);
        }

        const obj = editor.objectByUuid(selected.uuid);
        if (obj.userData.behaviors && obj.userData.behaviors.some((behavior: any) => behavior.id === id)) {
            obj.userData.behaviors = obj.userData.behaviors.filter((behavior: any) => behavior.id !== id);
        }

        app.call(`objectChanged`, app.editor, app.editor.selected);
        app.call(`objectUpdated`, app.editor, app.editor.selected);
    };

    return Object.keys(data).map(uuid => {
        return (
            <Container key={uuid}>
                <StyledButton isBlue={true} onClick={() => setIsBehaviorsListDisplayed(true)}>
                    <PlusIcon>+</PlusIcon> <span>Add Behavior</span>
                </StyledButton>
                <BehaviorsWrapper>
                    {data[uuid].userData.behaviors?.map((behavior: any, index: any) => (
                        <SingleBehavior
                            key={index}
                            behavior={behavior}
                            onRemoveBehaviorById={handleRemoveBehaviorById}
                            setSelectedBehavior={setSelectedBehavior}
                            copyAllBehaviors={copyAllBehaviors}
                            pasteBehavior={pasteBehavior}
                            allBehaviors={data[uuid].userData.behaviors}
                        />
                    ))}
                </BehaviorsWrapper>
            </Container>
        );
    });
};
