class MathUtils {

    static applyMatrix4ToVector3({x = 0, y = 0, z = 0}, matrix: number[] = []) {
        const w = 1 / (matrix[3] * x + matrix[7] * y + matrix[11] * z + matrix[15]);

        return {
            x: (matrix[0] * x + matrix[4] * y + matrix[8] * z + matrix[12]) * w,
            y: (matrix[1] * x + matrix[5] * y + matrix[9] * z + matrix[13]) * w,
            z: (matrix[2] * x + matrix[6] * y + matrix[10] * z + matrix[14]) * w,
        }
    }

    static clamp(value: number, min: number, max: number) {
        return Math.max(min, Math.min(max, value));
    }
}

export default MathUtils;