import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../../assets/style";
import {ChangeEvent, useState} from "react";
import loadModel from "../helpers/loadModel";
import {useModelAnimationCombinerContext} from "../../../../../context";
import {UploadSection} from "./UploadSection";
import {AnimationList} from "./AnimationList";
import {Export} from "./Export";
import {Save} from "./Save";
import {StyledButton} from "../../common/StyledButton";

export const Container = styled.div<{$isExpanded: boolean}>`
    box-sizing: border-box;
    position: fixed;
    z-index: 100;
    right: 12px;
    top: 12px;
    width: 240px;
    height: 100px;
    background: var(--theme-grey-bg-tertiary);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--theme-font-main-selected-color);
    z-index: 100;
    overflow: hidden;
    transition: height 0.2s ease-in-out;

    ${({$isExpanded}) =>
        $isExpanded &&
        `
        height: calc(100svh - 24px);
    `}
`;

export const Separator = styled.div`
    height: 1px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
`;

export const Wrapper = styled.div<{$isExpanded: boolean}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 0;
    overflow: hidden;

    ${({$isExpanded}) =>
        $isExpanded &&
        `
        height: 100%;
    `}
`;

export const AnimationButtonSection = styled.div`
    height: 48px;
    width: 100%;
    padding: 8px;
    background: var(--theme-container-unselected-tap-color);

    button {
        ${regularFont("xxs")};
        font-weight: 600;
        width: 100%;
    }
`;

type Props = {
    onClose: () => void;
    model: any | null;
};

export const RightPanel = ({onClose, model}: Props) => {
    const {addAnimations} = useModelAnimationCombinerContext();
    const [isExpanded, setIsExpanded] = useState(false);

    const onAnimationUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length) {
            Array.from(event.target.files).forEach(element => {
                const fileUrl = URL.createObjectURL(element);
                const fileExt = element.name.split(".").pop() || "gltf";
                loadModel(fileUrl, {Type: fileExt}, (object: any) => {
                    let fileName = element.name.split(".")[0].replace(/\s/g, "");
                    fileName = fileName.charAt(0).toUpperCase() + fileName.slice(1);
                    if (object.animations.length > 1) {
                        object.animations.forEach((anim: any, index: any) => {
                            anim.name = fileName + index;
                        });
                    } else {
                        if (object.animations[0].name === "Take 001") {
                            object.animations[0].name = "T-Pose (No Animation)";
                        } else {
                            object.animations[0].name = fileName;
                        }
                    }
                    addAnimations(object.animations);
                });
            });
        }
    };
    return (
        <Container $isExpanded={isExpanded}>
            <AnimationButtonSection>
                <StyledButton isGreyBlue onClick={() => setIsExpanded(!isExpanded)}>
                    Animations
                </StyledButton>
            </AnimationButtonSection>
            <Wrapper $isExpanded={isExpanded}>
                <UploadSection onAnimationUpload={onAnimationUpload} />
                <Separator />
                <AnimationList />
                <Separator />
                <Export />
            </Wrapper>

            <Save onSave={onClose} model={model} />
        </Container>
    );
};
