import {Link} from "../../../../v2/common/Link/Link";
import {SearchRow} from "../../../../v2/common/SearchRow/SearchRow";
import {Tag} from "./Tag";
import {Header, StyledSection, TagRow} from "./GamesSearchSection.style";
import {TAGS} from "../../constants";

export const GamesSearchSection = () => {
    return (
        <StyledSection>
            <Header>
                Explore ERTH.AI
                <Link href="/dashboard" text="Learn how to create your own world" />
            </Header>
            <div style={{width: "100%"}}>
                <SearchRow />
                <TagRow>
                    {TAGS.map(tag => (
                        <Tag key={tag} tag={tag} />
                    ))}
                </TagRow>
            </div>
        </StyledSection>
    );
};
