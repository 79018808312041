import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import {useAppGlobalContext, useAuthorizationContext, useHomepageContext} from "../../../context";
// @ts-ignore
import Editor from "../../../editor/Editor";
import {LoadMask} from "../../../ui/index";
import global from "../../../global";
import Application from "../../../Application";
import {backendUrlFromPath} from "../../..//utils/UrlUtils";
import {ROUTES} from "../../../AppRouter";

export const Create = () => {
    const {isAuthorized, initLoading, dbUser} = useAuthorizationContext();
    const {activePage} = useHomepageContext();
    const {projectPhase, setProjectPhase, setIsGameSettingsPanelOpen} = useAppGlobalContext();
    const navigate = useNavigate();
    const app = global?.app as Application | null;
    const editor = app?.editor;
    const {projectID} = useParams();
    const [showMask, setShowMask] = useState(true);
    const [isAutoLoadingMask, setIsAutoLoadingMask] = useState(false);
    const location = useLocation();

    const onShowMask = (enabled: boolean, isAuto: boolean = true) => {
        setShowMask(enabled);
        setIsAutoLoadingMask(isAuto);
    };

    useEffect(() => {
        if (!isAuthorized && !initLoading && !location.pathname.includes("/user/")) {
            navigate(ROUTES.LOGIN);
        }
    }, [isAuthorized, location.pathname]);

    useEffect(() => {
        app?.on(`showMask.Editor`, onShowMask);
        return () => {
            app?.on(`showMask.Editor`, null);
        };
    }, []);

    useEffect(() => {
        if (!app || !isAuthorized) return;

        if (projectID) {
            let url = backendUrlFromPath(`/api/Scene/Load?ID=${projectID}`);
            app.getScene(projectID, data => {
                if (data.Code === 200) {
                    const scene = data.Data;
                    app.call(`load`, this, url, scene.Name, projectID, scene.LockedItems, scene.Thumbnail);
                    setProjectPhase(3);
                } else {
                    navigate(ROUTES.DASHBOARD);
                }
            });
        } else {
            setShowMask(false);
            navigate(activePage ? ROUTES[activePage as string as keyof typeof ROUTES] : ROUTES.DASHBOARD, {
                replace: true,
            });
        }
    }, [projectID, app, isAuthorized, activePage]);

    useEffect(() => {
        if (!editor || !isAuthorized) return;

        if (projectPhase === 2 && !projectID && dbUser) {
            editor.projectUserId = dbUser.id;
        }
    }, [editor, isAuthorized, projectPhase, dbUser, projectID]);

    return (
        <>
            <LoadMask show={!!initLoading || showMask} isAutoLoading={isAutoLoadingMask} />
            {!initLoading && (
                <Editor setIsGameSettingsPanelOpen={setIsGameSettingsPanelOpen} projectPhase={projectPhase} />
            )}
        </>
    );
};
