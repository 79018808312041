/* eslint-disable react/no-multi-comp */
export const ThirdPersonOption = () => {
    return null;
};

import * as THREE from "three";
import { useEffect, useState } from "react";
import { DefaultWrapper } from "../../styles/Behaviors.style";
import global from "../../../../../../global";
import CharacterBehaviorConverter from "../../../../../../serialization/behaviours/CharacterBehaviorConverter";
import { NumericInputRow } from "../../common/NumericInputRow";
import { SelectRow } from "../../common/SelectRow";
import { ANIMATION_TYPES } from "../../../../../../types/editor";

const initialXRotations = [
    {
        key: "none",
        value: "None",
    },
    {
        key: "-x",
        value: "Negative X",
    },
    {
        key: "+x",
        value: "Positive X",
    },
];


type AnimationOption = {
    key: string;
    value: string;
};

const getSceneModels = () => {
    const editor = (global as any).app.editor;
    const sceneModels = {};

    editor.scene.traverse((obj: any) => {
        if (obj.type === "Group" && obj.parent === editor.scene) {
            //@ts-ignore
            sceneModels[obj.name] = obj.name;
        }
    });

    return sceneModels;
};

export const ThirdPersonOptions = () => {
    const app = (global as any).app;
    const editor = app.editor;
    const model = editor.selected;
    const thisModelCharacterBehavior = model.userData.behaviors.find((el: any) => el.type === "Character");
    const targetBehaviorthirdPersonOptions = thisModelCharacterBehavior?.thirdPersonOptions;
    const camera = editor.camera;

    const [animations, setAnimations] = useState([{ key: "0", value: "none" }]);
    const [targetBehavior, setTargetBehavior] = useState(() => {
        return targetBehaviorthirdPersonOptions
            ? { thirdPersonOptions: targetBehaviorthirdPersonOptions }
            : {
                thirdPersonOptions: {
                    ...CharacterBehaviorConverter.DEFAULT.getDefaultThirdPersonOptions(getSceneModels()),
                    initialXRotation: "-x"
                },
            };
    });

    const handleInputChange = (value: string | boolean | number, name: string) => {
        if (camera) {
            setTargetBehavior((prevState: any) => {
                const newState = { ...prevState };
                newState.thirdPersonOptions[name] = value;
                return newState;
            });
        }
    };

    const handleSelectChange = (item: { key: string; value: string }, name: string) => {
        setTargetBehavior((prevState: any) => {
            const newState = { ...prevState };
            newState.thirdPersonOptions[name] = item.value;
            return newState;
        });
    };

    const handleUpdate = () => {
        if (!model) return;
        const characterBehaviorConverter = CharacterBehaviorConverter.DEFAULT;

        if (!camera.userData.thirdPersonOptions || Object.keys(camera.userData.thirdPersonOptions).length === 0) {
            camera.userData.thirdPersonOptions =
                characterBehaviorConverter.getDefaultThirdPersonOptions(getSceneModels());
        }

        characterBehaviorConverter.updateThirdPersonOptions(model, camera);

        if (model) {
            if (model._obj && model._obj.animations && model._obj.animations.length > 0) {
                const mappedAnimations = model._obj.animations.map((anim: any) => {
                    return { key: `${anim.name}`, value: anim.name };
                });
                setAnimations([{ key: "none", value: "none" }, ...mappedAnimations]);
            }
        }
    };

    const updateScene = () => {
        if (camera && targetBehavior && thisModelCharacterBehavior.enabled) {
            camera.userData.thirdPersonOptions = targetBehavior.thirdPersonOptions;

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };


    const autoDetectAnimations = () => {
        if (model && model._obj && model._obj.animations && model._obj.animations.length > 0) {
            const mappedAnimations = model._obj.animations.map((anim: any) => ({
                key: `${anim.name}`,
                value: anim.name
            }));
            setAnimations([{ key: "none", value: "none" }, ...mappedAnimations]);

            const findAnimationByKeyword = (keywords: string[]) => {
                for (const keyword of keywords) {
                    const animation = mappedAnimations.find((animation: AnimationOption) =>
                        animation.value.toLowerCase().includes(keyword)
                    );
                    if (animation) {
                        return animation.value;
                    }
                }
                return "none";
            };

            setTargetBehavior((prevState) => ({
                ...prevState,
                thirdPersonOptions: {
                    ...prevState.thirdPersonOptions,
                    idleAnimation: findAnimationByKeyword([ANIMATION_TYPES.IDLE, ANIMATION_TYPES.STAND, ANIMATION_TYPES.WAIT]),
                    walkAnimation: findAnimationByKeyword([ANIMATION_TYPES.WALK]),
                    runAnimation: findAnimationByKeyword([ANIMATION_TYPES.RUN, ANIMATION_TYPES.JOG]),
                    jumpAnimation: findAnimationByKeyword([ANIMATION_TYPES.JUMP, ANIMATION_TYPES.RUN, ANIMATION_TYPES.HOP]),
                    shootAnimation: findAnimationByKeyword([ANIMATION_TYPES.SHOOT]),
                    reloadAnimation: findAnimationByKeyword([ANIMATION_TYPES.RELOAD]),
                    swordAnimation: findAnimationByKeyword([ANIMATION_TYPES.SWORD]),
                    punchAnimation: findAnimationByKeyword([ANIMATION_TYPES.PUNCH]),
                    throwAnimation: findAnimationByKeyword([ANIMATION_TYPES.THROW]),
                    pickUpAnimation: findAnimationByKeyword([ANIMATION_TYPES.PICK_UP]),
                    carryAnimation: findAnimationByKeyword([ANIMATION_TYPES.CARRY]),
                    leftDirectionAnimation: findAnimationByKeyword([ANIMATION_TYPES.LEFT_DIRECTION]),
                    rightDirectionAnimation: findAnimationByKeyword([ANIMATION_TYPES.RIGHT_DIRECTION]),
                    reverseDirectionAnimation: findAnimationByKeyword([ANIMATION_TYPES.REVERSE_DIRECTION]),
                    fallAnimation: findAnimationByKeyword([ANIMATION_TYPES.FALL]),
                    dieAnimation: findAnimationByKeyword([ANIMATION_TYPES.DIE]),
                },
            }));
        }
    };

    useEffect(() => {
        updateScene();
        if (!!thisModelCharacterBehavior) {
            thisModelCharacterBehavior.thirdPersonOptions = targetBehavior.thirdPersonOptions;
        }
    }, [targetBehavior]);

    useEffect(() => {
        handleUpdate();
        app.on(`objectSelected.thirdPersonnOptions`, handleUpdate);
        app.on(`objectChanged.thirdPersonOptions`, handleUpdate);
    }, []);

    useEffect(() => {
        if (model) {
        
            const boundingBox = new THREE.Box3().setFromObject(model);
            const modelHeight = boundingBox.max.y - boundingBox.min.y;

            if (!model.userData.autoDetectAnimations) {
                model.userData.autoDetectAnimations = false;
                autoDetectAnimations();
                model.userData.autoDetectAnimations = true;
            }

            if (!model.userData.playerJumpHeightSet) {
                const jumpHeight = Math.round((modelHeight * 2) * 1000) / 1000;

                setTargetBehavior((prevState) => ({
                    ...prevState,
                    thirdPersonOptions: {
                        ...prevState.thirdPersonOptions,
                        jumpHeight: jumpHeight,
                    },
                }));

                model.userData.playerJumpHeightSet = true;
            }

            if (!model.userData.characterDistanceSet) {
                const minDistance = Math.round((modelHeight * 1.5) * 1000) / 1000;
                const maxDistance = Math.round((modelHeight * 2) * 1000) / 1000;

                setTargetBehavior((prevState) => ({
                    ...prevState,
                    thirdPersonOptions: {
                        ...prevState.thirdPersonOptions,
                        cameraMINDistance: minDistance,
                        cameraMAXDistance: maxDistance,
                    },
                }));

                model.userData.characterDistanceSet = true;
            }
        }
    }, [model]);


    return (
        <DefaultWrapper>
            Movement Speed
            <NumericInputRow
                label="Walk Speed"
                value={targetBehavior.thirdPersonOptions.walkSpeed}
                setValue={value => handleInputChange(value, "walkSpeed")}
                min={0}
            />
            <NumericInputRow
                label="Run Speed"
                value={targetBehavior.thirdPersonOptions.runSpeed}
                setValue={value => handleInputChange(value, "runSpeed")}
                min={0}
            />
            <NumericInputRow
                label="Turn Speed"
                value={targetBehavior.thirdPersonOptions.turnSpeed}
                setValue={value => handleInputChange(value, "turnSpeed")}
                min={0}
            />
            <SelectRow
                label="Initial X Rotation"
                data={initialXRotations}
                value={initialXRotations.find(
                    rotation => rotation.key === targetBehavior.thirdPersonOptions.initialXRotation,
                )}
                onChange={item => handleInputChange(item.key, "initialXRotation")}
            />

            <DefaultWrapper>
                Animation Clip Control
                <SelectRow
                    label="Idle"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.idleAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "idleAnimation")}
                />
                <SelectRow
                    label="Walk"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.walkAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "walkAnimation")}
                />
                <SelectRow
                    label="Run"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.runAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "runAnimation")}
                />
                <SelectRow
                    label="Shoot"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.shootAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "shootAnimation")}
                />
                <SelectRow
                    label="Reload"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.reloadAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "reloadAnimation")}
                />
                <SelectRow
                    label="Sword"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.swordAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "swordAnimation")}
                />
                <SelectRow
                    label="Punch"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.punchAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "punchAnimation")}
                />
                <SelectRow
                    label="Throw"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.throwAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "throwAnimation")}
                />
                <SelectRow
                    label="Pick Up"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.pickUpAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "pickUpAnimation")}
                />
                <SelectRow
                    label="Carry"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.carryAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "carryAnimation")}
                />
                <SelectRow
                    label="Jump"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.jumpAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "jumpAnimation")}
                />
                <SelectRow
                    label="Left"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.leftDirectionAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "leftDirectionAnimation")}
                />
                <SelectRow
                    label="Right"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.rightDirectionAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "rightDirectionAnimation")}
                />
                <SelectRow
                    label="Reverse"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.reverseDirectionAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "reverseDirectionAnimation")}
                />
                <SelectRow
                    label="Fall"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.fallAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "fallAnimation")}
                />
                <NumericInputRow
                    label="Fall Delay (Seconds)"
                    value={targetBehavior.thirdPersonOptions.fallDelay}
                    setValue={value => handleInputChange(value, "fallDelay")}
                />
                <SelectRow
                    label="Die"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.dieAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "dieAnimation")}
                />
                <NumericInputRow
                    label="Slope Tolerance"
                    value={targetBehavior.thirdPersonOptions.slopeTolerance}
                    setValue={value => handleInputChange(value, "slopeTolerance")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Character Physics
                <NumericInputRow
                    label="Jump Height"
                    value={targetBehavior.thirdPersonOptions.jumpHeight}
                    setValue={value => handleInputChange(value, "jumpHeight")}
                    min={0}
                />
                <NumericInputRow
                    label="Player Gravity"
                    value={targetBehavior.thirdPersonOptions.playerGravity}
                    setValue={value => handleInputChange(value, "playerGravity")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Camera Interaction
                <NumericInputRow
                    label="Camera Min Distance"
                    value={targetBehavior.thirdPersonOptions.cameraMINDistance}
                    setValue={value => handleInputChange(value, "cameraMINDistance")}
                />
                <NumericInputRow
                    label="Camera Max Distance"
                    value={targetBehavior.thirdPersonOptions.cameraMAXDistance}
                    setValue={value => handleInputChange(value, "cameraMAXDistance")}
                />
            </DefaultWrapper>
        </DefaultWrapper>
    );
};