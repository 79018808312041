import {useState} from "react";
import * as THREE from "three";
import global from "../../../../../global";
import {PanelCheckbox} from "../common/PanelCheckbox";

interface Props {
    isLocked?: boolean;
    hideVisibility?: boolean;
}

export const VisibilitySection = ({isLocked, hideVisibility = false}: Props) => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor.selected;
    const [isBackfaceVisible, setIsBackfaceVisible] = useState(selected?.material?.side === THREE.DoubleSide);

    const handleVisibilityChange = (checked: boolean) => {
        selected.visible = checked;
        app?.call(`objectChanged`, selected, selected);
    };

    const handleBackfaceVisibilityChange = (isBackfaceVisibleSelected: boolean) => {
        if (selected && selected.material) {
            selected.material.side = isBackfaceVisibleSelected ? THREE.DoubleSide : THREE.FrontSide;
            setIsBackfaceVisible(isBackfaceVisibleSelected);
        }
        app?.call(`objectChanged`, selected, selected);
    };

    return (
        <>
            {!hideVisibility && (
                <PanelCheckbox
                    text="Make Object Visible"
                    checked={selected?.visible}
                    onChange={e => {
                        handleVisibilityChange(!!e.target.checked);
                    }}
                    isLocked={isLocked}
                    v2
                    isGray
                    regular
                />
            )}
            {selected?.material?.side !== undefined && (
                <PanelCheckbox
                    text="Backface Visibility"
                    checked={!!isBackfaceVisible}
                    onChange={e => {
                        handleBackfaceVisibilityChange(!!e.target.checked);
                    }}
                    isLocked={isLocked}
                    v2
                    isGray
                    regular
                />
            )}
        </>
    );
};
