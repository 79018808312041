import {useEffect, useState} from "react";
import I18n from "i18next";

import {FieldWrapper, Wrapper} from "../../../commonStyle";
import {useHUDContext, useHUDInGameMenuContext, useHUDStartGameMenuContext} from "../../../../../../../../context";
import {UploadField} from "../../../../../common/UploadField/UploadField";
import {UIIconSelection} from "../../UIIconSelection/UIIconSelection";
import {FONT_FAMILIES, HUD_TABS, Icon, IGameButtonInterface, ISound, START_MENU_BUTTON_TYPES} from "../../../types";

import aidKit from "./icons/aidKit.svg";
import heart from "./icons/heart.svg";
import shield from "./icons/shield.svg";
import lightning from "./icons/lightning.svg";
import coin from "./icons/coin.svg";
import play from "./icons/play.svg";
import settings from "./icons/settings.svg";

import Ajax from "../../../../../../../../utils/Ajax";
import {backendUrlFromPath} from "../../../../../../../../utils/UrlUtils";
import {FileData} from "../../../../../types/file";
import global from "../../../../../../../../global";

import {SelectRow} from "../../../../../RightPanel/common/SelectRow";
import {Item} from "../../../../../common/BasicCombobox";
import {NumericInputRow} from "../../../../../RightPanel/common/NumericInputRow";
import {ColorSelectionRow} from "../../../../../RightPanel/common/ColorSelectionRow";
import {SelectionOfButtons} from "../../../../../RightPanel/common/SelectionOfButtons";
import {StyledButton} from "../../../../../common/StyledButton";

const ICONS = [
    {src: aidKit, alt: "aid kit"},
    {src: heart, alt: "heart"},
    {src: shield, alt: "shield"},
    {src: lightning, alt: "lightning"},
    {src: coin, alt: "coin"},
    {src: play, alt: "play", maxWidth: "24px"},
    {src: settings, alt: "settings", maxWidth: "27px"},
];

export const GameButton = () => {
    const app = (global as any).app;
    const {popupCallback, popupId, activeScreen} = useHUDContext();
    const {startGameMenuLayout} = useHUDStartGameMenuContext();
    const {inGameMenuLayout} = useHUDInGameMenuContext();
    const [obj, setObj] = useState<IGameButtonInterface | undefined>();
    const [UIButtonType, setUIButtonType] = useState(obj?.UIButtonType || START_MENU_BUTTON_TYPES.START_GAME);
    const [buttonTypesOptions, setButtonTypesOptions] = useState<Item[]>([]);
    const [fontFamilyOptions, setFontFamilyOptions] = useState<Item[]>([]);
    const [soundOptions, setSoundOptions] = useState<Item[]>([]);
    const [soundData, setSoundData] = useState<ISound[]>();
    const [hoverSound, setHoverSound] = useState<ISound | undefined>(obj?.hoverSound);
    const [clickSound, setClickSound] = useState<ISound | undefined>(obj?.clickSound);
    const [fontFamily, setFontFamily] = useState(obj?.fontFamily || FONT_FAMILIES.ROBOTO);
    const [fontSize, setFontSize] = useState(obj?.fontSize || 18);
    const [fontColor, setFontColor] = useState(obj?.fontColor || "#000");
    const [radius, setRadius] = useState(obj?.radius || 8);
    const [buttonColor, setButtonColor] = useState(obj?.buttonColor || "#fff");
    const [uploadedButtonImg, setUploadedButtonImg] = useState<FileData | null | string>(
        obj?.uploadedButtonImg || null,
    );
    const [iconSelected, setIconSelected] = useState<Icon | undefined>(obj?.iconSelected);
    const [isCustomButton, setIsCustomButton] = useState(false);

    useEffect(() => {
        let buttonData;
        if (activeScreen === HUD_TABS.GAME_START_MENU && popupId) {
            buttonData = startGameMenuLayout?.[popupId as keyof typeof startGameMenuLayout];
        } else if (activeScreen === HUD_TABS.IN_GAME_MENU && popupId) {
            buttonData = inGameMenuLayout?.[popupId as keyof typeof inGameMenuLayout];
        }
        if (buttonData) {
            setObj(buttonData as IGameButtonInterface);
        }
    }, [popupId, activeScreen]);

    useEffect(() => {
        if (!isCustomButton) {
            setUploadedButtonImg(null);
        }
    }, [isCustomButton]);

    useEffect(() => {
        const buttonTypesValues = Object.values(START_MENU_BUTTON_TYPES);
        setButtonTypesOptions(
            buttonTypesValues.map((option: string, index: number) => {
                return {
                    key: `${index + 1}`,
                    value: option,
                };
            }),
        );
        const fontFamilyValues = Object.values(FONT_FAMILIES);
        setFontFamilyOptions(
            fontFamilyValues.map((option: string, index: number) => {
                return {
                    key: `${index + 1}`,
                    value: option,
                };
            }),
        );
    }, []);

    const fetchSounds = () => {
        Ajax.get({url: backendUrlFromPath(`/api/Audio/List`)}).then(response => {
            const obj = response?.data;
            if (obj.Code !== 200) {
                app.toast(I18n.t(obj.Msg), "warn");
                return;
            }
            setSoundData(obj.Data);
        });
    };

    useEffect(() => {
        fetchSounds();
    }, []);

    useEffect(() => {
        if (soundData) {
            setSoundOptions([
                {key: "0", value: "none"},
                ...soundData.map((option: any, index: number) => {
                    return {
                        key: `${index + 1}`,
                        value: option.Name,
                    };
                }),
            ]);
        }
    }, [soundData]);

    return (
        <>
            <Wrapper>
                <SelectRow
                    $margin="0"
                    label="UI Button Type"
                    data={buttonTypesOptions}
                    value={buttonTypesOptions.find(item => item.value === UIButtonType) || buttonTypesOptions[0]}
                    onChange={item => setUIButtonType(item.value as START_MENU_BUTTON_TYPES)}
                />
                <SelectionOfButtons margin="0 auto 0">
                    <StyledButton
                        width="109px"
                        isBlue={!isCustomButton}
                        isActive={isCustomButton}
                        onClick={() => setIsCustomButton(false)}>
                        <span>Default Button</span>
                    </StyledButton>
                    <StyledButton
                        width="109px"
                        isBlue={isCustomButton}
                        isActive={!isCustomButton}
                        onClick={() => setIsCustomButton(true)}>
                        <span>Custom Button</span>
                    </StyledButton>
                </SelectionOfButtons>
                {isCustomButton && (
                    <FieldWrapper>
                        <label className="buttonImageLabel">Upload Button Image &#40;460px x 50px&#41;</label>
                        <UploadField
                            style={{margin: "0 auto", borderRadius: "4px", fontSize: "21px", color: "#fff"}}
                            width="100%"
                            height="29px"
                            uploadedFile={uploadedButtonImg}
                            setUploadedFile={setUploadedButtonImg}
                            size={{minWidth: 460, minHeight: 50}}
                        />
                    </FieldWrapper>
                )}
                <SelectRow
                    $margin="0"
                    label="Font Family"
                    data={fontFamilyOptions}
                    value={fontFamilyOptions.find(item => item.value === fontFamily) || fontFamilyOptions[0]}
                    onChange={item => setFontFamily(item.value)}
                />
                <NumericInputRow
                    $margin="0"
                    width="75px"
                    label="Font Size"
                    value={fontSize}
                    setValue={value => setFontSize(value)}
                />
                <ColorSelectionRow $margin="0" value={fontColor} setValue={setFontColor} label="Font Color" />
                {!isCustomButton && (
                    <ColorSelectionRow $margin="0" value={buttonColor} setValue={setButtonColor} label="Button Color" />
                )}
                <NumericInputRow
                    $margin="0"
                    width="75px"
                    label="Button Roundness"
                    value={radius}
                    setValue={value => setRadius(value)}
                />
                <UIIconSelection icons={ICONS} iconSelected={iconSelected} setIconSelected={setIconSelected} />
                <SelectRow
                    $margin="0"
                    label="Hover Sound"
                    data={soundOptions}
                    value={soundOptions.find(item => item.value === hoverSound?.Name) || soundOptions[0]}
                    onChange={item => setHoverSound(soundData?.find(el => el.Name === item.value))}
                />
                <SelectRow
                    $margin="0"
                    label="Click Sound"
                    data={soundOptions}
                    value={soundOptions.find(item => item.value === clickSound?.Name) || soundOptions[0]}
                    onChange={item => setClickSound(soundData?.find(el => el.Name === item.value))}
                />
            </Wrapper>
            <StyledButton
                margin="16px 0 0"
                isBlue
                onClick={() => {
                    popupCallback &&
                        popupCallback({
                            UIButtonType,
                            fontFamily,
                            fontSize,
                            fontColor,
                            buttonColor,
                            uploadedButtonImg: backendUrlFromPath(uploadedButtonImg),
                            iconSelected,
                            hoverSound,
                            clickSound,
                        });
                }}>
                Apply
            </StyledButton>
        </>
    );
};
