import React, {useRef} from "react";
import {useOnClickOutside} from "usehooks-ts";
import {useNavigate} from "react-router-dom";
import {auth} from "../../../../../firebase";
import {useAuthorizationContext} from "../../../../../context";
import {Container, MenuItem} from "./UserMenu.style";
import {ROUTES} from "../../../../../AppRouter";
import {PAGES} from "../../GamesDashboard/constants";

export enum USER_MENU_ITEM {
    SETTINGS = "Account Settings",
    AVATAR_CREATOR = "Avatar Creator",
    LOGOUT = "Log out",
}

interface Props {
    close: () => void;
    userMenuButtonRef: React.MutableRefObject<HTMLButtonElement | SVGSVGElement | null>;
    showAvatarCreator: () => void;
    showLoading: (show: boolean) => void;
}

export const UserMenu = ({close, userMenuButtonRef, showAvatarCreator, showLoading}: Props) => {
    const {setIsAuthorized} = useAuthorizationContext();
    const userMenuRef = useRef<HTMLDivElement | null>(null);
    const menuListItems = Object.values(USER_MENU_ITEM);
    const navigate = useNavigate();
    const checkboxRef = useRef<any>(null);

    useOnClickOutside(
        [userMenuRef as React.MutableRefObject<HTMLElement>, userMenuButtonRef as React.MutableRefObject<HTMLElement>],
        close,
    );

    const handleUserMenuItemClick = (item: USER_MENU_ITEM) => {
        switch (item) {
            case USER_MENU_ITEM.SETTINGS:
                navigate(`/dashboard?tab=${PAGES.SETTINGS.toLocaleLowerCase()}`);
                break;
            case USER_MENU_ITEM.LOGOUT:
                auth.signOut().then(() => {
                    setIsAuthorized(false);
                    navigate(ROUTES.LOGIN);
                });
                break;
            case USER_MENU_ITEM.AVATAR_CREATOR:
                showAvatarCreator();
                close();
                break;

            default:
                break;
        }
    };

    const handleMenuItemClick = (event: any, item: USER_MENU_ITEM) => {
        if (checkboxRef.current && checkboxRef.current.contains(event.target)) {
            return;
        }

        handleUserMenuItemClick(item as USER_MENU_ITEM);
    };

    return (
        <Container ref={userMenuRef}>
            {menuListItems.map((item, index) => {
                return (
                    <MenuItem key={index} onClick={e => handleMenuItemClick(e, item)}>
                        {item}
                    </MenuItem>
                );
            })}
        </Container>
    );
};
