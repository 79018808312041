/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import * as THREE from "three";
import Application from "./Application";
import Player from "./player/Player";

interface GlobalType {
    app: Application | Player | null;
    three$1: any;
}

export default {
    app: null,
    three$1: THREE,
} as GlobalType;
