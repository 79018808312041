import styled from "styled-components";

export const GamesWrapper = styled.div`
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    justify-items: start;
    gap: 20px;

    .loadeMoreBtn {
        margin: 0 auto;
    }
`;
