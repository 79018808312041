import global from "../../../../../global";
import AnimationBehaviorConverter from "../../../../../serialization/behaviours/AnimationBehaviorConverter";
import {ANIMATION_TYPES, EASE_TYPES, AnimationBehaviorInterface} from "../../../../../types/editor";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {NumericInputRow} from "../common/NumericInputRow";
import {Separator} from "../common/Separator";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {StyledButton} from "../../common/StyledButton";
import {BasicCombobox} from "../../common/BasicCombobox";
import {PanelSectionTitle} from "../RightPanel.style";
import {useState} from "react";

type Props = {
    behavior: AnimationBehaviorInterface;
};

const easeTypes = Object.keys(EASE_TYPES).map(key => {
    return {
        key: `${key}`,
        value: EASE_TYPES[key as keyof typeof EASE_TYPES],
    };
});

export const AnimationBehaviors = ({behavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behaviorState, setBehaviorState] = useState(behavior);

    const targetBehavior = AnimationBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleAnimationTypeChange = (type: ANIMATION_TYPES) => {
        if (selected && targetBehavior) {
            targetBehavior.animationType = type;
            setBehaviorState({...targetBehavior, animationType: type});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleInputChange = (value: any, name: keyof AnimationBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehaviorState({...targetBehavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                checked={!!behaviorState.startOnTrigger}
                onChange={() => handleInputChange(!behaviorState.startOnTrigger, "startOnTrigger")}
            />
            <Separator margin="8px 0" />
            <NumericInputRow
                width="75px"
                label="Move X"
                value={behaviorState.horizontalX}
                setValue={value => handleInputChange(value, "horizontalX")}
            />
            <NumericInputRow
                width="75px"
                label="Move Y"
                value={behaviorState.vertical}
                setValue={value => handleInputChange(value, "vertical")}
            />
            <NumericInputRow
                width="75px"
                label="Move Z"
                value={behaviorState.horizontalZ}
                setValue={value => handleInputChange(value, "horizontalZ")}
            />
            <NumericInputRow
                width="75px"
                label="Rotation X"
                value={behaviorState.rotationX}
                setValue={value => handleInputChange(value, "rotationX")}
            />
            <NumericInputRow
                width="75px"
                label="Rotation Y"
                value={behaviorState.rotationY}
                setValue={value => handleInputChange(value, "rotationY")}
            />
            <NumericInputRow
                width="75px"
                label="Rotation Z"
                value={behaviorState.rotationZ}
                setValue={value => handleInputChange(value, "rotationZ")}
            />
            <NumericInputRow
                width="75px"
                label="Scale X"
                value={behaviorState.scaleX}
                setValue={value => handleInputChange(value, "scaleX")}
            />
            <NumericInputRow
                width="75px"
                label="Scale Y"
                value={behaviorState.scaleY}
                setValue={value => handleInputChange(value, "scaleY")}
            />
            <NumericInputRow
                width="75px"
                label="Scale Z"
                value={behaviorState.scaleZ}
                setValue={value => handleInputChange(value, "scaleZ")}
            />
            <NumericInputRow
                width="75px"
                label="Speed"
                value={behaviorState.speed}
                setValue={value => handleInputChange(value, "speed")}
            />

            <div className="wrapper ">
                <div className="box">
                    <PanelSectionTitle>Ease Type</PanelSectionTitle>
                    <BasicCombobox
                        className={"numeric-input"}
                        value={easeTypes.find(item => item.value === behaviorState.easeType)}
                        data={easeTypes}
                        onChange={item => handleInputChange(item.value, "easeType")}
                    />
                </div>
            </div>

            <SelectionOfButtons>
                {Object.keys(ANIMATION_TYPES).map(key => {
                    const type = ANIMATION_TYPES[key as unknown as keyof typeof ANIMATION_TYPES];
                    return (
                        <StyledButton
                            width="109px"
                            isBlue={behaviorState.animationType === type}
                            isActive={behaviorState.animationType !== type}
                            onClick={() => handleAnimationTypeChange(type)}
                            key={key}>
                            <span>{type}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
            <Separator margin="8px 0" />
        </>
    );
};
