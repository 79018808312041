/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseEvent from "./BaseEvent";
import SetPositionCommand from "./../command/SetPositionCommand";
import SetRotationCommand from "./../command/SetRotationCommand";
import SetScaleCommand from "./../command/SetScaleCommand";
import global from "../global";

/**
 * 平移旋转缩放控件事件
 * @author tengge / https://github.com/tengge1
 */
class TransformControlsEvent extends BaseEvent {
    constructor() {
        super();
        this.mode = "translate";

        this.objectPosition = null;
        this.objectRotation = null;
        this.objectScale = null;
        this.isCmdOrControlClicked = false;
    }

    start() {
        global.app.on(`appStarted.${this.id}`, this.onAppStarted.bind(this));
    }

    onAppStarted() {
        let transformControls = global.app.editor.transformControls;

        transformControls.addEventListener("mouseDown", this.onMouseDown.bind(this));
        transformControls.addEventListener("mouseUp", this.onMouseUp.bind(this));

        transformControls.addEventListener("objectChange", () => {
            const object = transformControls.object;
            if (object) {
                if (this.mode === "scale") {
                    global.app.call("scaling", this, object.scale);
                } else if (this.mode === "rotate") {
                    global.app.call("rotating", this, {
                        x: object.rotation._x,
                        y: object.rotation._y,
                        z: object.rotation._z,
                    });
                } else if (this.mode === "translate") {
                    global.app.call("translating", this, object.position);
                }
            }
        });

        global.app.on(`objectSelected.${this.id}`, this.onObjectSelected.bind(this));
        global.app.on(`objectArraySelected.${this.id}`, this.onObjectArraySelected.bind(this));
        global.app.on(`objectChanged.${this.id}`, this.onObjectChanged.bind(this));
        global.app.on(`changeMode.${this.id}`, this.onChangeMode.bind(this));
        global.app.on(`snapChanged.${this.id}`, this.onSnapChanged.bind(this));
        global.app.on(`spaceChanged.${this.id}`, this.onSpaceChanged.bind(this));
        this.addControlOrCommandListener();
    }

    stop() {
        global.app.on(`objectSelected.${this.id}`, null);
        global.app.on(`objectArraySelected.${this.id}`, null);
        global.app.on(`objectChanged.${this.id}`, null);
        global.app.on(`changeMode.${this.id}`, null);
        global.app.on(`snapChanged.${this.id}`, null);
        global.app.on(`spaceChanged.${this.id}`, null);
        global.app.on(`appStarted.${this.id}`, null);
    }

    /**
     * 点击鼠标，记录选中物体当前平移、旋转和缩放值
     */
    onMouseDown() {
        if (global.app.editor.view !== "perspective") {
            return;
        }

        if (["translate", "rotate", "scale"].indexOf(this.mode) === -1) {
            return;
        }

        if (this.isCmdOrControlClicked && !(global.app.editor?.selected instanceof Array)) {
            this.isCmdOrControlClicked = false;
            global.app.editor?.cloneObjectByUuid(global.app.editor.selected.uuid);
            return;
        }

        var object = global.app.editor.transformControls.object;

        this.objectPosition = object.position.clone();
        this.objectRotation = object.rotation.clone();
        this.objectScale = object.scale.clone();

        global.app.editor.controls.disable();
    }

    /**
     * 抬起鼠标，更新选中物体的平移、旋转和缩放值
     */
    onMouseUp() {
        if (global.app.editor.view !== "perspective") {
            return;
        }

        if (["translate", "rotate", "scale"].indexOf(this.mode) === -1) {
            return;
        }

        var editor = global.app.editor;
        var transformControls = editor.transformControls;
        var object = transformControls.object;

        if (object === null) {
            return;
        }

        switch (transformControls.getMode()) {
            case "translate":
                if (!this.objectPosition.equals(object.position)) {
                    editor.execute(new SetPositionCommand(object, object.position, this.objectPosition));
                }
                break;
            case "rotate":
                if (!this.objectRotation.equals(object.rotation)) {
                    editor.execute(new SetRotationCommand(object, object.rotation, this.objectRotation));
                }
                break;
            case "scale":
                if (!this.objectScale.equals(object.scale)) {
                    editor.execute(new SetScaleCommand(object, object.scale, this.objectScale));
                }
                break;
        }

        global.app.editor.controls.enable();
    }

    toggleControls(object) {
        const editor = global.app.editor;
        editor.transformControls.detach();

        if (!object) {
            return;
        }

        if (editor.sceneLockedItems?.includes(object?.uuid)) {
            return;
        }

        if (["translate", "rotate", "scale"].indexOf(this.mode) === -1) {
            return;
        }

        if (!object || object === editor.scene || object === editor.camera) {
            return;
        }

        editor.transformControls.visible = true;
        editor.transformControls.attach(object);
    }

    onObjectArraySelected(objects) {
        this.toggleControls(null);
    }

    onObjectSelected(object) {
        this.toggleControls(object);
    }

    onObjectChanged(object) {
        const editor = global.app.editor;
        if (editor.selected !== object) {
            return;
        }

        // disable transform controls if object is locked
        if (editor.sceneLockedItems?.includes(object?.uuid)) {
            this.toggleControls(null);
            return;
        }

        this.toggleControls(object);
    }

    /**
     * 切换平移、旋转、缩放模式
     * @param {*} mode 模式
     */
    onChangeMode(mode) {
        this.mode = mode;
        const transformControls = global.app.editor.transformControls;

        let isPlaneSelected = false;

        const selected = global.app.editor.selected;
        if (selected) {
            const isSelectedArray = selected instanceof Array;
            const objects = isSelectedArray ? selected : [selected];

            isPlaneSelected = objects.some(o => o.userData && o.userData.isPlane === true);
        }

        if (isPlaneSelected && mode === "scale") {
            transformControls.showY = false;
        } else {
            transformControls.showY = true;
        }

        if (mode === "translate" || mode === "rotate" || mode === "scale") {
            transformControls.setMode(mode);
        } else {
            transformControls.detach();
        }
    }

    /**
     * 设置平移移动的大小
     * @param {*} dist 参数
     */
    onSnapChanged(dist) {
        global.app.editor.transformControls.setTranslationSnap(dist);
    }

    /**
     * 设置世界坐标系还是物体坐标系
     * @param {*} space 参数
     */
    onSpaceChanged(space) {
        global.app.editor.transformControls.setSpace(space);
    }

    addControlOrCommandListener() {
        document.addEventListener("keydown", e => {
            this.isCmdOrControlClicked = !!(e.ctrlKey || e.metaKey);
        });
        document.addEventListener("keyup", e => {
            this.isCmdOrControlClicked = false;
        });
    }
}

export default TransformControlsEvent;
