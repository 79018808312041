import {useState} from "react";
import {InputSymbol} from "../InputSymbol";
import {NumericInput} from "../NumericInput";
import {AxisArray, AxisType} from "./MovementSection";
import {Box, BoxInputs, BoxLabels, InputWrapper, Wrapper} from "./MovementSection.style";

interface Props {
    isLocked: boolean;
}

export const ShapeSection = ({isLocked}: Props) => {
    const [shapeSize, setShapeSize] = useState({x: 0, y: 0, z: 0});

    return (
        <Wrapper>
            <Box>
                <BoxLabels>
                    <div className="titleSecondary">Size</div>
                </BoxLabels>
                <BoxInputs>
                    {AxisArray.map((axis: AxisType) => {
                        return (
                            <InputWrapper key={axis}>
                                <InputSymbol
                                    symbol={axis.toUpperCase()}
                                    value={shapeSize[axis]}
                                    setValue={value => setShapeSize(prev => ({...prev, [axis]: value}))}
                                    isLocked={isLocked}
                                />
                                <NumericInput
                                    value={shapeSize[axis]}
                                    setValue={value => {
                                        return null;
                                    }}
                                    className="dark-input"
                                    disabled={isLocked}
                                    onBlur={value => setShapeSize(prev => ({...prev, [axis]: value}))}
                                    onDragValueChange={value => setShapeSize(prev => ({...prev, [axis]: value}))}
                                />
                            </InputWrapper>
                        );
                    })}
                </BoxInputs>
            </Box>
        </Wrapper>
    );
};
