import React from "react";
import {useModelAnimationCombinerContext} from "../../../../../context";
import styled from "styled-components";
import {StyledButton} from "../../common/StyledButton";
import {regularFont} from "../../../../../assets/style";
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";
import {ModelUtils} from "../../../../../utils/ModelUtils";
import {JSONDocument} from "@gltf-transform/core";
import {toast} from "react-toastify";

const Section = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;
    padding-top: 12px;
    gap: 16px;
    box-sizing: border-box;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
`;

const Title = styled.div`
    ${regularFont("xxs")};
`;

export const Export: React.FC = () => {
    const {mainModel, animations, toggleLoading, action} = useModelAnimationCombinerContext();

    const save = (blob: Blob, filename: string) => {
        var link = document.createElement("a");
        link.style.display = "none";
        document.body.appendChild(link);
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        toggleLoading();
    };

    const saveString = (text: string, filename: string) => {
        save(new Blob([text], {type: "text/plain"}), filename);
    };

    const saveArrayBuffer = (buffer: ArrayBuffer, filename: string) => {
        save(new Blob([buffer], {type: "application/octet-stream"}), filename);
    };

    const exportGLB = async () => {
        action?.stop();
        toggleLoading();
        try {
            const exporter = new GLTFExporter();

            // Parse the input and generate the glTF output
            exporter.parse(
                mainModel,
                async result => {
                    let arrayBuffer = result as ArrayBuffer;
                    arrayBuffer = (await ModelUtils.compressModel(arrayBuffer, false, () => {
                        toast.warn("Could not compress model");
                    })) as ArrayBuffer;
                    saveArrayBuffer(arrayBuffer, `${mainModel.userData.Name.split(".")[0]}.glb`);
                },
                () => {},
                //@ts-ignore
                {trs: true, binary: true, includeCustomExtensions: true, animations: animations},
            );
        } catch (error) {
            toggleLoading();
        }
    };

    const exportGLTF = () => {
        action?.stop();
        try {
            toggleLoading();
            var exporter = new GLTFExporter();

            // Parse the input and generate the glTF output
            exporter.parse(
                mainModel.children.length > 0 ? mainModel.children : mainModel,
                async result => {
                    let json = result as JSONDocument;
                    json = (await ModelUtils.compressModel(result as JSONDocument, true, () => {
                        toast.warn("Could not compress model");
                    })) as JSONDocument;

                    var output = JSON.stringify(json, null, 2);
                    saveString(output, `${mainModel.userData.Name.split(".")[0]}.gltf`);
                },
                () => {},
                //@ts-ignore
                {trs: true, binary: false, includeCustomExtensions: true, animations: animations},
            );
        } catch (error) {
            toggleLoading();
            alert("Error: Try deleting the texture, if that does not help, Open an issue on the Github Repo");
        }
    };

    return (
        <Section>
            <Title>Export</Title>

            <Row>
                <StyledButton style={{height: "24px"}} onClick={exportGLTF} className="blueBtn" isGreyBlue>
                    GLTF
                </StyledButton>

                <StyledButton style={{height: "24px"}} onClick={exportGLB} className="blueBtn" isGreyBlue>
                    GLB
                </StyledButton>
            </Row>
        </Section>
    );
};
