import React from "react";
import "./css/StyledSwitch.css";

type Props = {
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    style?: React.CSSProperties;
};

export const StyledSwitch = ({checked, onChange, style}: Props) => {
    return (
        <label className="StyledSwitch" style={style}>
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span className="slider round" />
        </label>
    );
};
