import {Checkbox} from "../../../../../../ui/common/Checkbox";
import {ScriptBehaviorInterface} from "../../../../../../types/editor";
import {Container} from "./ScriptsPanelItem.style";
import {useState} from "react";

type Props = {
    script: ScriptBehaviorInterface;
    onClick: (e: any, script: ScriptBehaviorInterface) => void;
    onCheckChange: (script: ScriptBehaviorInterface, checked: boolean) => void;
    isLast: boolean;
};

export const ScriptsPanelItem = ({script, onClick, onCheckChange, isLast}: Props) => {
    const [isEnabled, setIsEnabled] = useState(script.enabled);

    return (
        <Container onClick={e => onClick(e, script)} $isLast={isLast}>
            <span>{script.name}</span>
            <Checkbox
                customId={script.id || script.name}
                checked={isEnabled}
                onChange={e => {
                    e.stopPropagation();
                    onCheckChange(script, e.target.checked);
                    setIsEnabled(e.target.checked);
                }}
            />
        </Container>
    );
};
