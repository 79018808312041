import {useEffect, useState} from "react";
import I18n from "i18next";
import * as THREE from "three";
import {StyledRange} from "../../common/StyledRange";
import {CharacterSoundsBehaviorInterface, SoundPropInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import CharacterSoundsBehaviorConverter from "../../../../../serialization/behaviours/CharacterSoundsBehaviorConverter";
import Ajax from "../../../../../utils/Ajax";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import {SelectRow} from "../common/SelectRow";
import {Item} from "../../common/BasicCombobox";

type Props = {
    behavior: CharacterSoundsBehaviorInterface;
};

export const CharacterSoundsBehavior = ({behavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behaviorState, setBehaviorState] = useState(behavior);
    const [soundData, setSoundData] = useState<
        {
            ID: string;
            Name: string;
            Url: string;
        }[]
    >();
    const [soundOptions, setSoundOptions] = useState<Item[]>([]);

    const jumpingSound = behavior.jumping.id
        ? {
              ID: behavior.jumping.id,
              Name: behavior.jumping.name,
              Url: behavior.jumping.url,
          }
        : undefined;
    const walikngSound = behavior.walking.id
        ? {
              ID: behavior.walking.id,
              Name: behavior.walking.name,
              Url: behavior.walking.url,
          }
        : undefined;

    const runningSound = behavior.running.id
        ? {
              ID: behavior.running.id,
              Name: behavior.running.name,
              Url: behavior.running.url,
          }
        : undefined;

    const dyingSound = behavior.dying.id
        ? {
              ID: behavior.dying.id,
              Name: behavior.dying.name,
              Url: behavior.dying.url,
          }
        : undefined;

    const targetBehavior = CharacterSoundsBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleInputChange = (value: any, name: keyof CharacterSoundsBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehaviorState({...targetBehavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const onSelectAudio = (data: any, name: keyof CharacterSoundsBehaviorInterface) => {
        let loader = new THREE.AudioLoader();
        loader.load(
            data.Url,
            (buffer: any) => {
                console.log("Audio buffer:", buffer);

                if (targetBehavior) {
                    (targetBehavior[name] as SoundPropInterface).name = data.Name;
                    (targetBehavior[name] as SoundPropInterface).url = data.Url;
                    (targetBehavior[name] as SoundPropInterface).id = data.ID;
                    app.call(`objectChanged`, app.editor, app.editor.selected);
                    app.call(`objectUpdated`, app.editor, app.editor.selected);
                }
            },
            (error: any) => {
                console.error("Error loading audio:", error);
            },
        );
    };

    const fetchSounds = () => {
        Ajax.get({url: backendUrlFromPath(`/api/Audio/List`)}).then(response => {
            const obj = response?.data;
            if (obj.Code !== 200) {
                app.toast(I18n.t(obj.Msg), "warn");
                return;
            }
            setSoundData(obj.Data);
        });
    };

    useEffect(() => {
        fetchSounds();
        app.on(`objectChanged.GenericSoundBehaviors`, fetchSounds);
    }, []);

    useEffect(() => {
        if (soundData) {
            setSoundOptions(
                soundData.map((option: any, index: number) => {
                    return {
                        key: `${index + 1}`,
                        value: option.Name,
                    };
                }),
            );
        }
    }, [soundData]);

    return (
        <>
            <SelectRow
                label="Jumping"
                data={soundOptions}
                value={soundOptions.find(item => item.value === jumpingSound?.Name) || {key: "0", value: "none"}}
                onChange={item =>
                    onSelectAudio(
                        soundData?.find(el => el.Name === item.value),
                        "jumping",
                    )
                }
            />
            <SelectRow
                label="Walking"
                data={soundOptions}
                value={soundOptions.find(item => item.value === walikngSound?.Name) || {key: "0", value: "none"}}
                onChange={item =>
                    onSelectAudio(
                        soundData?.find(el => el.Name === item.value),
                        "walking",
                    )
                }
            />
            <SelectRow
                label="Running"
                data={soundOptions}
                value={soundOptions.find(item => item.value === runningSound?.Name) || {key: "0", value: "none"}}
                onChange={item =>
                    onSelectAudio(
                        soundData?.find(el => el.Name === item.value),
                        "running",
                    )
                }
            />
            <SelectRow
                label="Dying"
                data={soundOptions}
                value={soundOptions.find(item => item.value === dyingSound?.Name) || {key: "0", value: "none"}}
                onChange={item =>
                    onSelectAudio(
                        soundData?.find(el => el.Name === item.value),
                        "dying",
                    )
                }
            />
            <span className="common-text">Volume {Number(behaviorState.volume).toFixed(0)}%</span>
            <StyledRange
                volume={behaviorState.volume / 100}
                setVolume={value => handleInputChange(value * 100, "volume")}
            />
        </>
    );
};
