import * as THREE from "three";
import BaseBehaviorConverter from "./BaseBehaviorConverter";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import SpawnPointBehaviorUpdater from "./SpawnPointBehaviorUpdater";
import {OBJECT_TYPES, SPAWNPOINT_TYPES, SpawnPointBehaviorInterface} from "../../types/editor";

class SpawnPointBehaviorConverter extends BaseBehaviorConverter<SpawnPointBehaviorInterface> {
    public static DEFAULT = new SpawnPointBehaviorConverter(null);

    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: any): BehaviorUpdater {
        return new SpawnPointBehaviorUpdater(target, behavior.objectNameToSpawn, behavior.spawnType);
    }

    getBehavior(target: THREE.Object3D, id: string): SpawnPointBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as SpawnPointBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have SPAWNPOINT behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): SpawnPointBehaviorInterface {
        return {
            type: OBJECT_TYPES.SPAWNPOINT,
            startOnTrigger: false,
            objectNameToSpawn: "",
            spawnType: SPAWNPOINT_TYPES.MOVE,
            enabled: true,
            id,
        };
    }
}

export default SpawnPointBehaviorConverter;
