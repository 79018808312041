/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseLoader from "./BaseLoader";
import * as THREE from "three";
import PLYLoaderImpl from "../assets/js/loaders/PLYLoader";
/**
 * PLYLoader
 * @author tengge / https://github.com/tengge1
 */
class PLYLoader extends BaseLoader {
  constructor() {
    super();
  }

  load(url) {
    return new Promise((resolve, reject) => {
      this.require("PLYLoader")
        .then(() => {
          var loader = new PLYLoaderImpl();

          loader.load(
            url,
            (geometry) => {
              geometry.computeVertexNormals();
              var material = new THREE.MeshStandardMaterial();
              var mesh = new THREE.Mesh(geometry, material);
              resolve(mesh);
            },
            undefined,
            () => {
              resolve(null);
            }
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default PLYLoader;
