import styled from "styled-components";

const Area = styled.textarea<{width?: string; height?: string; $padding?: string; $background?: string}>`
    width: ${props => props.width || "100%"};
    height: ${props => props.height || "64px"};
    padding: ${props => props.$padding || "8px"};
    background: ${props => props.$background || "var(--theme-grey-bg)"};
    resize: none;
    border: none;
    border-radius: 8px;
    font-size: var(--theme-font-size-xxs);
    color: #fafafa;
`;

type Props = {
    width?: string;
    height?: string;
    padding?: string;
    background?: string;
    placeholder?: string;
    value: string;
    setValue: (value: string) => void;
};

export const StyledTextarea = ({width, height, padding, background, placeholder, value, setValue}: Props) => {
    return (
        <Area
            width={width}
            height={height}
            $padding={padding}
            $background={background}
            placeholder={placeholder}
            value={value}
            onChange={e => setValue(e.target.value)}
        />
    );
};
