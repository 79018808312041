import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import { Object3D } from "three";

class AnimationBehaviorUpdater implements BehaviorUpdater {

    private game?: GameManager;

    target: Object3D;

    constructor(target: Object3D) {
        this.target = target;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
    }

    update(clock: THREE.Clock, delta: number) {
        //TODO: move character controller code here
    }

    reset() {}
}

export default AnimationBehaviorUpdater;