import React, {useRef} from "react";
import {useState} from "react";
import {useOnClickOutside} from "usehooks-ts";
import styled from "styled-components";

type Props = {
    items: string[];
    onChange: (value: string) => void;
    value: string;
};

export const StyledSelect = ({value, onChange, items}: Props) => {
    const [expanded, setExpanded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const handleChange = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        value: string,
    ) => {
        e.stopPropagation();
        onChange(value);
        setExpanded(false);
    };

    useOnClickOutside(ref, () => setExpanded(false));

    return (
        <Select onClick={() => setExpanded(true)} ref={ref}>
            <SelectedOption>{value}</SelectedOption>
            <Dropdown expanded={expanded}>
                {items
                    .filter(item => item !== value)
                    .map((item, index) => (
                        <Option
                            key={index}
                            onClick={e => handleChange(e, item)}>
                            {item}
                        </Option>
                    ))}
            </Dropdown>
        </Select>
    );
};

export const Select = styled.div`
    position: relative;
    width: 140px;
    height: 28px;
`;

export const SelectedOption = styled.div`
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    background-color: var(--theme-container-secondary-dark);
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: var(--theme-font-unselected-color);
    line-height: 120%;
    padding: 6px 7px 6px 14px;
`;

export const Dropdown = styled.div<{expanded: boolean}>`
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 15px 0px #000;
    z-index: 10;
    max-height: 240px;
    overflow-y: auto;
    ${({expanded}) =>
        expanded &&
        `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0;
    flex-direction: column;
    background-color: var(--theme-container-secondary-dark);
    `}
`;

export const Option = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    padding: 4px 7px;
    cursor: pointer;
    background-color: var(--theme-container-secondary-dark);
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: var(--theme-font-unselected-color);
    min-height: 25px;
    &:hover {
        filter: brightness(1.3);
    }
`;
