import {NumericInput} from "../../common/NumericInput";
import {StyledRowWrapper} from "./StyledRowWrapper";

interface Props {
    width?: string;
    min?: number;
    max?: number;
    label: string;
    value: number;
    setValue: (value: number) => void;
    disabled?: boolean;
    $margin?: string;
}

export const NumericInputRow = ({label, value, setValue, width, min, max, disabled, $margin}: Props) => {
    return (
        <StyledRowWrapper $margin={$margin}>
            <span className="text">{label}</span>
            <NumericInput width={width} value={value} setValue={setValue} min={min} max={max} disabled={disabled} />
        </StyledRowWrapper>
    );
};
