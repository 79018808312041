export const HeartIcon = ({userLikedGame}: {userLikedGame: boolean}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.00016 6.34163C2.01806 4.49304 3.52217 3 5.375 3C6.43522 3 7.38126 3.48888 8 4.2535C8.61873 3.48888 9.56477 3 10.625 3C12.489 3 14 4.51104 14 6.375C14 6.42155 13.999 6.46792 13.9972 6.51409C13.9334 8.64751 12.3785 10.2732 11.0417 11.298C10.3523 11.8264 9.67048 12.234 9.16289 12.5091C8.90823 12.6471 8.69527 12.7529 8.54429 12.825C8.46875 12.861 8.40858 12.8887 8.36629 12.9078C8.34514 12.9173 8.32845 12.9247 8.31654 12.93L8.30227 12.9362L8.2979 12.9381L8.29641 12.9387C8.11015 13.02 7.89015 13.02 7.7042 12.939L7.70212 12.9381L7.69767 12.9362L7.68315 12.9298C7.67102 12.9245 7.65401 12.9169 7.63247 12.9072C7.58938 12.8878 7.52807 12.8596 7.45112 12.8228C7.29734 12.7494 7.0805 12.6415 6.82152 12.5008C6.30536 12.2203 5.6128 11.8047 4.9159 11.2659C3.56385 10.2205 1.99926 8.55828 1.99918 6.38003C1.99918 6.36719 1.9995 6.35439 2.00016 6.34163Z"
                fill={userLikedGame ? "#e11d48" : "#c3c3c3"}
            />
        </svg>
    );
};
