import styled from "styled-components";
import {regularFont} from "../../../assets/style";
import {LEFT_COLUMN_WIDTH} from "./FloatingColumn/FloatingColumn.style";
import worldImg from "../../assets/world-bg.svg";

export const Container = styled.div`
    background-color: var(--theme-container-main-dark);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: calc(100% - ${LEFT_COLUMN_WIDTH});
    padding: 0 20px;
    position: relative;
    margin-left: auto;
    min-height: 700px;
`;

export const List = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 9px));
    gap: 20px;
    height: 100%;
    padding: 0 20px;

    .noContent {
        grid-column: 1/-1;
        ${regularFont("s")}
    }

    .singleGame {
        overflow: visible;
    }
`;

export const MainSection = styled.div`
    display: flex;
    gap: 20px;
    padding: 20px 20px 0 20px;
    margin-bottom: 4px;
`;

export const UserWorld = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-homepage-grey-bg-primary);

    height: 256px;
    width: 100%;
    border-radius: 40px;
    position: relative;
    overflow: hidden;
    background-color: var(--theme-homepage-grey-bg-primary);
    background-image: url("${worldImg}");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .worldButton {
        padding: 12px 32px;
        background: #8d0df2;
        border-top: 1px solid #ab40ff;
        border-radius: 40px;
    }
`;
