import React, {useEffect, useState} from "react";
import {ITransformValue, TRANSFORMATION_OPTIONS} from "../../../../../../types/editor";
import {MovementSection} from "../../../common/MovementSection/MovementSection";

interface Props {
    positionValue: ITransformValue;
    rotationValue: ITransformValue;

    setPositionValue: React.Dispatch<React.SetStateAction<ITransformValue>>;
    setRotationValue: React.Dispatch<React.SetStateAction<ITransformValue>>;
}

export const PositionSection = ({positionValue, setPositionValue, rotationValue, setRotationValue}: Props) => {
    const [positionOriginalValue, setPositionOriginalValue] = useState<ITransformValue>({x: 0, y: 0, z: 0});
    const [rotationOriginalValue, setRotationOriginalValue] = useState<ITransformValue>({x: 0, y: 0, z: 0});

    useEffect(() => {
        setPositionValue(positionValue);
        setRotationValue(rotationValue);

        setPositionOriginalValue(JSON.parse(JSON.stringify(positionValue)));
        setRotationOriginalValue(JSON.parse(JSON.stringify(rotationValue)));
    }, []);

    const reset = (type: TRANSFORMATION_OPTIONS) => {
        switch (type) {
            case TRANSFORMATION_OPTIONS.POSITION:
                setPositionValue(positionOriginalValue);
                break;

            case TRANSFORMATION_OPTIONS.ROTATION:
                setRotationValue(rotationOriginalValue);

                break;

            default:
                break;
        }
    };

    const getSetValueFunc = (type: TRANSFORMATION_OPTIONS, value: number, toUpdate: "x" | "y" | "z") => {
        if (type === TRANSFORMATION_OPTIONS.POSITION) {
            switch (toUpdate) {
                case "x":
                    return setPositionValue(prevState => ({
                        ...prevState,
                        x: value,
                    }));
                case "y":
                    return setPositionValue(prevState => ({
                        ...prevState,
                        y: value,
                    }));
                case "z":
                    return setPositionValue(prevState => ({
                        ...prevState,
                        z: value,
                    }));

                default:
                    break;
            }
        } else if (type === TRANSFORMATION_OPTIONS.ROTATION) {
            switch (toUpdate) {
                case "x":
                    return setRotationValue(prevState => ({
                        ...prevState,
                        x: value,
                    }));
                case "y":
                    return setRotationValue(prevState => ({
                        ...prevState,
                        y: value,
                    }));
                case "z":
                    return setRotationValue(prevState => ({
                        ...prevState,
                        z: value,
                    }));

                default:
                    break;
            }
        }
    };

    return (
        <MovementSection
            noScale
            isLocked={false}
            reset={reset}
            positionValue={positionValue}
            rotationValue={rotationValue}
            getSetValueFunc={getSetValueFunc}
        />
    );
};
