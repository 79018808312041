import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import {AiNPCBehaviorInterface, OBJECT_TYPES} from "../../types/editor";
import AiNPCBehaviorUpdater from "./AiNpcBehaviorsUpdater";

class AiNPCBehaviorConverter extends BaseBehaviorConverter<AiNPCBehaviorInterface> {
    public static DEFAULT = new AiNPCBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D): BehaviorUpdater {
        return new AiNPCBehaviorUpdater(target);
    }

    getBehavior(target: THREE.Object3D, id: string): AiNPCBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as AiNPCBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have AI NPC behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): AiNPCBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.AI_NPC,
            name: "Aria",
            range: 10,
            voice_id: "9BWtsMINqrJLrRacOk9x",
            description: "Aria is a friendly NPC that helps the player.",
            additional_prompt: "",
            active_in_voice_chat: false,
        };
    }
}

export default AiNPCBehaviorConverter;
