import React, {useEffect, useState} from "react";
import styled from "styled-components";
import GradientSpinner from "../../player/component/GradientSpinner";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: #00000099;
    width: 100vw;
    height: 100vh;
`;

export const LottieContainer = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

type Props = {
    show?: boolean;
    style?: React.CSSProperties;
};

export const LoadingAnimation = ({show, style}: Props) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (show) {
            setIsMounted(true);
        } else {
            setTimeout(() => {
                setIsMounted(false);
            }, 1000);
        }
    }, [show]);

    if (!isMounted) return null;
    return (
        <Container style={style}>
            <GradientSpinner />
        </Container>
    );
};
