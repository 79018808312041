import * as THREE from 'three';
import { Object3D } from 'three';
import EventBus from '../../behaviors/event/EventBus';
import GameManager from '../../behaviors/game/GameManager';
import { IPhysics } from "src/physics/common/types";
import { COLLISION_TYPE, OBJECT_TYPES } from "../../types/editor";

enum GAME_STATE {
    NOT_STARTED = 0,
    STARTED,
    FINISHED,
    PAUSED,
}

class ThrowableBehaviorUpdater {
    target: Object3D;
    pointAmount: number;
    removed = false;
    game?: GameManager;
    gunAimerImageBase64: string | null = null;
    imageVisibilityToken: string | null = null;
    weaponShowHUDAimerInGame: string | null = null;
    endingSession = false;
    usingPhysics = false;
    isDynamic = false;
    physics?: IPhysics;
    playerCollisionListenerId: string | undefined;

    constructor(target: Object3D, pointAmount: number) {
        this.target = target;
        this.pointAmount = pointAmount;
        this.usingPhysics =
            this.target.userData.physics && this.target.userData.physics.body;

    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;
        this.addCollisionListener();
    }

    addCollisionListener() {

        this.playerCollisionListenerId =
            this.game!.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: COLLISION_TYPE.WITH_PLAYER,
                    callback: this.onCollisionWithPlayer.bind(this),
                    useBoundingBoxes: false,
                },
                this.isDynamic
            );
        EventBus.instance.subscribe('collision', this.onCollision.bind(this));
    }

    onCollision(msg: string, data: any) {

        if (msg === 'collision' && data.target === this.target) {
            console.log('Throwable collided');
            EventBus.instance.send('game.score.inc', this.pointAmount);
            this.removed = true;
        }
    }

    onCollisionWithPlayer() {

        if (this.target.userData.allowPickUp) {
            this.target.userData.isThrowable = true;
        }

    }

    update(clock: THREE.Clock, delta: number) {

    }

    reset() {
        if (this.removed) {
            this.removed = false;
            this.addCollisionListener();
        }
    }
}

export default ThrowableBehaviorUpdater;