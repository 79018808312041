import * as THREE from "three";
import {CameraBehaviorInterface} from "../../types/editor";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";

class CameraBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    behavior: CameraBehaviorInterface;
    game?: GameManager;

    constructor(target: THREE.Object3D, behavior: CameraBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
    }

    update(clock: THREE.Clock, delta: number): void {}

    reset() {}
}

export default CameraBehaviorUpdater;
