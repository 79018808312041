import React, {useState} from "react";
import {HUD_TABS, LAYOUT_BUTTON_TYPE} from "../editor/assets/v2/HUD/HUDEditView/types";

interface HUDContextValue {
    popupType: LAYOUT_BUTTON_TYPE | undefined;
    setPopupType: React.Dispatch<React.SetStateAction<LAYOUT_BUTTON_TYPE | undefined>>;
    isPopupOpen: boolean;
    openPopup: (type: LAYOUT_BUTTON_TYPE, id: string) => void;
    popupCallback: ((arg: any) => void) | undefined;
    setPopupCallback: React.Dispatch<React.SetStateAction<((arg: any) => void) | undefined>>;
    popupId: string | undefined;
    setPopupId: React.Dispatch<React.SetStateAction<string | undefined>>;
    activeScreen: HUD_TABS;
    setActiveScreen: React.Dispatch<React.SetStateAction<HUD_TABS>>;
    openColorPicker: boolean;
    setOpenColorPicker: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HUDContext = React.createContext<HUDContextValue>(null!);

export interface HUDContextProviderProps {
    children: React.ReactNode;
}

const HUDContextProvider: React.FC<HUDContextProviderProps> = ({children}) => {
    const [activeScreen, setActiveScreen] = useState(HUD_TABS.GAME_START_MENU);
    const [popupType, setPopupType] = useState<LAYOUT_BUTTON_TYPE>();
    const [popupId, setPopupId] = useState<string>();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupCallback, setPopupCallback] = useState<(arg: any) => void>();
    const [openColorPicker, setOpenColorPicker] = useState(false);

    const openPopup = (type: LAYOUT_BUTTON_TYPE, id: string) => {
        if (type === LAYOUT_BUTTON_TYPE.ADD_PANEL_BG) {
            setOpenColorPicker(true);
            return;
        }
        setPopupId(id);
        setPopupType(type);
        setIsPopupOpen(true);
    };

    return (
        <HUDContext.Provider
            value={{
                popupType,
                setPopupType,
                openPopup,
                isPopupOpen,
                popupCallback,
                setPopupCallback,
                popupId,
                setPopupId,
                activeScreen,
                setActiveScreen,
                openColorPicker,
                setOpenColorPicker,
            }}>
            {children}
        </HUDContext.Provider>
    );
};

export default HUDContextProvider;
