import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import {COLLISION_TYPE} from "../../types/editor";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import { IPhysics } from "src/physics/common/types";
import {PhysicsUtil} from "../../physics/PhysicsUtil";
import MathUtils from "../../physics/common/math";

class FollowBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;
    followTargetUuid: string;
    distance: number;
    speed: number;
    rotate: boolean;
    physics?: IPhysics;

    constructor(target: THREE.Object3D, followTargetUuid: string, distance: number, speed: number, rotate: boolean) {
        this.target = target;
        this.followTargetUuid = followTargetUuid;
        this.distance = distance;
        this.speed = speed;
        this.rotate = rotate;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;
    }

    update(clock: THREE.Clock, delta: number): void {
        if (!this.followTargetUuid || this.speed <= 0) {
            return;
        }

        const followTarget = this.game?.scene?.getObjectByProperty("uuid", this.followTargetUuid);

        if (!followTarget) {
            return;
        }

        const followPosition = followTarget.position.clone();
        const parentPosition = this.target.position.clone();

        const currentDistance = parentPosition.distanceTo(followPosition);

        const newPosition = parentPosition.clone();
        const newQuaternion = this.target.quaternion.clone();

        const alpha = MathUtils.clamp(this.speed * delta, 0, 1);

        if (this.distance < currentDistance) {
            newPosition.lerp(followPosition, alpha);
        }

        if (this.rotate) {
            this.target.lookAt(followPosition);
            newQuaternion.slerp(this.target.quaternion, alpha);
        }

        this.target.quaternion.copy(newQuaternion);
        this.target.position.copy(newPosition);

        this.updatePhysicsObject();
    }

    private updatePhysicsObject() {
        if (this.physics && this.target.userData.physics?.enabled) {
            const targetPosition = PhysicsUtil.calculatePhysicsPositionFromObject(
                this.target.position,
                this.target.quaternion,
                this.target.userData.physics?.anchorOffset
            );
            this.physics.setOrigin(this.target.uuid, targetPosition);
            this.physics.setRotation(this.target.uuid, this.target.quaternion);
        }
    }

    reset() {}
}

export default FollowBehaviorUpdater
