/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseLoader from "./BaseLoader";
import * as THREE from "three";
import {REVISION} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {KTX2Loader} from "three/examples/jsm/loaders/KTX2Loader";
import {MeshoptDecoder} from "../assets/js/libs/meshopt_decoder.js";
import global from "../global";
/**
 * GLTFLoader
 * @author tengge / https://github.com/tengge1
 */
class GLTFLoaderExtended extends BaseLoader {
    constructor() {
        super();
        this.loader = new GLTFLoader();
        this.loadingManager = new THREE.LoadingManager();
    }

    setUpLoader() {
        this.require(["DRACOLoader", "GLTFLoader"]).then(() => {
            const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;

            let dracoLoader = new DRACOLoader(this.loadingManager).setDecoderPath(
                `${THREE_PATH}/examples/jsm/libs/draco/gltf/`,
            );
            let ktxLoader = new KTX2Loader(this.loadingManager).setTranscoderPath(
                `${THREE_PATH}/examples/jsm/libs/basis/`,
            );

            this.loader.setCrossOrigin("anonymous");
            this.loader.setDRACOLoader(dracoLoader);
            this.loader.setKTX2Loader(ktxLoader.detectSupport(global.app.renderer));
            this.loader.setMeshoptDecoder(MeshoptDecoder);
        });
    }

    load(url, rootPath, assetMap) {
        const baseURL = THREE.LoaderUtils.extractUrlBase(url);
        this.loadingManager = new THREE.LoadingManager();
        const blobURLs = [];

        this.loadingManager.setURLModifier((url, path) => {
            const normalizedURL =
                rootPath +
                decodeURI(url)
                    .replace(baseURL, "")
                    .replace(/^(\.?\/)/, "");

            if (assetMap.has(normalizedURL)) {
                const blob = assetMap.get(normalizedURL);
                const blobURL = URL.createObjectURL(blob);
                blobURLs.push(blobURL);
                return blobURL;
            }

            return (path || "") + url;
        });
        this.loader = new GLTFLoader(this.loadingManager);
        this.setUpLoader();

        return new Promise((resolve, reject) => {
            this.loader.load(
                url,
                result => {
                    var obj3d = result.scene;

                    obj3d._obj = result;
                    obj3d._root = result.scene;

                    resolve(obj3d);
                },
                undefined,
                () => {
                    reject();
                },
            );
        });
    }
}

export default GLTFLoaderExtended;
