import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import {COLLISION_TYPE} from "../../types/editor";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import { IPhysics } from "src/physics/common/types";
import { PhysicsUtil } from "../../physics/PhysicsUtil";

class TeleportBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;
    physics?: IPhysics;
    teleportTargetUuid?: string;

    constructor(target: THREE.Object3D, teleportTargetUuid: string) {
        this.target = target;
        this.teleportTargetUuid = teleportTargetUuid;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;
        this.addCollisionListener();
    }

    onCollision() {
        if (!this.teleportTargetUuid) {
            return;
        }

        const teleportTarget = this.game?.scene?.getObjectByProperty("uuid", this.teleportTargetUuid);
        const object = this.game?.player; // TODO: get collision object instead of player

        if (!teleportTarget || !object) {
            return;
        }

        object.position.copy(teleportTarget.position);
        object.quaternion.copy(teleportTarget.quaternion);

        if (object.userData.physics?.enabled) {
            const targetPosition = PhysicsUtil.calculatePhysicsPositionFromObject(
                object.position,
                object.quaternion,
                object.userData.physics?.anchorOffset
            );
            this.physics.setOrigin(object.uuid, targetPosition);
            this.physics.setRotation(object.uuid, object.quaternion);
        }
    }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollision.bind(this),
                useBoundingBoxes: true,
            },
            PhysicsUtil.isDynamicObject(this.target)
        );
    }

    update(clock: THREE.Clock, delta: number): void {}

    reset() {}
}

export default TeleportBehaviorUpdater
