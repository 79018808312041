/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseEvent from "./BaseEvent";
import Converter from "../serialization/Converter";
// import GISScene from '../gis/Scene';
import global from "../global";
import * as THREE from "three";

import i8n from "../i18n/config";
import Ajax from "../utils/Ajax";
import {backendUrlFromPath} from "../utils/UrlUtils";

const {t} = i8n;

/**
 * 加载场景事件
 * @author tengge / https://github.com/tengge1
 */
class LoadSceneEvent extends BaseEvent {
    constructor() {
        super();
    }

    start() {
        global.app.on(`load.${this.id}`, this.onLoad.bind(this));
        global.app.on(`loadSceneList.${this.id}`, this.onLoadSceneList.bind(this));
    }

    stop() {
        global.app.on(`load.${this.id}`, null);
        global.app.on(`loadSceneList.${this.id}`, null);
    }

    onLoad(url, name, id, lockedItems, thumbnail) {
        // id: MongoDB _id
        const prevAutoSaveState = global.app.storage.autoSave;
        if (!name || name.trim() === "") {
            name = t("No Name");
        }

        // 新增场景id为null，不需要创建；否则导致保存示例场景报错。
        // if (!id) {
        //     id = THREE.MathUtils.generateUUID();
        // }
        global.app.setAutoSave(false);

        global.app.editor.clear(false);
        document.title = name;

        global.app.mask(false);

        Ajax.get({
            url: backendUrlFromPath(url),
        }).then(res => {
            global.app.unmask();
            let data;
            if (res.data.Code) {
                if (res.data.Code !== 200) {
                    global.app.toast(obj.Msg, "warn");
                    return;
                } else {
                    data = res.data.Data;
                }
            } else {
                data = res.data;
            }
            if (!data) {
                global.app.toast("Could not load scene", "warn");
                return;
            }

            this.onLoadSceneList(data, name, id, lockedItems, prevAutoSaveState, thumbnail);
        });
    }

    onLoadSceneList(list, name, id, lockedItems, prevAutoSaveState, thumbnail) {
        global.app.mask(false);
        new Converter()
            .fromJson(list, {
                server: global.app.options.server,
                camera: global.app.editor.camera,
                domWidth: global.app.editor.renderer.domElement.width,
                domHeight: global.app.editor.renderer.domElement.height,
            })
            .then(obj => {
                this.onLoadScene(obj);
                global.app.editor.sceneID = id || 0;
                global.app.editor.sceneName = name || t("No Name");
                global.app.editor.sceneLockedItems = lockedItems ? lockedItems.split(",") : [];
                global.app.editor.sceneThumbnail = thumbnail;
                document.title = global.app.editor.sceneName;

                if (obj.options) {
                    global.app.call("optionsChanged", this);

                    // if (obj.options.sceneType === 'GIS') {
                    //     if (global.app.editor.gis) {
                    //         global.app.editor.gis.stop();
                    //     }
                    //     global.app.editor.gis = new GISScene(global.app, {
                    //         useCameraPosition: true
                    //     });
                    //     global.app.editor.gis.start();
                    // }
                }

                if (obj.scripts) {
                    global.app.call("scriptChanged", this);
                }

                if (obj.scene) {
                    global.app.call("sceneGraphChanged", this);
                }
                global.app.call("sceneLoaded", this);
                global.app.call(`clear`, global.app.editor, global.app.editor);
                global.app.setAutoSave(prevAutoSaveState);
            })
            .catch(error => {
                console.log("Error from loading scene:", error);
                global.app.setAutoSave(prevAutoSaveState);
                global.app.unmask();
                global.app.toast("Creating scene failed.", "error");
            });
    }

    onLoadScene(obj) {
        console.log("onLoadScene");
        //console.log(obj);
        if (obj.options) {
            Object.assign(global.app.options, obj.options);
        }

        if (obj.camera) {
            global.app.editor.camera.remove(global.app.editor.audioListener);
            global.app.editor.camera.copy(obj.camera);
            global.app.call("enableInfiniteGrid", this, this);
            let audioListener = global.app.editor.camera.children.filter(n => n instanceof THREE.AudioListener)[0];

            if (audioListener) {
                global.app.editor.audioListener = audioListener;
            }
        }

        if (obj.rendererCSS) {
            const viewport = global.app.viewport;
            const oldRenderer = global.app.editor.rendererCSS;

            viewport.removeChild(oldRenderer.domElement);
            viewport.appendChild(obj.renderer.domElement);
            global.app.editor.rendererCSS = obj.rendererCSS;
            global.app.editor.rendererCSS.setSize(viewport.offsetWidth, viewport.offsetHeight);
            global.app.call("resize", this);
        }

        if (obj.renderer) {
            const viewport = global.app.viewport;
            const oldRenderer = global.app.editor.renderer;

            if (global.app.editor.rendererCSS) {
                global.app.editor.rendererCSS.domElement.appendChild(obj.renderer.domElement);
            } else {
                viewport.remove(oldRenderer.domElement);
                viewport.appendChild(obj.renderer.domElement);
            }

            global.app.editor.renderer = obj.renderer;
            global.app.editor.renderer.setSize(viewport.offsetWidth, viewport.offsetHeight);
            global.app.call("resize", this);
        }

        if (obj.scripts) {
            Object.assign(global.app.editor.scripts, obj.scripts);
        }

        if (obj.animations) {
            Object.assign(global.app.editor.animations, obj.animations);
        } else {
            global.app.editor.animations = [
                {
                    id: null,
                    uuid: THREE.MathUtils.generateUUID(),
                    layer: 0,
                    layerName: t("AnimLayer1"),
                    animations: [],
                },
                {
                    id: null,
                    uuid: THREE.MathUtils.generateUUID(),
                    layer: 1,
                    layerName: t("AnimLayer2"),
                    animations: [],
                },
                {
                    id: null,
                    uuid: THREE.MathUtils.generateUUID(),
                    layer: 2,
                    layerName: t("AnimLayer3"),
                    animations: [],
                },
            ];
        }

        if (obj.scene) {
            //MISHA/PHASE2 - for Phase2 all scenes should be marked as games
            //TODO: remove after the Phase2 is completed
            // obj.scene.userData.game = {
            //     uuid: THREE.MathUtils.generateUUID()
            // }
            global.app.editor.setScene(obj.scene);
        }

        global.app.editor.camera.updateProjectionMatrix();

        if (obj.options.selected) {
            var selected = global.app.editor.objectByUuid(obj.options.selected);
            if (selected) {
                global.app.editor.select(selected);
            }
        }

        global.app.call("enableVR", this, global.app.options.enableVR);

        global.app.call("animationChanged", this);
        global.app.unmask();
    }
}

export default LoadSceneEvent;
