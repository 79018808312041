import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import {OBJECT_TYPES, CheckPointAssetBehaviorInterface} from "../../types/editor";
import CheckBehaviorAssetUpdater from "./CheckPointBehaviorAssetUpdater";

class CheckPointBehaviorAssetConverter extends BaseBehaviorConverter<CheckPointAssetBehaviorInterface> {
    public static DEFAULT = new CheckPointBehaviorAssetConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: CheckPointAssetBehaviorInterface): BehaviorUpdater {
        return new CheckBehaviorAssetUpdater(target);
    }

    reset(): void {}

    getBehavior(target: THREE.Object3D, id: string): CheckPointAssetBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as CheckPointAssetBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have Check Point behavior: ", target);
            target.userData.behaviors = target.userData.behaviors
                ? target.userData.behaviors.push(this.getDefaultBehavior(id))
                : [this.getDefaultBehavior(id)];
            behavior = super.findBehavior(target, id) as CheckPointAssetBehaviorInterface;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): CheckPointAssetBehaviorInterface {
        return {
            type: OBJECT_TYPES.CHECK_POINT,
            position: {x: 0, y: 0, z: 0},
            enabled: true,
            id,
        };
    }
}

export default CheckPointBehaviorAssetConverter;
