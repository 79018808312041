import {RefObject} from "react";
import {OBJECT_TYPES, OBJECT_TYPES_WHITE_LIST} from "../../../../../../../types/editor";
import {List, ListItem} from "./AllBehaviorsList.style";

interface Props {
    menuRef: RefObject<HTMLDivElement>;
    addNewBehavior: (type: OBJECT_TYPES) => void;
}

export const AllBehaviorsList = ({addNewBehavior, menuRef}: Props) => {
    return (
        <List ref={menuRef}>
            {Object.keys(OBJECT_TYPES).map(key => {
                const type = OBJECT_TYPES[key as keyof typeof OBJECT_TYPES];

                if (type === OBJECT_TYPES.SPAWN_POINT || type === OBJECT_TYPES.CHECK_POINT) return;

                return (
                    <ListItem
                        key={key}
                        $inactive={!OBJECT_TYPES_WHITE_LIST.includes(type)}
                        onClick={() => addNewBehavior(type)}>
                        {type}
                    </ListItem>
                );
            })}
        </List>
    );
};
