import {backendUrlFromPath} from "../../utils/UrlUtils";
import Ajax from "../../utils/Ajax";
import {SEARCH_GAME_QUERY} from "./types";
import {ROUTES} from "../../AppRouter";

export const truncateName = (name: string, limit: number) => {
    if (name.length > limit) {
        return name.slice(0, limit) + "...";
    }
    return name;
};

export const formatNumber = (n: number, maxWithoutK?: number) => {
    if (maxWithoutK && n > maxWithoutK) {
        return Math.round(n / 1000).toLocaleString() + "k";
    } else if (n > 99999) {
        return Math.round(n / 1000).toLocaleString() + "k";
    } else {
        return n.toLocaleString();
    }
};

export const getProgressPercentage = (args: {completed: number; total: number}) => {
    const {total, completed} = args;
    if (total <= 0) {
        return console.error("Total number of tasks must be greater than zero.");
    }
    if (completed < 0) {
        return console.error("Number of completed tasks cannot be negative.");
    }
    if (completed > total) {
        return console.error("Number of completed tasks cannot exceed total number of tasks.");
    }

    const progress = (completed / total) * 100;
    return `${progress}%`;
};

export const isHexColor = (color: string) => {
    const hexColorRegex = /^#?([a-fA-F0-9]{6})$/;
    return hexColorRegex.test(color);
};

export const hexToRgb = (hex: string) => {
    hex = hex.replace("#", "");

    if (hex.length === 3) {
        hex = hex
            .split("")
            .map(char => char + char)
            .join("");
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return {r, g, b};
};

export const numberToHex = (value: number): string => {
    const intValue = Math.round(value);
    const hex = intValue.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
};

export const handleGetRGBValues = (color: string) => {
    const rgbValues = color.substring(4, color.length - 1);

    const [r, g, b] = rgbValues.split(",").map(val => parseFloat(val.trim()));
    return {r, g, b};
};

export const isRGB = (rgbColor: string) => rgbColor.match(/rgb\((\d+(\.\d+)?), (\d+(\.\d+)?), (\d+(\.\d+)?)\)/);

export const rgbToHex = (rgbColor: string): string => {
    const match = isRGB(rgbColor);

    if (!match) {
        console.error("Incorrect format of RGB");
        return "#ffffff";
    }

    const r = numberToHex(parseFloat(match[1]));
    const g = numberToHex(parseFloat(match[3]));
    const b = numberToHex(parseFloat(match[5]));

    return `#${r}${g}${b}`;
};

export const fetchModels = async () => {
    const response = await Ajax.get({
        url: backendUrlFromPath(`/api/Mesh/List`),
    });

    if (response === null || response?.data.Code !== 200) {
        return;
    }
    return response?.data.Data as any[];
};

export const generateUniqueName = (baseName: string, existingNames: Set<string>): string => {
    const namePattern = /^(.*?)(?:\s(\d+))?$/;
    const match = baseName.match(namePattern);
    let name = match ? match[1].trim() : baseName;
    let counter = 1;
    let newName = `${name} ${counter}`;

    while (existingNames.has(newName)) {
        counter++;
        newName = `${name} ${counter}`;
    }

    return newName;
};

export const getGameUrl = (sceneID: string) => {
    return `${window.location.origin}/view.html?sceneID=${sceneID}`;
};

export const getProfilePath = (userName: string) => {
    return `/user/${userName.replaceAll(/\s+/g, "-")}`;
};

export const saveSearchToLocalStorage = (searchItem: string) => {
    const storedSearchHistory = localStorage.getItem("searchHistory");
    const currentSearches: string[] = storedSearchHistory ? JSON.parse(storedSearchHistory) : [];

    if (!currentSearches.includes(searchItem)) {
        currentSearches.unshift(searchItem);
        // keep max 3 recent searches
        if (currentSearches.length > 3) {
            currentSearches.pop(); //remove the oldest element
        }
        localStorage.setItem("searchHistory", JSON.stringify(currentSearches));
        return currentSearches;
    }
};

export const getQueryParams = (): Partial<Record<SEARCH_GAME_QUERY, string>> => {
    const queryParams = new URLSearchParams(location.search);
    const params: Partial<Record<SEARCH_GAME_QUERY, string>> = {};

    Object.values(SEARCH_GAME_QUERY).forEach(key => {
        const value = queryParams.get(key);
        if (value) {
            params[key] = value;
        }
    });

    return params;
};

export const generateProjectLink = (projectId?: string) => {
    const queryParam = "customLogo=ai16z";
    if (projectId) {
        return `${ROUTES.CREATE_PROJECT}/${projectId}?${queryParam}`;
    } else {
        return `${ROUTES.CREATE_PROJECT}?${queryParam}`;
    }
};
