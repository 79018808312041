import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useHomepageContext} from "../../../../context";
import {HeroSection} from "./HeroSection/HeroSection";
import {IBasicGameInterface} from "../../types";

export const HeroCarousel = () => {
    const {games} = useHomepageContext();

    const responsive = {
        desktop: {
            breakpoint: {max: 4000, min: 0},
            items: 1,
            partialVisibilityGutter: 60,
        },
    };

    const chunkArray = (array: IBasicGameInterface[], size: number) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const groupedGames = chunkArray(games, 5);

    return (
        <Carousel responsive={responsive} partialVisible itemClass="carousel-item" arrows>
            {groupedGames.map((games, index) => (
                <HeroSection selectedGames={games} key={index} />
            ))}
        </Carousel>
    );
};
