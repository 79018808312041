import {flexCenter} from "../../../../../assets/style";
import styled from "styled-components";

export const Container = styled.div`
    ${flexCenter};
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

export const Title = styled.div`
    font-size: var(--theme-font-size-xs);
    line-height: 120%;
    color: var(--theme-font-main-selected-color);
    font-weight: var(--theme-font-medium-plus);
    text-align: left;
    width: 100%;
    padding: 12px 8px;
`;

export const Content = styled.div`
    ${flexCenter};
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`;
