import styled from "styled-components";

export const Separator = ({margin, invisible}: {margin?: string; invisible?: boolean}) => {
    return <StyledSeparator $margin={margin} $invisible={invisible} />;
};

const StyledSeparator = styled.div<{$margin?: string; $invisible?: boolean}>`
    flex-shrink: 0;
    height: 1px;
    margin: ${({$margin}) => $margin || "12px 0"};
    background: var(--theme-container-divider);
    width: calc(100% + 16px);
    transform: translate(-8px, 0);
    ${({$invisible}) => $invisible && `height: 0;`};
`;
