import {useEffect, useState} from "react";
import * as THREE from "three";

import {PanelSectionTitle} from "../RightPanel.style";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {StyledButton} from "../../common/StyledButton";
import {Separator} from "../common/Separator";
import global from "../../../../../global";
import Application from "../../../../../Application";
import {
    CUSTOM_VOLUME_TYPES,
    CustomVolumeOptionsInterface,
    GravityVolumeOptionsInterface,
    ITransformValue,
    SCENE_VOLUME_TYPES,
    SceneVolumeAssetBehaviorInterface,
    WaterVolumeOptionsInterface,
} from "../../../../../types/editor";
import SceneVolumeBehaviorAssetConverter from "../../../../../serialization/behaviours/SceneVolumeBehaviorAssetConverter";
import {BasicPropertiesSection} from "../sections/BasicPropertiesSection";
import {NumericInputRow} from "../common/NumericInputRow";
import {TransformationSection} from "../sections/TransformationSection";
import {SelectRow} from "../common/SelectRow";
import {Item} from "../../common/BasicCombobox";
import {roundNumber} from "../../utils/roundNumber";

const swimSpeedLevels: Item[] = [
    {
        key: "0",
        value: "level 1",
    },
    {
        key: "1",
        value: "level 2",
    },
    {
        key: "2",
        value: "level 3",
    },
    {
        key: "3",
        value: "level 4",
    },
    {
        key: "4",
        value: "level 5",
    },
];

const getSwimSpeedLevelNumber = (key: string) => {
    return Number(swimSpeedLevels.find(level => level.key === key)?.value.split(" ")[1]);
};

const getSwimSpeedLevel = (value: number) => {
    return swimSpeedLevels.find(level => getSwimSpeedLevelNumber(level.key) === value);
};

export const VolumePanel = () => {
    const app = (global.app as Application) || null;
    const editor = app?.editor;
    const [behavior, setBehavior] = useState<SceneVolumeAssetBehaviorInterface | null>(null);
    const [positionValue, setPositionValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [rotationValue, setRotationValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [scaleValue, setScaleValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [locked, setLocked] = useState(false);
    const [visible, setVisible] = useState(true);

    const updateTransformationValues = () => {
        const selected = editor?.selected;
        if (!selected || Array.isArray(selected)) return;

        setVisible(selected.visible);
        setPositionValue({
            x: roundNumber(selected.position.x, 4),
            y: roundNumber(selected.position.y, 4),
            z: roundNumber(selected.position.z, 4),
        });
        setRotationValue({
            x: roundNumber(selected.rotation._x * THREE.MathUtils.RAD2DEG, 2),
            y: roundNumber(selected.rotation._y * THREE.MathUtils.RAD2DEG, 2),
            z: roundNumber(selected.rotation._z * THREE.MathUtils.RAD2DEG, 2),
        });
        setScaleValue({
            x: roundNumber(selected.scale.x, 4),
            y: roundNumber(selected.scale.y, 4),
            z: roundNumber(selected.scale.z, 4),
        });
    };

    const handleFieldChange = (name: any, value: any) => {
        const selected = editor?.selected;
        if (!selected || Array.isArray(selected)) return;

        const targetBehavior = SceneVolumeBehaviorAssetConverter.DEFAULT.getBehavior(selected);
        if (targetBehavior) {
            // @ts-ignore
            targetBehavior[name as keyof SceneVolumeAssetBehaviorInterface] = value;
        }

        if (name === "volumeType" && targetBehavior) {
            if (value === SCENE_VOLUME_TYPES.WATER) {
                targetBehavior.options = SceneVolumeBehaviorAssetConverter.DEFAULT.getDefaultWaterVolumeOptions();
            }

            if (value === SCENE_VOLUME_TYPES.GRAVITY) {
                targetBehavior.options = SceneVolumeBehaviorAssetConverter.DEFAULT.getDefaultGravityVolumeOptions();
            }

            if (value === SCENE_VOLUME_TYPES.CUSTOM) {
                targetBehavior.options = SceneVolumeBehaviorAssetConverter.DEFAULT.getDefaultCustomVolumeOptions();
            }
        }
        // @ts-ignore
        setBehavior({...targetBehavior, [name]: value});
    };

    const handleOptionsChange = (name: any, value: any) => {
        const selected = editor?.selected;
        if (!selected || Array.isArray(selected)) return;

        const targetBehavior = SceneVolumeBehaviorAssetConverter.DEFAULT.getBehavior(selected);
        if (targetBehavior) {
            //@ts-ignore
            targetBehavior.options[name] = value;
            //@ts-ignore
            setBehavior({...targetBehavior, options: {...targetBehavior.options, [name]: value}});
        }
    };

    const update = () => {
        const selected = editor?.selected;
        if (!selected || Array.isArray(selected)) return;
        const targetBehavior = SceneVolumeBehaviorAssetConverter.DEFAULT.getBehavior(selected);
        setBehavior(targetBehavior);
        updateTransformationValues();
    };

    useEffect(() => {
        update();
    }, []);

    useEffect(() => {
        if (editor && app) {
            app.on(`objectSelected.VolumePanel`, update);
            app.on(`objectChanged.VolumePanel`, update);
        }

        return () => {
            app?.on(`objectSelected.VolumePanel`, null);
            app?.on(`objectChanged.VolumePanel`, null);
        };
    }, [editor]);

    return (
        <>
            <BasicPropertiesSection />
            <Separator invisible margin="24px 0 0" />
            <TransformationSection
                positionValue={positionValue}
                setPositionValue={setPositionValue}
                rotationValue={rotationValue}
                setRotationValue={setRotationValue}
                scaleValue={scaleValue}
                setScaleValue={setScaleValue}
                isLocked={locked}
                hideVisibility
            />
            <Separator margin="12px 0 12px" />
            <SelectionOfButtons justifyContent="flex-start">
                {Object.values(SCENE_VOLUME_TYPES).map(type => (
                    <StyledButton
                        key={type}
                        width="48.5%"
                        isGreyBlue={type !== behavior?.volumeType}
                        isBlue={type === behavior?.volumeType}
                        onClick={() => handleFieldChange("volumeType", type)}>
                        <span>{type}</span>
                    </StyledButton>
                ))}
            </SelectionOfButtons>

            {behavior?.volumeType === SCENE_VOLUME_TYPES.WATER && (
                <>
                    <Separator margin="12px 0 12px" />
                    <div className="box">
                        <PanelSectionTitle>Water</PanelSectionTitle>
                        <Separator invisible margin="12px 0 0" />
                        <div className="flex-inputs">
                            <SelectRow
                                label="Swim Speed"
                                data={swimSpeedLevels}
                                value={getSwimSpeedLevel(
                                    (behavior?.options as WaterVolumeOptionsInterface)?.swimSpeed || 1,
                                )}
                                onChange={item => handleOptionsChange("swimSpeed", getSwimSpeedLevelNumber(item.key))}
                            />
                            <NumericInputRow
                                label="Damage Per Second"
                                value={(behavior?.options as WaterVolumeOptionsInterface)?.dps || 0}
                                setValue={value => handleOptionsChange("dps", value)}
                            />
                        </div>
                    </div>
                </>
            )}

            {behavior?.volumeType === SCENE_VOLUME_TYPES.GRAVITY && (
                <>
                    <Separator margin="12px 0 12px" />
                    <div className="box">
                        <PanelSectionTitle>Gravity</PanelSectionTitle>
                        <Separator invisible margin="12px 0 0" />
                        <div className="flex-inputs">
                            <NumericInputRow
                                label="Gravity Strength [%]"
                                value={(behavior?.options as GravityVolumeOptionsInterface)?.strength || 0}
                                setValue={value => handleOptionsChange("strength", value)}
                            />
                        </div>
                    </div>
                </>
            )}

            {behavior?.volumeType === SCENE_VOLUME_TYPES.CUSTOM && (
                <>
                    <Separator margin="12px 0 12px" />
                    <div className="box">
                        <PanelSectionTitle>Custom Volume</PanelSectionTitle>
                        <Separator invisible margin="12px 0 0" />
                        <SelectionOfButtons>
                            {Object.values(CUSTOM_VOLUME_TYPES).map(type => (
                                <StyledButton
                                    key={type}
                                    width="48.5%"
                                    isGreyBlue={type !== (behavior?.options as CustomVolumeOptionsInterface)?.type}
                                    isBlue={type === (behavior?.options as CustomVolumeOptionsInterface)?.type}
                                    onClick={() => handleOptionsChange("type", type)}>
                                    <span>{type}</span>
                                </StyledButton>
                            ))}
                        </SelectionOfButtons>
                        <Separator invisible margin="12px 0 0" />
                        <div className="flex-inputs">
                            <NumericInputRow
                                label="Lose Time"
                                value={(behavior?.options as CustomVolumeOptionsInterface)?.loseTime || 0}
                                setValue={value => handleOptionsChange("loseTime", value)}
                            />
                            <NumericInputRow
                                label="Damage Amount [%]"
                                value={(behavior?.options as CustomVolumeOptionsInterface)?.damageAmount || 0}
                                setValue={value => handleOptionsChange("damageAmount", value)}
                            />
                            <NumericInputRow
                                label="Lose Points"
                                value={(behavior?.options as CustomVolumeOptionsInterface)?.losePoints || 0}
                                setValue={value => handleOptionsChange("losePoints", value)}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
