import {useState} from "react";
import {PLATFORM_RESPAWN_TYPES, PlatformBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import PlatformBehaviorConverter from "../../../../../serialization/behaviours/PlatformBehaviorConverter";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {NumericInputRow} from "../common/NumericInputRow";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {StyledButton} from "../../common/StyledButton";

type Props = {
    behavior: PlatformBehaviorInterface;
};

export const PlatformBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);

    const targetBehavior = PlatformBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handlePlatformRespawnTypeChange = (type: PLATFORM_RESPAWN_TYPES) => {
        if (selected && targetBehavior) {
            targetBehavior.respawn = type;
            setBehavior({...behavior, respawn: type});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleInputChange = (value: any, name: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior({...behavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };
    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
            />
            <Separator />
            <NumericInputRow
                width="75px"
                label="X Axis"
                value={behavior.horizontalX}
                setValue={value => handleInputChange(value, "horizontalX")}
            />
            <NumericInputRow
                width="75px"
                label="Y Axis"
                value={behavior.vertical}
                setValue={value => handleInputChange(value, "vertical")}
            />
            <NumericInputRow
                width="75px"
                label="Z Axis"
                value={behavior.horizontalZ}
                setValue={value => handleInputChange(value, "horizontalZ")}
            />
            <NumericInputRow
                width="75px"
                label="Speed"
                value={behavior.speed}
                setValue={value => handleInputChange(value, "speed")}
            />
            <SelectionOfButtons>
                {Object.keys(PLATFORM_RESPAWN_TYPES).map(key => {
                    const type = PLATFORM_RESPAWN_TYPES[key as unknown as keyof typeof PLATFORM_RESPAWN_TYPES];
                    return (
                        <StyledButton
                            width="109px"
                            isBlue={behavior.respawn === type}
                            isActive={behavior.respawn !== type}
                            onClick={() => handlePlatformRespawnTypeChange(type)}
                            key={key}>
                            <span>{type}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
        </>
    );
};
