import logo from "../assets/logo.svg";
import discord from "../assets/discord-grey.svg";
import {InsideColumn, LeftColumn, MidColumn, RightColumn, ShadowContainer, StyledFooter} from "./Footer.style";
import {ROUTES} from "../../AppRouter";
import {Shadow} from "../common/Shadow/Shadow.style";

interface IColumns {
    label: string;
    options: {
        text: string;
        href: string;
        target?: string;
    }[];
}

export const Footer = () => {
    const INSIDE_COLUMNS: IColumns[] = [
        {label: "Play", options: [{text: "Explore", href: ROUTES.HOME}]},
        {label: "Create", options: [{text: "Studio", href: ROUTES.DASHBOARD, target: "_blank"}]},
        {
            label: "Company",
            options: [
                {text: "About Us", href: ""},
                {text: "Contact", href: ""},
            ],
        },
        {
            label: "Legal",
            options: [
                {text: "Terms of Service", href: ROUTES.TERMS_OF_SERVICE},
                {text: "Privacy Policy", href: ROUTES.PRIVACY_POLICY},
            ],
        },
    ];
    return (
        <StyledFooter id="footer">
            <ShadowContainer>
                <Shadow $left $bottom />
            </ShadowContainer>
            <LeftColumn>
                <img src={logo} alt="EARTH.AI" />
                <span className="copyright">© {new Date().getFullYear()} Erth.AI Inc.</span>
            </LeftColumn>
            <MidColumn>
                {INSIDE_COLUMNS.map(({label, options}) => (
                    <InsideColumn key={label}>
                        <div className="label">{label}</div>
                        {options.map(({text, href, target}) => (
                            <Link text={text} href={href} target={target} key={text} />
                        ))}
                    </InsideColumn>
                ))}
            </MidColumn>
            <RightColumn>
                <img src={discord} alt="Discord" />
                <span className="copyright">All Rights Reserved</span>
            </RightColumn>
        </StyledFooter>
    );
};

interface LinkProps {
    text: string;
    href: string;
    target?: string;
}
const Link = ({text, href, target}: LinkProps) => {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (window.location.pathname === href) {
            e.preventDefault();
            const container = document.getElementById("container");
            container?.scrollTo({top: 0, behavior: "smooth"});
        }
    };

    return (
        <a
            className={!!href ? "option" : "option disabled"}
            href={href ? href : undefined}
            target={target}
            rel="noopener noreferrer"
            onClick={handleClick}>
            {text}
        </a>
    );
};
