import * as THREE from "three";
import I18n from "i18next";
import BaseSceneTemplate from "./BaseSceneTemplate";
import global from "../../../global";
import SetPositionCommand from "../../../command/SetPositionCommand";

/**
 * 空场景模板
 */
class EmptySceneTemplate extends BaseSceneTemplate {
    create() {
        const app = (global as any).app as any;
        const editor = app.editor;

        // 添加场景物体
        const amlight = new THREE.AmbientLight(0xffffff, 1.0);
        amlight.name = I18n.t("Ambient");
        editor.addObject(amlight);
        editor.execute(new SetPositionCommand(amlight, new THREE.Vector3(0, 10, 0)));

        const dirlight = new THREE.DirectionalLight(0xffffff, 6);
        dirlight.name = I18n.t("Directional");
        dirlight.castShadow = true;
        dirlight.position.set(5, 9, 7.5);
        dirlight.shadow.radius = 5;
        dirlight.shadow.bias = 0.0001;
        dirlight.shadow.normalBias = 0.2;
        dirlight.shadow.mapSize.x = 2048;
        dirlight.shadow.mapSize.y = 2048;
        dirlight.shadow.camera.left = -100;
        dirlight.shadow.camera.right = 100;
        dirlight.shadow.camera.top = 100;
        dirlight.shadow.camera.bottom = -100;
        dirlight.shadow.camera.near = 0.1;
        dirlight.shadow.camera.far = 1000;
        editor.addObject(dirlight);

        const hemisphereLight = new THREE.HemisphereLight(0x6060ff, 0x404040, 0.6);
        hemisphereLight.name = I18n.t("Hemisphere");
        hemisphereLight.position.set(0, 50, 0);
        editor.addObject(hemisphereLight);
        editor.execute(new SetPositionCommand(hemisphereLight, new THREE.Vector3(0, 50, 0)));

        editor.execute(new SetPositionCommand(dirlight, new THREE.Vector3(5, 50, 7.5)));

        global.app!.call("enableInfiniteGrid", this, this);

        // 添加场景动画
        editor.animations = [
            {
                id: null,
                uuid: THREE.MathUtils.generateUUID(),
                layer: 0,
                layerName: I18n.t("AnimLayer1"),
                animations: [],
            },
            {
                id: null,
                uuid: THREE.MathUtils.generateUUID(),
                layer: 1,
                layerName: I18n.t("AnimLayer2"),
                animations: [],
            },
            {
                id: null,
                uuid: THREE.MathUtils.generateUUID(),
                layer: 2,
                layerName: I18n.t("AnimLayer3"),
                animations: [],
            },
        ];
    }
}

export default EmptySceneTemplate;
