/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseSerializer from "../BaseSerializer";
import * as THREE from "three";
import {PhysicsUtil} from "../../physics/PhysicsUtil";
import {CSS3DObject, CSS3DSprite} from "three/examples/jsm/renderers/CSS3DRenderer";

/**
 * Object3DSerializer
 * @author tengge / https://github.com/tengge1
 */
class Object3DSerializer extends BaseSerializer {
    toJSON(obj) {
        var json = BaseSerializer.prototype.toJSON.call(this, obj);

        json.castShadow = obj.castShadow;
        json.children = obj.children.map(child => {
            return child.uuid;
        });

        if (obj.element) {
            json.element = obj.element.outerHTML;
        }

        json.frustumCulled = obj.frustumCulled;
        json.layers = obj.layers;
        json.matrixAutoUpdate = obj.matrixAutoUpdate;
        json.matrixWorldNeedsUpdate = obj.matrixWorldNeedsUpdate;
        json.modelViewMatrix = obj.modelViewMatrix;
        json.name = obj.name;
        json.parent = !obj.parent ? null : obj.parent.uuid;
        json.position = obj.position;
        json.quaternion = {
            x: obj.quaternion.x,
            y: obj.quaternion.y,
            z: obj.quaternion.z,
            w: obj.quaternion.w,
        };
        json.receiveShadow = obj.receiveShadow;
        json.renderOrder = obj.renderOrder;
        json.rotation = {
            x: obj.rotation.x,
            y: obj.rotation.y,
            z: obj.rotation.z,
            order: obj.rotation.order,
        };
        json.scale = obj.scale;
        json.type = obj.type;
        json.up = obj.up;

        json.userData = {};
        json.animations = [];

        //calculate shape for multiplayer
        if (PhysicsUtil.isPhysicsEnabled(obj)) {
            PhysicsUtil.setShapeData(obj);
        }

        Object.assign(json.userData, obj.userData);
        Object.assign(json.animations, obj.animations);

        delete json.userData.helper;

        json.uuid = obj.uuid;
        json.visible = obj.visible;
        json.isObject3D = obj.isObject3D;
        json.isCSS3DObject = obj.isCSS3DObject;
        json.isCSS3DSprite = obj.isCSS3DSprite;

        return json;
    }

    fromJSON(json, parent, revertUuid = true) {
        let element;
        let div = json.element ? document.createElement("div") : null;

        if (div) {
            element = new DOMParser().parseFromString(json.element, "text/xml").firstElementChild;
            if (element.childNodes.length === 1) {
                div.innerHTML = element.innerHTML;
            } else {
                div.innerHTML = element;
            }
        }
        let obj;

        if (json.isCSS3DSprite) {
            obj = new CSS3DSprite(div);
        } else if (json.isCSS3DObject) {
            obj = new CSS3DObject(div);
        } else {
            obj = parent === undefined ? new THREE.Object3D() : parent;
        }

        BaseSerializer.prototype.fromJSON.call(this, json, obj);

        obj.castShadow = json.castShadow;
        obj.frustumCulled = json.frustumCulled;
        obj.type = json.type;
        if (revertUuid) obj.uuid = json.uuid;

        obj.matrixAutoUpdate = json.matrixAutoUpdate;
        obj.name = json.name;
        obj.position.copy(json.position);
        obj.quaternion.copy(json.quaternion);
        obj.receiveShadow = json.receiveShadow;
        obj.renderOrder = json.renderOrder;
        obj.rotation.set(json.rotation.x, json.rotation.y, json.rotation.z, json.rotation.order);
        obj.scale.copy(json.scale);
        obj.up.copy(json.up);
        obj.visible = json.visible;

        if (json.animations instanceof Array) {
            obj.animations = [];
            json.animations.forEach(animation => {
                obj.animations.push(
                    new THREE.AnimationClip(
                        animation.name,
                        animation.duration,
                        animation.tracks.map(track => new THREE.KeyframeTrack(track.name, track.times, track.values)),
                    ),
                );
            });
        }

        for (var i in json.userData) {
            if (json.userData[i]) {
                obj.userData[i] = json.userData[i];
            }
        }

        return obj;
    }
}

export default Object3DSerializer;
