import * as THREE from "three";
import {Object3D} from "three";
import {COLLISION_TYPE, ConsumableBehaviorInterface} from "../../types/editor";
import GameManager from "../../behaviors/game/GameManager";
import {PhysicsUtil} from "../../physics/PhysicsUtil";
import EventBus from "../../behaviors/event/EventBus";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";

export default class CollectableBehaviorUpdaterBase implements BehaviorUpdater {
    private game?: GameManager;

    target: Object3D;
    usingPhysics = false;
    isDynamic = false;

    behavior: ConsumableBehaviorInterface;

    removed = false;

    constructor(target: Object3D, behavior: ConsumableBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.usingPhysics = PhysicsUtil.isPhysicsEnabled(this.target);
        this.isDynamic = PhysicsUtil.isDynamicObject(this.target);
        this.addCollisionListener();
    }

    addCollisionListener() {
        //TODO: support for enemy collisions
        let collisionType = this.getCollisionType();
        if (collisionType !== COLLISION_TYPE.UNKNOWN) {
            this.game!.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: collisionType,
                    callback: this.onCollision.bind(this),
                    useBoundingBoxes: this.behavior.useBoundingBoxes,
                },
                this.isDynamic,
            );
        } else {
            console.warn(
                "Collision type is not specified for " + this.target.name,
            );
        }
    }

    getCollisionType(): COLLISION_TYPE {
        if (!this.behavior.collisionSettings) return COLLISION_TYPE.UNKNOWN;
        return this.behavior.collisionSettings.playerCollision
            ? COLLISION_TYPE.WITH_PLAYER
            : this.behavior.collisionSettings.throwableCollision
              ? COLLISION_TYPE.WITH_COLLIDABLE_OBJECTS
              : this.behavior.collisionSettings.enemyCollision
                ? COLLISION_TYPE.WITH_ENEMY
                : COLLISION_TYPE.UNKNOWN;
    }

    onCollision() {
        this.game!.behaviorManager?.collisionDetector.deleteListener(
            this.target,
        );
        this.updateGameState();
        console.log("Collectable/consumable collided: " + this.target.name);
        if (!this.behavior.collisionSettings.disposable) {
            //FIXME: we need a better solution
            setTimeout(() => {
                this.addCollisionListener();
            }, 1000);
        } else {
            this.game!.app.removePhysicsObject(this.target);
            this.removed = true;
        }
    }

    updateGameState() {
        if (this.behavior.healthAmount > 0) {
            EventBus.instance.send(
                "game.lives.inc",
                this.behavior.healthAmount,
            );
        }
        if (this.behavior.pointAmount > 0) {
            EventBus.instance.send("game.score.inc", this.behavior.pointAmount);
        }
        if (this.behavior.timeAmount > 0) {
            EventBus.instance.send("game.time.inc", this.behavior.timeAmount);
        }
    }

    update(clock: THREE.Clock, delta: number) {}

    reset() {
        if (this.removed) {
            if (this.usingPhysics) {
                this.game!.app.addPhysicsObject(this.target);
            } else {
                this.game!.scene?.add(this.target);
            }
            this.addCollisionListener();
            this.removed = false;
        }
    }
}
