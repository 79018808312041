import {useEffect, useState} from "react";
import "../css/SwitchSection.css";
import {TextInput} from "../../common/TextInput";
import global from "../../../../../global";

interface Props {
    isLocked?: boolean;
}

export const BasicPropertiesSection = ({isLocked}: Props) => {
    const app = global.app;
    const editor = app?.editor;
    const {name, visible, isLight} = editor?.selected || {};
    const [basicProperties, setBasicProperties] = useState({
        name: name || "",
        visible: visible !== undefined ? visible : true,
    });
    const [initRender, setInitRender] = useState(true);

    const handleNameChange = (value: string) => {
        if (app && editor.selected) {
            setBasicProperties(prevState => ({
                ...prevState,
                name: value,
            }));
            editor.selected.name = value;
            editor.selected.userData.uiTag = `UITag_${value}`;
            editor.selected.userData.variable = value;

            app.call(`objectUpdated`, editor.selected, editor.selected);
            app.call(`objectChanged`, editor.selected, editor.selected);
        }
    };

    const handleVisibilityChange = (value: boolean) => {
        if (app && editor.selected) {
            setBasicProperties(prevState => ({
                ...prevState,
                visible: value,
            }));
            editor.selected.visible = value;

            app.call(`objectUpdated`, editor.selected, editor.selected);
            app.call(`objectChanged`, editor.selected, editor.selected);
        }
    };

    const handleUpdate = () => {
        if (editor?.selected) {
            setBasicProperties({
                name: editor.selected.name || "",
                visible: editor.selected.visible !== undefined ? editor.selected.visible : true,
            });
        }
    };

    useEffect(() => {
        if (!editor || !editor.selected) return;

        handleUpdate();
    }, [editor?.selected]);

    return (
        <div className="Section">
            <div className="title">Name</div>
            <div className="box extended column">
                <TextInput
                    value={basicProperties.name}
                    setValue={handleNameChange}
                    disabled={isLocked}
                    className="dark-input name-input"
                />
            </div>
            {/* {!isLight && (
                <div className="box column">
                    <div className="label">Visible</div>
                    <StyledSwitch
                        checked={basicProperties.visible}
                        style={isLocked ? {pointerEvents: "none"} : {}}
                        onChange={e => handleVisibilityChange(e.target.checked)}
                    />
                </div>
            )} */}
        </div>
    );
};
