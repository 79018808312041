import React from "react";
import "./css/TextInput.css";

interface Props {
    value: string;
    setValue: (value: string) => void;
    onBlur?: () => void;
    className?: string;
    disabled?: boolean;
    placeholder?: string;
    width?: string;
    height?: string;
}

export const TextInput = ({value, setValue, className, disabled, onBlur, placeholder, width, height}: Props) => {
    return (
        <input
            className={`${className} TextInput`}
            type="text"
            value={value}
            onChange={e => setValue(e.target.value)}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={placeholder}
            style={{width, height}}
        />
    );
};
