import React, {useEffect, useState} from "react";
import * as THREE from "three";
import global from "../global";
import {ILightState} from "../types/editor";

interface LightingContextValue {
    setColorChangeActivated: React.Dispatch<React.SetStateAction<boolean>>;
    setSkyColorChangeActivated: React.Dispatch<React.SetStateAction<boolean>>;
    setGroundColorChangeActivated: React.Dispatch<React.SetStateAction<boolean>>;
    colorChangeActivated: boolean;
    skyColorChangeActivated: boolean;
    groundColorChangeActivated: boolean;
    lightState: ILightState;
    setLightState: React.Dispatch<React.SetStateAction<ILightState>>;
}

export const LightingContext = React.createContext<LightingContextValue>(null!);

export interface LightingContextProviderProps {
    children: React.ReactNode;
}

const LightingContextProvider: React.FC<LightingContextProviderProps> = ({children}) => {
    const [colorChangeActivated, setColorChangeActivated] = useState(false);
    const [skyColorChangeActivated, setSkyColorChangeActivated] = useState(false);
    const [groundColorChangeActivated, setGroundColorChangeActivated] = useState(false);
    const [lightState, setLightState] = useState<ILightState>({
        label: "",
        show: false,
        showColor: false,
        color: "",
        showIntensity: false,
        intensity: 0,
        showDistance: false,
        showDecay: false,
        distance: 0,
        decay: 0,
        showSkyColor: false,
        showGroundColor: false,
        skyColor: "",
        groundColor: "",
        showAngle: false,
        showPenumbra: false,
        angle: 0,
        penumbra: 0,
        showCastShadow: false,
        showWidth: false,
        showHeight: false,
        width: 0,
        height: 0,
    });
    const app = global.app;
    const editor = app?.editor;

    useEffect(() => {
        if (!editor?.selected || !(editor.selected instanceof THREE.Light)) {
            setLightState(prevState => ({
                ...prevState,
                show: false,
            }));
            return;
        } else {
            setLightState(prevState => ({
                ...prevState,
                show: true,
            }));
        }
    }, [editor, editor?.selected]);

    return (
        <LightingContext.Provider
            value={{
                setColorChangeActivated,
                colorChangeActivated,
                setSkyColorChangeActivated,
                skyColorChangeActivated,
                setGroundColorChangeActivated,
                groundColorChangeActivated,
                lightState,
                setLightState,
            }}>
            {children}
        </LightingContext.Provider>
    );
};

export default LightingContextProvider;
