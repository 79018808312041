import {useState, useEffect} from "react";
import {ENEMY_TYPES, EnemyBehaviorInterface, WEAPON_TYPES} from "../../../../../types/editor";
import global from "../../../../../global";
import EnemyBehaviorConverter from "../../../../../serialization/behaviours/EnemyBehaviorConverter";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {Heading} from "../common/Heading";
import {NumericInputRow} from "../common/NumericInputRow";
import {SelectRow} from "../common/SelectRow";
import {StyledButton} from "../../common/StyledButton";

const ANIMATIONS: {
    label: string;
    key: keyof ExtendedEnemyBehaviorInterface;
}[] = [
    {label: "Idle", key: "idleAnimation"},
    {label: "Walking", key: "walkAnimation"},
    {label: "Running", key: "runAnimation"},
    {label: "Jumping", key: "jumpAnimation"},
    {label: "Crouching", key: "crouchAnimation"},
    {label: "Tripping", key: "trippingAnimation"},
    {label: "Falling", key: "fallAnimation"},
    {label: "Dying", key: "dieAnimation"},
    {label: "Attacking", key: "attackAnimation"},
];

type Props = {
    behavior: EnemyBehaviorInterface;
};

interface ExtendedEnemyBehaviorInterface extends EnemyBehaviorInterface {
    animationClips: string[];
    idleAnimation?: string;
    walkAnimation?: string;
    runAnimation?: string;
    attackAnimation?: string;
    jumpAnimation?: string;
    fallAnimation?: string;
    dieAnimation?: string;
    crouchAnimation?: string;
    trippingAnimation?: string;
}

export const EnemyBehaviors = ({behavior: initialBehavior}: Props) => {
    const [behavior, setBehavior] = useState<ExtendedEnemyBehaviorInterface>({
        ...initialBehavior,
        animationClips: [],
    });
    const [animationOptions, setAnimationOptions] = useState([{key: "0", value: "none"}]);
    const [weaponOptions, setWeaponOptions] = useState([{key: "0", value: "none"}]);
    const [animationsReady, setAnimationsReady] = useState(false);

    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;

    useEffect(() => {
        if (selected && selected._obj && selected._obj.animations) {
            const loadGLTFAndExtractAnimationClips = () => {
                const animations = selected._obj.animations;
                if (animations && animations.length > 0) {
                    const animationClips = animations.map((clip: any) => clip.name);
                    setBehavior(prevState => ({...prevState, animationClips}));
                }
            };

            loadGLTFAndExtractAnimationClips();
        }
    }, [selected]);

    const targetBehavior = EnemyBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id) || behavior;

    const handleEnemyTypeChange = (type: ENEMY_TYPES) => {
        if (selected && targetBehavior) {
            targetBehavior.enemyType = type;

            if (type === ENEMY_TYPES.AGGRESIVE) {
                targetBehavior.attackDistance = 200;
                targetBehavior.fightDistance = 500;
                targetBehavior.attackSpeed = 100;
                targetBehavior.roamDistance = 1000;
                targetBehavior.rotationSpeed = 200;
                targetBehavior.directionDuration = 50;
            } else if (type === ENEMY_TYPES.DEFENSIVE) {
                targetBehavior.attackDistance = 100;
                targetBehavior.fightDistance = 200;
                targetBehavior.attackSpeed = 100;
                targetBehavior.roamDistance = 300;
                targetBehavior.rotationSpeed = 300;
                targetBehavior.directionDuration = 10;
            } else if (type === ENEMY_TYPES.PATROLS) {
                targetBehavior.attackDistance = 50;
                targetBehavior.fightDistance = 100;
                targetBehavior.attackSpeed = 20;
                targetBehavior.roamDistance = 600;
                targetBehavior.rotationSpeed = 50;
                targetBehavior.directionDuration = 500;
            }

            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    const handleEnemyEnabledChange = (value: boolean) => {
        if (selected && targetBehavior) {
            targetBehavior.enemyEnabled = value;
            setBehavior({...behavior, enemyEnabled: value});

            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    const handleShowRoamAreaChange = (value: boolean) => {
        if (selected && targetBehavior) {
            targetBehavior.showRoamArea = value;
            setBehavior({...behavior, showRoamArea: value});

            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    const handleInputChange = (value: number | string | boolean, name: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior({...behavior, [name]: value});

            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    const handleAnimationClipChange = (clipName: string, type: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[type] = clipName;
            setBehavior({...behavior, [type]: clipName});
            selected.userData[type] = clipName; // Save to userData

            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    useEffect(() => {
        if (behavior.animationClips.length > 0 && !animationsReady) {
            const animations: {
                key: string;
                value: string;
            }[] = behavior.animationClips.map((el, index) => ({
                key: `${index + 1}`,
                value: el,
            }));
            setAnimationOptions(animationOptions.concat(animations));
            setAnimationsReady(true);
        }
    }, [behavior.animationClips]);

    useEffect(() => {
        setWeaponOptions(
            weaponOptions.concat(
                Object.keys(WEAPON_TYPES).map((key, index) => ({
                    key: `${index}`,
                    value: WEAPON_TYPES[key as keyof typeof WEAPON_TYPES],
                })),
            ),
        );
    }, []);

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                checked={!!targetBehavior.enemyEnabled}
                onChange={() => handleEnemyEnabledChange(!targetBehavior.enemyEnabled)}
            />
            <Separator margin="8px 0" />
            <Heading margin={"0 0 12px"}>Enemy Type</Heading>
            <SelectionOfButtons>
                {Object.keys(ENEMY_TYPES).map(key => {
                    const type = ENEMY_TYPES[key as unknown as keyof typeof ENEMY_TYPES];
                    return (
                        <StyledButton
                            width="100px"
                            isBlue={targetBehavior.enemyType === type}
                            isActive={targetBehavior.enemyType !== type}
                            onClick={() => handleEnemyTypeChange(type)}
                            key={key}>
                            <span>{type}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
            <Separator margin="12px 0" />
            <Heading margin={"0 0 12px"}>Enemy settings</Heading>
            <PanelCheckbox
                text="Show Roam Area in editor"
                checked={!!targetBehavior.showRoamArea}
                onChange={() => handleShowRoamAreaChange(!behavior.showRoamArea)}
            />
            <Separator margin="0 0 12px 0" invisible />
            <SelectRow
                $margin="0 0 8px 0"
                label="Type of weapon"
                data={weaponOptions}
                value={weaponOptions.find(el => el.value === behavior.weapon)}
                onChange={item => handleInputChange(item.value, "weapon")}
            />
            <NumericInputRow
                width="75px"
                label="Health"
                value={targetBehavior.health}
                setValue={value => handleInputChange(value, "health")}
            />
            <NumericInputRow
                width="75px"
                label="Movement Speed"
                value={targetBehavior.movementSpeed}
                setValue={value => handleInputChange(value, "movementSpeed")}
            />
            <NumericInputRow
                width="75px"
                label="Attack Damage"
                value={targetBehavior.attackDamage}
                setValue={value => handleInputChange(value, "attackDamage")}
            />
            <NumericInputRow
                width="75px"
                label="Respawn"
                value={targetBehavior.respawnAmount}
                setValue={value => handleInputChange(value, "respawnAmount")}
            />
            <NumericInputRow
                width="75px"
                label=" Fall Delay"
                value={targetBehavior.fallDelay}
                setValue={value => handleInputChange(value, "fallDelay")}
            />
            {targetBehavior.enemyType === ENEMY_TYPES.CUSTOM && (
                <>
                    <NumericInputRow
                        width="75px"
                        label="Attack Distance"
                        value={targetBehavior.attackDistance}
                        setValue={value => handleInputChange(value, "attackDistance")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Fight Distance"
                        value={targetBehavior.fightDistance}
                        setValue={value => handleInputChange(value, "fightDistance")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Attack Speed"
                        value={targetBehavior.attackSpeed}
                        setValue={value => handleInputChange(value, "attackSpeed")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Roam Distance"
                        value={targetBehavior.roamDistance}
                        setValue={value => handleInputChange(value, "roamDistance")}
                    />
                    <NumericInputRow
                        width="75px"
                        label=" Rotation Speed"
                        value={targetBehavior.rotationSpeed}
                        setValue={value => handleInputChange(value, "rotationSpeed")}
                    />
                    <NumericInputRow
                        width="75px"
                        label=" Direction Duration"
                        value={targetBehavior.directionDuration}
                        setValue={value => handleInputChange(value, "directionDuration")}
                    />
                </>
            )}
            <Heading margin={"12px 0"}>Animations</Heading>
            {ANIMATIONS.map(element => (
                <SelectRow
                    $margin="0 0 8px 0"
                    label={element.label}
                    data={animationOptions}
                    value={
                        animationOptions.find(item => item.value === behavior[element.key]) || {key: "0", value: "none"}
                    }
                    onChange={item => handleAnimationClipChange(item.value, element.key)}
                    key={element.label}
                />
            ))}
        </>
    );
};
