import {getAuth} from "firebase/auth";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {toast} from "react-toastify";
import {db} from "../firebase";
import {IUser} from "../v2/pages/types";
import {IStats, LIKES_ACTION, updateLikesCount} from "./getGames";

export const addLikedGame = async (
    gameId: string,
    setDbUser: React.Dispatch<React.SetStateAction<IUser | null>>,
    redirectToLogin: () => void,
): Promise<IStats | null | void> => {
    try {
        const authUser = getAuth().currentUser;
        if (!authUser) {
            redirectToLogin();
            console.log("addLikedGame: Cannot perform function, login data missing");
            return;
        }
        const id = authUser.uid;
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);
        let likeAction;
        if (docSnap.exists()) {
            const user = docSnap.data() as IUser;
            if (!user.likedGamesIds) {
                likeAction = LIKES_ACTION.INCREMENT;
                await updateDoc(docRef, {
                    likedGamesIds: [gameId],
                });
            } else {
                const gameIndex = user.likedGamesIds.findIndex(el => el === gameId);
                if (gameIndex === -1) {
                    await updateDoc(docRef, {
                        likedGamesIds: [...user.likedGamesIds, gameId],
                    });
                    likeAction = LIKES_ACTION.INCREMENT;
                } else {
                    const updatedGames = user.likedGamesIds.filter(el => el !== gameId);
                    await updateDoc(docRef, {
                        likedGamesIds: updatedGames,
                    });
                    likeAction = LIKES_ACTION.DECREMENT;
                }
            }
            const updatedDocRef = doc(db, "users", id);
            const updatedDocSnap = await getDoc(updatedDocRef);
            const updatedUser = updatedDocSnap.data() as IUser;
            console.log("updatedUser", updatedUser);
            setDbUser(updatedUser);
            const res = await updateLikesCount(gameId, likeAction);
            return res;
        } else {
            console.log("No such user in db! ");
            toast.error("Something went wrong - no user found");
        }
    } catch (e) {
        console.error("Error from fetching document: ", e);
        toast.error("Something went wrong with fetching user data");
    }
};

export const getLikedGames = async (userId?: string) => {
    try {
        const authUser = getAuth().currentUser;
        if (!authUser && !userId) return;
        const id = authUser?.uid || userId;
        if (!id) return console.error("Missing user ID");
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const user = docSnap.data() as IUser;
            return user.likedGamesIds || [];
        } else {
            console.log("No such user in db! ");
            return [];
        }
    } catch (e) {
        console.error("Error from fetching document: ", e);
        toast.error("Something went wrong with fetching user data");
    }
};
