import {BillboardBehaviorInterface} from "../../types/editor";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";

class BillboardBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    behavior: BillboardBehaviorInterface;
    game?: GameManager;

    constructor(target: THREE.Object3D, behavior: BillboardBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
    }

    update(clock: THREE.Clock, delta: number): void {
        if (!this.game?.player) {
            return;
        }

        if (this.behavior.faceCamera) {
            this.target.lookAt(this.game.player.position);
        }
    }

    reset() {}
}

export default BillboardBehaviorUpdater;
