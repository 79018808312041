import {HUDContainer, HUDContainerButtonsLayer, Menu} from "./HUDEditView.style";
import {GameStartMenu} from "./GameStartMenu/GameStartMenu";
import {InGameMenu} from "./InGameMenu/InGameMenu";
import {GameHUD} from "./GameHUD/GameHUD";
import {HUDPopup} from "./HUDPopup/HUDPopup";
import {
    useHUDContext,
    useHUDGameContext,
    useHUDInGameMenuContext,
    useHUDStartGameMenuContext,
} from "../../../../../context";
import {HUD_TABS, START_MENU_IDS} from "./types";
import global from "../../../../../global";
import Application from "../../../../../Application";

interface Props {
    onClose: () => void;
}

const menuButtonClass = "screen-selection-btn reset-css";

export const HUDEditView = ({onClose}: Props) => {
    const app = global.app;
    const {gameLayout, setGameLayout, isReady} = useHUDGameContext();
    const {isPopupOpen, activeScreen, setActiveScreen} = useHUDContext();
    const {startGameMenuLayout, setStartGameMenuLayout, isReady: isStartMenuReady} = useHUDStartGameMenuContext();
    const {inGameMenuLayout, setInGameMenuLayout, isReady: inGameReady} = useHUDInGameMenuContext();

    const handleBgImage = () => {
        return startGameMenuLayout?.[START_MENU_IDS.MENU_BG] as string;
        // if (activeScreen === HUD_TABS.GAME_START_MENU) {
        //     return startGameMenuLayout?.[START_MENU_IDS.MENU_BG] as string;
        // } else if (activeScreen === HUD_TABS.IN_GAME_MENU) {
        //     return inGameMenuLayout?.[IN_GAME_MENU_IDS.MENU_BG] as string;
        // } else return;
    };

    const handleSave = () => {
        if (!app || !app.editor) return;

        if (gameLayout && isReady) {
            app.editor.scene.userData.gameUI = {
                ...app?.editor.scene.userData.gameUI,
                gameHUD: gameLayout,
            };
        }
        if (inGameMenuLayout && inGameReady) {
            app.editor.scene.userData.gameUI = {
                ...app?.editor.scene.userData.gameUI,
                inGameMenu: inGameMenuLayout,
            };
        }
        if (startGameMenuLayout && isStartMenuReady) {
            app.editor.scene.userData.gameUI = {
                ...app?.editor.scene.userData.gameUI,
                gameStartMenu: startGameMenuLayout,
            };
        }

        app.editor.scene.userData.lastSaveTime = new Date().toISOString();
        (app as Application).saveScene();
        onClose();
    };

    const handleClose = () => {
        setInGameMenuLayout({
            ...app?.editor.scene.userData.gameUI?.inGameMenu,
        });
        setStartGameMenuLayout({
            ...app?.editor.scene.userData.gameUI?.gameStartMenu,
        });
        setGameLayout({
            ...app?.editor.scene.userData.gameUI?.gameHUD,
        });
        onClose();
    };

    return (
        <HUDContainer $bgImg={handleBgImage()} $isStartMenu={activeScreen === HUD_TABS.GAME_START_MENU}>
            <Menu>
                <div className="options">
                    {Object.values(HUD_TABS).map(tab => (
                        <button
                            key={tab}
                            className={`${menuButtonClass} ${activeScreen === tab && "btn-active"}`}
                            onClick={() => setActiveScreen(tab)}>
                            {tab}
                        </button>
                    ))}
                </div>
                <div>
                    <button className="reset-css done-btn margin-btn" onClick={handleSave}>
                        Save
                    </button>
                    <button className="reset-css done-btn" onClick={handleClose}>
                        Cancel
                    </button>
                </div>
            </Menu>
            <HUDContainerButtonsLayer>
                {activeScreen === HUD_TABS.GAME_START_MENU && <GameStartMenu />}
                {activeScreen === HUD_TABS.IN_GAME_MENU && <InGameMenu />}
                {activeScreen === HUD_TABS.GAME_HUD && <GameHUD />}
                {isPopupOpen && <HUDPopup />}
            </HUDContainerButtonsLayer>
        </HUDContainer>
    );
};
