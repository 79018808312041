import {useEffect} from "react";
import {BasicAnimationSection} from "../sections/BasicAnimationSection";
import {TweenAnimationSection} from "../sections/TweenAnimationSection";
import {useAnimationContext} from "../../../../../context";

const AnimationPanel = () => {
    const {setAnimationsState, animationsState} = useAnimationContext();

    useEffect(() => {
        if (animationsState.selectedAnimation) {
            const animations = [...animationsState.animations];

            const updatedAnimations = animations.map(layer => {
                return layer.animations.map((animation: any) => {
                    if (
                        animation.uuid ===
                        animationsState.selectedAnimation.uuid
                    ) {
                        return {
                            ...animation,
                            ...animationsState.selectedAnimation,
                        };
                    } else {
                        return animation;
                    }
                });
            });

            const layers = animations.map((layer, index) => {
                layer.animations = updatedAnimations[index];
                return layer;
            });

            setAnimationsState((prevState: any) => {
                return {
                    ...prevState,
                    animations: layers,
                };
            });
        }
    }, [animationsState.selectedAnimation]);

    return (
        <>
            <BasicAnimationSection />
            <TweenAnimationSection />
        </>
    );
};
export default AnimationPanel;
