import homeIcon from "../icons/home.svg";
import brushIcon from "../icons/paint-brush.svg";
import communityIcon from "../icons/users.svg";
import settingsIcon from "../icons/settings.svg";

export enum PAGES {
    DASHBOARD = "Dashboard",
    MY_GAMES = "My Games",
    COMMUNITY = "Community",
    SETTINGS = "Settings",
    PROFILE = "Profile",
    PLAY = "Play",
}
export const DASHBOARD_MENU = [
    {label: PAGES.DASHBOARD, icon: homeIcon},
    {label: PAGES.MY_GAMES, icon: brushIcon},
    {label: PAGES.COMMUNITY, icon: communityIcon},
    {label: PAGES.SETTINGS, icon: settingsIcon, bottom: true},
];
