import {useNavigate} from "react-router-dom";
import searchIconBlue from "../../../assets/search-blue.svg";
import {ROUTES} from "../../../../AppRouter";
import {SEARCH_GAME_QUERY} from "../../types";
import {StyledFrazeButton} from "./FrazeButton.styled";
import {saveSearchToLocalStorage} from "../../services";

interface Props {
    fraze: string;
}

export const FrazeButton = ({fraze}: Props) => {
    const navigate = useNavigate();
    const useFraze = () => {
        const queryKey = fraze.startsWith("#") ? SEARCH_GAME_QUERY.GAME_TAGS : SEARCH_GAME_QUERY.GAME_NAME;
        const sanitizedPhrase = fraze.startsWith("#") ? fraze.slice(1) : fraze;
        navigate(`${ROUTES.SEARCH_RESULTS}?${queryKey}=${sanitizedPhrase}`);

        saveSearchToLocalStorage(fraze);
    };

    return (
        <StyledFrazeButton onClick={useFraze}>
            <img src={searchIconBlue} alt="search" />
            <span className="text">{fraze}</span>
        </StyledFrazeButton>
    );
};
