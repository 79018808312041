import { Mesh, Box3, Object3D, Scene, Vector3, Vector3Like, QuaternionLike } from "three";
import { BodyShapeType, BoxData, CapsuleData, SphereData, ConcaveHullData, ConvexHullData, COLLISION_MAP, CollisionFlag, CommonData, IPhysics } from "./common/types";
import BoundingBoxUtil, { BoxShape } from "../utils/BoundingBoxUtil";
import * as THREE from "three";
import { SimplifyModifier } from "three/examples/jsm/modifiers/SimplifyModifier";
import { ConvexHull } from "three/examples/jsm/math/ConvexHull";

interface ObjectGeometryData {
    vertices: THREE.Vector3[];
    indexes: number[];
}

export class PhysicsUtil {

    private static positionCalcAux = new THREE.Vector3();
    private static anchorCalcAux = new THREE.Vector3


    public static setShapeData(object: Object3D) {
        const shape = object.userData.physics.shape;
        const physicsData = object.userData.physics;

        switch (shape) {
            case BodyShapeType.BOX:
                const boxB = this.calculateBoundingBox(object);
                const anchorOffset = PhysicsUtil.calculateAnchorOffset(object, boxB);

                physicsData.shape = BodyShapeType.BOX;
                physicsData.anchorOffset = anchorOffset;
                physicsData.shapeData = {
                    width: boxB.max.x - boxB.min.x,
                    height: boxB.max.y - boxB.min.y,
                    length: boxB.max.z - boxB.min.z
                };
                break;
            case BodyShapeType.SPHERE:
                physicsData.shape = BodyShapeType.SPHERE;
                physicsData.shapeData = {
                    radius: this.getRadius(object)
                }
                break;
            case BodyShapeType.CONVEX_HULL:
                physicsData.shape = BodyShapeType.CONVEX_HULL;
                physicsData.shapeData = {
                    vertices: this.getConvexHullVertices(object)
                };
                break
            case BodyShapeType.CONCAVE_HULL:
                physicsData.shape = BodyShapeType.CONCAVE_HULL;
                physicsData.shapeData = {
                    ...this.getConcaveHullVertices(object)
                }
                break;
            case BodyShapeType.CAPSULE:
                const boxC = this.calculateBoundingBox(object);
                const anchorOffsetC = PhysicsUtil.calculateAnchorOffset(object, boxC);

                const radius = Math.max(boxC.max.x - boxC.min.x, boxC.max.z - boxC.min.z) / 2;
                const height = boxC.max.y - boxC.min.y - radius * 2;

                physicsData.shape = BodyShapeType.CAPSULE;
                physicsData.anchorOffset = anchorOffsetC;
                physicsData.shapeData = {
                    radius: radius,
                    height: height,
                }
                break;
            default:
                console.warn("PhysicsUtil: set shape data failed, shape not supported", shape);
                break;
        }
    }

    public static addObjectShapeToPhysics(object: Object3D, physics: IPhysics | null) {
        if (!physics) {
            console.warn("PhysicsUtil: add object shape to physics failed, physics is not specified for object", object);
            return;
        }

        const physicsConfig = object.userData.physics;
        const shape = physicsConfig.shape;

        switch (shape) {
            case BodyShapeType.BOX:
                const position = PhysicsUtil.calculatePhysicsPositionFromObject(object.position, object.quaternion, physicsConfig.anchorOffset);
                let boxShape = physicsConfig.shapeData;
                //MISHA: workaround for missing shape data; shouldn't happen if scene is valid
                if (!boxShape) {
                    PhysicsUtil.setShapeData(object);
                    boxShape = physicsConfig.shapeData;
                    if (!boxShape) {
                        console.warn("PhysicsUtil: set box shape data failed", object);
                        return;
                    }
                }

                physics?.addBox(object, {
                    ...PhysicsUtil.getCommonData(object, physicsConfig),
                    position: position,
                    width: boxShape.width,
                    height: boxShape.height,
                    length: boxShape.length,
                });
                break;
            case BodyShapeType.SPHERE:
                //FIXME: check if object is a mesh and have a fallback if it is not
                const geometry = (object as Mesh).geometry;
                geometry.computeBoundingSphere();
                physics?.addSphere(object, {
                    ...PhysicsUtil.getCommonData(object, physicsConfig),
                    radius: geometry.boundingSphere?.radius || 0,
                });
                break;
            case BodyShapeType.CONCAVE_HULL:
                const concaveHullVerts = physicsConfig.shapeData;
                physics?.addConcaveHull(object, {
                    ...PhysicsUtil.getCommonData(object, physicsConfig),
                    vertices: concaveHullVerts.vertices,
                    indexes: concaveHullVerts.indexes,
                });
                break;
            case BodyShapeType.CONVEX_HULL:
                const convexHullVerts = physicsConfig.shapeData;
                physics?.addConvexHull(object, {
                    ...PhysicsUtil.getCommonData(object, physicsConfig),
                    vertices: convexHullVerts.vertices,
                });
                break;
            case BodyShapeType.CAPSULE:
                const cPosition = PhysicsUtil.calculatePhysicsPositionFromObject(object.position, object.quaternion, physicsConfig.anchorOffset);
                const capsuleShape = physicsConfig.shapeData;

                physics?.addCapsuleShape(object, {
                    ...PhysicsUtil.getCommonData(object, physicsConfig),
                    position: cPosition,
                    radius: capsuleShape.radius,
                    height: capsuleShape.height,
                });

                break;
            default:
                console.warn("PhysicsUtil: add object shape to physics failed, shape not supported", shape);
                break;
        }
    }

    public static getConcaveHullVertices(object: THREE.Object3D) {
        const objGeomData: ObjectGeometryData[] = this.getObjectGeometryDataSimplified(object, 0);

        const vertices: number[][] = [];
        const indexes: number[][] = [];

        objGeomData.forEach(data => {
            const currentVertices: number[] = [];
            const currentIndexes: number[] = [];
            data.vertices.forEach(point => {
                currentVertices.push(point.x, point.y, point.z);
            });
            data.indexes.forEach(index => {
                currentIndexes.push(index);
            });
            vertices.push(currentVertices);
            indexes.push(currentIndexes);
        });

        return { vertices, indexes };
    }

    public static getConvexHullVertices(object: THREE.Object3D, simplifyFactor: number = 0.7): number[] {
        const objGeomData: ObjectGeometryData[] = this.getObjectGeometryDataSimplified(object, simplifyFactor);
        const points: THREE.Vector3[] = [];

        objGeomData.forEach(objectPoints => {
            objectPoints.vertices.forEach(point => {
                if (!points.find(p => p.equals(point))) {
                    points.push(point);
                }
            });
        });


        const hull = new ConvexHull().setFromPoints(points);
        const vertices: number[] = [];

        const uniqueVertices = new Set();

        // get vertices from faces by traversing edges
        hull.faces.forEach(face => {
            let edge = face.edge;
            do {
                const point = edge.head().point;
                const key = `${point.x},${point.y},${point.z}`;
                if (!uniqueVertices.has(key)) {
                    uniqueVertices.add(key);
                    vertices.push(point.x, point.y, point.z);
                }
                edge = edge.next;
            } while (edge !== face.edge);
        });

        return vertices;
    }

    private static getObjectGeometryDataSimplified(object: THREE.Object3D, simplifyFactor: number = 0.8): ObjectGeometryData[] {
        const simplifiedGeometry = this.getSimplifiedGeometry(object, simplifyFactor);
        const data: ObjectGeometryData[] = [];

        simplifiedGeometry.forEach(geometry => {
            const positionAttribute = geometry.getAttribute("position");
            const currentPoints: THREE.Vector3[] = [];
            const currentIndices: number[] = [];

            for (let i = 0; i < positionAttribute.count; i++) {
                currentPoints.push(new THREE.Vector3(positionAttribute.getX(i), positionAttribute.getY(i), positionAttribute.getZ(i)));
            }

            const indexAttribute = geometry.getIndex();
            if (indexAttribute) {
                for (let i = 0; i < indexAttribute.count; i++) {
                    currentIndices.push(indexAttribute.getX(i));
                }
            }
            data.push({ vertices: currentPoints, indexes: currentIndices });
        });

        return data;
    }

    public static getSimplifiedGeometry(object: THREE.Object3D, simplifyFactor: number = 0.8): Array<THREE.BufferGeometry> {
        const prevPosition = object.position.clone();
        const prevRotation = object.rotation.clone();
        object.position.set(0, 0, 0);
        object.rotation.set(0, 0, 0);

        object.updateMatrixWorld(true);

        const simplifiedGeometry: Array<THREE.BufferGeometry> = [];
        const simplifyModifier = new SimplifyModifier();
        object.traverse((child: THREE.Object3D) => {
            if ((child as THREE.Mesh).isMesh && (child as THREE.Mesh).geometry) {
                const mesh = child as THREE.Mesh;
                const geometry = mesh.geometry.clone() as THREE.BufferGeometry;

                const positionAttribute = geometry.getAttribute("position");
                const vertex = new THREE.Vector3();
                for (let i = 0; i < positionAttribute.count; i++) {
                    mesh.getVertexPosition(i, vertex);
                    vertex.applyMatrix4(mesh.matrixWorld);
                    positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
                }

                let newGeometry = geometry;

                if (simplifyFactor > 0) {
                    try {
                        newGeometry = simplifyModifier.modify(
                            geometry,
                            Math.floor(positionAttribute.count * simplifyFactor),
                        );
                    } catch (error) {
                        //error here but do nothing, just keep the original vertices
                    } finally {
                        if (newGeometry.getAttribute("position").count === 0) {
                            newGeometry = geometry;
                        }
                    }
                }

                simplifiedGeometry.push(newGeometry);
            }
        });

        object.position.copy(prevPosition);
        object.rotation.copy(prevRotation);

        return simplifiedGeometry
    }

    public static removePhysicsObject(scene: Scene, physics: IPhysics, target: Object3D) {
        scene.remove(target);
        physics?.remove(target.uuid);
    }

    public static isPhysicsEnabled(target: Object3D) {
        return target.userData.physics && target.userData.physics.enabled;
    }

    public static isDynamicObject(target: Object3D) {
        return (
            this.isPhysicsEnabled(target) &&
            (!target.userData.physics.ctype || target.userData.physics.ctype === "Dynamic")
        );
    }

    public static calculateObjectPositionFromPhysics(bodyPosition: Vector3Like, bodyQuaternion: QuaternionLike, anchorOffset?: Vector3Like): Vector3Like {
        if (!anchorOffset) {
            return bodyPosition;
        }

        // anchor offset calculation based on body rotation in order to get the correct position
        this.anchorCalcAux.copy(anchorOffset).applyQuaternion(bodyQuaternion);
        this.positionCalcAux.copy(bodyPosition).sub(this.anchorCalcAux);

        return this.positionCalcAux;
    }

    public static calculatePhysicsPositionFromObject(objectPosition: Vector3Like, objectQuaternion: QuaternionLike, anchorOffset?: Vector3Like): Vector3Like {
        if (!anchorOffset) {
            return objectPosition;
        }

        // anchor offset calculation based on body rotation in order to get the correct position
        this.anchorCalcAux.copy(anchorOffset).applyQuaternion(objectQuaternion);
        this.positionCalcAux.copy(objectPosition).add(this.anchorCalcAux);

        return this.positionCalcAux;
    }

    public static calculateBoundingBox(object: Object3D): Box3 {
        // calculate bounding box without rotation to avoid incorrect size
        const prevRotation = object.rotation.clone();
        object.rotation.set(0, 0, 0);
        const box = new Box3().setFromObject(object, true);
        object.rotation.copy(prevRotation);

        return box;
    }

    public static getRadius(object: Object3D): number {
        const bbox = BoundingBoxUtil.getBoxWithoutTransform(object);
        return Math.max(bbox.width, bbox.height, bbox.length) / 2;
    }

    public static calculateAnchorOffset(object: Object3D, box: Box3): Vector3 {
        const anchorOffset = new Vector3();
        box.getCenter(anchorOffset);
        anchorOffset.sub(object.position);
        return anchorOffset;
    }

    static getCommonData(object: Object3D, physicsConfig: any): CommonData {
        return {
            uuid: object.uuid,
            template: "",
            name: object.name,
            position: { x: object.position.x, y: object.position.y, z: object.position.z },
            quaternion: {
                x: object.quaternion.x,
                y: object.quaternion.y,
                z: object.quaternion.z,
                w: object.quaternion.w,
            },
            scale: { x: object.scale.x, y: object.scale.y, z: object.scale.z },
            mass: physicsConfig.mass,
            collision_flag: physicsConfig.ctype ? COLLISION_MAP.get(physicsConfig.ctype) : CollisionFlag.DYNAMIC,
            friction: physicsConfig.friction,
            restitution: physicsConfig.restitution,
            damping: physicsConfig.damping,
        };
    }
}
