import {useState} from "react";
import {ThrowableBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {ImageSection} from "../common/ImageSection";
import {NumericInputRow} from "../common/NumericInputRow";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {StyledButton} from "../../common/StyledButton";

type Props = {
    behavior: ThrowableBehaviorInterface;
};

export const ThrowableBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);

    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            const objBehavior = obj.userData.behaviors?.find((userBehavior: any) => userBehavior.id === behavior.id);

            return objBehavior;
        }
        return null;
    };

    const handleInputChange = (value: any, name: string) => {
        if (selected) {
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
                objBehavior[name] = value;
                setBehavior({...objBehavior, [name]: value});
            }

            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                text="Start With Throwable Object"
                checked={!!behavior.throwableStarting}
                onChange={() => handleInputChange(!behavior.throwableStarting, "throwableStarting")}
            />
            <Separator />
            <PanelCheckbox
                text="Show Object"
                checked={!!behavior.throwableVisible}
                onChange={() => handleInputChange(!behavior.throwableVisible, "throwableVisible")}
            />
            <Separator />
            <ImageSection
                text="Throwable UI Image"
                uploadedFile={behavior.throwableUIImage || null}
                setUploadedFile={value => handleInputChange(value, "throwableUIImage")}
            />
            <NumericInputRow
                width="75px"
                label="Object Damage"
                value={behavior.throwableDamage}
                setValue={value => handleInputChange(value, "throwableDamage")}
            />
            <NumericInputRow
                width="75px"
                label="Power Level"
                value={behavior.powerLevel}
                setValue={value => handleInputChange(value, "powerLevel")}
            />
            <NumericInputRow
                width="75px"
                label="Bounce Effect"
                value={behavior.bounceEffect}
                setValue={value => handleInputChange(value, "bounceEffect")}
            />
            <NumericInputRow
                width="75px"
                label="Object Amount"
                value={behavior.throwableAmount}
                setValue={value => handleInputChange(value, "throwableAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Object Mass"
                value={behavior.throwableMass}
                setValue={value => handleInputChange(value, "throwableMass")}
            />
            <NumericInputRow
                width="75px"
                label="Time Object Shows"
                value={behavior.throwableLife}
                setValue={value => handleInputChange(value, "throwableLife")}
            />
            <NumericInputRow
                width="75px"
                label="Object Speed"
                value={behavior.throwableSpeed}
                setValue={value => handleInputChange(value, "throwableSpeed")}
            />
            <NumericInputRow
                width="75px"
                label="Friction"
                value={behavior.throwableFriction}
                setValue={value => handleInputChange(value, "throwableFriction")}
            />
            <NumericInputRow
                width="75px"
                label="Inertia"
                value={behavior.throwableInertia}
                setValue={value => handleInputChange(value, "throwableInertia")}
            />
            <Separator />
            <SelectionOfButtons margin="0 auto">
                <StyledButton
                    width="109px"
                    isBlueSecondary={behavior.aimer}
                    isActive={!behavior.aimer}
                    onClick={() => handleInputChange(!behavior.aimer, "aimer")}>
                    <span>Aimer</span>
                </StyledButton>
                <StyledButton
                    width="109px"
                    isBlueSecondary={behavior.aimerGuide}
                    isActive={!behavior.aimerGuide}
                    onClick={() => handleInputChange(!behavior.aimerGuide, "aimerGuide")}>
                    <span>Aimer Guide</span>
                </StyledButton>
            </SelectionOfButtons>
            <Separator />
        </>
    );
};
