import {useEffect, useRef} from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";

interface Props {
    onClickoutsideCallback: () => void;
    children: any;
    top: number;
    left: number;
}

function useCustomOnClickOutside(ref: React.RefObject<HTMLElement>, callback: () => void) {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}

export const RightClickMenu = ({onClickoutsideCallback, children, top, left}: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);
    useCustomOnClickOutside(ref, onClickoutsideCallback);

    return ReactDOM.createPortal(
        <ItemMenu ref={ref} top={top} left={left}>
            {/* pass ItemText as children */}
            {children}
        </ItemMenu>,
        document.body,
    );
};

export const ItemMenu = styled.div<{top: number; left: number}>`
    position: absolute;
    top: ${({top}) => top}px;
    left: ${({left}) => left}px;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    min-width: 84px;

    background-color: var(--theme-grey-bg);
    font-size: var(--theme-font-size-xxs);
    pointer-events: all;
    overflow: hidden;

    padding: 4px;
    border: 1px solid var(--theme-grey-bg-secondary-button);
    border-radius: 8px;
    box-shadow: 0px 4px 15px 0px #000;
`;

export const ItemMenuText = styled.div<{$red?: boolean}>`
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    display: inline-block;
    color: ${({$red}) => ($red ? "var(--theme-font-red)" : "#d4d4d8")};
    text-align: left;
    &:hover {
        background: var(--theme-grey-bg-secondary-button);
    }
`;
