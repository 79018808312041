import * as THREE from "three";
import Config from "../config";

/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */

/**
 * Configuration options
 * @author tengge / https://github.com/tengge1
 * @param options Configuration options
 */
interface VRSetting {
    cameraPosX: number;
    cameraPosY: number;
    cameraPosZ: number;
    cameraRotateX: number;
    cameraRotateY: number;
    cameraRotateZ: number;
}

interface ApplicationPropsOptions {
    server?: string;
    sceneType?: string;
    enableCache?: boolean;
    saveChild?: boolean;
    saveMaterial?: boolean;
    shadowMapType?: number;
    gammaFactor?: number;
    hueRotate?: number;
    saturate?: number;
    brightness?: number;
    blur?: number;
    contrast?: number;
    grayscale?: number;
    invert?: number;
    sepia?: number;
    weather?: string;
    enablePhysics?: boolean;
    enableVR?: boolean;
    vrSetting?: VRSetting;
}

class ApplicationProps {
    server: string;
    sceneType: string;
    enableCache: boolean;
    saveChild: boolean;
    saveMaterial: boolean;
    shadowMapType: number;
    gammaFactor: number;
    hueRotate: number;
    saturate: number;
    brightness: number;
    blur: number;
    contrast: number;
    grayscale: number;
    invert: number;
    sepia: number;
    weather: string;
    enablePhysics: boolean;
    enableVR: boolean;
    vrSetting: VRSetting;

    constructor(options: ApplicationPropsOptions = {}) {
        // Server configuration
        this.server = options.server ?? Config.server_host;

        this.sceneType = options.sceneType ?? "Empty";

        this.enableCache = options.enableCache ?? false;
        this.saveChild = options.saveChild ?? false;

        // Save internal sub-component materials of the model
        this.saveMaterial = options.saveMaterial ?? false;

        // Shadow configuration
        this.shadowMapType = options.shadowMapType ?? THREE.PCFSoftShadowMap;

        this.gammaFactor = options.gammaFactor ?? 2.0;

        // Filters
        this.hueRotate = options.hueRotate ?? 0;
        this.saturate = options.saturate ?? 1;
        this.brightness = options.brightness ?? 1;
        this.blur = options.blur ?? 0;
        this.contrast = options.contrast ?? 1;
        this.grayscale = options.grayscale ?? 0;
        this.invert = options.invert ?? 0;
        this.sepia = options.sepia ?? 0;

        // Weather
        this.weather = options.weather ?? ""; // ''-none, rain-rain, snow-snow

        // Physics engine
        this.enablePhysics = options.enablePhysics ?? true; // Enable physics engine

        // VR
        this.enableVR = options.enableVR ?? false; // Enable VR
        this.vrSetting = options.vrSetting ?? {
            cameraPosX: 0,
            cameraPosY: 0,
            cameraPosZ: 0,
            cameraRotateX: 0,
            cameraRotateY: 0,
            cameraRotateZ: 0,
        };
    }
}

export default ApplicationProps;
