import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import {Object3D, Vector3} from "three"; // Import Vector3 from Three.js
import EventBus from "../../behaviors/event/EventBus";
import {COLLISION_TYPE, OBJECT_TYPES, SCENE_VOLUME_TYPES, SceneVolumeAssetBehaviorInterface} from "../../types/editor";
import global from "../../global";
import Application from "../../Application";

class SceneVolumeBehaviorAssetUpdater implements BehaviorUpdater {
    game?: GameManager;
    target: Object3D;
    volumeType: SCENE_VOLUME_TYPES;
    usingPhysics = false;
    physicsEnabled = false;
    isDynamic = false;
    removed = false;
    prevPlayerPosition: Vector3 = new Vector3();
    toastId: any = "";

    constructor(target: Object3D, behavior: SceneVolumeAssetBehaviorInterface) {
        this.target = target;
        this.volumeType = behavior.volumeType;
        this.usingPhysics = this.target.userData.physics && this.target.userData.physics.body;
        this.physicsEnabled = this.target.userData.physics && this.target.userData.physics.enabled;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.addCollisionListener();
    }

    onCollision() {
        if (!this.game || !this.game.player || !this.game.scene) return;

        const volumeBehavior = this.target.userData.behaviors.find(
            (behavior: any) => behavior.type === OBJECT_TYPES.SCENE_VOLUME,
        );

        const volumeType = volumeBehavior ? volumeBehavior.volumeType : "Unknown";

        // if (volumeType === VOLUME_TYPES.BLOCKING_VOLUME) {
        //console.log(`Stop ${volumeType} volume.`);
        // }

        if (volumeType === SCENE_VOLUME_TYPES.KILL_VOLUME) {
            if (!this.game.initialLives) {
                this.toast("Lives are not set", "warn");
            }
            EventBus.instance.send("game.lives.dec", Number(this.game.initialLives));
            this.game!.behaviorManager!.collisionDetector.deleteListener(this.target);
            this.game!.app.removePhysicsObject(this.target);
            this.removed = true;
        }

        if (volumeType === SCENE_VOLUME_TYPES.WIN_VOLUME) {
            if (!this.game.maxScore) {
                this.toast("Max score is not set", "warn");
            }
            EventBus.instance.send("game.score.inc", this.game.maxScore);
            this.game!.behaviorManager!.collisionDetector.deleteListener(this.target);
            this.game!.app.removePhysicsObject(this.target);
            this.removed = true;
        }

        if (volumeType === SCENE_VOLUME_TYPES.LOSE_VOLUME) {
            if (!this.game.initialLives) {
                this.toast("Lives are not set", "warn");
            }
            EventBus.instance.send("game.lives.dec", this.game.initialLives);
            this.game!.behaviorManager!.collisionDetector.deleteListener(this.target);
            this.game!.app.removePhysicsObject(this.target);
            this.removed = true;
        }
    }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollision.bind(this),
                useBoundingBoxes: true,
            },
            this.isDynamic,
        );
    }

    update(clock: THREE.Clock, delta: number): void {}

    reset() {
        if (this.removed) {
            if (this.usingPhysics || this.physicsEnabled) {
                this.game!.app.addPhysicsObject(this.target);
            } else {
                this.game!.scene?.add(this.target);
            }
            this.addCollisionListener();
            this.removed = false;
        }
    }

    toast(message: string, type: "info" | "success" | "error" | "warn") {
        if (global.app instanceof Application) {
            global.app.toast(message, type);
        }
    }
}

export default SceneVolumeBehaviorAssetUpdater;
