import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import WeaponBehaviorUpdater from "./WeaponBehaviorUpdater";
import {WeaponBehaviorInterface, OBJECT_TYPES, WEAPON_TYPES} from "../../types/editor";

class WeaponBehaviorConverter extends BaseBehaviorConverter<WeaponBehaviorInterface> {
    public static DEFAULT = new WeaponBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: WeaponBehaviorInterface): BehaviorUpdater {
        return new WeaponBehaviorUpdater(target, behavior.weaponType);
    }

    getBehavior(target: THREE.Object3D, id: string): WeaponBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as WeaponBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have WEAPON behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): WeaponBehaviorInterface {
        return {
            type: OBJECT_TYPES.WEAPON,
            weaponType: WEAPON_TYPES.MACHINE_GUN,
            enabled: true,
            id,
        } as WeaponBehaviorInterface;
    }
}

export default WeaponBehaviorConverter;
