import {useRef, useState} from "react";
import styled from "styled-components";
import {flexCenter} from "../../../../../assets/style";
import {UploadField} from "../../common/UploadField/UploadField";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import {FileData} from "../../types/file";

interface Props {
    text: string;
    uploadedFile: string | null | FileData;
    setUploadedFile: React.Dispatch<
        React.SetStateAction<FileData | null | string>
    >;
}

export const ImageSection = ({text, uploadedFile, setUploadedFile}: Props) => {
    const getUploadedFile = () => {
        if (uploadedFile) {
            const url = backendUrlFromPath(uploadedFile);
            return url || null;
        } else return null;
    };

    return (
        <ImageSectionWrapper>
            <span className="text">{text}</span>
            <UploadField
                width="80px"
                height="80px"
                uploadedFile={getUploadedFile()}
                setUploadedFile={setUploadedFile}
            />
        </ImageSectionWrapper>
    );
};

export const ImageSectionWrapper = styled.div`
    ${flexCenter};
    align-items: flex-start;
    flex-direction: column;
    row-gap: 8px;
    margin-right: auto;
    margin-bottom: 12px;
    .text {
        font-size: var(--theme-font-size-xxs);
        font-weight: var(--theme-font-regular);
        color: var(--theme-font-unselected-color);
        line-height: 120%;
        text-align: left;
    }
`;
