import React, {useEffect, useState} from "react";
import {FileData} from "../../../../types/file";
import {AssetsList} from "../../../../common/AssetsList";
import uploadIconSmall from "../../../../icons/upload-icon-small.svg";
import I18n from "i18next";
import global from "../../../../../../../global";
import Ajax from "../../../../../../../utils/Ajax";
import {backendUrlFromPath} from "../../../../../../../utils/UrlUtils";

export const TexturesTab = () => {
    const [search, setSearch] = useState("");
    const [data, setData] = useState<FileData[]>();
    const [filteredData, setFilteredData] = useState<FileData[]>();

    const app = (global as any).app as any;

    const fetchData = () => {
        Ajax.get({url: backendUrlFromPath(`/api/Map/List`)}).then(response => {
            const obj = response?.data;
            if (obj.Code !== 200) {
                app.toast(I18n.t(obj.Msg), "warn");
                return;
            }
            setData(obj.Data);
        });
    };

    const handleAdd = () => {
        app.upload(backendUrlFromPath(`/api/Map/Add`), (obj: any) => {
            if (obj.Code === 200) {
                fetchData();
            }
            app.toast(I18n.t(obj.Msg));
        });
    };

    const handleClick = (id: string) => {
        const obj = data?.find(item => item.ID === id);
        if (obj) {
            app.call(`selectMap`, obj, obj);
        }
    };

    useEffect(() => {
        if (!search) {
            setFilteredData(data);
            return;
        } else {
            setFilteredData(
                data?.filter(n => {
                    return (
                        n.Name.toLowerCase().indexOf(search.toLowerCase()) > -1
                    );
                }),
            );
        }
    }, [search, data]);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <img
                src={uploadIconSmall}
                alt="upload-icon-small"
                className="upload-assets-button"
                onClick={handleAdd}
            />

            <input
                className="search-input"
                type="text"
                onChange={e => setSearch(e.target.value)}
            />

            {filteredData && (
                <AssetsList data={filteredData} onClick={handleClick} />
            )}
        </>
    );
};
