import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import {OBJECT_TYPES, BillboardBehaviorInterface, BILLBOARD_TYPES} from "../../types/editor";
import BillboardBehaviorUpdater from "./BillboardBehaviorUpdater";

class BillboardBehaviorConverter extends BaseBehaviorConverter<BillboardBehaviorInterface> {
    public static DEFAULT = new BillboardBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: BillboardBehaviorInterface): BehaviorUpdater {
        return new BillboardBehaviorUpdater(target, behavior);
    }

    reset(): void {}

    getBehavior(target: THREE.Object3D, id: string): BillboardBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as BillboardBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have Billboard behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): BillboardBehaviorInterface {
        return {
            id,
            type: OBJECT_TYPES.BILLBOARD,
            billboardMode: BILLBOARD_TYPES.IMAGE,
            loop: true,
        };
    }
}

export default BillboardBehaviorConverter;
