import {flexCenter} from "../../../../assets/style";
import styled from "styled-components";

export const Overlay = styled.div<{$show: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({$show}) =>
        !$show &&
        `
        display: none;
    `}
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 640px;
    max-height: 80%;
    width: 720px;
    max-width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--theme-grey-bg);
    border-radius: 16px;
    box-sizing: border-box;

    background: var(--theme-container-main-dark);
    * {
        box-sizing: border-box;
    }
`;

export const Header = styled.div`
    ${flexCenter};
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--theme-grey-bg);
    padding: 8px;
    height: 48px;
`;

export const Title = styled.span`
    font-size: var(--theme-font-size-s);
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: white;
`;

export const Content = styled.div`
    width: calc(100% - 16px);
    height: calc(100% - 64px);
    margin: auto;
    background: var(--theme-grey-bg);
    border-radius: 8px;
    overflow-y: auto;
`;
