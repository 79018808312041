const scaleUpKeyWords = ["scale up", "scale it up", "times bigger"];
const scaleDownKeyWords = ["scale down", "scale it down", "times smaller"];

export enum SCALE_OPERATION_TYPE {
    SCALE_UP,
    SCALE_DOWN,
}
export type ScaleOperation = {
    type: SCALE_OPERATION_TYPE;
    scaleFactor: number;
};

export const getScaleOperationFromText = (
    text: string,
): ScaleOperation | null => {
    const match = text.match(/\d+/g);
    const scaleFactor = match ? +match[0] : 1;

    if (scaleUpKeyWords.some(word => text.toLowerCase().includes(word))) {
        return {
            type: SCALE_OPERATION_TYPE.SCALE_UP,
            scaleFactor: scaleFactor,
        };
    } else if (
        scaleDownKeyWords.some(word => text.toLowerCase().includes(word))
    ) {
        return {
            type: SCALE_OPERATION_TYPE.SCALE_DOWN,
            scaleFactor: scaleFactor,
        };
    } else {
        return null;
    }
};
