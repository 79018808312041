import {useEffect, useState} from "react";
import * as THREE from "three";

import global from "../../../../../../global";
import {ITransformValue} from "../../../../../../types/editor";
import {roundNumber} from "../../../utils/roundNumber";

import {Separator} from "../../common/Separator";
import {ShapeSection} from "../../../common/MovementSection/ShapeSection";
import {VisibilitySection} from "../../sections/VisibilitySection";
import {TransformationSection} from "../../sections/TransformationSection";
import {ModelLigthningSection} from "../../sections/ModelLigthningSection";
import {PhysicsSection} from "../../sections/v2/PhysicsSection";
import {TextureSection} from "../../sections/TextureSection";
import { SectionTitle, SectionWrapper } from "./PrimitivePanel.style";
import { MaterialRenderingSection } from "../../sections/MaterialRenderingSection";

interface Props {
    color: string | null;
    setColor: React.Dispatch<React.SetStateAction<string | null>>;
    texture: any;
}

export const PrimitivePanel = ({color, setColor, texture}: Props) => {
    const app = global.app;
    const editor = app?.editor;

    const [locked, setLocked] = useState(false);
    const [positionValue, setPositionValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [rotationValue, setRotationValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [scaleValue, setScaleValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });

    const updateTransformationValues = () => {
        const selected = editor.selected;
        if (!selected || Array.isArray(selected)) return;

        setPositionValue({
            x: roundNumber(selected.position.x, 4),
            y: roundNumber(selected.position.y, 4),
            z: roundNumber(selected.position.z, 4),
        });
        setRotationValue({
            x: roundNumber(selected.rotation._x * THREE.MathUtils.RAD2DEG, 2),
            y: roundNumber(selected.rotation._y * THREE.MathUtils.RAD2DEG, 2),
            z: roundNumber(selected.rotation._z * THREE.MathUtils.RAD2DEG, 2),
        });
        setScaleValue({
            x: roundNumber(selected.scale.x, 4),
            y: roundNumber(selected.scale.y, 4),
            z: roundNumber(selected.scale.z, 4),
        });
    };

    useEffect(() => {
        if (editor && app) {
            app.on(`objectSelected.PrimitivePanel`, updateTransformationValues);
            app.on(`objectChanged.PrimitivePanel`, updateTransformationValues);
        }

        return () => {
            app?.on(`objectSelected.PrimitivePanel`, null);
            app?.on(`objectChanged.PrimitivePanel`, null);
        };
    }, [editor]);

    useEffect(() => {
        setLocked(editor?.sceneLockedItems?.includes(editor.selected?.uuid));
    }, [editor?.sceneLockedItems]);

    return (
        <>
            <Separator invisible />
            <SectionWrapper>
                <SectionTitle>Visibility</SectionTitle>
                <VisibilitySection isLocked={editor?.sceneLockedItems?.includes(editor.selected?.uuid)} />
            </SectionWrapper>
            <Separator />
            <TransformationSection
                positionValue={positionValue}
                setPositionValue={setPositionValue}
                rotationValue={rotationValue}
                setRotationValue={setRotationValue}
                scaleValue={scaleValue}
                setScaleValue={setScaleValue}
                isLocked={locked}
                hideVisibilitySection
            />
            <Separator />
            <SectionWrapper>
                <SectionTitle>Shape</SectionTitle>
                <ShapeSection isLocked={true} />
            </SectionWrapper>
            <Separator />
            <SectionWrapper>
                <SectionTitle>Lighting</SectionTitle>
                <ModelLigthningSection isLocked={locked} />
            </SectionWrapper>
            <Separator />
            <SectionWrapper>
                <SectionTitle>Physics</SectionTitle>
                <PhysicsSection isLocked={locked} />
            </SectionWrapper>
            <Separator />
            {color && (
                <>
                    <SectionWrapper>
                        <SectionTitle>Texture</SectionTitle>
                        <TextureSection isLocked={locked} texture={texture} color={color} setColor={setColor} />
                    </SectionWrapper>
                    <Separator />
                </>
            )}
            <SectionWrapper>
                <SectionTitle>Material Rendering</SectionTitle>
                <MaterialRenderingSection />
            </SectionWrapper>
        </>
    );
};
