/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import MIMETypeUtils from "./MIMETypeUtils";
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import pako from "pako";
import global from "../global";
import {auth} from "../firebase";

let authToken: string | undefined = undefined;

export const axiosTokenConfig = (token: string | undefined) => {
    authToken = token;
};

export interface AjaxParams {
    url?: string;
    method?: string;
    data?: any;
    token?: string | null;
    multipart?: boolean;
    usesApiKey?: boolean;
    needAuthorization?: boolean;
}

export const ajax = async (params: AjaxParams): Promise<AxiosResponse | undefined> => {
    const url = params.url || "";
    const method = params.method || "GET";
    const data = params.data || null;
    const multipart = params.multipart ?? true;
    const usesApiKey = params.usesApiKey ?? false;
    const secure = params.needAuthorization ?? true;
    const token = params.token;

    //TODO: backend requires refactoring to support gzip encoding
    let compress = true;
    let headers: any = {};
    if (!usesApiKey && secure) {
        if (authToken != null || token !== null) {
            headers["Authorization"] = `Bearer ${token || authToken}`;
        } else {
            throw new Error("Unauthorized ajax error");
        }
    }

    let request = {
        method: method,
        url: url,
        headers,
    } as AxiosRequestConfig;

    if (method !== "GET") {
        request["transformRequest"] = [
            (data, headers) => {
                if (typeof data === "string" && data.length > 1024) {
                    headers["Content-Encoding"] = "gzip";
                    let zippedData = pako.gzip(data);
                    console.log(`API: compressing data: ${data.length} -> ${zippedData.length}`);
                    return zippedData;
                }
                return data;
            },
        ];
    }

    if (data) {
        let hasFile = false,
            name;

        for (name in data) {
            if (data[name] instanceof Blob) {
                hasFile = true;
                break;
            }
        }

        if (hasFile || multipart) {
            if (hasFile) {
                // 上传文件
                let formData = new FormData();

                for (name in data) {
                    if (data[name] instanceof File) {
                        formData.append(name, data[name]);
                    } else if (data[name] instanceof Blob) {
                        formData.append(
                            name,
                            data[name],
                            `${data[name].name}.${MIMETypeUtils.getExtension(data[name].type)}`,
                        );
                    }
                }

                request.data = formData;
            } else {
                // 发送表单
                let bodies = [];
                for (name in data) {
                    bodies.push(name + "=" + encodeURIComponent(data[name]));
                }

                let body = bodies.join("&");
                if (body.length) {
                    request.headers!["Content-type"] = "application/x-www-form-urlencoded";
                }

                request.data = body;
            }
        } else {
            request.headers = {"Content-type": "application/json"};
            request.data = data;
        }
    }

    try {
        return await axios(request);
    } catch (error) {
        console.error(`ERROR: API request failed`);
        console.error(request);
        console.error(error);
        if (error instanceof AxiosError) {
            const axiosError = error as AxiosError;
            let msg = axiosError.message;
            if (axiosError.response) {
                msg = msg + ` with status code ${axiosError.response.status}`;
            } else if (axiosError.request) {
                msg = msg + " because no response was received";
            }

            if (axiosError.response?.status === 401) {
                if (auth.currentUser) {
                    auth.currentUser
                        .getIdToken(true)
                        .then(token => {
                            global.app?.call("updateToken", null, token);
                            if (token !== null) {
                                axiosTokenConfig(token);
                            } else {
                                window.location.href = "/";
                            }
                        })
                        .catch(() => {
                            window.location.href = "/";
                        });
                } else {
                    window.location.href = "/";
                }
            }
        }

        throw error;
    }
};

export const post = async (params: AjaxParams): Promise<AxiosResponse | undefined> => {
    return ajax({...params, method: "POST"});
};

export const get = async (params: AjaxParams): Promise<AxiosResponse | undefined> => {
    return ajax({...params, method: "GET"});
};

const Ajax = {
    request: ajax,
    get,
    post,
};

export default Ajax;
