import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    > div:nth-child(2) {
        border-top: none;
    }

    .BehaviorTextInput {
        height: 28px;
        border-radius: 0;
        box-sizing: border-box;
        width: 100%;
        background: #232323;
    }
`;

export const Header = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 11px 8px;
    margin: 0;
    width: 100%;
    color: #fff;
    font-size: var(--theme-font-size-xs);
    font-weight: var(--theme-font-regular);
    border-bottom: 1px solid #27272a;
    cursor: pointer;
    margin-bottom: 8px;

    .icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    .delete-icon {
        margin-left: auto;
    }
`;

export const CheckboxWrapper = styled.div<{$disabled?: boolean}>`
    border-top: 1px solid var(--theme-container-secondary-dark);
    background: #232323;
    padding: 8px 17px 8px;
    display: flex;
    align-items: center;
    margin: 0 8px;
    width: calc(100% - 16px);
    box-sizing: border-box;
    gap: 7px;
    font-size: var(--theme-font-size-xs);
    font-weight: var(--theme-font-regular);
    color: var(--theme-font-unselected-color);
    .common-checkbox {
        margin: 0;
    }
    ${({$disabled}) => $disabled && `opacity: 0.5;`}
`;

export const DefaultWrapper = styled.div`
    border-top: 1px solid var(--theme-container-secondary-dark);
    background: transparent;
    font-size: var(--theme-font-size-xs);
    font-weight: var(--theme-font-regular);
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;
`;

export const AddIconWrapper = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
`;
