import {useEffect, useState} from "react";
import Ajax from "../../../../../../utils/Ajax";
import {backendUrlFromPath} from "../../../../../../utils/UrlUtils";
import {LayoutButton} from "../LayoutButton/LayoutButton";
import {HUD_TABS, LAYOUT_BUTTON_TYPE, START_MENU_IDS} from "../types";
import {useHUDStartGameMenuContext} from "../../../../../../context";
import {ButtonsColumn, Grid, PanelColorWrapper} from "./GameStartMenu.style";
import {ColorSelectionRow} from "../../../RightPanel/common/ColorSelectionRow";
import {Item} from "../../../common/BasicCombobox";
import {SelectRow} from "../../../RightPanel/common/SelectRow";
import {FileData} from "../../../types/file";

export const GameStartMenu = () => {
    const {startGameMenuLayout, useInitialSetup, setStartGameMenuLayout} = useHUDStartGameMenuContext();
    const [panelBg, setPanelBg] = useState((startGameMenuLayout?.[START_MENU_IDS.PANEL_BG] as string) || "#000000");
    const [soundOptions, setSoundOptions] = useState<Item[]>([]);
    const [soundData, setSoundData] = useState<FileData[]>();
    const [menuMusic, setMenuMusic] = useState<FileData | undefined>(
        (startGameMenuLayout?.menu_music as FileData) || undefined,
    );

    useEffect(() => {
        useInitialSetup();
        document.getElementById(START_MENU_IDS.LOGO_LEFT)?.click();
    }, []);

    useEffect(() => {
        setStartGameMenuLayout({
            ...startGameMenuLayout,
            [START_MENU_IDS.PANEL_BG]: panelBg,
        });
    }, [panelBg]);

    useEffect(() => {
        setStartGameMenuLayout({
            ...startGameMenuLayout,
            menu_music: menuMusic,
        });
    }, [menuMusic]);

    const fetchSounds = () => {
        Ajax.get({url: backendUrlFromPath(`/api/Audio/List`)}).then(response => {
            const obj = response?.data;
            if (obj.Code !== 200) {
                console.error("Fetching sounds failed");
                return;
            }
            setSoundData(obj.Data);
        });
    };

    useEffect(() => {
        fetchSounds();
    }, []);

    useEffect(() => {
        if (soundData) {
            setSoundOptions([
                {key: "0", value: "none"},
                ...soundData.map((option: any, index: number) => {
                    return {
                        key: `${index + 1}`,
                        value: option.Name,
                    };
                }),
            ]);
        }
    }, [soundData]);

    return (
        <Grid>
            <ButtonsColumn $panelBg={panelBg}>
                <LayoutButton
                    tab={HUD_TABS.GAME_START_MENU}
                    // Styling should always be the same as in HUDView/GameStartView
                    width="285px"
                    height="285px"
                    type={LAYOUT_BUTTON_TYPE.ADD_GAME_LOGO}
                    id={START_MENU_IDS.LOGO_LEFT}
                />
                {Array(5)
                    .fill(5)
                    .map((_, index) => (
                        <LayoutButton
                            tab={HUD_TABS.GAME_START_MENU}
                            key={index}
                            // Styling should always be the same as in HUDView/GameStartView
                            width="285px"
                            height="32px"
                            type={LAYOUT_BUTTON_TYPE.ADD_GAME_BUTTON}
                            id={`start-menu-game-button-column-left-${index + 1}` as START_MENU_IDS}
                        />
                    ))}
                <PanelColorWrapper>
                    <ColorSelectionRow $margin="0" value={panelBg} setValue={setPanelBg} label="Panel Color" border />
                </PanelColorWrapper>
                <PanelColorWrapper>
                    <SelectRow
                        showListOnTop
                        $margin="0"
                        label="Menu Music"
                        data={soundOptions}
                        value={soundOptions.find(item => item.value === menuMusic?.Name) || soundOptions[0]}
                        onChange={item => setMenuMusic(soundData?.find(el => el.Name === item.value))}
                    />
                </PanelColorWrapper>
            </ButtonsColumn>
            <ButtonsColumn $menuBgColumn>
                <LayoutButton
                    tab={HUD_TABS.GAME_START_MENU}
                    width="50%"
                    maxWidth="309px"
                    height="174px"
                    type={LAYOUT_BUTTON_TYPE.ADD_MENU_BG}
                    id={START_MENU_IDS.MENU_BG}
                    helperText="Main Menu Background"
                    plusNewLine
                    plusIconBig
                />
            </ButtonsColumn>
        </Grid>
    );
};
