import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";

class ScriptBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;

    constructor(target: THREE.Object3D) {
        this.target = target;
        this.target.userData.hasScript = true;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
    }

    update(clock: THREE.Clock, delta: number): void {}

    reset() {}
}

export default ScriptBehaviorUpdater;
