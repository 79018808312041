import PubSub from "pubsub-js";

class EventBus {
    static instance: EventBus = new EventBus();

    private tokens: string[] = [];

    private constructor() {}

    reset() {
        PubSub.clearAllSubscriptions();
        this.tokens = [];
    }

    unsubscribe(token: string) {
        PubSub.unsubscribe(token);
        this.tokens = this.tokens.filter(t => t !== token);
    }

    subscribe(
        topic: string,
        callback: (msg: string, data: string) => void,
    ): string {
        let token = PubSub.subscribe(topic, callback);
        this.tokens.push(token);
        return token;
    }

    send(topic: string, data: any = {}) {
        PubSub.publish(topic, data);
    }
}

export default EventBus;
