import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

export const positionCameraForModel = (
    model: THREE.Object3D,
    camera: THREE.PerspectiveCamera,
    controls: OrbitControls,
) => {
    // Calculate the bounding box of the model
    const box = new THREE.Box3().setFromObject(model);
    const size = box.getSize(new THREE.Vector3());
    const center = box.getCenter(new THREE.Vector3());

    // Calculate the appropriate distance for the camera
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = camera.fov * (Math.PI / 180);
    let cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2));
    cameraZ *= 1.5; // Add some margin

    camera.position.set(center.x, center.y, cameraZ);
    camera.lookAt(center);

    controls.target.set(center.x, center.y, center.z);
    controls.update();
};
