import styled from "styled-components";
import {regularFont} from "../../../../../assets/style";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px;
    width: 200px;
    height: auto;
    border-radius: 16px;
    cursor: default;

    position: absolute;
    top: 0px;
    left: calc(100% + 6px);

    background: var(--theme-container-minor-dark);
    border: 1px solid #ffffff1a;

    input,
    label {
        margin-top: 0;
    }
`;

export const MenuItem = styled.li<{$disabled: boolean}>`
    display: flex;
    align-items: center;
    cursor: ${({$disabled}) => ($disabled ? "not-allowed" : "pointer")};
    width: 100%;
    transition: 0.2s;
    padding: 8px;
    border-radius: 8px;
    height: 32px;
    background-color: transparent;

    ${regularFont("xxs")};
    font-weight: var(--theme-font-medium);
    color: var(--theme-font-unselected-secondary-color);
    &:hover {
        background-color: var(--theme-container-hover-blue-border);
        color: var(--theme-font-main-selected-color);
    }

    .checkbox {
        margin-left: 8px;
    }
`;
