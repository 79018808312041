import {OBJECT_TYPES} from "../../../../../types/editor";

export const getBehaviorsFromText = (text: string): OBJECT_TYPES[] => {
    const textArray = text.split(" ");
    return Object.values(OBJECT_TYPES).filter(type => {
        const splittedType = type
            .split(" ")
            .map(word => word.toLowerCase().trim()) as string[];
        splittedType.length = 2;

        return textArray.find(word =>
            splittedType.includes(
                word
                    .toLowerCase()
                    .trim()
                    .replace("\n", "")
                    .replace(",", "")
                    .replace(".", ""),
            ),
        );
    });
};
