import {regularFont} from "../../../../../../../../assets/style";
import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    pointer-events: all;
    .title {
        ${regularFont("xxs")};
        text-align: left;
        color: #aeaeae;
        margin-bottom: 8px;
    }
`;
