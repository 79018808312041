import {useState} from "react";
import {ScriptBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {StyledButton} from "../../common/StyledButton";
import {javaScriptStarter} from "../../../../../editor/script/JavaScriptStarter";
import {TextInput} from "../../common/TextInput";
import {Label} from "../RightPanel.style";

type Props = {
    behavior: ScriptBehaviorInterface;
};

export const ScriptBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor?.selected;
    const [behavior, setBehavior] = useState(initialBehavior);

    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            const objBehavior = obj.userData.behaviors?.find((userBehavior: any) => userBehavior.id === behavior.id);

            return objBehavior as ScriptBehaviorInterface;
        }
        return null;
    };

    const openScriptEditor = () => {
        const behavior = getObjectBehavior();
        if (behavior) {
            if (!behavior.source) {
                const starter = javaScriptStarter(selected.uuid);
                behavior.source = starter;
                behavior.id = selected.uuid;
                setBehavior({...behavior, source: starter, id: selected.uuid});
            }

            const {id, scriptType, source, name} = behavior;
            app?.call(
                `editScript`,
                this,
                id,
                name,
                scriptType,
                source,
                (id: string, name: string, type: string, source: string) => handleSaveScript(id, name, type, source),
            );
        }
    };

    const handleSaveScript = (id: string, name: string, scriptType: string, source: string) => {
        const behavior = getObjectBehavior();
        if (behavior) {
            behavior.source = source;
            setBehavior({...behavior, source: source});
        }
    };

    const handleInputChange = (value: any, name: keyof ScriptBehaviorInterface) => {
        if (!app || !selected) return;

        const objBehavior = getObjectBehavior();

        if (objBehavior) {
            (objBehavior as any)[name] = value;
            setBehavior({...objBehavior, [name]: value});

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <Label>Name</Label>
            <TextInput
                width="100%"
                height="24px"
                value={behavior.name}
                setValue={value => handleInputChange(value, "name")}
            />
            <Separator margin="8px 0" />
            <PanelCheckbox
                text="Enable"
                checked={!!behavior.enabled}
                onChange={() => handleInputChange(!behavior.enabled, "enabled")}
                v2
            />
            <Separator margin="8px 0" />
            <StyledButton isBlue onClick={openScriptEditor}>
                <span>Edit Script</span>
            </StyledButton>
        </>
    );
};
