import {flexCenter} from "../../../../../../assets/style";
import styled from "styled-components";

export const Container = styled.div<{$isLast: boolean}>`
    ${flexCenter};
    width: 100%;
    height: 28px;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: var(--theme-font-input-color);
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid var(--theme-container-secondary-dark);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: white;
    &:hover {
        background: #435a70;
    }

    ${({$isLast}) =>
        $isLast &&
        `
    border-bottom: 1px solid var(--theme-container-secondary-dark);
    `}
`;
