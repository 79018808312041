import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../../../assets/style";

export const TabContent = styled.div`
    width: 100%;
    height: 100%;
`;

export const Wrapper = styled.div`
    ${flexCenter};
    position: relative;
`;

export const ContentItem = styled.div<{
    $flexDirection?: string;
    $justifyContent?: string;
    $alignItems?: string;
    $rowGap?: string;
    $columnGap?: string;
    $padding?: string;
}>`
    ${flexCenter};
    flex-direction: column;
    align-items: flex-start;
    ${({$flexDirection}) => $flexDirection && `flex-direction: ${$flexDirection};`};
    ${({$justifyContent}) => $justifyContent && `justify-content: ${$justifyContent};`};
    ${({$alignItems}) => $alignItems && `align-items: ${$alignItems};`};
    row-gap: 8px;
    ${({$rowGap}) => $rowGap && `row-gap: ${$rowGap};`};
    ${({$columnGap}) => $columnGap && `column-gap: ${$columnGap};`};
    flex-wrap: wrap;
    padding: 8px 0;
    width: 100%;
    ${({$padding}) => $padding && `padding: ${$padding};`};
    position: relative;
    span {
        ${regularFont("xxs")};
    }
    input {
        color: #fafafa;
    }

    .uploadButton {
        border-radius: 8px;
        background-color: var(--theme-editor-box-bg);
        font-size: 14px;

        .closeIconWrapper {
            width: 22px;
            height: 22px;
            top: 0;
            right: 0;
            background-color: var(--theme-editor-box-bg);
            img {
                width: 8px;
                height: 10px;
            }
        }
    }
`;

export const Tag = styled.div`
    ${flexCenter};
    gap: 8px;
    padding: 4px 8px;
    background: #3f3f46;
    border-top: 1px solid #52525b;
    border-radius: 8px;
    font-size: var(--theme-font-size-xxs);
    img {
        cursor: pointer;
    }
`;

export const ExpandButton = styled.div<{$expanded: boolean}>`
    ${flexCenter};
    position: absolute;
    top: 3px;
    right: 4px;

    img {
        cursor: pointer;
        transform: rotate(${({$expanded}) => ($expanded ? "0" : "180deg")});
    }
`;
