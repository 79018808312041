import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../../assets/style";
import {Title} from "../GamesDashboard.style";

export const StyledSceneList = styled.div`
    width: 100%;
    margin: 0 auto;
    display: inline-grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    align-items: start;
    justify-content: start;
    grid-gap: 20px;
    padding: 20px;
    overflow-y: scroll;
    max-width: 100%;
`;

export const ViewAllRow = styled.div`
    grid-column: 1 / -1;
    height: 56px;
    ${flexCenter};
    .btn {
        width: 83px;
        height: 40px;
        border-radius: 8px;
        background-color: var(--theme-grey-bg);
        ${regularFont("xs")};
        transition: all 0.2s;
        &:hover {
            background-color: #3f3f46;
        }
        &:active {
            background: #52525b;
        }
    }
`;

export const DashboardHeading = styled(Title)`
    grid-column: 1 / -1;
`;

export const ListItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    font-size: ${regularFont("xxs")};
    color: #fff;
    position: relative;
    cursor: pointer;
    margin: 0 auto;
    &:hover {
        color: white;
    }
    img,
    .selectedBorder {
        border-radius: 16px;
        width: 100%;
        aspect-ratio: 16 / 9;
    }
`;

export const SceneDetailsWrapper = styled.div<{$flex?: boolean}>`
    width: 100%;
    position: relative;
    line-height: 140%;
    ${({$flex}) =>
        $flex &&
        `
    ${flexCenter};
    justify-content: flex-start;
    column-gap: 8px;
    `}

    .textContainer {
        flex-grow: 1;
        padding-right: 28px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
`;

export const Options = styled.button`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    img {
        width: 100%;
        height: 100%;
    }
`;

export const SceneName = styled.span`
    display: inline-block;
    max-width: 175px;
    text-align: left;
    line-clamp: 1;
    font-size: var(--theme-font-size-xs);
    font-weight: var(--theme-font-medium);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

export const EditedText = styled.div`
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: var(--theme-font-unselected-color);
    line-height: 120%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

export const StyledSceneMenu = styled.div<{open: boolean; $left: boolean}>`
    display: none;
    position: absolute;
    bottom: -10px;
    ${({$left}) => ($left ? "left: 0; transform: translateX(-100%);" : "right: 0; transform: translateX(100%);")}
    z-index: 10000;
    text-align: left;
    width: 160px;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px 0px #000;
    background: var(--theme-grey-bg);
    box-sizing: border-box;
    font-size: var(--theme-font-size-xs);
    color: var(--theme-font-unselected-secondary-color);
    border: 1px solid var(--theme-grey-bg);

    .option {
        transition: 0.3s ease-in-out;
        width: 100%;
        height: 32px;
        line-height: 16px;
        padding: 8px;
        border-radius: 4px;
    }

    ${({open}) =>
        open &&
        `
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 10;
    .option:hover {background: #3F3F46;}
`}
`;
export const SelectedBorder = styled.div`
    border: 1px solid #ffffff;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
`;

export const ThumbnailPlaceholder = styled.div`
    width: 95px;
    height: 95px;
    border-radius: 12px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SceneImage = styled.div<{$bgImage?: string}>`
    border-radius: 16px;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--theme-grey-bg);
    ${flexCenter};
    .default-img {
        width: 40%;
        max-width: 104px;
    }

    ${({$bgImage}) =>
        $bgImage &&
        `
                background-image: url('${$bgImage}');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

              `}
`;
