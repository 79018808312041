import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`;
export const BehaviorsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    row-gap: 12px;
    margin-top: 12px;
`;

export const PlusIcon = styled.span`
    font-size: 20px;
    font-weight: 400;
    margin-right: 11px;
    line-height: 100%;
    height: 22px;
`;
