import {useState, useEffect} from "react";
import {TeleportBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import TeleportBehaviorConverter from "../../../../../serialization/behaviours/TeleportBehaviorConverter";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {SelectRow} from "../common/SelectRow";

type Props = {
    behavior: TeleportBehaviorInterface;
};

interface IOptions {
    key: string;
    value: string;
    uuid?: string;
}

export const TeleportBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);
    const [objectOptions, setObjectOptions] = useState<IOptions[]>([{key: "0", value: "none", uuid: ""}]);

    const targetBehavior = TeleportBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleInputChange = (value: any, name: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior({...targetBehavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleTargetChange = (name: string, value: any) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value.uuid;
            setBehavior({...targetBehavior, [name]: value.uuid});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    useEffect(() => {
        const getObjects = async () => {
            const options = [{name: "none", uuid: ""}];
            editor.scene.traverse((child: any) => {
                if ((child && !!child.userData?.isStemObject) || child.isGroup) {
                    options.push({name: child.name, uuid: child.uuid});
                }
            });
            setObjectOptions(
                options.map((option, index) => {
                    return {
                        key: `${index + 1}`,
                        value: option.name,
                        uuid: option.uuid,
                    };
                }),
            );
        };
        getObjects();
    }, []);

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
            />
            <Separator />
            <SelectRow
                $margin={"12px 0"}
                label="Target"
                value={objectOptions.find(el => el.uuid === behavior.teleporterExitObject)}
                onChange={value => handleTargetChange("teleporterExitObject", value)}
                data={objectOptions}
            />
        </>
    );
};
