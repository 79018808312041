interface Props {
    isMenuOpen: boolean;
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    userMenuButtonRef: React.MutableRefObject<SVGSVGElement | null>;
}

export const MenuIcon = ({
    userMenuButtonRef,
    isMenuOpen,
    setIsMenuOpen,
}: Props) => {
    return (
        <svg
            className="menuIcon"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            ref={userMenuButtonRef}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            {isMenuOpen ? (
                <>
                    <path
                        d="M9.27991 8.20129C9.13773 8.06881 8.94969 7.99669 8.75539 8.00012C8.56109 8.00355 8.3757 8.08226 8.23829 8.21967C8.10087 8.35708 8.02216 8.54247 8.01873 8.73677C8.01531 8.93107 8.08743 9.11912 8.21991 9.26129L10.9399 11.9813L8.21991 14.7013C8.14622 14.77 8.08712 14.8528 8.04613 14.9448C8.00514 15.0368 7.98309 15.1361 7.98132 15.2368C7.97954 15.3375 7.99807 15.4375 8.03579 15.5309C8.07351 15.6243 8.12965 15.7091 8.20087 15.7803C8.27209 15.8515 8.35692 15.9077 8.45031 15.9454C8.5437 15.9831 8.64373 16.0017 8.74443 15.9999C8.84513 15.9981 8.94445 15.9761 9.03645 15.9351C9.12845 15.8941 9.21125 15.835 9.27991 15.7613L11.9999 13.0413L14.7199 15.7613C14.7886 15.835 14.8714 15.8941 14.9634 15.9351C15.0554 15.9761 15.1547 15.9981 15.2554 15.9999C15.3561 16.0017 15.4561 15.9831 15.5495 15.9454C15.6429 15.9077 15.7277 15.8515 15.7989 15.7803C15.8702 15.7091 15.9263 15.6243 15.964 15.5309C16.0018 15.4375 16.0203 15.3375 16.0185 15.2368C16.0167 15.1361 15.9947 15.0368 15.9537 14.9448C15.9127 14.8528 15.8536 14.77 15.7799 14.7013L13.0599 11.9813L15.7799 9.26129C15.9124 9.11912 15.9845 8.93107 15.9811 8.73677C15.9777 8.54247 15.8989 8.35708 15.7615 8.21967C15.6241 8.08226 15.4387 8.00355 15.2444 8.00012C15.0501 7.99669 14.8621 8.06881 14.7199 8.20129L11.9999 10.9213L9.27991 8.20129Z"
                        fill="#A3A3A3"
                    />
                </>
            ) : (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 8.75C6 8.55109 6.07902 8.36032 6.21967 8.21967C6.36032 8.07902 6.55109 8 6.75 8H17.25C17.4489 8 17.6397 8.07902 17.7803 8.21967C17.921 8.36032 18 8.55109 18 8.75C18 8.94891 17.921 9.13968 17.7803 9.28033C17.6397 9.42098 17.4489 9.5 17.25 9.5H6.75C6.55109 9.5 6.36032 9.42098 6.21967 9.28033C6.07902 9.13968 6 8.94891 6 8.75ZM6 15.25C6 15.0511 6.07902 14.8603 6.21967 14.7197C6.36032 14.579 6.55109 14.5 6.75 14.5H17.25C17.4489 14.5 17.6397 14.579 17.7803 14.7197C17.921 14.8603 18 15.0511 18 15.25C18 15.4489 17.921 15.6397 17.7803 15.7803C17.6397 15.921 17.4489 16 17.25 16H6.75C6.55109 16 6.36032 15.921 6.21967 15.7803C6.07902 15.6397 6 15.4489 6 15.25Z"
                    fill="#A3A3A3"
                />
            )}
        </svg>
    );
};
