import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {SingleGame} from "../SingleGame/SingleGame";
import {useHomepageContext} from "../../../../context";
import {IBasicGameInterface} from "../../types";

type Props = {
    itemsCount?: number;
    partialVisibilityGutterCount?: number;
    games?: IBasicGameInterface[];
};

export const GamesCarousel = ({itemsCount, partialVisibilityGutterCount, games}: Props) => {
    const {games: contextGames} = useHomepageContext();

    const responsive = {
        desktop: {
            breakpoint: {max: 4000, min: 0},
            items: itemsCount ?? 4,
            partialVisibilityGutter: partialVisibilityGutterCount ?? 12,
        },
    };

    return (
        <Carousel responsive={responsive} partialVisible itemClass="carousel-item carousel-padding">
            {(games || contextGames).map(item => (
                <SingleGame item={item} key={item.ID} />
            ))}
        </Carousel>
    );
};
