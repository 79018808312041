import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../assets/style";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;

    background: var(--theme-container-minor-dark);
    z-index: 999;
    overflow: hidden;
`;

export const RightSideContainer = styled.div`
    height: 100%;
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    transition: all 0.4s;
    background: var(--theme-container-minor-dark);
    overflow: hidden;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    height: 64px;
    gap: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--theme-container-divider);
`;

export const RightSide = styled.div`
    margin-left: auto;
    ${flexCenter};
    column-gap: 8px;

    .logout {
        color: var(--theme-font-main-selected-color);
    }

    .plus-icon {
        width: 16px;
        height: 16px;
    }
`;

export const PanelButton = styled.button<{width?: string}>`
    background-color: var(--theme-grey-bg-button) !important;
    height: 32px;
    width: ${({width}) => width || "120px"};
    color: #fff;
    ${flexCenter};
    column-gap: 4px;
    border-radius: 8px;
    ${regularFont("xxs")};
    font-weight: var(--theme-font-medium);
    transition: 0.3s;
    &:hover {
        background-color: var(--theme-grey-bg-button-hover) !important;
    }
    &:active {
        background-color: var(--theme-grey-bg-button-active) !important;
    }
`;

export const SearchSection = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    box-sizing: border-box;
`;

export const Title = styled.div`
    ${regularFont("s")}
    font-weight: var(--theme-font-bold);
`;

export const NoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--theme-font-regular);
    font-size: var(--theme-font-size-s);
    color: white;
    font-family: "Inter";
    height: calc(100% - 64px - 64px);
`;
