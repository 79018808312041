import {useEffect, useRef, useState} from "react";
import {useOnClickOutside} from "usehooks-ts";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";

import {ROUTES} from "../../AppRouter";
import {SEARCH_GAME_QUERY} from "../pages/types";
import search from "../assets/search.svg";

export const Search = () => {
    const [searchActive, setSearchActive] = useState(false);
    const [value, setValue] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    useOnClickOutside(inputRef, () => setSearchActive(false));
    const handleSearch = () => {
        if (value.trim()) {
            navigate(`${ROUTES.SEARCH_RESULTS}?${SEARCH_GAME_QUERY.GAME_NAME}=${value}`);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    useEffect(() => {
        if (searchActive && inputRef.current) {
            inputRef.current.focus();
        }
    }, [searchActive]);

    return (
        <StyledSearchButton className="reset-css" onClick={() => setSearchActive(true)}>
            <img src={search} alt="search" className={searchActive ? "searchIcon" : undefined} />
            <StyledInput
                ref={inputRef}
                type="text"
                placeholder="Search..."
                value={value}
                onChange={e => setValue(e.target.value)}
                $searchActive={searchActive}
                onKeyDown={handleKeyDown}
            />
        </StyledSearchButton>
    );
};

const StyledSearchButton = styled.button`
    position: relative;
    .searchIcon {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
    }
`;

const StyledInput = styled.input<{$searchActive: boolean}>`
    width: ${props => (props.$searchActive ? "320px" : "0")};
    height: 40px;
    border: none;
    border-radius: 8px;
    background-color: var(--theme-input-background-color);
    font-size: var(--theme-font-size-s);
    font-weight: var(--theme-font-medium);
    color: var(--theme-font-unselected-secondary-color);
    line-height: 120%;
    padding: ${props => (props.$searchActive ? "12px 12px 12px 38px" : "12px 0")};
    transition:
        width 0.3s ease,
        padding 0.3s ease;
    overflow: hidden;
    opacity: ${props => (props.$searchActive ? 1 : 0)};
    pointer-events: ${props => (props.$searchActive ? "auto" : "none")};
`;
