import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import {COLLISION_TYPE} from "../../types/editor";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import { IPhysics } from "src/physics/common/types";
import {PhysicsUtil} from "../../physics/PhysicsUtil";

class JumpPadBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;
    angle: number;
    strength: number;
    physics?: IPhysics;

    constructor(target: THREE.Object3D, angle: number, strength: number) {
        this.target = target;
        this.angle = angle;
        this.strength = strength;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;
        this.addCollisionListener();
    }

    onCollision() {
        if (this.strength <= 0) {
            return;
        }

        const object = this.game?.player; // TODO: get collision object instead of player

        if (!object || !object.userData.physics?.enabled) {
            return;
        }

        const angle = this.angle;
        const strength = this.strength;

        const radians = angle * Math.PI / 180;
        const impulse = new THREE.Vector3(0, 1, 0);
        impulse.applyAxisAngle(new THREE.Vector3(0, 0, 1), radians);
        impulse.multiplyScalar(strength);

        // rotate impulse to match object rotation
        const quaternion = this.target.quaternion.clone();
        impulse.applyQuaternion(quaternion);

        //apply impulse to the player object
        //TODO: MP support is needed for multiple player instances
        this.physics?.applyImpulseToPlayer(object.uuid, impulse);
    }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollision.bind(this),
                useBoundingBoxes: true,
            },
            PhysicsUtil.isDynamicObject(this.target)
        );
    }

    update(clock: THREE.Clock, delta: number): void {}

    reset() {}
}

export default JumpPadBehaviorUpdater
