import {AnimationBehaviorInterface} from "../../types/editor";
import BaseBehaviorConverter from "./BaseBehaviorConverter";

abstract class BaseAnimationBehaviorConverter extends BaseBehaviorConverter<AnimationBehaviorInterface> {
    protected constructor(obj: any) {
        super(obj);
    }

    getAnimationLayer(layerId: string, layerName: string) {
        let animationLayer = this.obj?.animations.filter(
            (n: any) => n.uuid === layerId,
        )[0];

        if (!animationLayer) {
            animationLayer = {
                uuid: layerId,
                layerName: layerName,
                animations: [],
            };
            this.obj.animations.push(animationLayer);
        }

        return animationLayer;
    }
}

export default BaseAnimationBehaviorConverter;
