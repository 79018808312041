export const IMAGE_URL = process.env.REACT_APP_API_GAME_SERVICE_URL || "https://gameservice.develop.erth.xyz";

export const REACT_APP_MULTIPLAYER_SERVER_URL = process.env.REACT_APP_MULTIPLAYER_SERVER_URL || "ws://localhost:2567";

export enum GAME_CATEGORY {
    FPS = "FPS",
    OBBY = "Obby",
    MULTIPLAYER = "Multiplayer",
    SANDBOX = "Sandbox",
    COMPETITIVE = "Competitive",
    SIMULATION = "Simulation",
    VIBE = "Vibe",
}

export const TAGS = [
    GAME_CATEGORY.FPS,
    GAME_CATEGORY.OBBY,
    GAME_CATEGORY.MULTIPLAYER,
    GAME_CATEGORY.SANDBOX,
    GAME_CATEGORY.COMPETITIVE,
    GAME_CATEGORY.SIMULATION,
    GAME_CATEGORY.VIBE,
];
