import {useEffect, useState} from "react";
import * as THREE from "three";
import SetPositionCommand from "../../../../../command/SetPositionCommand.js";
import {useAppGlobalContext} from "../../../../../context";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {StyledButton} from "../../common/StyledButton";
import {Separator} from "../common/Separator";
import {SelectRow} from "../common/SelectRow";
import {Item} from "../../common/BasicCombobox";
import global from "../../../../../global";
import Application from "../../../../../Application";
import {OBJECT_TYPES, ITransformValue, TRANSFORMATION_OPTIONS} from "../../../../../types/editor";
import {MovementSection} from "../../common/MovementSection/MovementSection";

export const PointsPanel = () => {
    const app = (global.app as Application) || null;
    const editor = app?.editor;
    const {isCheckPointPanelOpen} = useAppGlobalContext();
    const [positionValues, setPositionValues] = useState<ITransformValue>({x: 0, y: 0, z: 0});
    const [positionOriginalValue, setPositionOriginalValue] = useState<ITransformValue>({x: 0, y: 0, z: 0});
    const [isTeam, setIsTeam] = useState(false);
    const [teamOptions, setTeamOptions] = useState<Item[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<string>();

    const getObjectBehavior = () => {
        const type = isCheckPointPanelOpen ? OBJECT_TYPES.CHECK_POINT : OBJECT_TYPES.SPAWN_POINT;
        const selected = app?.editor?.selected;

        if (selected && !app?.editor?.selected) {
            app.editor!.select(selected);
        }

        if (selected) {
            const obj = app?.editor?.objectByUuid((selected as any).uuid as string);
            let objBehavior;
            if (obj) {
                if (obj.userData.behaviors && !Array.isArray(obj.userData.behaviors)) return;
                objBehavior = obj.userData.behaviors?.find((behavior: any) => behavior.type === type);
            }
            return objBehavior;
        }
        return null;
    };

    const handleChange = (value: ITransformValue, noCall?: boolean) => {
        if (!app?.editor?.selected) return;

        const objBehavior = getObjectBehavior();
        if (objBehavior) {
            objBehavior.position = value;
        }
    };

    const reset = () => {
        setPositionValues(positionOriginalValue);
    };

    useEffect(() => {
        setTeamOptions([{key: "0", value: "none"}]);
    }, []);

    const updateTransformationValues = () => {
        const selected = editor?.selected;
        if (!selected || Array.isArray(selected)) return;
        const positionObj = {x: selected.position.x, y: selected.position.y, z: selected.position.z};
        setPositionValues(positionObj);
        handleChange(positionObj);
    };

    useEffect(() => {
        updateTransformationValues();
    }, []);

    const getSetValueFunc = (type: TRANSFORMATION_OPTIONS, value: number, toUpdate: "x" | "y" | "z") => {
        if (!editor) return;
        const selected = editor.selected;
        if (type === TRANSFORMATION_OPTIONS.POSITION) {
            switch (toUpdate) {
                case "x":
                    editor.execute(
                        new SetPositionCommand(
                            selected,
                            new THREE.Vector3(+value, +positionValues.y, +positionValues.z),
                        ),
                    );
                    return setPositionValues(prevState => ({
                        ...prevState,
                        x: value,
                    }));
                case "y":
                    editor.execute(
                        new SetPositionCommand(
                            selected,
                            new THREE.Vector3(+positionValues.x, +value, +positionValues.z),
                        ),
                    );
                    return setPositionValues(prevState => ({
                        ...prevState,
                        y: value,
                    }));
                case "z":
                    editor.execute(
                        new SetPositionCommand(
                            selected,
                            new THREE.Vector3(+positionValues.x, +positionValues.y, +value),
                        ),
                    );
                    return setPositionValues(prevState => ({
                        ...prevState,
                        z: value,
                    }));

                default:
                    break;
            }
        }
    };

    useEffect(() => {
        if (editor && app) {
            app.on(`objectSelected.PointsPanel`, updateTransformationValues);
            app.on(`objectChanged.PointsPanel`, updateTransformationValues);
        }

        return () => {
            app?.on(`objectSelected.PointsPanel`, null);
            app?.on(`objectChanged.PointsPanel`, null);
        };
    }, [editor]);

    return (
        <>
            <MovementSection
                noRotation
                noScale
                isLocked={false}
                reset={reset}
                positionValue={positionValues}
                getSetValueFunc={getSetValueFunc}
            />
            <Separator invisible margin="24px 0 0" />
            <SelectionOfButtons nowrap>
                <StyledButton width="50%" isBlue={!isTeam} isActive={isTeam} onClick={() => setIsTeam(false)}>
                    <span>Normal {isCheckPointPanelOpen ? "Point" : "Spawn"}</span>
                </StyledButton>
                <StyledButton width="50%" isBlue={isTeam} isActive={!isTeam} onClick={() => setIsTeam(true)} disabled>
                    <span>Team {isCheckPointPanelOpen ? "Point" : "Spawn"}</span>
                </StyledButton>
            </SelectionOfButtons>
            <Separator invisible margin="24px 0 0" />
            {isTeam && (
                <SelectRow
                    $margin="0"
                    label={isCheckPointPanelOpen ? "Team Check Point" : "Team Spawn"}
                    data={teamOptions}
                    value={teamOptions.find(item => item.value === selectedTeam) || teamOptions[0]}
                    onChange={item => setSelectedTeam(item.value)}
                />
            )}
        </>
    );
};
