import React, {useEffect, useState} from "react";
import {DefaultWrapper} from "../../styles/Behaviors.style";
import {VehicleOptionsInterface} from "../../../../../../types/editor";
import {NumericInput} from "../../../common/NumericInput";
import {BasicCombobox} from "../../../common/BasicCombobox";
import * as THREE from "three";
import global from "../../../../../../global";
import CharacterBehaviorConverter from "../../../../../../serialization/behaviours/CharacterBehaviorConverter";

export const VehicleOptions = () => {
    const app = (global as any).app;
    const editor = app.editor;
    const model = editor.selected;
    const thisModelCharacterBehavior = model.userData.behaviors.find((el: any) => el.type === "Character");
    const targetBehaviorVehicleOptions = thisModelCharacterBehavior?.vehicleOptions;

    const [targetBehavior, setTargetBehavior] = useState(
        targetBehaviorVehicleOptions
            ? {vehicleOptions: targetBehaviorVehicleOptions}
            : {
                  vehicleOptions: CharacterBehaviorConverter.DEFAULT.getDefaultVehicleOptions(),
              },
    );
    const [wheelOptions, setWheelOptions] = useState<string[]>([]);
    const [trackOptions, setTrackOptions] = useState<string[]>([]);
    const [trackSurfaceOptions, setTrackSurfaceOptions] = useState<string[]>([]);
    const [trackBoundaryOptions, setTrackBoundaryOptions] = useState<string[]>([]);

    const handleInputChange = (value: string | number, name: keyof VehicleOptionsInterface) => {
        if (editor.camera) {
            const newOptions = {
                ...targetBehavior.vehicleOptions,
                [name]: value,
            };

            setTargetBehavior({vehicleOptions: newOptions});
        }
    };

    const updateScene = () => {
        if (editor.camera.userData && targetBehavior && thisModelCharacterBehavior.enabled) {
            editor.camera.userData.VehicleOptions = targetBehavior.vehicleOptions;
            app.call(`objectChanged`, app.editor, app.editor.camera);
            app.call(`objectUpdated`, app.editor, app.editor.camera);
        }
    };

    useEffect(() => {
        updateScene();
        if (!!thisModelCharacterBehavior) {
            thisModelCharacterBehavior.vehicleOptions = targetBehavior.vehicleOptions;
        }
    }, [targetBehavior]);

    const updateWheelOptions = () => {
        const selectedObject = editor.selected;
        const wheelOptions: string[] = [];

        if (selectedObject) {
            selectedObject.traverse((child: THREE.Object3D) => {
                if (child instanceof THREE.Mesh && child.name) {
                    wheelOptions.push(child.name);
                }
            });
        }

        setWheelOptions(wheelOptions);
    };

    const updateTrackOptions = () => {
        const topLevelObjects: string[] = [];

        editor.scene.children.forEach((object: THREE.Object3D) => {
            if (object.name) {
                topLevelObjects.push(object.name);
            }
        });

        setTrackOptions(topLevelObjects);
    };

    const updateTrackSurfaceAndBoundaryOptions = () => {
        const selectedTrackModelName = targetBehavior.vehicleOptions.trackModel;

        if (selectedTrackModelName !== "none") {
            const trackModelObject = editor.scene.getObjectByName(selectedTrackModelName);

            if (trackModelObject) {
                const surfaceOptions: string[] = [];
                const boundaryOptions: string[] = [];

                trackModelObject.traverse((child: THREE.Object3D) => {
                    if (child instanceof THREE.Mesh) {
                        surfaceOptions.push(child.name);
                        boundaryOptions.push(child.name);
                    }
                });

                setTrackSurfaceOptions(surfaceOptions);
                setTrackBoundaryOptions(boundaryOptions);
            } else {
                console.warn(`Track Model Object "${selectedTrackModelName}" not found.`);
            }
        }
    };

    useEffect(() => {
        updateWheelOptions();
        updateTrackOptions();
        const handleObjectSelected = () => {
            updateWheelOptions();
            updateTrackOptions();
        };
        app.on(`objectSelected.VehicleOptions`, handleObjectSelected);

        return () => {
            app.on(`objectSelected.VehicleOptions`, null);
        };
    }, []);

    useEffect(() => {
        updateTrackSurfaceAndBoundaryOptions();
    }, [targetBehavior.vehicleOptions.trackModel]);

    return (
        <DefaultWrapper>
            Vehicle Options
            <DefaultWrapper>
                Max Speed
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.vehicleOptions.maxSpeed}
                    setValue={value => handleInputChange(value, "maxSpeed")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Acceleration
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.vehicleOptions.acceleration}
                    setValue={value => handleInputChange(value, "acceleration")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Camera Min Distance
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.vehicleOptions.cameraMINDistance}
                    setValue={value => handleInputChange(value, "cameraMINDistance")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Camera Max Distance
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.vehicleOptions.cameraMAXDistance}
                    setValue={value => handleInputChange(value, "cameraMAXDistance")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Engine Horsepower
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.vehicleOptions.engineHorsepower}
                    setValue={value => handleInputChange(value, "engineHorsepower")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Tire Friction
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.vehicleOptions.tireFriction}
                    setValue={value => handleInputChange(value, "tireFriction")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Brake Force
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.vehicleOptions.brakeForce}
                    setValue={value => handleInputChange(value, "brakeForce")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Left Front Wheel
                <BasicCombobox
                    data={wheelOptions.map(name => ({key: name, value: name}))}
                    value={targetBehavior.vehicleOptions.leftFrontWheel}
                    onChange={value => handleInputChange(value, "leftFrontWheel")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Right Front Wheel
                <BasicCombobox
                    data={wheelOptions.map(name => ({key: name, value: name}))}
                    value={targetBehavior.vehicleOptions.rightFrontWheel}
                    onChange={value => handleInputChange(value, "rightFrontWheel")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Left Rear Wheel
                <BasicCombobox
                    data={wheelOptions.map(name => ({key: name, value: name}))}
                    value={targetBehavior.vehicleOptions.leftRearWheel}
                    onChange={value => handleInputChange(value, "leftRearWheel")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Right Rear Wheel
                <BasicCombobox
                    data={wheelOptions.map(name => ({key: name, value: name}))}
                    value={targetBehavior.vehicleOptions.rightRearWheel}
                    onChange={value => handleInputChange(value, "rightRearWheel")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Steering Wheel
                <BasicCombobox
                    data={wheelOptions.map(name => ({key: name, value: name}))}
                    value={targetBehavior.vehicleOptions.steeringWheel}
                    onChange={value => handleInputChange(value, "steeringWheel")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Track Model
                <BasicCombobox
                    data={trackOptions.map(name => ({key: name, value: name}))}
                    value={targetBehavior.vehicleOptions.trackModel}
                    onChange={value => handleInputChange(value, "trackModel")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Track Surface
                <BasicCombobox
                    data={trackSurfaceOptions.map(name => ({key: name, value: name}))}
                    value={targetBehavior.vehicleOptions.trackSurface}
                    onChange={value => handleInputChange(value, "trackSurface")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Track Boundary
                <BasicCombobox
                    data={trackBoundaryOptions.map(name => ({key: name, value: name}))}
                    value={targetBehavior.vehicleOptions.trackBoundary}
                    onChange={value => handleInputChange(value, "trackBoundary")}
                />
            </DefaultWrapper>
        </DefaultWrapper>
    );
};
