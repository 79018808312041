import {useEffect, useState} from "react";
import global from "../../../../../global";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {CollisionType, IPhysics, Shape} from "../../types/physics";
import {getPhysics} from "../../utils/getPhysics";
import {NumericInputRow} from "../common/NumericInputRow";
import {SelectRow} from "../common/SelectRow";
import {Separator} from "../common/Separator";
import * as THREE from "three";
import Application from "../../../../../Application";
import {NumericInput} from "../../common/NumericInput";
import {InputSymbol} from "../../common/InputSymbol";
import {PanelSectionTitleSecondary} from "../RightPanel.style";
import {Wrapper, Box, BoxInputs, InputWrapper} from "../../common/MovementSection/MovementSection.style";
import {PhysicsUtil} from "../../../../../physics/PhysicsUtil";
import {ConvexGeometry} from "three/examples/jsm/geometries/ConvexGeometry.js";
import {AxisTransformSection} from "../../common/MovementSection/AxisTransformSection";

const collistionTypes = Object.keys(CollisionType).map(key => {
    return {
        key: `${key}`,
        value: CollisionType[key as keyof typeof CollisionType],
    };
});

const getShapes = (isNotMesh: boolean) => {
    const shapes = Object.keys(Shape).map(key => {
        return {
            key: `${key}`,
            value: Shape[key as keyof typeof Shape],
        };
    });

    if (isNotMesh) {
        return shapes.filter(shape => shape.key !== "btSphereShape");
    } else {
        return shapes;
    }
};

interface Props {
    isLocked?: boolean;
}

export const PhysicsSection = ({isLocked}: Props) => {
    const app = global.app as Application;
    const editor = app?.editor;
    const selected = editor?.selected;

    const previewShapeColor = 0x00ff00;
    const previewShapeOpacity = 0.5;

    const cachedConvexHullPoints = new Map();

    let userData: any = undefined;
    let rigidBodyPreviewObjects = [] as any;
    if (selected && !(selected instanceof Array)) {
        userData = selected?.userData;
    }

    const [physicsEnabledState, setPhysicsEnabledState] = useState(userData?.physics?.enabled ?? true);
    const [physics, setPhysics] = useState(getPhysics(userData?.physics));
    const [shapes, setShapes] = useState(getShapes(!(selected instanceof THREE.Mesh)));
    const [shapeScale, setShapeScale] = useState(userData?.physics?.shapeScale ?? {x: 1, y: 1, z: 1});
    const [shapeOffset, setShapeOffset] = useState(userData?.physics?.shapeOffset ?? {x: 0, y: 0, z: 0});

    const clearScenePreviewObjects = () => {
        if (!rigidBodyPreviewObjects) {
            return;
        }

        const sceneHelpers = app?.editor?.sceneHelpers;
        if (sceneHelpers) {
            while (rigidBodyPreviewObjects.length > 0) {
                const object = rigidBodyPreviewObjects.pop();
                sceneHelpers.remove(object);
            }
        }
    };

    const handlePhysicsChange = (value: number | string | boolean, name: keyof IPhysics) => {
        if (
            name === "ctype" &&
            value !== CollisionType.Dynamic &&
            selected &&
            !(selected instanceof Array) &&
            selected?.userData.physics.mass > 0
        ) {
            selected.userData.physics = {
                ...selected.userData.physics,
                [name]: value,
                mass: 0,
            };
            app?.call(`objectChanged`, selected, selected);
            return;
        }

        if (selected && !(selected instanceof Array) && selected?.userData.physics) {
            if (name === "ctype" && value === CollisionType.Dynamic) {
                selected.userData.physics = {
                    ...selected.userData.physics,
                    [name]: value,
                    mass: 1,
                };
            } else {
                selected.userData.physics = {
                    ...selected.userData.physics,
                    [name]: value,
                };
            }
        }

        app?.call(`objectChanged`, selected, selected);
    };

    const handleNestedPhysicsChange = (value: number | string, name: keyof IPhysics, sub: string) => {
        if (selected && !(selected instanceof Array)) {
            const nestedPhysics = selected.userData.physics[name] as any;
            selected.userData.physics = {
                ...selected.userData.physics,
                [name]: {
                    ...nestedPhysics,
                    [sub]: value,
                },
            };
            app?.call(`objectChanged`, selected, selected);
            return;
        }
    };

    const updatePhysics = () => {
        clearScenePreviewObjects();

        const selected = app.editor?.selected;
        if (selected && !(selected instanceof Array)) {
            setPhysics(getPhysics(selected?.userData.physics));
            setShapes(getShapes(!(selected instanceof THREE.Mesh)));

            const sceneHelpers = app?.editor?.sceneHelpers;

            if (sceneHelpers && selected?.userData.physics?.enable_preview) {
                const object = selected;

                if (object.userData.physics && object.userData.physics.enabled) {
                    const physicsData = object.userData.physics;

                    const prevPosition = object.position.clone();
                    const prevScale = object.scale.clone();

                    if (physicsData.shapeScale) {
                        object.scale.multiply(physicsData.shapeScale);
                    }

                    if (physicsData.shapeOffset) {
                        object.position.add(physicsData.shapeOffset);
                    }

                    object.updateMatrixWorld(true);

                    const shape = object.userData.physics.shape;
                    let previewObject: THREE.Object3D | THREE.Object3D[] | null = null;
                    switch (shape) {
                        case "btBoxShape":
                            previewObject = getBoxPreview(object);
                            break;
                        case "btSphereShape":
                            previewObject = getSpherePreview(object);
                            break;
                        case "btCapsuleShape":
                            previewObject = getCapsulePreview(object);
                            break;
                        case "btConcaveHullShape":
                            previewObject = getConcaveHullPreview(object);
                            break;
                        case "btConvexHullShape":
                            previewObject = getConvexHullPreview(object);
                            break;
                        default:
                            break;
                    }

                    object.position.copy(prevPosition);
                    object.scale.copy(prevScale);

                    if (!previewObject) {
                        return;
                    }

                    const previewObjects = previewObject instanceof Array ? previewObject : [previewObject];

                    previewObjects.forEach(obj => {
                        rigidBodyPreviewObjects.push(obj);
                        sceneHelpers.add(obj);
                    });
                }
            }
        }
    };

    const getBoxPreview = (object: THREE.Object3D) => {
        const box = PhysicsUtil.calculateBoundingBox(object);

        const anchorOffset = PhysicsUtil.calculateAnchorOffset(object, box);

        const boxSize = box.getSize(new THREE.Vector3());

        const currentPosition = PhysicsUtil.calculatePhysicsPositionFromObject(
            object.position,
            object.quaternion,
            anchorOffset,
        );

        const boxGeometry = new THREE.BoxGeometry(boxSize.x, boxSize.y, boxSize.z);
        const boxMaterial = new THREE.MeshBasicMaterial({
            color: previewShapeColor,
            transparent: true,
            opacity: previewShapeOpacity,
        });
        const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);

        boxMesh.position.copy(currentPosition);
        boxMesh.quaternion.copy(object.quaternion);

        boxMesh.name = "rigidBodyPreview_" + boxMesh.name;
        return boxMesh;
    };

    const getSpherePreview = (object: THREE.Object3D) => {
        const geometry = (object as THREE.Mesh).geometry;

        if (!geometry) {
            return null;
        }

        geometry.computeBoundingSphere();
        const sphereRadius = geometry.boundingSphere?.radius ?? 0;

        if (sphereRadius <= 0) {
            return null;
        }

        const sphereGeometry = new THREE.SphereGeometry(sphereRadius);
        const sphereMaterial = new THREE.MeshBasicMaterial({
            color: previewShapeColor,
            transparent: true,
            opacity: previewShapeOpacity,
        });
        const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);

        sphereMesh.position.copy(object.position);

        sphereMesh.name = "rigidBodyPreview_" + sphereMesh.name;
        return sphereMesh;
    };

    const getConcaveHullPreview = (object: THREE.Object3D) => {
        const prevPosition = object.position.clone();
        const prevRotation = object.rotation.clone();
        const prevScale = object.scale.clone();

        object.position.set(0, 0, 0);
        object.rotation.set(0, 0, 0);
        object.scale.set(1, 1, 1);

        object.updateMatrixWorld(true);

        // this is hacky, but it's the same way we calculate the concave hull for physics
        const geometrySimplified = PhysicsUtil.getSimplifiedGeometry(object, 0);

        object.position.copy(prevPosition);
        object.rotation.copy(prevRotation);
        object.scale.copy(prevScale);
        object.updateMatrixWorld(true);

        if (geometrySimplified.length === 0) {
            return null;
        }

        const meshes: THREE.Mesh[] = [];
        geometrySimplified.forEach((geometry: THREE.BufferGeometry) => {
            const concaveMaterial = new THREE.MeshBasicMaterial({
                color: previewShapeColor,
                transparent: true,
                opacity: previewShapeOpacity,
                side: THREE.DoubleSide, // Disable backface culling
            });
            const concaveMesh = new THREE.Mesh(geometry, concaveMaterial);
            concaveMesh.name = "rigidBodyPreview_" + concaveMesh.name;
            concaveMesh.position.add(object.position);
            concaveMesh.quaternion.multiply(object.quaternion);
            concaveMesh.scale.multiply(object.scale);
            meshes.push(concaveMesh);
        });

        return meshes;
    };

    const getConvexHullPreview = (object: THREE.Object3D) => {
        let hullsPoints = cachedConvexHullPoints.get(object.uuid);

        // if points are not cached, calculate them
        if (!hullsPoints) {
            const prevPosition = object.position.clone();
            const prevRotation = object.rotation.clone();
            const prevScale = object.scale.clone();
            object.position.set(0, 0, 0);
            object.rotation.set(0, 0, 0);
            object.scale.set(1, 1, 1);

            object.updateMatrixWorld(true);

            hullsPoints = PhysicsUtil.getConvexHullVertices(object);
            cachedConvexHullPoints.set(object.uuid, hullsPoints);

            object.position.copy(prevPosition);
            object.rotation.copy(prevRotation);
            object.scale.copy(prevScale);
        }

        const convexVerts: THREE.Vector3[] = [];
        for (let i = 0; i < hullsPoints.length; i += 3) {
            convexVerts.push(new THREE.Vector3(hullsPoints[i], hullsPoints[i + 1], hullsPoints[i + 2]));
        }

        const convexGeometry = new ConvexGeometry(convexVerts);
        const convexMaterial = new THREE.MeshBasicMaterial({
            color: previewShapeColor,
            transparent: true,
            opacity: previewShapeOpacity,
        });
        const convexMesh = new THREE.Mesh(convexGeometry, convexMaterial);

        convexMesh.position.copy(object.position);
        convexMesh.quaternion.copy(object.quaternion);
        convexMesh.scale.copy(object.scale);

        convexMesh.name = "rigidBodyPreview_" + convexMesh.name;

        return convexMesh;
    };

    const getCapsulePreview = (object: THREE.Object3D) => {
        const cBox = PhysicsUtil.calculateBoundingBox(object);
        const anchorOffset = PhysicsUtil.calculateAnchorOffset(object, cBox);
        const cBoxSize = cBox.getSize(new THREE.Vector3());

        const currentPosition = PhysicsUtil.calculatePhysicsPositionFromObject(
            object.position,
            object.quaternion,
            anchorOffset,
        );

        const cRadius = Math.max(cBoxSize.x, cBoxSize.z) / 2;
        let cHeight = cBoxSize.y - cRadius * 2;

        if (cHeight < 0) {
            cHeight = 0;
        }

        if (cRadius <= 0) {
            return null;
        }

        const capsuleGeometry = new THREE.CapsuleGeometry(cRadius, cHeight, 4, 12);
        const capsuleMaterial = new THREE.MeshBasicMaterial({
            color: previewShapeColor,
            transparent: true,
            opacity: previewShapeOpacity,
        });

        const capsuleMesh = new THREE.Mesh(capsuleGeometry, capsuleMaterial);

        capsuleMesh.position.copy(currentPosition);
        capsuleMesh.quaternion.copy(object.quaternion);

        capsuleMesh.name = "rigidBodyPreview_" + capsuleMesh.name;
        return capsuleMesh;
    };

    useEffect(() => {
        app?.on("objectChanged.PhysicsSection", updatePhysics);
        app?.on("objectSelected.PhysicsSection", updatePhysics);
        app?.on("objectArraySelected.PhysicsSection", updatePhysics);

        return () => {
            clearScenePreviewObjects();
            cachedConvexHullPoints.clear();
            app?.on("objectChanged.PhysicsSection", null);
            app?.on("objectSelected.PhysicsSection", null);
            app?.on("objectArraySelected.PhysicsSection", null);
        };
    }, []);

    useEffect(() => {
        if (physicsEnabledState !== undefined) {
            if (selected && !(selected instanceof Array) && selected?.userData) {
                if (!selected?.userData.physics) {
                    selected.userData.physics = {};
                }
                selected.userData.physics.enabled = physicsEnabledState;
                app?.call(`objectChanged`, selected, selected);
            }
        }
    }, [physicsEnabledState]);

    useEffect(() => {
        if (selected && !(selected instanceof Array) && selected?.userData) {
            if (!selected?.userData.physics) {
                selected.userData.physics = {};
            }
            selected.userData.physics.shapeScale = shapeScale;
            app?.call(`objectChanged`, selected, selected);
        }
    }, [shapeScale]);

    useEffect(() => {
        if (selected && !(selected instanceof Array) && selected?.userData) {
            if (!selected?.userData.physics) {
                selected.userData.physics = {};
            }
            selected.userData.physics.shapeOffset = shapeOffset;
            app?.call(`objectChanged`, selected, selected);
        }
    }, [shapeOffset]);

    return (
        <>
            <PanelCheckbox
                text="Physics"
                checked={!!physicsEnabledState}
                onChange={e => {
                    const isChecked = !!e.target.checked;
                    handlePhysicsChange(isChecked ? CollisionType.Static : CollisionType.Dynamic, "ctype");
                    setPhysicsEnabledState(isChecked);
                }}
                isLocked={isLocked}
            />
            <Separator invisible />
            <PanelCheckbox
                text="Shape Preview"
                checked={!!physics.enable_preview}
                onChange={e => {
                    handlePhysicsChange(!!e.target.checked, "enable_preview");
                }}
                isLocked={isLocked}
            />
            <Separator invisible />
            <AxisTransformSection isLocked={false} value={shapeScale} setValue={setShapeScale} name="Shape Scale" />
            <Separator invisible />
            <AxisTransformSection isLocked={false} value={shapeOffset} setValue={setShapeOffset} name="Shape Offset" />
            <Separator invisible />
            {physicsEnabledState && (
                <>
                    <SelectRow
                        label="Shape"
                        value={shapes.find(item => item.key === physics.shape)}
                        data={shapes}
                        onChange={item => (!isLocked ? handlePhysicsChange(item.key, "shape") : undefined)}
                    />
                    <Separator invisible />
                    <SelectRow
                        label="Physics type"
                        data={collistionTypes}
                        value={collistionTypes.find(item => item.value === physics.ctype)}
                        onChange={item => (!isLocked ? handlePhysicsChange(item.value, "ctype") : undefined)}
                    />
                    <Separator invisible />
                    {physics.ctype !== CollisionType.Static && (
                        <>
                            {physics.ctype !== CollisionType.Kinematic && (
                                <>
                                    <NumericInputRow
                                        label="Mass"
                                        value={physics.mass}
                                        setValue={value => handlePhysicsChange(value, "mass")}
                                        disabled={isLocked}
                                    />
                                    <Separator invisible />{" "}
                                </>
                            )}
                            <Wrapper>
                                <Box>
                                    <PanelSectionTitleSecondary style={{marginRight: "auto"}}>
                                        Inertia
                                    </PanelSectionTitleSecondary>
                                    <BoxInputs>
                                        {["x", "y", "z"].map(axis => (
                                            <InputWrapper key={axis}>
                                                <InputSymbol
                                                    isLocked={!!isLocked}
                                                    symbol={axis.toUpperCase()}
                                                    value={physics.inertia[axis as keyof typeof physics.inertia]}
                                                    setValue={value =>
                                                        handleNestedPhysicsChange(value, "inertia", axis)
                                                    }
                                                />
                                                <NumericInput
                                                    value={physics.inertia[axis as keyof typeof physics.inertia]}
                                                    setValue={value =>
                                                        handleNestedPhysicsChange(value, "inertia", axis)
                                                    }
                                                    className="dark-input"
                                                    disabled={isLocked}
                                                />
                                            </InputWrapper>
                                        ))}
                                    </BoxInputs>
                                </Box>
                            </Wrapper>
                        </>
                    )}
                </>
            )}
        </>
    );
};
