import {useCallback, useEffect, useRef, useState} from "react";
import * as THREE from "three";

import AnimationPanel from "./panels/AnimationPanel";
import {useAnimationContext, useAppGlobalContext} from "../../../../context";
import global from "../../../../global";
import {BorderedWrapper, Container, PanelContentWrapper} from "./RightPanel.style";
import {TopMenu} from "./common/TopMenu/TopMenu";
import {GameSettings} from "./panels/GameSettings/GameSettings";
import {StyledButton} from "../common/StyledButton";
import {Panel} from "./panels/Panels/Panels";
import {PANEL_TYPES, ROWS} from "./helpers";
import {BasicPropertiesSection} from "./sections/BasicPropertiesSection";
import {Separator} from "./common/Separator";
import LightingContextProvider from "../../../../context/LightingContext";
import {MainButtons} from "./MainButtons";
import {ObjectBehaviorsTab} from "./tabs/ObjectBehaviors";
import {PointsPanel} from "./panels/PointsPanel";
import {VolumePanel} from "./panels/VolumePanel";
import {PrimitivePanel} from "./panels/PrimitivePanel/PrimitivePanel";
import {BillboardPanel} from "./panels/BillboardPanel";

export enum TABS {
    OBJECT_3D = "Properties",
    BEHAVIORS = "Behaviors",
}

type Props = {
    showModelAnimationCombiner: () => void;
    showLoading: (show: boolean) => void;
    showAvatarCreator: () => void;
    openUIPanel: () => void;
};

const RightPanel = ({showModelAnimationCombiner, showLoading, showAvatarCreator, openUIPanel}: Props) => {
    const {animationsState} = useAnimationContext();
    const {
        isCheckPointPanelOpen,
        isSpawnPointPanelOpen,
        isGameSettingsPanelOpen,
        isVolumePanelOpen,
        setIsSpawnPointPanelOpen,
        setIsCheckPointPanelOpen,
        setIsVolumePanelOpen,
        setIsBillboardPanelOpen,
        isBillboardPanelOpen,
    } = useAppGlobalContext();
    const [activeTab, setActiveTab] = useState(TABS.OBJECT_3D);
    const [isScene, setIsScene] = useState(false);
    const [isPrimitive, setIsPrimitive] = useState(false);
    // state for rows
    const [expanded, setExpanded] = useState<PANEL_TYPES | null>(null);
    const [showPhysics, setShowPhysics] = useState(false);
    const [showRigidBody, setShowRigidBody] = useState(false);
    const [showModelLighting, setShowModelLighting] = useState(false);
    const [showPropAnimation, setShowPropAnimation] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [showCollision, setShowCollision] = useState(false);
    const [hideAll, setHideAll] = useState(false);
    const [showTexture, setShowTexture] = useState(false);
    const [texture, setTexture] = useState(null);
    const [showMaterial, setShowMaterial] = useState(false);
    const [color, setColor] = useState<string | null>(null);
    const app = global.app;
    const editor = app?.editor;
    const selected = editor?.selected;
    const selectedRef = useRef(selected);

    useEffect(() => {
        selectedRef.current = selected;
    }, [selected]);

    const hideRows = () => {
        setShowPhysics(false);
        setShowRigidBody(false);
        setShowModelLighting(false);
        setShowTexture(false);
        setShowPropAnimation(false);
        setShowCollision(false);
        setShowMaterial(false);
    };

    const handleUpdate = useCallback(() => {
        setIsCheckPointPanelOpen(app?.editor.selected?.userData.isCheckPoint);
        setIsSpawnPointPanelOpen(app?.editor.selected?.userData.isSpawnPoint);
        setIsVolumePanelOpen(app?.editor.selected?.userData.isSceneVolume);
        setIsBillboardPanelOpen(app?.editor.selected?.userData.isBillboard);
        const isSelectedScene =
            selectedRef.current && selectedRef.current.type === "Scene" && selectedRef.current instanceof THREE.Scene;
        const is3dObject =
            selectedRef.current &&
            selectedRef.current instanceof THREE.Object3D &&
            (selectedRef.current.type === "Object3D" || selectedRef.current.isObject3D);
        const isGroup =
            selectedRef.current &&
            selectedRef.current instanceof THREE.Group &&
            (selectedRef.current.type === "Group" || selectedRef.current.isGroup);
        const isLight = selectedRef.current instanceof THREE.Light;
        const isPrimitiveSelected = !!selectedRef.current?.geometry?.type;
        setIsPrimitive(isPrimitiveSelected);

        setIsScene(isSelectedScene);
        setHideAll(!!isSelectedScene);

        if (
            isLight ||
            isSelectedScene ||
            !selectedRef.current ||
            !(
                selectedRef.current instanceof THREE.Mesh ||
                selectedRef.current instanceof THREE.SkinnedMesh ||
                isGroup ||
                is3dObject
            )
        ) {
            hideRows();
            setTexture(null);
            setColor(null);
            setShowButtons(false);
            return;
        }
        if (isGroup || is3dObject) {
            setShowPhysics(true);
            setShowRigidBody(selectedRef.current?.userData?.physics?.enabled);
            setShowModelLighting(true);
            setShowPropAnimation(true);
            setShowCollision(true);
            setShowTexture(false);
            setTexture(null);
            setShowMaterial(true);
            setColor(null);
            setShowButtons(true);
        }

        if (selectedRef.current instanceof THREE.Mesh || selectedRef.current instanceof THREE.SkinnedMesh) {
            setShowPhysics(true);
            setShowRigidBody(selectedRef.current?.userData?.physics?.enabled);
            setShowModelLighting(true);
            setShowTexture(true);
            setShowPropAnimation(true);
            setShowCollision(true);
            setTexture(selectedRef.current.material.map);
            setShowMaterial(true);
            const colorHex = `#${selectedRef.current.material.color?.getHexString()}`;
            setColor(colorHex);
            setShowButtons(true);
        }

        if (isPrimitiveSelected) {
            setShowPropAnimation(false);
            setShowButtons(false);
        }
    }, [selected]);

    useEffect(() => {
        if (selected) {
            handleUpdate();
        }
    }, [selected]);

    useEffect(() => {
        if (editor && app) {
            app.on(`objectSelected.RightPanel`, handleUpdate);
            app.on(`objectChanged.RightPanel`, handleUpdate);
        }

        return () => {
            app?.on(`objectSelected.RightPanel`, handleUpdate);
            app?.on(`objectChanged.RightPanel`, handleUpdate);
        };
    }, [editor]);

    useEffect(() => {
        if (isCheckPointPanelOpen || isSpawnPointPanelOpen || isVolumePanelOpen || isBillboardPanelOpen) {
            setActiveTab(TABS.OBJECT_3D);
        }
    }, [isCheckPointPanelOpen, isSpawnPointPanelOpen, isVolumePanelOpen, isBillboardPanelOpen]);

    const shouldRenderBasicTabs = () => {
        return (
            !isGameSettingsPanelOpen &&
            !isCheckPointPanelOpen &&
            !isSpawnPointPanelOpen &&
            !isVolumePanelOpen &&
            !isBillboardPanelOpen
        );
    };

    return (
        <Container>
            <TopMenu showLoading={showLoading} showAvatarCreator={showAvatarCreator} />
            {!isGameSettingsPanelOpen && !isScene && (
                <BorderedWrapper height="48px">
                    <StyledButton isActive={activeTab === TABS.OBJECT_3D} onClick={() => setActiveTab(TABS.OBJECT_3D)}>
                        {TABS.OBJECT_3D}
                    </StyledButton>
                    {!isCheckPointPanelOpen &&
                        !isSpawnPointPanelOpen &&
                        !isVolumePanelOpen &&
                        !isBillboardPanelOpen && (
                            <StyledButton
                                isActive={activeTab === TABS.BEHAVIORS}
                                onClick={() => setActiveTab(TABS.BEHAVIORS)}>
                                {TABS.BEHAVIORS}
                            </StyledButton>
                        )}
                </BorderedWrapper>
            )}
            <PanelContentWrapper className="hidden-scroll" $isBehaviorOpen={activeTab === TABS.BEHAVIORS}>
                <GameSettings openUIPanel={openUIPanel} />
                {(isCheckPointPanelOpen || isSpawnPointPanelOpen) && <PointsPanel />}
                {isVolumePanelOpen && <VolumePanel />}
                {isBillboardPanelOpen && <BillboardPanel />}
                {animationsState.selectedAnimation ? (
                    <AnimationPanel />
                ) : (
                    <>
                        {activeTab === TABS.OBJECT_3D && (
                            <LightingContextProvider>
                                {shouldRenderBasicTabs() && (
                                    <>
                                        <BasicPropertiesSection />
                                        <Separator margin="12px 0 0" />

                                        {isPrimitive ? (
                                            <PrimitivePanel texture={texture} color={color} setColor={setColor} />
                                        ) : (
                                            ROWS.map(({name, type}, index) => (
                                                <Panel
                                                    key={type + index}
                                                    panelType={type}
                                                    label={name}
                                                    texture={texture}
                                                    color={color}
                                                    setColor={setColor}
                                                    showCollision={showCollision}
                                                    showModelLighting={showModelLighting}
                                                    showPhysics={showPhysics}
                                                    showRigidBody={showRigidBody}
                                                    showPropAnimation={showPropAnimation}
                                                    showTexture={showTexture}
                                                    hideAll={hideAll}
                                                    expanded={expanded}
                                                    setExpanded={setExpanded}
                                                />
                                            ))
                                        )}
                                        {showButtons && (
                                            <MainButtons showModelAnimationCombiner={showModelAnimationCombiner} />
                                        )}
                                    </>
                                )}
                            </LightingContextProvider>
                        )}
                        {activeTab === TABS.BEHAVIORS && <ObjectBehaviorsTab />}
                    </>
                )}
            </PanelContentWrapper>
        </Container>
    );
};

export default RightPanel;
