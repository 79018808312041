import {StyledTextarea} from "../../common/StyledTextarea";
import {TextInput} from "../../common/TextInput";
import {StyledRowWrapper} from "./StyledRowWrapper";

interface Props {
    width?: string;
    height?: string;
    label: string;
    value: string;
    setValue: (value: string) => void;
    margin?: string;
    type?: "input" | "textarea";
    isColumn?: boolean;
    color?: string;
    placeholder?: string;
}

export const TextInputRow = ({
    label,
    value,
    setValue,
    width,
    height,
    margin,
    type,
    isColumn,
    color,
    placeholder,
}: Props) => {
    return (
        <StyledRowWrapper $margin={margin} $isColumn={isColumn} $color={color}>
            <span className="text">{label}</span>
            {type === "textarea" ? (
                <StyledTextarea
                    width={width}
                    value={value}
                    setValue={setValue}
                    height={height}
                    placeholder={placeholder}
                />
            ) : (
                <TextInput width={width} value={value} setValue={setValue} height={height} placeholder={placeholder} />
            )}
        </StyledRowWrapper>
    );
};
