import styled from "styled-components";
import plusIcon from "../../v2/icons/plus-icon.svg";
import {flexCenter} from "../../../../assets/style";

const Wrapper = styled.div`
    width: 32px;
    height: 32px;
    ${flexCenter};
    border-top: 1px solid transparent;
    transition: all 0.2s;
    border-radius: 8px;
    cursor: pointer;
    background: var(--theme-container-divider);

    &:hover {
        background-color: var(--theme-container-main-blue);
        border-top: 1px solid var(--theme-container-selected-border);
        img {
            filter: brightness(2);
        }
    }
`;

type Props = {
    onClick: () => void;
};

export const UploadButton = ({onClick}: Props) => {
    return (
        <Wrapper onClick={onClick}>
            <img src={plusIcon} alt="upload-icon-small" />
        </Wrapper>
    );
};
