import {useCallback, useEffect, useState} from "react";
import * as THREE from "three";
import AddObjectCommand from "../../../../../../../command/AddObjectCommand";

import sphereIcon from "../../../../icons/assetsTab/primitives/sphere.svg";
import cubeIcon from "../../../../icons/assetsTab/primitives/cube.svg";
import triangleIcon from "../../../../icons/assetsTab/primitives/triangle.svg";
import cylinderIcon from "../../../../icons/assetsTab/primitives/cylinder.svg";
import planeIcon from "../../../../icons/assetsTab/primitives/plane.svg";

//@ts-ignore
import Box from "../../../../../../../object/geometry/Box.js";
//@ts-ignore
import Plane from "../../../../../../../object/geometry/Plane.js";
//@ts-ignore
import Cylinder from "../../../../../../../object/geometry/Cylinder.js";
//@ts-ignore
import Sphere from "../../../../../../../object/geometry/Sphere.js";
//@ts-ignore
import Triangle from "../../../../../../../object/geometry/Triangle.js";
import {IconsFlexContainer} from "../../../../common/IconsFlexContainer";
import global from "../../../../../../../global";
import {TitleContainer, TopContainer} from "../AssetsTab.style";
import {SearchInput} from "../../../../../../../editor/assets/v2/common/SearchInput";
import {generateUniqueName} from "../../../../../../../v2/pages/services";

const generateRandomColor = () => {
    // Losujemy wartości dla każdego z kanałów koloru (R, G, B)
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Konwertujemy wartości na format heksadecymalny
    const redHex = red.toString(16).padStart(2, "0");
    const greenHex = green.toString(16).padStart(2, "0");
    const blueHex = blue.toString(16).padStart(2, "0");

    // Składamy kolor w formacie heksadecymalnym
    const randomColor = `#${redHex}${greenHex}${blueHex}`;

    return randomColor;
};

export enum PRIMITIVES_NAME {
    SPHERE = "Sphere",
    CUBE = "Cube",
    TRIANGLE = "Triangle",
    CYLINDER = "Cylinder",
    PLANE = "Plane",
}

const OPTIONS = [
    {icon: sphereIcon, text: "Sphere", name: PRIMITIVES_NAME.SPHERE},
    {icon: cubeIcon, text: "Cube", name: PRIMITIVES_NAME.CUBE},
    {icon: triangleIcon, text: "Triangle", name: PRIMITIVES_NAME.TRIANGLE},
    {icon: cylinderIcon, text: "Cylinder", name: PRIMITIVES_NAME.CYLINDER},
    {icon: planeIcon, text: "Plane", name: PRIMITIVES_NAME.PLANE},
];

export const PrimitivesTab = () => {
    const [search, setSearch] = useState("");
    const [list, setList] = useState<any[]>(OPTIONS);
    const [existingNames, setExistingNames] = useState<Set<string>>(new Set());
    const app = (global as any).app;
    const editor = app?.editor;
    const size = 2;

    const handleAddPlane = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const material = new THREE.MeshStandardMaterial({
                    color: generateRandomColor(),
                });
                const geometry = new THREE.BoxGeometry(20, 0.001, 20);
                const plane = new Box(geometry, material);
                const uniqueName = generateUniqueName("Plane", prevNames);
                plane.name = uniqueName;
                plane.userData.isPlane = true;

                addObjectToSceneInCameraView(plane);
                callback && callback(plane);

                const updatedNames = new Set(prevNames);
                updatedNames.add(uniqueName);
                return updatedNames;
            });
        },
        [editor],
    );

    const handleAddBox = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const material = new THREE.MeshStandardMaterial({
                    color: generateRandomColor(),
                });
                const geometry = new THREE.BoxGeometry(size, size, size);
                const box = new Box(geometry, material);
                const uniqueName = generateUniqueName("Cube", prevNames);
                box.name = uniqueName;

                addObjectToSceneInCameraView(box);
                callback && callback(box);

                const updatedNames = new Set(prevNames);
                updatedNames.add(uniqueName);
                return updatedNames;
            });
        },
        [editor],
    );

    const handleAddCylinder = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const material = new THREE.MeshStandardMaterial({
                    color: generateRandomColor(),
                });

                const geometry = new THREE.CylinderGeometry(size / 2, size / 2, size, 32);

                const cylinder = new Cylinder(geometry, material);
                const uniqueName = generateUniqueName("Cylinder", prevNames);
                cylinder.name = uniqueName;

                addObjectToSceneInCameraView(cylinder);
                callback && callback(cylinder);

                const updatedNames = new Set(prevNames);
                updatedNames.add(uniqueName);
                return updatedNames;
            });
        },
        [editor],
    );

    const handleAddSphere = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const material = new THREE.MeshStandardMaterial({
                    color: generateRandomColor(),
                });
                const geometry = new THREE.SphereGeometry(size / 2, 32, 32);
                const sphere = new Sphere(geometry, material);
                const uniqueName = generateUniqueName("Sphere", prevNames);
                sphere.name = uniqueName;

                addObjectToSceneInCameraView(sphere);
                callback && callback(sphere);

                const updatedNames = new Set(prevNames);
                updatedNames.add(uniqueName);
                return updatedNames;
            });
        },
        [editor],
    );

    const handleAddTriangle = useCallback(
        (callback?: (obj: any) => void) => {
            setExistingNames(prevNames => {
                const material = new THREE.MeshStandardMaterial({
                    color: generateRandomColor(),
                });

                const geometry = new THREE.TetrahedronGeometry(size, 0);
                const triangle = new Triangle(geometry, material);
                const uniqueName = generateUniqueName("Triangle", prevNames);
                triangle.name = uniqueName;

                addObjectToSceneInCameraView(triangle);
                callback && callback(triangle);

                const updatedNames = new Set(prevNames);
                updatedNames.add(uniqueName);
                return updatedNames;
            });
        },
        [editor],
    );

    const handleClick = (name: PRIMITIVES_NAME, callback?: any) => {
        switch (name) {
            case PRIMITIVES_NAME.SPHERE:
                handleAddSphere(callback);
                break;
            case PRIMITIVES_NAME.CUBE:
                handleAddBox(callback);
                break;
            case PRIMITIVES_NAME.TRIANGLE:
                handleAddTriangle(callback);
                break;
            case PRIMITIVES_NAME.CYLINDER:
                handleAddCylinder(callback);
                break;
            case PRIMITIVES_NAME.PLANE:
                handleAddPlane(callback);
                break;

            default:
                break;
        }
    };

    const addObjectToSceneInCameraView = (object: THREE.Object3D) => {
        app.editor.moveObjectToCameraClosestPoint(object);
        editor.execute(new (AddObjectCommand as any)(object));
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, name: string) => {
        e.dataTransfer.setData("asset-id", name);
        e.dataTransfer.setData("asset-type", "primitive");
    };

    useEffect(() => {
        if (!search) {
            setList(OPTIONS);
            return;
        } else {
            setList(
                OPTIONS?.filter(n => {
                    return n.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                }),
            );
        }
    }, [search]);

    useEffect(() => {
        app.on(`dragEnd.PrimitivesTab`, (type: string, name: string, position: any) => {
            if (type === "primitive") {
                handleClick(name as PRIMITIVES_NAME, (obj: any) => {
                    obj.position.copy(position);
                });
            }
        });
        return () => {
            app.on(`dragEnd.PrimitivesTab`, null);
        };
    }, []);

    return (
        <>
            <TopContainer>
                <SearchInput width="224px" placeholder="Search Primitives" onChange={setSearch} value={search} />
            </TopContainer>
            <TitleContainer>Primitives</TitleContainer>
            <IconsFlexContainer
                list={list}
                onSelectItem={handleClick}
                disableSelection
                draggable
                onDragStart={handleDragStart}
            />
        </>
    );
};
