import {useEffect, useRef, useState} from "react";
import arrowUp from "./icons/arrow-up.svg";
import xIcon from "./icons/x-mark.svg";
import {HexColorPicker} from "react-colorful";
import {ColorPickerWrapper, FoldingContainer, InputsWrapper, Label} from "./StyledColorPicker.style";
import {NumericInput} from "../NumericInput";
import {TextInput} from "../TextInput";
import {hexToRgb, rgbToHex, isHexColor} from "../../../../../v2/pages/services";
import {ColorSuggestions} from "./ColorSuggestions/ColorSuggestions";

interface Props {
    color: string;
    setColor: (value: string) => void;
    setAlpha?: (value: number) => void;
    hide: () => void;
    hideAlpha?: boolean;
    className?: string;
}

const getDefaultRgbColor = (color: string) => {
    if (color.startsWith("#")) {
        return hexToRgb(color);
    } else {
        return hexToRgb(rgbToHex(color));
    }
};

export const getDefaultHexColor = (color: string) => {
    if (color.startsWith("#")) {
        return color;
    } else {
        return rgbToHex(color);
    }
};

export default function StyledColorPicker({color, setColor, hide, hideAlpha, setAlpha, className}: Props) {
    const [expanded, setExpanded] = useState(false);
    const [RGB, setRGB] = useState(getDefaultRgbColor(color));
    const [hexInput, setHexInput] = useState(getDefaultHexColor(color));
    const colorPickerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
                const inputs = colorPickerRef.current?.querySelectorAll("input");
                if (inputs.length > 0) {
                    inputs.forEach(input => {
                        input.blur();
                    });
                }
                hide();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (RGB) {
            const hex = rgbToHex(`rgb(${RGB.r}, ${RGB.g}, ${RGB.b})`);
            setColor(hex);
            setHexInput(hex);
        }
    }, [RGB]);

    return (
        <ColorPickerWrapper ref={colorPickerRef} className={`${className} colorPickerWrapper`}>
            <Label>
                Swatches
                <button className="reset-css" onClick={hide}>
                    <img src={xIcon} alt="close" />
                </button>
            </Label>
            <ColorSuggestions setColor={setColor} currentColor={color} setRGB={setRGB} />
            <Label $pointer onClick={() => setExpanded(!expanded)}>
                Color Picker
                <img src={arrowUp} alt="expand" style={expanded ? {transform: "rotate(180deg)"} : undefined} />
            </Label>
            <FoldingContainer $expanded={expanded}>
                <HexColorPicker
                    className="colorPicker"
                    color={rgbToHex(`rgb(${RGB.r}, ${RGB.g}, ${RGB.b})`)}
                    onChange={value => {
                        const result = hexToRgb(value);
                        setRGB(result);
                    }}
                />
                <InputsWrapper>
                    <div className="singleInputWrapper">
                        <TextInput
                            className="hexInput"
                            value={hexInput}
                            setValue={value => {
                                setHexInput(value);
                                if (isHexColor(value)) {
                                    const result = hexToRgb(value);
                                    setRGB(result);
                                }
                            }}
                        />
                        <div className="label">Hex</div>
                    </div>
                    <div className="singleInputWrapper">
                        <NumericInput
                            className="numericInput"
                            value={RGB.r}
                            setValue={value => {
                                setRGB({...RGB, r: value});
                            }}
                        />
                        <div className="label">R</div>
                    </div>
                    <div className="singleInputWrapper">
                        <NumericInput
                            className="numericInput"
                            value={RGB.g}
                            setValue={value => {
                                setRGB({...RGB, g: value});
                            }}
                        />
                        <div className="label">G</div>
                    </div>
                    <div className="singleInputWrapper">
                        <NumericInput
                            className="numericInput"
                            value={RGB.b}
                            setValue={value => {
                                setRGB({...RGB, b: value});
                            }}
                        />
                        <div className="label">B</div>
                    </div>
                </InputsWrapper>
            </FoldingContainer>
        </ColorPickerWrapper>
    );
}
