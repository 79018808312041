import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import {OBJECT_TYPES, SpawnPointAssetBehaviorInterface} from "../../types/editor";
import SpawnBehaviorAssetUpdater from "./SpawnPointBehaviorAssetUpdater";

class SpawnPointBehaviorAssetConverter extends BaseBehaviorConverter<SpawnPointAssetBehaviorInterface> {
    public static DEFAULT = new SpawnPointBehaviorAssetConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: SpawnPointAssetBehaviorInterface): BehaviorUpdater {
        return new SpawnBehaviorAssetUpdater(target);
    }

    reset(): void {}

    getBehavior(target: THREE.Object3D, id: string): SpawnPointAssetBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as SpawnPointAssetBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have Spawn Point behavior: ", target);
            target.userData.behaviors = target.userData.behaviors
                ? target.userData.behaviors.push(this.getDefaultBehavior(id))
                : [this.getDefaultBehavior(id)];
            behavior = super.findBehavior(target, id) as SpawnPointAssetBehaviorInterface;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): SpawnPointAssetBehaviorInterface {
        return {
            type: OBJECT_TYPES.SPAWN_POINT,
            position: {x: 0, y: 0, z: 0},
            enabled: true,
            id,
        };
    }
}

export default SpawnPointBehaviorAssetConverter;
