/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseLoader from "./BaseLoader";
import * as THREE from "three";
import {FBXLoader as FBXLoaderImpl} from "three/examples/jsm/loaders/FBXLoader";

/**
 * FBXLoader
 * @author tengge / https://github.com/tengge1
 */
class FBXLoader extends BaseLoader {
    constructor() {
        super();
        this.loadingManager = new THREE.LoadingManager();
        this.loader = new FBXLoaderImpl(this.loadingManager);
    }

    load(url, options) {
        return new Promise(resolve => {
            this.require("FBXLoader").then(() => {
                this.loader.load(
                    url,
                    obj3d => {
                        obj3d._obj = obj3d;
                        obj3d._root = obj3d;

                        // MISHA: disable animation scripts (animations are done by the player controller and other behaviors)
                        /* if (obj3d.animations && obj3d.animations.length > 0) {
                        Object.assign(obj3d.userData, {
                            animNames: obj3d.animations.map(n => n.name),
                            scripts: [{
                                id: null,
                                name: `${options.Name}${_t('Animation')}`,
                                type: 'javascript',
                                source: this.createScripts(options.Name),
                                uuid: THREE.MathUtils.generateUUID()
                            }]
                        });
                    } */

                        resolve(obj3d);
                    },
                    undefined,
                    () => {
                        resolve(null);
                    },
                );
            });
        });
    }

    createScripts(name) {
        return (
            `var mesh = this.getObjectByName('${name}');\n\n` +
            `var obj = mesh._obj;\n\n` +
            `var root = mesh._root;\n\n` +
            `var mixer = new THREE.AnimationMixer(root);\n\n` +
            `mixer.clipAction(obj.animations[0]).play();\n\n` +
            `function update(clock, deltaTime) { \n    mixer.update(deltaTime); \n}`
        );
    }
}

export default FBXLoader;
