import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../../assets/style";

export const Nav = styled.div`
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 1px solid var(--theme-container-divider);
    flex-shrink: 0;
`;

export const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 16px 12px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

export const ListItem = styled.li<{$active: boolean; $bottomItem?: boolean; $disabled?: boolean}>`
    ${regularFont("xxs")};
    width: 100%;
    height: 32px;
    background-color: ${({$active}) => ($active ? "var(--theme-container-divider)" : "transparent")};
    padding: 12px 8px;
    ${flexCenter};
    justify-content: flex-start;
    column-gap: 8px;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.3s;
    ${({$bottomItem}) =>
        $bottomItem &&
        `margin: auto 0 0; 
        `};
    ${({$disabled}) => $disabled && `cursor: not-allowed;`};

    &:hover {
        background-color: var(--theme-container-divider);
    }

    .icon {
        width: 16px;
        height: 16px;
    }
`;

export const Top = styled.div`
    width: 100%;
    ${flexCenter};
    justify-content: flex-start;
    column-gap: 8px;
    height: 64px;
    border-bottom: 1px solid var(--theme-container-divider);
    padding: 16px 12px;
    ${regularFont("s")};
    font-weight: var(--theme-font-medium);
    cursor: pointer;
`;
