import {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";

import {useAuthorizationContext, useHomepageContext} from "../../../context";
import {PAGES} from "../../../editor/assets/v2/GamesDashboard/constants";
import {IBasicGameInterface, IUser} from "../types";
import {getGames} from "../../../api/getGames";
import {ROUTES} from "../../../AppRouter";
import {getLikedGames} from "../../../api/updateUser";

import {Footer} from "../../Footer/Footer";
import {Header} from "../../Header/Header";
import {SingleGame} from "../Home/SingleGame/SingleGame";
import {GamesCarousel} from "../Home/GamesCarousel/GamesCarousel";
import {FloatingColumn} from "./FloatingColumn/FloatingColumn";
import {EmptyBox} from "./EmptyBox/EmptyBox";
import {Link} from "../../common/Link/Link";
import {StyledButton} from "../../../editor/assets/v2/common/StyledButton";
import {GamesSection, SectionTitle, StyledGamesList} from "../Home/Home.style";
import {List, MainSection, UserWorld, Wrapper, Container, Content} from "./UserProfile.style";

const UserProfile = () => {
    const {getUser, dbUser} = useAuthorizationContext();
    const {games, favoriteGames, setFavoriteGames, fetchGames} = useHomepageContext();
    const {setActivePage} = useHomepageContext();
    const contentRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const [userGames, setUserGames] = useState<IBasicGameInterface[]>([]);
    const [user, setUser] = useState<IUser | null>(null);
    const [thisUserLikedGamesIds, setThisUserLikedGamesIds] = useState<string[]>([]);

    useEffect(() => {
        setActivePage(PAGES.PROFILE);
        if (thisUserLikedGamesIds && thisUserLikedGamesIds.length > 0) {
            setFavoriteGames([]);
            thisUserLikedGamesIds.forEach(sceneId => {
                if (typeof sceneId === "string") {
                    const foundGame = games.find(el => el.ID === sceneId);
                    if (foundGame) {
                        setFavoriteGames(prev => [...prev, foundGame]);
                    }
                }
            });
        } else {
            setFavoriteGames([]);
        }
    }, [thisUserLikedGamesIds, games]);

    useEffect(() => {
        fetchGames();
    }, [thisUserLikedGamesIds]);

    useEffect(() => {
        setThisUserLikedGamesIds([]);
        const handleGetLikedGames = async () => {
            if (user) {
                const res = await getLikedGames(user.id);
                res && setThisUserLikedGamesIds(res);
            }
        };
        user && handleGetLikedGames();
    }, [user]);

    useEffect(() => {
        if (user) {
            (async () => {
                const response = await getGames(user?.id);
                response && setUserGames(response);
            })();
        }
    }, [user]);

    useEffect(() => {
        if (location.pathname) {
            const username = location.pathname.split("/")[2];
            (async () => {
                const response = await getUser(undefined, username);
                response && setUser(response);
            })();
        }
        const container = document.getElementById("container");
        if (container) {
            container.style.overflowY = "auto";
            container.style.position = "relative";

            container.scrollTo(0, 0);
        }
    }, [location]);

    const isMyProfile = user?.id === dbUser?.id;
    const username = user?.username || user?.name;
    const profileText = isMyProfile ? "Your" : `${username || "This user"}'s`;
    const noGamesText = isMyProfile ? "You have no games!" : `${username || "This user"} hasn’t made any games!`;
    const noFavGamesText = isMyProfile
        ? "You have no favorites!"
        : `${username || "This user"} hasn’t favorited any games!`;
    return (
        <>
            <Container id="user-page-container">
                <Header />

                <Wrapper className="Wrapper">
                    <FloatingColumn user={user} isMyProfile={isMyProfile} contentRef={contentRef} />
                    <Content ref={contentRef}>
                        <MainSection>
                            <UserWorld>
                                <StyledButton width="auto" onClick={() => void 0} isBlue className="worldButton">
                                    Visit {isMyProfile ? "your" : `${user?.name}'s`} world
                                </StyledButton>
                            </UserWorld>
                        </MainSection>
                        <GamesSection>
                            <SectionTitle>{profileText} Games</SectionTitle>
                            {userGames.length > 0 ? (
                                <StyledGamesList>
                                    <GamesCarousel partialVisibilityGutterCount={60} itemsCount={3} games={userGames} />
                                </StyledGamesList>
                            ) : (
                                <EmptyBox text={noGamesText}>
                                    {isMyProfile && <Link href={ROUTES.HOME} text="Create your first game" />}
                                </EmptyBox>
                            )}
                        </GamesSection>
                        <GamesSection>
                            <SectionTitle>{profileText} Favorites</SectionTitle>
                            {favoriteGames?.length > 0 ? (
                                <List>
                                    {favoriteGames.map(item => (
                                        <SingleGame item={item} key={item.ID} />
                                    ))}
                                </List>
                            ) : (
                                <EmptyBox text={noFavGamesText}>
                                    {isMyProfile && <Link href={ROUTES.HOME} text="Explore games" inner />}
                                </EmptyBox>
                            )}
                        </GamesSection>
                    </Content>
                </Wrapper>
                <Footer />
            </Container>
        </>
    );
};

export default UserProfile;
