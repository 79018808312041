import {useCallback, useEffect, useRef, useState} from "react";
import * as THREE from "three";
import {debounce} from "lodash";

import searchIcon from "../../../icons/search-icon-small.svg";
import plusIcon from "../../../icons/plus-bold.svg";

import MoveObjectCommand from "../../../../../../command/MoveObjectCommand";
import {useAnimationContext, useAppGlobalContext} from "../../../../../../context";
import {Tree} from "../../../../../../ui/tree/v2/Tree";
import "../../css/ProjectTab.css";
import global from "../../../../../../global";
import {TextInput} from "../../../common/TextInput";
import {BasicCombobox, Item} from "../../../common/BasicCombobox";
import {StyledButton} from "../../../common/StyledButton";
import {TABS} from "../../LeftPanel";

type Props = {
    isVisible: boolean;
    activeTab: TABS;
};

const scenesMock: Item[] = [
    {
        key: "0",
        value: "Scene 1",
    },
];

export const ProjectTab = ({isVisible, activeTab}: Props) => {
    const treeRef = useRef<HTMLUListElement>(null);
    const [data, setData] = useState<any>();
    const [selected, setSelected] = useState<string[] | null>(null);
    const [checked, setChecked] = useState<any>({});
    const [expanded, setExpanded] = useState<any>({});
    const [search, setSearch] = useState("");
    const [searchDebaunced, setSearchDebaunced] = useState(search);
    const [foundObjects, setFoundObjects] = useState<any[]>([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isSceneSelected, setIsSceneSelected] = useState(false);
    const [selectedScene, setSelectedScene] = useState<Item>(scenesMock[0]);
    const {setIsGameSettingsPanelOpen, closeCheckSpawnPointsPanels, setIsVolumePanelOpen, setIsBillboardPanelOpen} =
        useAppGlobalContext();
    const {animationsState, setAnimationsState} = useAnimationContext();

    const app = global.app;
    const selectedRef = useRef<any>(null);

    const [lockedItems, setLockedItems] = useState<string[]>(app?.editor?.sceneLockedItems || []);

    const handleSelect = (value: string, shiftPressed: boolean, noSelectByUuid?: boolean) => {
        setSelected(prevSelected => {
            selectedRef.current = null;

            let newSelected;
            if (shiftPressed) {
                if (prevSelected?.includes(value)) {
                    newSelected = prevSelected.filter(item => item !== value);
                } else {
                    newSelected = prevSelected ? [...prevSelected, value] : [value];
                }
                if (!noSelectByUuid) {
                    selectedRef.current = newSelected;
                }
            } else {
                newSelected = [value];
                // need to pass single value, not array
                if (!noSelectByUuid) {
                    selectedRef.current = value;
                }
            }
            return newSelected;
        });
    };

    useEffect(() => {
        if (selectedRef.current) {
            app?.editor.selectByUuid(selectedRef.current);
        }
    }, [selectedRef.current]);

    const handleDoubleClick = (value: any) => {
        setSelected([value]);
        app?.editor.focusByUUID(value);
    };

    const handleObjectSelected = (object: any) => {
        if (!app) return;
        if (!object) {
            setSelected(null);
            setIsSceneSelected(false);
            return;
        }
        const selectedUuid = object.uuid;
        setIsSceneSelected(selected === app.editor.scene.uuid);
        handleSelect(selectedUuid, false, true);
    };

    const handleObjectArraySelected = (selectedObjectsArr: any) => {
        if (!selectedObjectsArr || selectedObjectsArr.length === 0) {
            setSelected(null);
            return;
        }
        const uniqueObjects = selectedObjectsArr.filter(
            (obj: any, index: number, self: any) => self.indexOf(obj) === index,
        );
        const uuids = uniqueObjects.map((obj: any) => obj.uuid);
        setSelected(uuids);
    };

    const updateUI = (shouldExpandData = false) => {
        if (!app || !app.editor) return;
        const scene = app.editor.scene;
        const camera = app.editor.camera;
        setLockedItems(app.editor.sceneLockedItems || []);
        let data = [
            {
                value: camera.uuid,
                text: camera.name,
                cls: "Camera",
                expanded: false,
                checked: checked[camera.uuid] || false,
                draggable: false,
                children: [],
                isCamera: true,
            },
        ];

        if (shouldExpandData && app.editor.selected && !Array.isArray(app.editor.selected)) {
            expandData(app.editor.selected.uuid, data);
        }

        scene.children.forEach((n: any) => {
            _parseData(n, data);
        });
        setData(data);

        setSelected([app.editor.selected?.uuid]);
    };

    const expandData = (uuid: any, list: any) => {
        for (let item of list) {
            if (uuid === item.value) {
                return true;
            }
            if (expandData(uuid, item.children)) {
                const copy = {...expanded};
                copy[item.uuid] = true;
                setExpanded(copy);
                item.expanded = true;
                return true;
            }
        }
        return false;
    };

    const _parseData = (obj: any, list: any) => {
        if (!app) return;
        const scene = app.editor.scene;
        const camera = app.editor.camera;

        let cls = null;

        if (obj === scene) {
            cls = "Scene";
        } else if (obj instanceof THREE.Line) {
            cls = "Line";
        } else if (obj instanceof THREE.Light) {
            cls = "Light";
        } else if (obj instanceof THREE.Points) {
            cls = "Points";
        } else {
            cls = "Default";
        }

        if (obj === scene && expanded[obj.uuid] === undefined) {
            expanded[obj.uuid] = true;
        }

        let isChildSelectedAndFound = false;
        let isChildFound = false;

        obj?.traverse((n: any) => {
            if (foundObjects.indexOf(n) > -1) {
                isChildFound = true;
                if (n.uuid === app.editor.selected?.uuid && !isChildSelectedAndFound) {
                    isChildSelectedAndFound = true;
                }
            }
        });

        var data = {
            value: obj.uuid,
            text: obj.name,
            vertices:
                obj instanceof THREE.Mesh && obj.geometry.attributes.hasOwnProperty("position")
                    ? obj.geometry.attributes.position.count
                    : -1,
            expanded: !!expanded[obj.uuid] || isChildSelectedAndFound,
            checked: checked[obj.uuid] || false,
            draggable: obj !== scene && obj !== camera,
            cls: cls,
            children: [],
            isCamera: obj.isCamera || false,
            isObject3D: obj.isObject3D || false,
            isLight: obj.isLight || false,
            isMesh: obj.isMesh || false,
            type: obj.type,
            icons: [
                {
                    name: "visible",
                    icon: obj.visible ? "visible" : "invisible",
                    title: obj.visible ? "Hide" : "Show",
                },
            ],
            userData: obj.userData,
        };

        if (search) {
            if (isChildFound) {
                list.push(data);
            }
        } else {
            list.push(data);
        }

        if (Array.isArray(obj.children)) {
            obj.children.forEach((n: any) => {
                if (n.userData.isStemObject) {
                    _parseData(n, data.children);
                }
            });
        }
    };

    const searchObjects = (obj: any) => {
        if (search && obj.name.toLowerCase().startsWith(search.toLowerCase())) {
            setFoundObjects(prevState => [...prevState, obj]);
        }

        if (Array.isArray(obj.children)) {
            obj.children.forEach((n: any) => {
                searchObjects(n);
            });
        }
    };

    const handleExpand = (value: any) => {
        let expandedCopy = {...expanded};

        if (expandedCopy[value]) {
            expandedCopy[value] = false;
        } else {
            expandedCopy[value] = true;
        }

        setSearch("");

        setExpanded(expandedCopy);
    };

    const handleDrop = (value: any, newParentValue: any, newBeforeValue: any) => {
        if (!app) return;
        var editor = app.editor;

        let object = editor.objectByUuid(value);
        let newParent = editor.objectByUuid(newParentValue);
        let newBefore = null;

        if (newBeforeValue) {
            newBefore = editor.objectByUuid(newBeforeValue);
        }

        app.editor.execute(new (MoveObjectCommand as any)(object, newParent, newBefore));
        let expandedCopy = {...expanded};
        expandedCopy[newParentValue] = true;
        setExpanded(expandedCopy);

        updateUI();
    };

    const handleLockClick = (id: string) => {
        if (!app) return;
        const lockedItemsCopy = [...lockedItems];

        if (lockedItemsCopy?.includes(id)) {
            lockedItemsCopy.splice(lockedItemsCopy.indexOf(id), 1);
        } else {
            lockedItemsCopy.push(id);
        }

        app.editor.sceneLockedItems = lockedItemsCopy;
        setLockedItems(lockedItemsCopy);

        const object = app.editor.objectByUuid(id);
        app.call(`objectChanged`, app.editor, object);
    };

    const debouncedHandleSearch = useCallback(
        debounce(async search => {
            setSearchDebaunced(search);
        }, 500),
        [],
    );

    const handleSearch = (value: string) => {
        setSearch(value);
        void debouncedHandleSearch(value);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case "ArrowUp":
                if (foundObjects.length > 0 && search) {
                    setSelectedIndex(prevIndex => Math.max(prevIndex - 1, 0));
                }
                break;
            case "ArrowDown":
                if (foundObjects.length > 0 && search) {
                    setSelectedIndex(prevIndex => Math.min(prevIndex + 1, foundObjects.length - 1));
                }
                break;
        }
    };

    const openGameManager = () => {
        setAnimationsState({...animationsState, selectedAnimation: null});
        setIsSceneSelected(false);
        closeCheckSpawnPointsPanels();
        setIsVolumePanelOpen(false);
        setIsBillboardPanelOpen(false);
        setIsGameSettingsPanelOpen(true);
        if (app?.editor?.selected) {
            app.editor.select(null);
        }
    };

    const selectScene = () => {
        if (!app) return;
        setSelected(null);
        setIsSceneSelected(true);
        closeCheckSpawnPointsPanels();
        setIsVolumePanelOpen(false);
        setIsBillboardPanelOpen(false);
        app.editor.select(app.editor.scene);
    };

    useEffect(() => {
        if (foundObjects.length > 0) {
            setSelectedIndex(0);
        }
    }, [foundObjects]);

    useEffect(() => {
        updateUI();
    }, [expanded, lockedItems?.length]);

    useEffect(() => {
        setSelectedIndex(0);
        setFoundObjects([]);
        searchObjects(app?.editor.scene);
    }, [searchDebaunced]);

    useEffect(() => {
        if (foundObjects[selectedIndex]) {
            if (!app) return;
            app.editor.select(foundObjects[selectedIndex]);
        }
        updateUI();
    }, [selectedIndex, foundObjects]);

    useEffect(() => {
        if (!app) return;
        updateUI();
        app.on(`sceneGraphChanged.ProjectTab`, updateUI);

        // bug: https://gitee.com/tengge1/ShadowEditor/issues/ITCA9
        app.on(`objectUpdated.ProjectTab`, updateUI);

        app.on(`objectRemoved.ProjectTab`, updateUI);

        app.on(`objectSelected.ProjectTab`, handleObjectSelected);
        app.on(`objectArraySelected.ProjectTab`, handleObjectArraySelected);

        return () => {
            app.on(`sceneGraphChanged.ProjectTab`, null);
            app.on(`objectUpdated.ProjectTab`, null);

            app.on(`objectRemoved.ProjectTab`, null);

            app.on(`objectSelected.ProjectTab`, null);
            app.on(`objectArraySelected.ProjectTab`, handleObjectArraySelected);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [foundObjects]);

    const scrollToSelected = () => {
        if (treeRef.current && selected) {
            const selectedElement = treeRef.current.querySelector(`li.selected`) as HTMLElement;
            if (selectedElement) {
                treeRef.current.scrollTo({
                    top: selectedElement.offsetTop - 30,
                    left: 0,
                    behavior: "smooth",
                });
            }
        }
    };

    useEffect(() => {
        scrollToSelected();
    }, [selected, activeTab]);

    return (
        <div className="ProjectTab" style={!isVisible ? {display: "none"} : {}}>
            <div className="tab-header">
                <div className="wrapper">
                    <div className={`scene ${isSceneSelected && "selected"}`} onClick={selectScene}>
                        <BasicCombobox
                            data={scenesMock}
                            onChange={item => setSelectedScene(item)}
                            value={selectedScene}
                        />
                        <StyledButton isGreyBlue width="32px">
                            <img src={plusIcon} alt="add" className="icon" />
                        </StyledButton>
                    </div>
                </div>
                <div className="separator" />
                <div className="wrapper">
                    <div className="search">
                        <TextInput
                            height="32px"
                            value={search}
                            width="100%"
                            setValue={handleSearch}
                            placeholder="Search"
                        />
                        <img src={searchIcon} alt="search" className="icon" />
                    </div>
                </div>
            </div>
            <Tree
                data={data}
                selected={selected}
                treeRef={treeRef}
                onSelect={handleSelect}
                onDoubleClick={handleDoubleClick}
                onExpand={handleExpand}
                onLockClick={handleLockClick}
                lockedItems={lockedItems}
                onDrop={handleDrop}
                openGameManager={openGameManager}
                className="project-tab-tree"
                style={{}}
                scrollToSelected={scrollToSelected}
            />
        </div>
    );
};
