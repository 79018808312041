import EventBus from "../event/EventBus";
import Application from "../../Application";
import SetScaleCommand from "../../command/SetScaleCommand";
import SetPositionCommand from "../../command/SetPositionCommand";
import SetRotationCommand from "../../command/SetRotationCommand";
import * as THREE from "three";
import {setBehaviorForObject} from "../../editor/assets/v2/RightPanel/behaviors/helpers/setBehaviorForObject";
import {IBehavior} from "../../types/editor";
import {
    IBehaviorsMessageData,
    IPhysicsMessageData,
    ITransformMessageData,
    IPhysicsAttachMessageData,
    IPhysicsUpdateMessageData,
    IBehaviorsAttachMessageData,
    IBehaviorsDetachMessageData,
    IBehaviorsUpdateMessageData,
} from "../../types/editor";
import {getPhysics} from "../../editor/assets/v2/utils/getPhysics";

class EditorManager {
    static BEHAVIORS_TOPIC = "behaviors";
    static TRANSFORM_TOPIC = "transform";
    static PHYSICS_TOPIC = "physics";

    app: Application;
    topics: {
        topic: string;
        callback: (topic: string, data: any) => void;
    }[];
    constructor(app: Application) {
        this.app = app;
        this.topics = [
            {
                topic: EditorManager.BEHAVIORS_TOPIC,
                callback: this.onBehaviorsMessage.bind(this),
            },
            {
                topic: EditorManager.TRANSFORM_TOPIC,
                callback: this.onTransformMessage.bind(this),
            },
            {
                topic: EditorManager.PHYSICS_TOPIC,
                callback: this.onPhysicsMessage.bind(this),
            },
        ];
    }

    //call to start the new session
    start() {
        this.topics.forEach(item => {
            EventBus.instance.subscribe(item.topic, item.callback);
        });
    }

    stop() {
        this.topics.forEach(item => {
            EventBus.instance.unsubscribe(item.topic);
        });
    }

    private callUpdate(object: any) {
        this.app.call(`objectChanged`, this.app.editor, object);
        this.app.call(`objectUpdated`, this.app.editor, object);
    }

    //behaviors.detach, behaviors.attach, behaviors.update
    private onBehaviorsMessage(topic: string, data: IBehaviorsMessageData) {
        const cmd = topic.split(".")[1];
        const {objectId} = data;

        const object = this.app.editor?.objectByUuid(objectId);
        if (object) {
            if (cmd === "attach") {
                const {types, behaviors} = data as IBehaviorsAttachMessageData;

                types?.forEach(type => {
                    setBehaviorForObject(type, this.app.editor!, object);
                });

                if (!object.userData.behaviors) {
                    object.userData.behaviors = [];
                }

                behaviors?.forEach(behavior => {
                    object.userData.behaviors.push(behavior);
                });
                this.callUpdate(object);
                return;
            }

            if (cmd === "detach") {
                const {types} = data as IBehaviorsDetachMessageData;

                const updatedBehaviors = object.userData.behaviors.filter(
                    (behavior: IBehavior) => types?.indexOf(behavior.type) === -1,
                );

                object.userData.behaviors = updatedBehaviors;
                this.callUpdate(object);
                return;
            }

            if (cmd === "update") {
                const {behaviors} = data as IBehaviorsUpdateMessageData;

                const updatedBehaviors = object.userData.behaviors.map((behavior: IBehavior) => {
                    const update = behaviors.find(b => b.type === behavior.type);

                    if (update) {
                        return {...behavior, ...update};
                    }

                    return behavior;
                });

                object.userData.behaviors = updatedBehaviors;
                this.callUpdate(object);
                return;
            }

            console.error(`EditorManager.onBehaviorsMessage, invalid command: ${cmd}`);
        } else {
            console.error(`EditorManager.onBehaviorsMessage, No object found with id ${objectId}`);
            return;
        }
    }

    //transform.scale, transform.position, transform.rotation
    private onTransformMessage(topic: string, data: ITransformMessageData) {
        const cmd = topic.split(".")[1];
        const {objectId, values} = data;
        const object = this.app.editor?.objectByUuid(objectId);

        if (object && this.app.editor) {
            const {x, y, z} = values;
            const {editor} = this.app;
            if (cmd === "scale") {
                editor.execute(new SetScaleCommand(object, new THREE.Vector3(x, y, z)));
                this.callUpdate(object);
                return;
            }

            if (cmd === "position") {
                editor.execute(new SetPositionCommand(object, new THREE.Vector3(x, y, z)));
                this.callUpdate(object);
                return;
            }

            if (cmd === "rotation") {
                editor.execute(
                    new SetRotationCommand(
                        object,
                        new THREE.Euler(
                            x * THREE.MathUtils.DEG2RAD,
                            y * THREE.MathUtils.DEG2RAD,
                            z * THREE.MathUtils.DEG2RAD,
                        ),
                    ),
                );
                this.callUpdate(object);
                return;
            }

            console.error(`EditorManager.onTransformMessage, invalid command: ${cmd}`);
        } else {
            console.error(`EditorManager.onTransformMessage, No object found with id ${objectId}`);
            return;
        }
    }

    //physics.detach, physics.attach, physics.update
    private onPhysicsMessage(topic: string, data: IPhysicsMessageData) {
        const cmd = topic.split(".")[1];
        const {objectId} = data;
        const object = this.app.editor?.objectByUuid(objectId);

        if (object) {
            if (cmd === "attach") {
                const {physics} = data as IPhysicsAttachMessageData;

                const defaultPhysics = getPhysics(null);
                object.userData.physics = physics || defaultPhysics;
                object.userData.physics.enabled = true;
                this.callUpdate(object);
                return;
            }

            if (cmd === "detach") {
                object.userData.physics.enabled = false;
                this.callUpdate(object);
                return;
            }

            if (cmd === "update") {
                const {physics} = data as IPhysicsUpdateMessageData;

                object.userData.physics = {
                    ...object.userData.physics,
                    ...physics,
                };
                this.callUpdate(object);
                return;
            }

            console.error(`EditorManager.onPhysicsMessage, invalid command: ${cmd}`);
        } else {
            console.error(`EditorManager.onPhysicsMessage, No object found with id ${objectId}`);
            return;
        }
    }
}

export default EditorManager;
