export const CheckboxIcon = () => {
    return (
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.14729 0.126248C9.31278 0.236586 9.42766 0.40814 9.46666 0.603174C9.50567 0.798208 9.46561 1.00075 9.35529 1.16625L4.35529 8.66625C4.29371 8.75849 4.21244 8.83593 4.11732 8.89298C4.02221 8.95004 3.91563 8.98528 3.80525 8.99619C3.69488 9.00709 3.58346 8.99338 3.47902 8.95604C3.37459 8.9187 3.27973 8.85866 3.20129 8.78025L0.201292 5.78025C0.0688118 5.63807 -0.00331137 5.45003 0.000116847 5.25573C0.00354506 5.06143 0.0822571 4.87604 0.21967 4.73863C0.357083 4.60121 0.542468 4.5225 0.736769 4.51907C0.93107 4.51565 1.11912 4.58777 1.26129 4.72025L3.61429 7.07325L8.10729 0.333248C8.21779 0.167925 8.38941 0.0532489 8.58444 0.0144318C8.77946 -0.0243853 8.98192 0.0158343 9.14729 0.126248Z"
                fill="white"
            />
        </svg>
    );
};
