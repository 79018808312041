import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: var(--theme-container-minor-dark);
    color: #fff;
    overflow: hidden auto;
`;

export const Wrapper = styled.div`
    width: 100%;
    margin: 0 24px;
`;
export const Content = styled.div`
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    padding: 40px 0;

    .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
        text-align: left;
    }

    .updateDate {
        margin: 8px 0 40px;
        font-size: 16px;
    }

    .text {
        font-size: 16px;
        line-height: 24px;
    }
`;
