import styled from "styled-components";
import questionMarkIcon from "../icons/question-mark.svg";
import {flexCenter} from "../../../../assets/style";

const Wrapper = styled.div<{$width?: string; $height?: string; $padding?: string; $background?: string}>`
    position: relative;
    cursor: pointer;
    div {
        text-align: center;
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        position: absolute;
        bottom: calc(100% + 8px);
        visibility: hidden;
        transition: all 0.2s ease-in-out;
        ${flexCenter};
        align-items: center;
        border-radius: 8px;
        font-size: var(--theme-font-size-xxs);
        padding: 4px 8px;
        background: var(--theme-grey-bg);
        color: white;
        left: 50%;
        transform: translateX(-50%);
        ${({$width}) => $width && `width: ${$width};`};
        ${({$height}) => $height && `height: ${$height};`};
        ${({$padding}) => $padding && `width: ${$padding};`};
        ${({$background}) => $background && `width: ${$background};`};
    }

    &:hover {
        div {
            visibility: visible;
            position: absolute;
            z-index: 100;
            opacity: 1;
            pointer-events: all;
        }
    }
`;

type Props = {
    width?: string;
    height?: string;
    padding?: string;
    background?: string;
    text?: string;
};

export const Tooltip = ({width, height, padding, background, text}: Props) => {
    return (
        <Wrapper $width={width} $height={height} $padding={padding} $background={background}>
            <img src={questionMarkIcon} />
            <div>{text}</div>
        </Wrapper>
    );
};
