import "./css/Editor.css";
import I18n from "i18next";

import {Message} from "../ui/index";
import {LoadingAnimation} from "../ui/progress/LoadingAnimation.js";
import Viewport from "./viewport/Viewport.js";
import TemplatePanel from "./assets/v2/TemplatePanel/TemplatePanel.js";
import {LeftPanel} from "./assets/v2/LeftPanel/LeftPanel.js";
import RightPanel from "./assets/v2/RightPanel/RightPanel.js";
import {AvatarCreatorPanel} from "./assets/v2/AvatarCreatorPanel/AvatarCreatorPanel.js";
import {ModelAnimationCombiner} from "./assets/v2/AnimationCombiner/ModelAnimationCombiner.js";
import {HUDEditView} from "./assets/v2/HUD/HUDEditView/HUDEditView.js";
import History from "../command/History.js";
import RemoveObjectCommand from "../command/RemoveObjectCommand.js";
import AddObjectCommand from "../command/AddObjectCommand.js";
import ModelLoader from "../loader/ModelLoader.js";
import Helpers from "../helper/Helpers.js";

import ControlsManager from "../controls/ControlsManager.js";
import global from "../global";
import * as THREE from "three";

import React from "react";
import {TransformControls} from "three/examples/jsm/controls/TransformControls.js";

import AnimationContextProvier from "../context/AnimationContext";
import HUDGameContextProvider from "../context/HUDGameContext";
import HUDStartGameMenuContextProvider from "../context/HUDStartGameMenuContext";
import HUDInGameMenuContextProvider from "../context/HUDInGameMenuContext";
import ModelAnimationCombinerContextProvider from "../context/ModelAnimationCombinerContext";
import i18n from "../i18n/config";
import EmptySceneTemplate from "./menu/scene/EmptySceneTemplate";
import Application from "../Application";
import {ActionBar} from "./assets/v2/ActionBar/ActionBar";
import {backendUrlFromPath} from "../utils/UrlUtils";
import {AiAssistant} from "./assets/v2/AiAssistant/AiAssistant";
import EditorManager from "../behaviors/editor/EditorManager";
import ScriptEditorPanel from "./assets/v2/ScriptEditorPanel/ScriptEditorPanel";
import {isInputActive} from "./assets/v2/utils/isInputActive";
import {ReadOnlyBadge} from "./assets/v2/ReadOnlyBadge/ReadOnlyBadge";
import StringUtils from "../utils/StringUtils";
import TimeUtils from "../utils/TimeUtils";
import Converter from "../serialization/Converter";
import ShadowUtils from "../utils/ShadowUtils";
import {CSS3DRenderer} from "three/examples/jsm/renderers/CSS3DRenderer";

const {t} = i18n;

/*
 * @author tengge / https://github.com/tengge1
 */

interface IEditorProps {
    setIsGameSettingsPanelOpen: (isOpen: boolean) => void;
    projectPhase: number;
}
class Editor extends React.Component<IEditorProps> {
    state: {
        maskText: string;
        elements: any[];
        playerStarted: boolean;
        isReady: boolean;
        libraryPanelOpened: boolean;
        HUDEditViewOpened: boolean;
        showLoding: boolean;
        showRightPanel: boolean;
        showSidePanels: boolean;
        showAvatarCreator: boolean;
        showModelAnimationCombiner: boolean;
        showAiAssistant: boolean;
    };
    type: string = "scene";
    view: string = "perspective";
    history: History;
    scene: THREE.Scene = new THREE.Scene();
    selected: THREE.Object3D | THREE.Object3D[] | null = null;
    selectionHelpers: THREE.Object3D[] = [];
    sceneHelpers: THREE.Scene = new THREE.Scene();
    sceneID: string | null = null;
    sceneName: string | null = null;
    DEFAULT_CAMERA: THREE.PerspectiveCamera;
    camera: THREE.PerspectiveCamera;
    orthCamera: THREE.OrthographicCamera = new THREE.OrthographicCamera();
    rendererCSS: CSS3DRenderer = new CSS3DRenderer();
    renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
        preserveDrawingBuffer: true,
    });
    objects: THREE.Object3D[] = [];
    scripts: any = [];
    animations: any = [];
    transformControls: TransformControls | null = null;
    controls: ControlsManager | null = null;
    showViewHelper: boolean = true;
    gpuPickNum: number = 0;
    helpers: Helpers = new Helpers();
    audioListener: THREE.AudioListener = new THREE.AudioListener();
    sceneLockedItems: string[] = [];
    sceneThumbnail: string | null = null;
    isPublic: boolean = false;
    isCloneable: boolean = false;
    isPublished: boolean = false;
    projectUserId: string = "";
    useAvatar: boolean = false;
    isMultiplayer: boolean = false;
    voiceChatEnabled: boolean = false;
    useWebGPU: boolean = false;
    description: string = "";
    tags: string[] = [];
    editorManager: EditorManager | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            maskText: t("Waiting..."),
            elements: [],
            playerStarted: false,
            isReady: false,
            libraryPanelOpened: true,
            HUDEditViewOpened: false,
            showLoding: false,
            showRightPanel: true,
            showSidePanels: true,
            showAvatarCreator: false,
            showModelAnimationCombiner: false,
            showAiAssistant: false,
        };

        this.history = new History(this);
        this.DEFAULT_CAMERA = new THREE.PerspectiveCamera(50, window.innerHeight / window.innerWidth, 0.1, 10000);
        this.camera = this.DEFAULT_CAMERA.clone();

        //used by the GLTF loader
        global.app!.renderer = this.renderer;

        this.onToggle = this.onToggle.bind(this);
    }

    isGame = () => {
        return !!this.scene?.userData?.game?.enabled;
    };

    handleLoading(show: boolean) {
        this.setState({showLoding: show});
    }

    updatePhase = (value: number) => {
        this.setState({
            projectPhase: value,
        });
    };

    showLibraryPanel = (value: boolean) => {
        this.setState({
            libraryPanelOpened: value,
        });
    };

    showHUDEditView = (value: boolean) => {
        this.setState({
            HUDEditViewOpened: value,
        });
    };

    queryBeforeCreateScene() {
        return new Promise<void>(resolve => {
            if (this.sceneID === null) {
                resolve();
            } else {
                (global.app as Application)?.confirm({
                    title: "Confirm",
                    content: "All unsaved data will be lost. Are you sure?",
                    onOK: () => {
                        resolve();
                    },
                });
            }
        });
    }

    querySceneName = () => {
        var sceneName = this.sceneName;

        if (!this.sceneName) {
            sceneName = I18n.t(`Scene{{Time}}`, {
                Time: (TimeUtils as any).getDateTime(),
            });
        }

        return new Promise(resolve => {
            if (!global.app) return;
            (global.app as Application).prompt({
                title: I18n.t("Input File Name"),
                content: I18n.t("Name"),
                value: sceneName || undefined,
                onOK: (name: string) => {
                    resolve(name);
                },
            });
        });
    };

    handleExportSceneToJson = () => {
        if (!global.app) return;
        this.querySceneName().then((name: any) => {
            // var output = global.app.editor.scene.toJSON();
            let output = new (Converter as any)(null, true).toJSON({
                options: (global.app as Application).options,
                camera: this.camera,
                renderer: this.renderer,
                scripts: this.scripts,
                animations: this.animations,
                scene: this.scene,
            });

            try {
                output = JSON.stringify(output, StringUtils.parseNumber, "\t");
                // eslint-disable-next-line
                output = output.replace(/[\n\t]+([\d\.e\-\[\]]+)/g, "$1");
            } catch (e) {
                output = JSON.stringify(output);
            }

            StringUtils.saveString(output, `${name}.json`);
        });
    };

    handleZoomChange = (value: number) => {
        const minZoom = 0.1;
        const maxZoom = 1;
        const zoom = minZoom + (maxZoom - minZoom) * (value / 100);

        if (zoom > 0) {
            this.camera.zoom = zoom;
            this.camera.updateProjectionMatrix();

            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.rendererCSS.setSize(window.innerWidth, window.innerHeight);
        }
    };
    render() {
        const {elements} = this.state;

        return (
            <HUDGameContextProvider isReady={this.state.isReady}>
                <HUDStartGameMenuContextProvider isReady={this.state.isReady}>
                    <HUDInGameMenuContextProvider isReady={this.state.isReady}>
                        <AnimationContextProvier isReady={this.state.isReady}>
                            {!this.state.playerStarted && this.props.projectPhase === 2 && <TemplatePanel />}
                            {this.state.showSidePanels && (
                                <>
                                    <LeftPanel
                                        showAvatarCreator={() =>
                                            this.setState({
                                                showAvatarCreator: true,
                                            })
                                        }
                                        showLoading={show => this.setState({showLoding: show})}
                                        show={!this.state.playerStarted}
                                        scene={this.scene}
                                    />

                                    {this.state.showRightPanel && !this.state.playerStarted && (
                                        <RightPanel
                                            openUIPanel={() => this.showHUDEditView(true)}
                                            showLoading={show =>
                                                this.setState({
                                                    showLoding: show,
                                                })
                                            }
                                            showAvatarCreator={() =>
                                                this.setState({
                                                    showAvatarCreator: true,
                                                })
                                            }
                                            showModelAnimationCombiner={() =>
                                                this.setState({
                                                    showModelAnimationCombiner: true,
                                                })
                                            }
                                        />
                                    )}
                                </>
                            )}

                            {this.state.showAvatarCreator && (
                                <AvatarCreatorPanel
                                    onClose={() =>
                                        this.setState({
                                            showAvatarCreator: false,
                                        })
                                    }
                                />
                            )}

                            {this.state.showModelAnimationCombiner && (
                                <ModelAnimationCombinerContextProvider>
                                    <ModelAnimationCombiner
                                        onClose={() =>
                                            this.setState({
                                                showModelAnimationCombiner: false,
                                            })
                                        }
                                        model={this.selected}
                                    />
                                </ModelAnimationCombinerContextProvider>
                            )}

                            {this.state.HUDEditViewOpened && !this.state.playerStarted && (
                                <HUDEditView onClose={() => this.showHUDEditView(false)} />
                            )}

                            <Viewport />
                            {/*
                            {this.state.projectPhase === 3 &&
                                !this.state.playerStarted && <TimelinePanel />} */}
                            {!this.state.playerStarted && this.props.projectPhase === 3 && (
                                <ActionBar
                                    playerStarted={this.state.playerStarted}
                                    handleZoomChange={(value: number) => this.handleZoomChange(value)}
                                    handleAssistanOpen={() => {
                                        this.setState({
                                            showAiAssistant: true,
                                        });
                                    }}
                                    isAiAssistantOpen={this.state.showAiAssistant}
                                />
                            )}
                            {!this.state.playerStarted && this.props.projectPhase === 3 && (
                                <AiAssistant
                                    isOpen={this.state.showAiAssistant}
                                    onClose={() =>
                                        this.setState({
                                            showAiAssistant: false,
                                        })
                                    }
                                />
                            )}
                            {!this.state.playerStarted && <ReadOnlyBadge />}

                            <LoadingAnimation show={this.state.showLoding} />

                            <ScriptEditorPanel />

                            {elements.map((n, i) => {
                                return <div key={i}>{n}</div>;
                            })}
                        </AnimationContextProvier>
                    </HUDInGameMenuContextProvider>
                </HUDStartGameMenuContextProvider>
            </HUDGameContextProvider>
        );
    }
    onEditorInit() {
        const app = global.app as Application;
        if (app) {
            this.editorManager = new EditorManager(app);
            this.editorManager.start();

            app.event.start();
            app.editor = this;

            this.scene.name = t("Scene");
            this.scene.background = new THREE.Color(0xaaaaaa);

            const width = app.viewport?.clientWidth;
            const height = app.viewport?.clientHeight;

            this.DEFAULT_CAMERA.name = t("DefaultCamera");
            this.DEFAULT_CAMERA.userData.isDefault = true;
            this.DEFAULT_CAMERA.userData.control = "OrbitControls";
            this.DEFAULT_CAMERA.userData.orbitOptions = {
                enableDamping: true,
                dampingFactor: 0.08,
                panSpeed: 1.6,
            };
            this.DEFAULT_CAMERA.position.set(20, 10, 20);
            this.DEFAULT_CAMERA.lookAt(new THREE.Vector3());

            this.camera = this.DEFAULT_CAMERA.clone();

            if (width && height) {
                this.orthCamera = new THREE.OrthographicCamera(
                    -width / 4,
                    width / 4,
                    height / 4,
                    -height / 4,
                    0.1,
                    10000,
                );
            }

            this.rendererCSS = new CSS3DRenderer();
            if (width && height) {
                this.rendererCSS.setSize(width, height);
            }

            const wrapper = this.rendererCSS.domElement.getElementsByTagName("div")[0];
            wrapper.style.position = "absolute";
            wrapper.style.top = "0";
            wrapper.style.left = "0";
            wrapper.style.zIndex = "2";
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            this.renderer.autoClear = false;
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.domElement.style.position = "absolute";
            this.renderer.domElement.style.top = "0";
            this.renderer.domElement.style.left = "0";

            this.rendererCSS.domElement.appendChild(this.renderer.domElement);
            app.viewport?.appendChild(this.rendererCSS.domElement);
            //app.viewport?.appendChild(this.renderer.domElement);

            if (width && height) {
                this.renderer.setSize(width, height);
            }

            this.transformControls = new TransformControls(this.camera, app.viewport);
            // recursively add tag "gizmo" to all children to disable ability to select them
            this.transformControls.traverse(n => {
                (n as any).tag = "gizmo";
            });

            this.sceneHelpers.add(this.transformControls);

            this.controls = new ControlsManager(this.camera, app.viewport);

            const orbitControls = this.controls.current?.controls;
            if (orbitControls) {
                orbitControls.enableDamping = false;
                orbitControls.mouseButtons = {
                    LEFT: THREE.MOUSE.ROTATE,
                    MIDDLE: THREE.MOUSE.PAN,
                    RIGHT: THREE.MOUSE.ROTATE,
                };
            }

            let light = new THREE.DirectionalLight(0xfffff, 1.0);
            light.position.z = 10;
            this.addSelectionHelper(light);

            this.gpuPickNum = app.storage.hoverEnabled ? 1 : 0;

            app.on(`appStarted.Editor`, this.onAppStarted.bind(this));
            app.on("playerStarted.Editor", () => {
                this.setState({
                    playerStarted: true,
                });
            });
            app.on("playerStopped.Editor", () => {
                this.setState({
                    playerStarted: false,
                });
            });

            app.on("sceneLoaded.Editor", () => {
                if (app.editor?.sceneID) {
                    app.getScene(app.editor?.sceneID, data => {
                        if (data.Code === 200) {
                            this.isCloneable = data.Data.IsCloneable ?? false;
                            this.isPublic = data.Data.IsPublic;
                            this.isPublished = data.Data.IsPublished ?? false;
                            this.useAvatar = data.Data.UseAvatar ?? false;
                            this.isMultiplayer = data.Data.IsMultiplayer ?? false;
                            this.voiceChatEnabled = data.Data.VoiceChatEnabled ?? false;
                            this.useWebGPU = data.Data.UseWebGPU ?? false;
                            this.projectUserId = data.Data.UserID;
                            this.description = data.Data.Description;
                            this.tags = data.Data.Tags ? JSON.parse(data.Data.Tags) : [];
                            app.call("clear");
                        }
                    });
                }
            });

            app.on("sceneSaveStart.Editor", () => {
                this.removePreviewBoxes();
            });

            app.on("objectRemoved.Editor", (object: any) => {
                this.removeOjbectPreviewBox(object);
            });

            app.call("appStart", this);
            app.call("appStarted", this);
            app.call("sceneLoaded", this);
            app.call("resize", this);

            app.log("Program started.");

            //TODO: MISHA - review the auto-save policy
            app.storage.autoSave = false;
            app.call("storageChanged", this, "autoSave", true);
            this.setState({
                isReady: true,
            });
            this.select(null);
            this.addDeleteEventListener();
            this.addCopyEventListener();
            this.addDuplicateObjEventListener();
            this.addPasteEventListener();
            this.addGroupEventListener();
            this.addUngroupEventListener();
            this.addUndoRedoListener();
            this.addPanelsVisibilityListener();
        }
    }
    componentDidMount() {
        this.onEditorInit();
    }

    componentWillUnmount() {
        const app = global.app as Application;
        if (app) {
            app.call("appStop", this);
            app.call("appStoped", this);

            app.on(`appStarted.Editor`, null);
            app.on(`storageChanged.Editor`, null);
            app.on(`sceneLoaded.Editor`, null);
            app.on("playerStarted.Editor", null);
            app.on("playerStopped.Editor", null);
            app.on("sceneSaveStart.Editor", null);

            this.editorManager?.stop();
            app.event.stop();
            if (this.transformControls) {
                this.sceneHelpers.remove(this.transformControls);
            }
            this.removePreviewBoxes();
        }
        this.removePasteEventListener();
    }

    onAppStarted() {
        // TODO: helpers subscribing to events but not unsubscribing, there is no stop method called anywhere
        this.helpers.start();
        this.clear();

        this._addAudioListener = this._addAudioListener.bind(this);
        document.addEventListener("click", this._addAudioListener);
    }

    onToggle() {
        global.app && global.app.call("resize", this);
    }

    setScene(scene: THREE.Scene) {
        this.scene = scene;
    }

    clear(addObject = true) {
        const template = new EmptySceneTemplate();
        template.clear();

        if (addObject) {
            template.create();
        }

        global.app?.call("editorCleared", this);
        global.app?.call("scriptChanged", this);
        global.app?.call("animationChanged", this);
    }

    clearAndReset(addObject = true) {
        this.componentWillUnmount();
        this.onEditorInit();
        const template = new EmptySceneTemplate();
        template.clear();

        if (addObject) {
            template.create();
        }

        global.app?.call("editorCleared", this);
        global.app?.call("scriptChanged", this);
        global.app?.call("animationChanged", this);
    }

    _addAudioListener() {
        document.removeEventListener("click", this._addAudioListener);
        this.audioListener = new THREE.AudioListener();
        this.audioListener.name = t("AudioListener");

        if (this.camera.children.findIndex(o => o instanceof THREE.AudioListener) === -1) {
            this.camera.add(this.audioListener);
        }
    }

    objectByUuid(uuid: string) {
        return this.scene.getObjectByProperty("uuid", uuid);
    }

    addSelectionHelper(object: THREE.Object3D) {
        this.selectionHelpers.push(object);
        this.sceneHelpers.add(object);
    }

    removeSelectionHelper(object: THREE.Object3D) {
        const index = this.selectionHelpers.indexOf(object);
        if (index !== -1) {
            this.selectionHelpers.splice(index, 1);
        }
        this.sceneHelpers.remove(object);
    }

    addObject(object: THREE.Object3D, parent?: THREE.Object3D) {
        global.app!.call("disableInfiniteGrid", this, object);

        this.setObjectDefaultAnimation(object);
        this.setObjectDefaultUserData(object);
        this.setObjectDefaultShadowSettings(object);

        if (!parent) {
            parent = this.scene;
        }

        try {
            parent.add(object);
        } catch (e) {
            console.error(e);
        }

        object.updateMatrixWorld();

        global.app?.call("objectAdded", this, object);
        global.app?.call("sceneGraphChanged", this);
    }

    moveObjectToCameraClosestPoint(object: THREE.Object3D) {
        const intersection = this.getObjectInsertPoint();
        if (intersection) {
            object.position.copy(intersection.point);
        }
    }

    getObjectInsertPoint() {
        this.camera.updateMatrixWorld();

        const origin = new THREE.Vector3().setFromMatrixPosition(this.camera.matrixWorld);
        const direction = new THREE.Vector3().copy(this.camera.getWorldDirection(new THREE.Vector3()));

        const planeNormal = new THREE.Vector3(0, 1, 0);
        const planePoint = new THREE.Vector3(0, 0, 0);

        const planeD = -planeNormal.dot(planePoint);
        const denominator = planeNormal.dot(direction);

        if (Math.abs(denominator) > 1e-6) {
            const t = -(planeNormal.dot(origin) + planeD) / denominator;

            if (t >= 0) {
                const intersectionPoint = origin.clone().add(direction.clone().multiplyScalar(t));
                return {
                    point: intersectionPoint,
                    distance: origin.distanceTo(intersectionPoint),
                };
            }
        }

        return null;
    }

    setObjectDefaultAnimation(object: THREE.Object3D) {
        if (object?.animations.length > 0 || object._obj?.animations.length > 0) {
            const animations = object.animations.length > 0 ? object.animations : object._obj?.animations;

            const idleAnimation = animations.find((animation: THREE.AnimationClip) =>
                animation.name.toLowerCase().includes("idle"),
            );
            if (!object.userData.selectedAnimation) {
                if (idleAnimation) {
                    object.userData.selectedAnimation = idleAnimation.name;
                    object.userData.objectType = "gameProp";
                } else if (animations.length > 0) {
                    object.userData.selectedAnimation = animations[0]?.name;
                    object.userData.objectType = "gameProp";
                } else {
                    object.userData.selectedAnimation = "none";
                }
            }
        }
    }

    private setObjectDefaultUserData(object: THREE.Object3D) {
        object.userData.isStemObject = true;
    }

    private setObjectDefaultShadowSettings(object: THREE.Object3D) {
        // @ts-ignore
        if (object.geometry) return; // skip primitivies
        if (!object.userData.shadow) {
            object.userData.shadow = {
                castShadow: true,
                receiveShadow: true,
            };
        }
        ShadowUtils.applyCastShadow(object, object.userData.shadow.castShadow, false);
        ShadowUtils.applyReceiveShadow(object, object.userData.shadow.receiveShadow, false);
    }

    moveObject(object: THREE.Object3D, parent: THREE.Object3D, before: THREE.Object3D) {
        if (parent === undefined) {
            parent = this.scene;
        }

        parent.add(object);

        if (before !== undefined) {
            let index = parent.children.indexOf(before);
            parent.children.splice(index, 0, object);
            parent.children.pop();
        }

        global.app?.call("sceneGraphChanged", this);
    }

    removeObject(object: THREE.Object3D) {
        if (object.parent === null) {
            return;
        }

        object.parent.remove(object);

        global.app?.call("objectRemoved", this, object);
        global.app?.call("sceneGraphChanged", this);
    }

    addPhysicsHelper(helper: any) {
        let geometry = new THREE.SphereGeometry(2, 4, 2);
        let material = new THREE.MeshBasicMaterial({
            color: 0xff0000,
            visible: false,
        });

        let picker = new THREE.Mesh(geometry, material);
        picker.name = "picker";
        picker.userData.object = helper.object;
        helper.add(picker);

        this.sceneHelpers.add(helper);
        // @ts-ignore
        this.helpers[helper.object.id] = helper;
        this.objects.push(picker);
    }

    removePhysicsHelper(helper: any) {
        // @ts-ignore
        if (this.helpers[helper.object.id] !== undefined) {
            helper.parent.remove(helper);
            // @ts-ignore
            delete this.helpers[helper.object.id];

            let objects = this.objects;
            objects.splice(objects.indexOf(helper.getObjectByName("picker")), 1);
        }
    }

    select(object: THREE.Object3D | THREE.Object3D[] | null) {
        if (!object) {
            this.selected = null;
            this.setState({showRightPanel: true});
            global.app?.call("objectSelected", this, null);
        }

        const selectedObject = Array.isArray(object) && object.length === 1 ? object[0] : object;

        if (Array.isArray(selectedObject)) {
            this.setState({showRightPanel: false});

            this.selected = selectedObject;

            global.app?.call("objectArraySelected", this, this.selected);
        } else {
            this.props.setIsGameSettingsPanelOpen(
                selectedObject?.uuid !== this.scene.uuid || selectedObject?.uuid === this.scene.uuid,
            );

            this.setState({showRightPanel: true});

            if (selectedObject?.type === "Box3Helper") {
                if (selectedObject.userData.previewBoxId) {
                    const targetObj = this.objectByUuid(selectedObject.userData.previewBoxId);
                    if (targetObj) {
                        this.selected = targetObj;
                    }
                }
            } else {
                this.selected = selectedObject;
            }

            global.app?.call("objectSelected", this, this.selected);
        }
    }

    selectById(id: number) {
        if (id === this.camera.id) {
            this.select(this.camera);
            return;
        }

        this.select(this.scene.getObjectById(id) || null);
    }

    selectByUuid(uuid: string | string[]) {
        if (typeof uuid === "string") {
            if (uuid === this.camera.uuid) {
                this.select(this.camera);
                return;
            }
            const child = this.scene.getObjectByProperty("uuid", uuid);
            if (child) {
                this.select(child);
            }
        } else {
            const selectedObjects: THREE.Object3D[] = [];

            uuid.forEach(id => {
                if (id === this.camera.uuid) {
                    selectedObjects.push(this.camera);
                    return;
                }

                this.scene.traverse(child => {
                    if (child.uuid === id) {
                        selectedObjects.push(child);
                    }
                });
            });

            if (selectedObjects.length > 0) {
                this.select(selectedObjects);
            } else {
                console.log("No objects found for the given UUIDs");
            }
        }
    }

    deselect() {
        this.select(null);
    }

    removeBehavior = (id: string) => {
        if (!global.app) return;
        const selected = global.app?.editor.selected;
        if (!selected) return;

        const obj = global.app?.editor.objectByUuid(selected.uuid);
        if (obj.userData.behaviors && obj.userData.behaviors.some((behavior: any) => behavior.id === id)) {
            obj.userData.behaviors = obj.userData.behaviors.filter((behavior: any) => behavior.id !== id);
        }

        global.app?.call(`objectChanged`, global.app?.editor, global.app?.editor.selected);
        global.app?.call(`objectUpdated`, global.app?.editor, global.app?.editor.selected);
    };

    // ---------------------- 焦点事件 --------------------------

    focus(object: THREE.Object3D) {
        global.app?.call("objectFocused", this, object);
    }

    focusById(id: number) {
        let obj = this.scene.getObjectById(id);
        if (obj) {
            this.focus(obj);
        }
    }

    focusByUUID(uuid: string) {
        if (uuid === this.camera.uuid) {
            this.focus(this.camera);
            return;
        }

        this.scene.traverse(child => {
            if (child.uuid === uuid) {
                this.focus(child);
            }
        });
    }

    execute(cmd: any, optionalName: string = "") {
        this.history.execute(cmd, optionalName);
    }

    undo() {
        this.history.undo();
    }

    redo() {
        this.history.redo();
    }

    toast(content: any, type = "info") {
        let component = this.createElement(
            Message,
            {
                type,
            },
            content,
        );

        this.addElement(component, () => {});

        setTimeout(() => {
            this.removeElement(component, () => {});
        }, 3000);
    }

    createElement(type: any, props: any, children: any) {
        let ref = React.createRef();
        props.ref = ref;
        return React.createElement(type, props, children);
    }

    addElement(element: any, callback: any) {
        let elements = this.state.elements;

        elements.push(element);

        this.setState({elements}, callback);
    }

    removeElement(element: any, callback: any) {
        let elements = this.state.elements;

        let index = elements.findIndex(n => n === element || (n.ref && n.ref.current === element));

        if (index > -1) {
            elements.splice(index, 1);
        }

        this.setState({elements}, callback);
    }

    cloneObjectByUuid = async (id: string) => {
        const app = global.app as Application;
        let object = this.objectByUuid(id);
        if (object) {
            const objectParent = object.parent;
            const loader = new (ModelLoader as any)();

            const url = backendUrlFromPath(object.userData.Url);

            const deepClone = (obj: any) => {
                const clone = obj.clone(false);
                clone.uuid = THREE.MathUtils.generateUUID();
                clone.castShadow = !!object?.castShadow;

                if (clone.geometry) {
                    clone.geometry = clone.geometry.clone();
                    clone.geometry.uuid = THREE.MathUtils.generateUUID();
                }

                if (clone.material) {
                    if (Array.isArray(clone.material)) {
                        clone.material = clone.material.map((material: any) => {
                            const newMaterial = material.clone();
                            newMaterial.uuid = THREE.MathUtils.generateUUID();
                            return newMaterial;
                        });
                    } else {
                        clone.material = clone.material.clone();
                        clone.material.uuid = THREE.MathUtils.generateUUID();
                    }
                }

                obj.children.forEach((child: any) => {
                    const childClone = deepClone(child);
                    clone.add(childClone);
                });

                return clone;
            };

            if (!url) {
                const clone = deepClone(object);
                this.execute(new (AddObjectCommand as any)(clone, objectParent));
                return;
            }

            loader
                .load(url, object.userData, {
                    camera: this.camera,
                    renderer: this.renderer,
                    audioListener: this.audioListener,
                    clearChildren: true,
                })
                .then((obj: any) => {
                    if (!obj) {
                        const clone = deepClone(object!);
                        this.execute(new (AddObjectCommand as any)(clone, objectParent));
                        return;
                    }
                    obj.name = object!.userData.Name;

                    Object.assign(obj.userData, object!.userData, {
                        Server: true,
                    });
                    Object.assign(obj.position, object!.position);
                    Object.assign(obj.scale, object!.scale);
                    obj.rotation.x = object!.rotation.x;
                    obj.rotation.y = object!.rotation.y;
                    obj.rotation.z = object!.rotation.z;
                    obj.castShadow = object!.castShadow;

                    this.execute(new (AddObjectCommand as any)(obj, objectParent));
                })
                .catch((e: any) => {
                    console.error(e);
                    app.toast(t("Could not load model"), "error");
                });
        }
    };

    replaceObjectByUuid(
        id: string,
        newObjectUserData: {
            ID: string;
            Name: string;
            Url: string;
            Type: string;
        },
    ) {
        const app = global.app as Application;
        let object = this.objectByUuid(id);
        if (object) {
            let loader = new (ModelLoader as any)();
            const url = backendUrlFromPath(newObjectUserData.Url);
            loader
                .load(url, newObjectUserData, {
                    camera: this.camera,
                    renderer: this.renderer,
                    audioListener: this.audioListener,
                    clearChildren: true,
                })
                .then((obj: any) => {
                    if (!obj) {
                        return;
                    }

                    const extension = object?.name.split(".")[1]?.split(" ")[0];
                    const name = object?.name.replace(`.${extension}`, `.${newObjectUserData.Type.toLowerCase()}`);
                    obj.name = name;

                    Object.assign(obj.userData, object!.userData, {
                        Server: true,
                        ...newObjectUserData,
                    });
                    Object.assign(obj.position, object!.position);
                    Object.assign(obj.scale, object!.scale);
                    obj.rotation.x = object!.rotation.x;
                    obj.rotation.y = object!.rotation.y;
                    obj.rotation.z = object!.rotation.z;
                    obj.castShadow = object!.castShadow;

                    this.execute(new (AddObjectCommand as any)(obj));
                    this.execute(new (RemoveObjectCommand as any)(object));
                })
                .catch((e: any) => {
                    app.toast(t("Could not load model"), "error");
                });
        }
    }

    moveElementToIndex(element: THREE.Object3D, index: number) {
        const currentIndex = this.scene.children.findIndex(child => child.uuid === element.uuid);

        if (currentIndex > -1) {
            this.scene.children.splice(currentIndex, 1);
        }
        this.scene.children.splice(index, 0, element);
    }

    groupElements(elements: THREE.Object3D[] | THREE.Object3D) {
        if (elements instanceof Array && elements.length > 1) {
            const group = new THREE.Group();
            group.name = "Group";
            const index = this.scene.children.findIndex(child => child.uuid === elements[0].uuid);

            // calculate the center of the group
            const bounds = new THREE.Box3();
            elements.forEach(object => {
                bounds.expandByPoint(object.getWorldPosition(new THREE.Vector3()));
            });
            const center = new THREE.Vector3();
            bounds.getCenter(center);

            group.position.copy(center);

            elements.forEach(object => {
                this.scene.remove(object);
                object.position.sub(center);
                group.add(object);
            });

            this.scene.add(group);
            const groupElement = this.objectByUuid(group.uuid);
            if (groupElement) {
                this.moveElementToIndex(groupElement, index);
            }
            this.select(group);
            global.app?.call("objectUpdated");
        }
    }

    ungroupElements(group: THREE.Object3D[] | THREE.Object3D) {
        if (group instanceof THREE.Group) {
            const elements = [...group.children];

            const index = this.scene.children.findIndex(child => child.uuid === group.uuid);
            elements.forEach(object => {
                this.scene.add(object);
            });

            elements.forEach(object => {
                const obj = this.objectByUuid(object.uuid);
                if (obj) {
                    this.moveElementToIndex(obj, index);
                }
            });
            this.select(null);
            this.scene.remove(group);

            global.app?.call("objectUpdated");
        }
    }

    addDeleteEventListener() {
        document.addEventListener("keydown", e => {
            if ((e.key === "Delete" || e.key === "Backspace") && !(this.selected instanceof Array)) {
                if (this.selected && !isInputActive()) {
                    const object = this.objectByUuid(this.selected.uuid);
                    if (object) {
                        this.execute(new (RemoveObjectCommand as any)(object));
                    }
                }
            }
            if (e.key === "Escape" && this.transformControls) {
                this.select(null);
            }
        });
    }

    copy() {
        if (this.selected && !(this.selected instanceof Array)) {
            const object = this.objectByUuid(this.selected?.uuid);
            if (object) {
                const objectData = object.toJSON();

                navigator.clipboard.writeText("").catch(err => {
                    console.error("Failed to clear clipboard: ", err);
                });
                navigator.clipboard.writeText(JSON.stringify(objectData));
            }
        }
    }

    isPasting = false;

    paste() {
        if (this.isPasting) return;
        this.isPasting = true;
        const app = global.app as Application;
        if (app) {
            navigator.clipboard
                .readText()
                .then(text => {
                    const objectData = JSON.parse(text);
                    const objectLoader = new THREE.ObjectLoader();
                    const clonedObject = objectLoader.parse(objectData);
                    this.cloneObjectFromData(clonedObject);
                })
                .catch(err => {
                    console.error("Failed to read clipboard contents: ", err);
                })
                .finally(() => {
                    this.isPasting = false;
                });
        }
    }

    cloneObjectFromData = async (object: any) => {
        const app = global.app as Application;

        if (object) {
            const objectParent = object.parent;
            const loader = new (ModelLoader as any)();

            const url = backendUrlFromPath(object.userData.Url);

            const deepClone = (obj: any) => {
                const clone = obj.clone(false);
                clone.uuid = THREE.MathUtils.generateUUID();
                clone.castShadow = !!object?.castShadow;

                if (clone.geometry) {
                    clone.geometry = clone.geometry.clone();
                    clone.geometry.uuid = THREE.MathUtils.generateUUID();
                }

                if (clone.material) {
                    if (Array.isArray(clone.material)) {
                        clone.material = clone.material.map((material: any) => {
                            const newMaterial = material.clone();
                            newMaterial.uuid = THREE.MathUtils.generateUUID();
                            return newMaterial;
                        });
                    } else {
                        clone.material = clone.material.clone();
                        clone.material.uuid = THREE.MathUtils.generateUUID();
                    }
                }

                obj.children.forEach((child: any) => {
                    const childClone = deepClone(child);
                    clone.add(childClone);
                });

                return clone;
            };

            if (!url) {
                const clone = deepClone(object);
                this.execute(new (AddObjectCommand as any)(clone, objectParent));
                return;
            }

            loader
                .load(url, object.userData, {
                    camera: this.camera,
                    renderer: this.renderer,
                    audioListener: this.audioListener,
                    clearChildren: true,
                })
                .then((obj: any) => {
                    if (!obj) {
                        const clone = deepClone(object!);
                        this.execute(new (AddObjectCommand as any)(clone, objectParent));
                        return;
                    }
                    obj.name = object!.userData.Name;

                    Object.assign(obj.userData, object!.userData, {
                        Server: true,
                    });
                    Object.assign(obj.position, object!.position);
                    Object.assign(obj.scale, object!.scale);
                    obj.rotation.x = object!.rotation.x;
                    obj.rotation.y = object!.rotation.y;
                    obj.rotation.z = object!.rotation.z;
                    obj.castShadow = object!.castShadow;

                    this.execute(new (AddObjectCommand as any)(obj, objectParent));
                })
                .catch((e: any) => {
                    app.toast(t("Could not load model"), "error");
                });
        }
    };

    duplicateObject() {
        const selection = window.getSelection();
        if (this.selected && !(this.selected instanceof Array)) {
            if (selection?.type !== "Range") {
                this.cloneObjectByUuid(this.selected?.uuid);
            }
        }
    }

    addCopyEventListener() {
        document.addEventListener("keydown", e => {
            if ((e.ctrlKey || e.metaKey) && e.key === "c") {
                this.copy();
            }
        });
    }

    addDuplicateObjEventListener() {
        document.addEventListener("keydown", e => {
            if ((e.ctrlKey || e.metaKey) && e.key === "d") {
                e.preventDefault();
                this.duplicateObject();
            }
        });
    }

    handlePaste = (e: any) => {
        if ((e.ctrlKey || e.metaKey) && e.key === "v" && !isInputActive()) {
            this.paste();
        }
    };

    addPasteEventListener() {
        document.addEventListener("keydown", this.handlePaste);
    }

    removePasteEventListener() {
        document.removeEventListener("keydown", this.handlePaste);
    }

    addUndoRedoListener() {
        document.addEventListener("keydown", e => {
            const isMac = /Mac/i.test(navigator.userAgent);
            if (isMac ? e.metaKey : e.ctrlKey) {
                if (e.key === "z" || e.key === "Z") {
                    if (e.shiftKey) {
                        this.redo();
                    } else {
                        this.undo();
                    }
                }
            }
        });
    }

    toggleUI() {
        this.setState({
            showSidePanels: !this.state.showSidePanels,
        });
    }

    addPanelsVisibilityListener() {
        document.addEventListener("keydown", e => {
            const isMac = /Mac/i.test(navigator.userAgent);
            if ((isMac && e.metaKey && e.key === ".") || (!isMac && e.ctrlKey && e.key === ".")) {
                this.toggleUI();
            }
        });
    }

    addGroupEventListener() {
        document.addEventListener("keydown", e => {
            if ((e.ctrlKey || e.metaKey) && e.key === "g") {
                if (this.selected) {
                    this.groupElements(this.selected);
                }
            }
        });
    }

    addUngroupEventListener() {
        document.addEventListener("keydown", e => {
            if ((e.ctrlKey || e.metaKey) && e.key === "g" && e.shiftKey) {
                if (this.selected) {
                    this.ungroupElements(this.selected);
                }
            }
        });
    }

    removePreviewBoxes() {
        this.sceneHelpers.traverse((object: any) => {
            if (object.userData && object.userData.previewBoxId) {
                const obj = this.scene.getObjectByProperty("uuid", object.userData.previewBoxId);
                if (obj?.userData.physics?.enable_preview) {
                    obj.userData.physics.enable_preview = false;
                }

                this.sceneHelpers.remove(object);
            }
        });
        global.app?.call("objectChanged", this, this);
    }

    removeOjbectPreviewBox(parent: THREE.Object3D) {
        this.sceneHelpers.traverse((object: any) => {
            if (object.userData && object.userData.previewBoxId === parent.uuid) {
                this.sceneHelpers.remove(object);
            }
        });
    }
}

export default Editor;
