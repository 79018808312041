import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {GenericSoundBehaviorInterface, OBJECT_TYPES} from "../../types/editor";

class GenericSoundBehaviorConverter extends BaseBehaviorConverter<GenericSoundBehaviorInterface> {
    public static DEFAULT = new GenericSoundBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: any): any {}
    getBehavior(target: THREE.Object3D, id: string): GenericSoundBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as GenericSoundBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have CHARACTER_SOUNDS behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): GenericSoundBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.GENERIC_SOUND,
            sound: {id: "", name: "", url: ""},
            soundDistance: 10,
            volume: 50,
            loop: false,
            startOnTrigger: false,
        };
    }
}

export default GenericSoundBehaviorConverter;
