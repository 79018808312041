import {useRef} from "react";
import styled from "styled-components";
import {useOnClickOutside} from "usehooks-ts";
import {OBJECT_TYPES} from "../../../../../../types/editor";
import {AllBehaviorsList} from "./AllBehaviorsList/AllBehaviorsList";
import {SelectedBehaviorsList} from "./SelectedBehaviorsList/SelectedBehaviorsList";

interface Props {
    selectedBehavior: any;
    setSelectedBehavior: React.Dispatch<any>;
    copyAllBehaviors: () => void;
    pasteBehavior: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    setIsBehaviorsListDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
    isBehaviorsListDisplayed: boolean;
    addNewBehavior: (type: OBJECT_TYPES) => void;
}

export const MainView = ({
    selectedBehavior,
    setSelectedBehavior,
    copyAllBehaviors,
    pasteBehavior,
    setIsBehaviorsListDisplayed,
    isBehaviorsListDisplayed,
    addNewBehavior,
}: Props) => {
    const menuRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(menuRef, () => setIsBehaviorsListDisplayed(false));

    return (
        <Container>
            <SelectedBehaviorsList
                setIsBehaviorsListDisplayed={setIsBehaviorsListDisplayed}
                setSelectedBehavior={setSelectedBehavior}
                selectedBehavior={selectedBehavior}
                copyAllBehaviors={copyAllBehaviors}
                pasteBehavior={pasteBehavior}
            />

            {isBehaviorsListDisplayed && <AllBehaviorsList addNewBehavior={addNewBehavior} menuRef={menuRef} />}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 11px;
    box-sizing: border-box;
    overflow-y: auto;
`;
