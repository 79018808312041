import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Oval} from "react-loader-spinner";
import * as THREE from "three";

import {ROUTES} from "../../../../AppRouter";
import {formatNumber, getProfilePath, truncateName} from "../../services";
import playIcon from "../assets/playIcon.png";
import remixIcon from "../assets/remix.png";
import copyBtn from "../assets/copyBtn.png";
import {IBasicGameInterface, IUser} from "../../types";

import {SingleGame} from "../SingleGame/SingleGame";
import {
    AvatarContainer,
    Container,
    CopyMessage,
    Description,
    DetailsBlock,
    GameBlock,
    GameData,
    GameDataWrapper,
    GameDetails,
    Games,
    GamesSection,
    Info,
    MainContent,
    RightInfo,
    RightInfoBlock,
    SectionTitle,
    Stats,
    StyledGamesList,
    Tag,
    Tags,
    UrlWrapper,
    UserName,
    Wrapper,
    WrapperInfo,
} from "./PlayPage.style";
import {getGames, updatePlayCount} from "../../../../api/getGames";
import {addLikedGame} from "../../../../api/updateUser";
import {useAuthorizationContext, useHomepageContext} from "../../../../context";
import {ShareModal} from "../../../../editor/assets/v2/common/ShareModal";
import {PAGES} from "../../../../editor/assets/v2/GamesDashboard/constants";
import gamePlaceholder from "../../../assets/game-controller.svg";
import fullScreeenicon from "../../../assets/fullscreen-btn.svg";
import plusIcon from "../../../../editor/assets/v2/icons/plus-bold.svg";
import {StyledButton} from "../../../../editor/assets/v2/common/StyledButton";
import {HeartIcon} from "./HeartIcon";
import {Header} from "../../../Header/Header";
import {Footer} from "../../../Footer/Footer";

export const PlayPage = () => {
    const navigate = useNavigate();
    const {games, setActivePage, fetchGames} = useHomepageContext();
    const {setDbUser, handleGetLikedGames, likedGamesIds, getUser, dbUser} = useAuthorizationContext();

    useEffect(() => {
        setActivePage(PAGES.PLAY);
        fetchGames();
    }, []);

    const [isCopied, setIsCopied] = useState(false);
    const location = useLocation();
    const [selectedGameData, setSelectedGameData] = useState<IBasicGameInterface>();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [userLikedGame, setUserLikedGame] = useState(false);
    const [loadingLike, setLoadingLike] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [hasUpdatedPlayCount, setHasUpdatedPlayCount] = useState(false);
    const [userGames, setUserGames] = useState<IBasicGameInterface[]>([]);
    const [randomGames, setRandomGames] = useState<IBasicGameInterface[]>(games || []);
    const [anotherRandomGames, setAnotherRandomGames] = useState<IBasicGameInterface[]>([]);
    const [gameOwner, setGameOwner] = useState<IUser>();
    const [oldPathname, setOldPathname] = useState("");
    const username = gameOwner?.username || gameOwner?.name;

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    }, [isCopied]);

    useEffect(() => {
        if (likedGamesIds && selectedGameData) {
            const likedGameIndex = likedGamesIds.findIndex(id => id === selectedGameData.ID);
            setUserLikedGame(likedGameIndex !== -1);
        }
    }, [likedGamesIds, selectedGameData]);

    const handleUpdateLikes = async (gameId: string) => {
        setLoadingLike(true);
        const res = await addLikedGame(gameId, setDbUser, () =>
            navigate(ROUTES.LOGIN, {state: {from: location.pathname}}),
        );
        await handleGetLikedGames();
        if (!selectedGameData) {
            toast.error("Something went wrong.");
        } else {
            if (res && selectedGameData) {
                const {playCount, likes} = res;
                setSelectedGameData(prev => ({...prev!, PlayCount: playCount, Likes: likes}));
            }
        }
        setLoadingLike(false);
    };

    const handleUpdatePlayCount = async (game: IBasicGameInterface) => {
        const res = await updatePlayCount(game.ID);
        if (res) {
            const {playCount, likes} = res;
            setSelectedGameData({...game, PlayCount: playCount, Likes: likes});
        }
    };

    useEffect(() => {
        const pathname = location.pathname;
        if (oldPathname !== pathname) {
            setOldPathname(pathname);
            setHasUpdatedPlayCount(false);
            const container = document.getElementById("container");
            if (container) {
                container.scrollTo(0, 0);
            }
            return;
        }

        if (pathname) {
            const match = pathname.match(/\/play\/id-([a-zA-Z0-9]+)-/);
            const id = match ? match[1] : null;
            const gameFromUrl = games.find(el => el.ID === id);
            if (!gameFromUrl) {
                toast.error("Something went wrong. Game not found.");
            } else {
                if (!hasUpdatedPlayCount) {
                    handleUpdatePlayCount(gameFromUrl);
                    setHasUpdatedPlayCount(true);
                }
            }
        }

        if (games && games.length > 0) {
            const randomGames = games.sort(() => Math.random() - 0.5);
            setRandomGames(randomGames);
            const anotherRandomGames = games.sort(() => Math.random() - 0.5);
            setAnotherRandomGames(anotherRandomGames);
        }
    }, [location.key, games, hasUpdatedPlayCount]);

    useEffect(() => {
        (async () => {
            const response = await getGames(gameOwner?.id);

            response && setUserGames(response.filter(game => game.ID !== selectedGameData?.ID));
        })();
    }, [gameOwner]);

    useEffect(() => {
        if (selectedGameData) {
            const getOwner = async () => {
                const response = await getUser(selectedGameData?.UserID);
                response && setGameOwner(response);
            };
            getOwner();
        }
    }, [selectedGameData]);

    const formatPublishedTime = (time?: string) => {
        if (!time) return "...";
        const publishedDate = new Date(time.replace(" ", "T") + "Z");
        const options: Intl.DateTimeFormatOptions = {year: "numeric", month: "long", day: "numeric"};
        return publishedDate.toLocaleDateString("en-US", options);
    };

    const handleGoToProfile = () => {
        if (!gameOwner) return;
        if (!username) return console.error("Username field doesn't exist.");
        navigate(getProfilePath(username));
    };

    const urlPrefix = dbUser
        ? `&UserID=${dbUser.id}&username=${dbUser.username || dbUser.name}`
        : `&username=${"guest" + THREE.MathUtils.generateUUID()}`;

    return (
        <Container>
            <Header />

            <Wrapper>
                <MainContent>
                    <GameBlock>
                        <div className={isFullScreen ? "fullScreenIframe iframeWrapper" : "iframeWrapper"}>
                            <button className="reset-css fullScreenBtn" onClick={() => setIsFullScreen(!isFullScreen)}>
                                <img src={fullScreeenicon} alt="full screen mode" />
                            </button>
                            {selectedGameData?.GameURL ? (
                                <iframe src={selectedGameData?.GameURL + urlPrefix} />
                            ) : (
                                <img className="default-img" src={gamePlaceholder} alt="" />
                            )}
                        </div>
                        <Info>
                            <GameDataWrapper>
                                <GameData>
                                    <GameDetails>
                                        <span className="name">{selectedGameData?.Name}</span>
                                    </GameDetails>
                                    <WrapperInfo>
                                        <UserName>
                                            <AvatarContainer
                                                avatar={gameOwner?.avatar || undefined}
                                                onClick={handleGoToProfile}
                                            />
                                            <div className="container">
                                                <div onClick={handleGoToProfile}>
                                                    {username && truncateName(username, 16)}
                                                </div>
                                                <StyledButton
                                                    width="85px"
                                                    onClick={e => e.stopPropagation()}
                                                    isGreySecondary>
                                                    <img className="plus-icon" src={plusIcon} alt="" />{" "}
                                                    <span className="follow">Follow</span>
                                                </StyledButton>
                                            </div>
                                        </UserName>
                                    </WrapperInfo>
                                </GameData>
                                <Stats>
                                    <button className="data playButton reset-css">
                                        <img src={playIcon} alt="number of players" className="icon" />
                                        <span>
                                            {selectedGameData ? formatNumber(selectedGameData?.PlayCount || 0) : "..."}
                                        </span>
                                    </button>
                                    <button
                                        className="data likeButton reset-css"
                                        onClick={() =>
                                            selectedGameData ? handleUpdateLikes(selectedGameData.ID) : undefined
                                        }>
                                        {loadingLike ? (
                                            <Oval
                                                visible
                                                height="16"
                                                width="16"
                                                strokeWidth={4}
                                                color="#0284c7"
                                                secondaryColor="#333"
                                                ariaLabel="oval-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="loaderWrapper"
                                            />
                                        ) : (
                                            <HeartIcon userLikedGame={userLikedGame} />
                                        )}
                                        {userLikedGame}
                                        <span>{selectedGameData ? selectedGameData?.Likes || 0 : "..."}</span>
                                    </button>
                                    <button className="data reset-css" disabled>
                                        <img src={remixIcon} alt="remin" className="icon" />
                                        <span>Remix</span>
                                    </button>
                                </Stats>
                            </GameDataWrapper>
                            <DetailsBlock>
                                <Description>
                                    <div className="wrapper">
                                        <div className="info">
                                            <span className="title">Published:</span>
                                            <span className="value">
                                                {formatPublishedTime(selectedGameData?.PublishedTime)}
                                            </span>
                                        </div>
                                        <div className="info">
                                            <span className="title">Last Updated:</span>
                                            <span className="value">
                                                {formatPublishedTime(selectedGameData?.UpdateTime)}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="description">
                                        <span className="title">Description</span>
                                        <span className="value">
                                            {selectedGameData?.Description || "No description yet"}
                                        </span>
                                    </div>
                                </Description>
                                <RightInfo>
                                    <RightInfoBlock>
                                        <span>Share</span>
                                        <UrlWrapper>
                                            <CopyMessage $visible={isCopied}>Copied!</CopyMessage>
                                            <div className="textWrapper">{window.location.href}</div>
                                            <div
                                                onClick={() => {
                                                    navigator.clipboard.writeText(window.location.href);
                                                    setIsCopied(true);
                                                }}>
                                                <img src={copyBtn} alt="copy btn" className="copyBtn" />
                                            </div>
                                        </UrlWrapper>
                                    </RightInfoBlock>
                                    <RightInfoBlock>
                                        <span>Tags</span>
                                        <Tags>
                                            {selectedGameData?.Tags?.map((tag, index) => (
                                                <Tag key={tag + index}>
                                                    <span>#</span>
                                                    {tag}
                                                </Tag>
                                            ))}
                                        </Tags>
                                    </RightInfoBlock>
                                </RightInfo>
                            </DetailsBlock>
                        </Info>
                    </GameBlock>
                    {userGames.length > 0 ? (
                        <Games>
                            <span>More by {username}</span>
                            {userGames.map((game, index) => {
                                const recommended = index <= 2;
                                if (!recommended) return;
                                return <SingleGame key={game.Name + index} item={game} />;
                            })}
                        </Games>
                    ) : (
                        <Games>
                            <span>More Games</span>
                            {anotherRandomGames.slice(0, 3).map((game, index) => {
                                const recommended = index <= 2;
                                if (!recommended) return;
                                return <SingleGame key={game.Name + index} item={game} />;
                            })}
                        </Games>
                    )}
                </MainContent>
                <GamesSection>
                    <SectionTitle>More Games</SectionTitle>
                    <StyledGamesList>
                        {randomGames.slice(0, 4).map((game, index) => (
                            <SingleGame item={game} key={game.ID} />
                        ))}
                    </StyledGamesList>
                </GamesSection>
            </Wrapper>
            <Footer />
            {openShareModal && (
                <ShareModal
                    onClose={() => setOpenShareModal(false)}
                    url={window.location.href}
                    title={selectedGameData?.Name}
                />
            )}
        </Container>
    );
};
