/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
const PackageList = [
    {
        name: 'glslprep',
        assets: [
            '/assets/js/glslprep.min.js'
        ]
    },
    {
        name: 'codemirror',
        assets: [
            '/assets/css/codemirror.css',
            '/assets/css/theme/monokai.css',
    ]
}
];

export default PackageList;
