/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseHelper from "./BaseHelper";

import global from "../global";
import * as THREE from "three";
import SpawnPointMarker from "../object/mark/SpawnPointMarker";
import Application from "../Application";

class PointHelper extends BaseHelper {
    private sceneHelpers: THREE.Scene | null | undefined = null;
    private scene: THREE.Scene | null | undefined = null;
    private pointMarkers: SpawnPointMarker[];
    private selectedObject: THREE.Object3D | Array<THREE.Object3D> | null = null;
    constructor() {
        super();
        this.sceneHelpers = null;
        this.scene = null;

        this.pointMarkers = [];

        this.selectedObject = null;
    }

    start() {
        global.app?.on(`objectSelected.${this.id}`, this.onObjectSelected.bind(this));
        global.app?.on(`objectArraySelected.${this.id}`, this.onObjectSelected.bind(this));
        global.app?.on(`objectRemoved.${this.id}`, this.onObjectDeleted.bind(this));
        global.app?.on(`sceneLoaded.${this.id}`, this.onSceneLoaded.bind(this));
        global.app?.on(`objectAdded.${this.id}`, this.onObjectAdded.bind(this));

        document.addEventListener("mousemove", this.onMouseMove.bind(this));
        document.addEventListener("mouseup", this.onMouseUp.bind(this));
    }

    stop() {
        global.app?.on(`objectSelected.${this.id}`, null);
        global.app?.on(`objectArraySelected.${this.id}`, null);
        global.app?.on(`objectRemoved.${this.id}`, null);
        global.app?.on(`sceneLoaded.${this.id}`, null);
        global.app?.on(`objectAdded.${this.id}`, null);

        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);
    }

    onSceneLoaded() {
        const {editor} = global.app as Application;

        this.sceneHelpers = editor?.sceneHelpers;
        this.scene = editor?.scene;

        if (this.sceneHelpers && this.scene) {
            this.pointMarkers.forEach(marker => {
                this.sceneHelpers?.remove(marker);
            });

            this.pointMarkers = [];

            this.scene.traverse(object => {
                if (object.userData.isCheckPoint || object.userData.isSpawnPoint) {
                    this.createPointMarker(object);
                }
            });
        }
    }

    onMouseDown(event: any) {
        if (event.button === 0 && this.selectedObject) {
            this.updatePointMarkers(this.selectedObject);
        }
    }

    onMouseMove() {
        if (this.selectedObject) {
            this.updatePointMarkers(this.selectedObject);
        }
    }

    onMouseUp() {
        if (this.selectedObject) {
            this.updatePointMarkers(this.selectedObject);
        }
    }

    onObjectSelected(object: any) {
        if (!object) {
            this.selectedObject = null;
            return;
        }

        this.selectedObject = object;

        this.updatePointMarker(object);
    }

    onObjectAdded(object: any) {
        if (object.userData.isCheckPoint || object.userData.isSpawnPoint) {
            this.createPointMarker(object);
        }
    }

    onObjectDeleted(object: any) {
        if (!this.sceneHelpers || !this.scene) return;
        const marker = this.pointMarkers.find(m => m.userData.objectUUID === object.uuid);
        if (marker) {
            this.sceneHelpers.remove(marker);
        }
    }

    updatePointMarkers(object: any) {
        if (Array.isArray(object)) {
            object.forEach((obj, index) => {
                this.updatePointMarker(obj);
            });
        } else {
            this.updatePointMarker(object);
        }
    }

    updatePointMarker(object: any) {
        const marker = this.pointMarkers.find(m => m.userData.objectUUID === object.uuid);
        if (marker) {
            marker.position.copy(object.position);
            marker.rotation.copy(object.rotation);
        }
    }

    createPointMarker(obj: any) {
        const marker = new SpawnPointMarker(obj.position, obj.rotation);
        marker.userData.objectUUID = obj.uuid;
        this.pointMarkers.push(marker);
        this.sceneHelpers?.add(marker);
        return marker;
    }
}

export default PointHelper;
