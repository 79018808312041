import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import {
    CUSTOM_VOLUME_TYPES,
    CustomVolumeOptionsInterface,
    GravityVolumeOptionsInterface,
    OBJECT_TYPES,
    SCENE_VOLUME_TYPES,
    SceneVolumeAssetBehaviorInterface,
    WaterVolumeOptionsInterface,
} from "../../types/editor";
import SceneVolumeBehaviorAssetUpdater from "./SceneVolumeBehaviorAssetUpdater";

class SceneVolumeBehaviorAssetConverter extends BaseBehaviorConverter<SceneVolumeAssetBehaviorInterface> {
    public static DEFAULT = new SceneVolumeBehaviorAssetConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: SceneVolumeAssetBehaviorInterface): BehaviorUpdater {
        return new SceneVolumeBehaviorAssetUpdater(target, behavior);
    }

    reset(): void {}

    getBehavior(target: THREE.Object3D): SceneVolumeAssetBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, OBJECT_TYPES.SCENE_VOLUME) as SceneVolumeAssetBehaviorInterface;

        return behavior;
    }

    getDefaultBehavior(): SceneVolumeAssetBehaviorInterface {
        return {
            type: OBJECT_TYPES.SCENE_VOLUME,
            volumeType: SCENE_VOLUME_TYPES.BLOCKING,
            options: null,
        };
    }

    getDefaultWaterVolumeOptions(): WaterVolumeOptionsInterface {
        return {
            swimSpeed: 1,
            dps: 0,
        };
    }

    getDefaultGravityVolumeOptions(): GravityVolumeOptionsInterface {
        return {
            strength: 100,
        };
    }

    getDefaultCustomVolumeOptions(): CustomVolumeOptionsInterface {
        return {
            type: CUSTOM_VOLUME_TYPES.LEVEL_CHANGER,
            loseTime: 5,
            damageAmount: 100,
            losePoints: 100,
        };
    }
}

export default SceneVolumeBehaviorAssetConverter;
