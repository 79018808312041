import styled from "styled-components";
import {flexCenter} from "../../../../assets/style";

export const Container = styled.div`
    width: 100%;
    max-width: 720px;
    height: 640px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #ffffff1a;
    transition: all 0.4s;
    background: var(--theme-container-minor-dark);
    z-index: 999;
    overflow: hidden;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    .close-button {
        position: absolute;
        right: 24px;
        top: 9px;
        font-size: 20px;
        cursor: pointer;
    }

    @media only screen and (min-height: 900px) {
        height: 800px;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    padding: 16px;
    height: 64px;
    gap: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #262626;
`;

export const RightSide = styled.div`
    margin-left: auto;
    ${flexCenter};
    column-gap: 8px;
`;

export const PanelButton = styled.button<{width?: string; $blue?: boolean}>`
    background-color: ${({$blue}) =>
        $blue ? "var(--theme-container-main-blue) !important" : "var(--theme-grey-bg) !important"};
    height: 32px;
    width: ${({width}) => width || "120px"};
    color: #fff;
    ${flexCenter};
    column-gap: 4px;
    border-radius: 8px;
    font-family: "Inter";
    font-size: var(--theme-font-size-xs);
    font-weight: var(--theme-font-medium-plus);
`;

export const SearchSection = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    box-sizing: border-box;
`;

export const Title = styled.div`
    color: white;
    font-size: var(--theme-font-size-s);
    font-weight: var(--theme-font-bold);
`;

export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 8px;
    height: 64px;
    padding: 16px;
    border-top: 1px solid #262626;
`;
