import {useEffect, useState} from "react";
import {StyledButton} from "../../../../../common/StyledButton";
import {useHUDContext, useHUDGameContext} from "../../../../../../../../context";
import {UIIconSelection} from "../../UIIconSelection/UIIconSelection";

import whiteEllipse from "./icons/whiteEllipse.svg";
import redEllipse from "./icons/redEllipse.svg";
import yellowEllipse from "./icons/yellowEllipse.svg";
import whiteTriangle from "./icons/whiteTriangle.svg";
import blueTriangle from "./icons/blueTriangle.svg";
import purpleTriangle from "./icons/purpleTriangle.svg";
import yellowTriangle from "./icons/yellowTriangle.svg";
import greenTriangle from "./icons/greenTriangle.svg";

import {HUD_TABS, Icon, IMiniMapInterface, MINI_MAP_STYLES} from "../../../types";
import {UploadField} from "../../../../../common/UploadField/UploadField";
import {FileData} from "../../../../../types/file";
import {backendUrlFromPath} from "../../../../../../../../utils/UrlUtils";
import {Wrapper} from "../../../commonStyle";
import {SelectRow} from "../../../../../RightPanel/common/SelectRow";
import {Item} from "../../../../../common/BasicCombobox";
import {ColorSelectionRow} from "../../../../../RightPanel/common/ColorSelectionRow";
import {PanelCheckbox} from "../../../../../RightPanel/common/PanelCheckbox";
import {MapImgWrapper} from "./AddMiniMap.style";

const ICONS = [
    {src: whiteEllipse, alt: "whiteEllipse"},
    {src: redEllipse, alt: "redEllipse"},
    {src: yellowEllipse, alt: "yellowEllipse"},
    {src: whiteTriangle, alt: "whiteTriangle"},
    {src: blueTriangle, alt: "blueTriangle"},
    {src: purpleTriangle, alt: "purpleTriangle"},
    {src: yellowTriangle, alt: "yellowTriangle"},
    {src: greenTriangle, alt: "greenTriangle"},
];

export const AddMiniMap = () => {
    const {popupCallback, activeScreen, popupId} = useHUDContext();
    const {gameLayout} = useHUDGameContext();

    let obj;

    if (activeScreen === HUD_TABS.GAME_HUD && popupId) {
        const bannerData = gameLayout?.[popupId as keyof typeof gameLayout];
        if (bannerData) {
            obj = bannerData as IMiniMapInterface;
        }
    }
    const [UIStyle, setUIStyle] = useState(obj?.UIStyle || MINI_MAP_STYLES.DARK_VERSION);
    const [enemyColor, setEnemyColor] = useState(obj?.enemyColor || "#000");
    const [teamColor, setTeamColor] = useState(obj?.teamColor || "#00f");
    const [iconSelected, setIconSelected] = useState<Icon | undefined>(obj?.iconSelected || undefined);
    const [uploadedMapImg, setUploadedMapImage] = useState<FileData | null | string>(obj?.uploadedMapImg || null);
    const [UIStyleOptions, setUIStyleOptions] = useState<Item[]>([]);
    const [useMiniMapCamera, setUseMiniMapCamera] = useState<boolean>(obj ? obj.useMiniMapCamera : false);

    useEffect(() => {
        const UIStyleValues = Object.values(MINI_MAP_STYLES);
        setUIStyleOptions(
            UIStyleValues.map((option: string, index: number) => {
                return {
                    key: `${index + 1}`,
                    value: option,
                };
            }),
        );
    }, []);

    return (
        <>
            <Wrapper>
                <SelectRow
                    $margin="0"
                    label="Mini Map Theme"
                    data={UIStyleOptions}
                    value={UIStyleOptions.find(item => item.value === UIStyle) || UIStyleOptions[0]}
                    onChange={item => setUIStyle(item.value as MINI_MAP_STYLES)}
                />
                <ColorSelectionRow $margin="0" value={enemyColor} setValue={setEnemyColor} label="Enemy Color" />
                <ColorSelectionRow $margin="0" value={teamColor} setValue={setTeamColor} label="Team Color" />
                <UIIconSelection icons={ICONS} iconSelected={iconSelected} setIconSelected={setIconSelected} />
                <MapImgWrapper>
                    <div className="title">Mini Map Image</div>
                    <UploadField
                        width="100%"
                        height="210px"
                        uploadedFile={uploadedMapImg}
                        setUploadedFile={setUploadedMapImage}
                    />
                </MapImgWrapper>
                <PanelCheckbox
                    text="Use Mini Map Camera"
                    checked={!!useMiniMapCamera}
                    onChange={() => setUseMiniMapCamera(!useMiniMapCamera)}
                />
            </Wrapper>
            <StyledButton
                margin="16px 0 0"
                isBlue
                onClick={() => {
                    popupCallback &&
                        popupCallback({
                            UIStyle,
                            iconSelected,
                            enemyColor,
                            teamColor,
                            uploadedMapImg: backendUrlFromPath(uploadedMapImg),
                        });
                }}>
                Apply
            </StyledButton>
        </>
    );
};
