import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import loadingGif from "../../../assets/image/loading.gif";

type LoadMaskProps = {
    className?: string;
    style?: React.CSSProperties;
    show?: boolean;
    text?: string;
};

const LoadMaskWrapper = styled.div<{$show: boolean}>`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    display: ${props => (props.$show ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    z-index: 2000;

    &.hidden {
        display: none;
    }
`;

const Box = styled.div`
    position: relative;
    background: #c3daf9;
    border: 1px solid #6593cf;
    padding: 2px;
`;

const Message = styled.div`
    font: normal 12px "Microsoft YaHei";
    line-height: 16px;
    color: #222;
    background: #fbfbfb url("${loadingGif}") no-repeat 5px 5px;
    padding: 5px 10px 5px 25px;
    border: 1px solid #a3bad9;
    cursor: wait;
`;

const SimpleLoadMask: React.FC<LoadMaskProps> = ({
    className,
    style,
    show = true,
    text = "Waiting...",
}) => {
    return (
        <LoadMaskWrapper
            className={classNames(className, {hidden: !show})}
            style={style}
            $show={show}>
            <Box>
                <Message>{text}</Message>
            </Box>
        </LoadMaskWrapper>
    );
};

export default SimpleLoadMask;
