import { CloseIconWrapper, StyledUploadButton } from "../../common/UploadField/UploadField.style";
import { ImageSectionWrapper } from "./ImageSection";
import closeIcon from "../../icons/trash.svg";
import * as THREE from "three";

interface Props {
    label: string;
    image: string;
    setImage: React.Dispatch<React.SetStateAction<string>>;
    handleBumpImageChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
    ) => void;
    imageKeyName: string;
    inputId: string;
    selected: THREE.Object3D;
    updateMaterialProperties: (selected: THREE.Object3D) => void;
}

export const MaterialBumpSection = ({
    label,
    image: materialBumpImage,
    setImage,
    handleBumpImageChange,
    imageKeyName,
    inputId,
    selected,
    updateMaterialProperties
}: Props) => {

    return (
        <ImageSectionWrapper>
            <span className="text">{label}</span>
            <StyledUploadButton
                width="80px"
                height="80px"
                $bgImage={materialBumpImage}
                onClick={() => document.getElementById(inputId)?.click()}
            >
                {(!materialBumpImage || materialBumpImage === "") && "+"}
                {materialBumpImage && (
                    <CloseIconWrapper
                        className="closeIconWrapper"
                        onClick={e => {
                            e.stopPropagation();
                            if (selected && selected.material as THREE.Material) {
                                selected.userData.materialSettings.textureBumpBase64 = null;
                                selected.traverse(child => {
                                    if (child instanceof THREE.Mesh && child.material) {
                                        child.material.bumpScale = 0;
                                        child.material.needsUpdate = true;
                                    }
                                });
                            }
                            setImage("");
                            updateMaterialProperties(selected);
                            const fileInput = document.getElementById(inputId) as HTMLInputElement;
                            if (fileInput) {
                                fileInput.value = "";
                            }
                        }}
                    >
                        <img src={closeIcon} alt="remove file" />
                    </CloseIconWrapper>
                )}
                <input
                    id={inputId}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={e => handleBumpImageChange(e, imageKeyName)}
                />
            </StyledUploadButton>
        </ImageSectionWrapper>
    );
};