import {COLLISION_MATERIAL_TYPE} from "../../../../types/editor";

export enum CollisionType {
    Static = "Static",
    Dynamic = "Dynamic",
    Kinematic = "Kinematic",
}

export enum Shape {
    btBoxShape = "BoxShape",
    btSphereShape = "SphereShape",
    btConcaveHullShape = "ConcaveHullShape",
    btConvexHullShape = "ConvexHullShape",
    btCapsuleShape = "CapsuleShape",
}

export interface IPhysics {
    enabled: boolean;
    shape: keyof Shape;
    shapeData: any;
    shapeOffset: {
        x: number;
        y: number;
        z: number;
    };
    shapeScale: {
        x: number;
        y: number;
        z: number;
    };
    mass: number;
    inertia: {
        x: number;
        y: number;
        z: number;
    };
    ctype: CollisionType;
    position: {
        x: number;
        y: number;
        z: number;
    };
    scale: {
        x: number;
        y: number;
        z: number;
    };
    rotation: {
        x: number;
        y: number;
        z: number;
    };
    anchorOffset?: {
        x: number;
        y: number;
        z: number;
    };
    enable_preview: boolean;
    collision_material: COLLISION_MATERIAL_TYPE;
}
