/* eslint-disable react/no-multi-comp */
import React, {useState, useRef, useLayoutEffect, useEffect, RefObject} from "react";
import {StickyBoxRoot} from "./StickyBox.style";
import {useSmoothScrollY} from "../../../../v2/hooks/useScrollY";
import {animatedScroll} from "../../../../v2/utils/animatedScroll";
import {useWindowSize} from "../../../../v2/hooks/useWindowSize";

export interface StickyBoxProps {
    topOffset?: number;
    bottomOffset?: number;
    bottomMargin?: number;
    children: React.ReactNode;
    isEnabled?: boolean;
    contentElement?: RefObject<HTMLDivElement>;
    scrollStinkyBoxContent?: boolean;
}

export const StickyBox: React.FC<StickyBoxProps> = ({
    isEnabled,
    topOffset = 72,
    bottomOffset = 0,
    bottomMargin = 72,
    children,
    contentElement,
    scrollStinkyBoxContent,
}) => {
    if (isEnabled) {
        return (
            <StickyBoxEnabled
                bottomOffset={bottomOffset}
                bottomMargin={bottomMargin}
                isEnabled={isEnabled}
                topOffset={topOffset}
                contentElement={contentElement}
                scrollStinkyBoxContent={scrollStinkyBoxContent}>
                {children}
            </StickyBoxEnabled>
        );
    }
    return <React.Fragment>{children}</React.Fragment>;
};

const StickyBoxEnabled: React.FC<StickyBoxProps> = ({
    topOffset = 92,
    bottomOffset = 0,
    bottomMargin = 30,
    children,
    contentElement,
    scrollStinkyBoxContent,
}) => {
    const scrollY = useSmoothScrollY();

    const containerRef = useRef<HTMLDivElement>(null);
    const {height} = useWindowSize();

    const originalVerticalPosition = useRef<number | null>(null);
    const originalWidth = useRef<number | null>(null);
    const maxHeight = useRef(0);

    const [isFixed, setFixed] = useState(false);
    const [top, setTop] = useState(topOffset);
    const [canBeScrolled, setCanBeScrolled] = useState(false);

    useEffect(() => {
        if (!scrollStinkyBoxContent) {
            return;
        }
        document.onwheel = (e: WheelEvent) => {
            const isEndOfPage = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 10;
            const scrolledDown = e.deltaY > 0;

            if (isEndOfPage && scrolledDown && containerRef.current) {
                animatedScroll({
                    element: containerRef.current,
                    toY: containerRef.current.scrollTop + 150,
                    duration: 100,
                });
            }
        };

        return () => {
            document.onwheel = null;
        };
    }, [scrollStinkyBoxContent]);

    // set ref dimension values from container div ref
    useLayoutEffect(() => {
        if (containerRef.current && originalVerticalPosition.current === null) {
            const {top, y, width} = containerRef.current.getBoundingClientRect();

            originalVerticalPosition.current = (top || y) + scrollY;
            originalWidth.current = width;
            maxHeight.current = window.innerHeight - topOffset;
        }
    }, [scrollY]);

    // toggle fixed state basing on window scrollY position and available vertical space
    useLayoutEffect(() => {
        if (originalVerticalPosition.current !== null) {
            const totalBoxHeight = originalVerticalPosition.current;
            const availableWindowHeight = document.body.offsetHeight - totalBoxHeight;

            const isHeightEnough = availableWindowHeight > bottomOffset;
            const isFixedPosition = scrollY + topOffset >= originalVerticalPosition.current;

            setFixed(isHeightEnough && isFixedPosition);
        }
    }, [scrollY, height]);

    // toggle top and bottom state basing on window scrollY position and element height

    useLayoutEffect(() => {
        if (contentElement && contentElement.current && containerRef && containerRef.current) {
            const {top, height, bottom} = contentElement.current.getBoundingClientRect();
            const {
                height: boxHeight,
                bottom: boxBottomPosition,
                top: boxTopPosition,
            } = containerRef.current.getBoundingClientRect();

            const container = document.getElementById("container");

            const contentHeight = Math.ceil(height) + top + (container?.scrollTop || 0);

            const contentElementBottomPosition = bottom - bottomMargin;

            if (boxHeight < contentHeight && isFixed) {
                if (
                    contentElementBottomPosition <= boxBottomPosition ||
                    (boxTopPosition < 0 && contentElementBottomPosition > boxBottomPosition)
                ) {
                    if (contentElementBottomPosition - boxHeight < 0) {
                        setTop(contentElementBottomPosition - boxHeight + topOffset);
                    } else {
                        setTop(topOffset);
                    }
                } else if (contentElementBottomPosition > maxHeight.current) {
                    setTop(topOffset);
                }

                setCanBeScrolled(true);
            }
        }
    }, [height, scrollY, contentElement, containerRef, isFixed]);

    return (
        <>
            {canBeScrolled ? (
                <StickyBoxRoot
                    $isSticky={isFixed}
                    $top={top < 0 ? "initial" : `${top}px`}
                    $bottom={top < 0 ? "0px" : `initial`}
                    $width={originalWidth.current ?? 0}
                    $maxHeight={maxHeight.current}
                    ref={containerRef}>
                    {children}
                </StickyBoxRoot>
            ) : (
                <div ref={containerRef}>{children}</div>
            )}
        </>
    );
};
