import {StyledButton} from "../common/StyledButton";
import styled from "styled-components";

interface Props {
    showModelAnimationCombiner: () => void;
}

export const MainButtons = ({showModelAnimationCombiner}: Props) => {
    return (
        <Wrapper>
            <StyledButton onClick={showModelAnimationCombiner} isBlue margin="16px 0 0 0">
                3D Model Editor
            </StyledButton>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: absolute;
    bottom: 12px;
    left: 8px;
    right: 8px;
`;
