import Command from "./Command";
import global from "../global";

class MoveObjectCommand extends Command {
    constructor(object, newParent, newBefore) {
        super();
        this.type = "MoveObjectCommand";
        this.name = _t("Move Object");

        this.object = object;
        this.oldParent = object?.parent || null;
        this.oldIndex = this.oldParent
            ? this.oldParent.children.indexOf(this.object)
            : null;
        this.newParent = newParent || null;

        if (newBefore !== undefined) {
            this.newIndex = this.newParent
                ? this.newParent.children.indexOf(newBefore)
                : null;
        } else {
            this.newIndex = this.newParent
                ? this.newParent.children.length
                : null;
        }

        if (
            this.oldParent === this.newParent &&
            this.newIndex > this.oldIndex
        ) {
            this.newIndex--;
        }

        this.newBefore = newBefore;
    }

    execute() {
        if (this.newParent) {
            if (this.oldParent) {
                this.oldParent.remove(this.object);
            }

            var children = this.newParent.children;
            children.splice(this.newIndex, 0, this.object);
            this.object.parent = this.newParent;
        } else if (this.oldParent) {
            var children = this.oldParent.children;
            children.splice(this.oldIndex, 1); // Remove from old position
            children.splice(this.newIndex, 0, this.object); // Insert at new position
            this.object.parent = this.oldParent;
        } else {
            console.log("No new or old parent");
        }
        global.app?.call("sceneGraphChanged", this);
    }

    undo() {
        if (this.oldParent) {
            if (this.newParent) {
                this.newParent.remove(this.object);
            }

            var children = this.oldParent.children;
            children.splice(this.oldIndex, 0, this.object);
            this.object.parent = this.oldParent;
        } else if (this.newParent) {
            var children = this.newParent.children;
            children.splice(this.newIndex, 1); // Remove from new position
            children.splice(this.oldIndex, 0, this.object); // Insert at old position
            this.object.parent = this.newParent;
        }
        global.app?.call("sceneGraphChanged", this);
    }

    toJSON() {
        var output = Command.prototype.toJSON.call(this);

        output.objectUuid = this.object.uuid;
        output.newParentUuid = this.newParent ? this.newParent.uuid : null;
        output.oldParentUuid = this.oldParent ? this.oldParent.uuid : null;
        output.newIndex = this.newIndex;
        output.oldIndex = this.oldIndex;

        return output;
    }

    fromJSON(json) {
        Command.prototype.fromJSON.call(this, json);

        this.object = this.editor.objectByUuid(json.objectUuid);
        this.oldParent =
            this.editor.objectByUuid(json.oldParentUuid) || this.editor.scene;
        this.newParent =
            this.editor.objectByUuid(json.newParentUuid) || this.editor.scene;
        this.newIndex = json.newIndex;
        this.oldIndex = json.oldIndex;
    }
}

export default MoveObjectCommand;
