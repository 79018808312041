import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../../assets/style";

export const Wrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
    row-gap: 12px;
    box-sizing: border-box;
    width: 100%;
`;

export const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    width: 100%;
`;

export const BoxLabels = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
    .title {
        ${regularFont("xxs")}
        font-weight: var(--theme-font-medium);
        text-align: left;
    }
    .titleSecondary {
        ${regularFont("xxs")};
        text-align: left;
        color: var(--theme-font-unselected-color);
    }
`;

export const RightSide = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
    ${regularFont("xxs")}
    color: var(--theme-font-unselected-color);
`;

export const LockIcon = styled.img<{$locked: boolean}>`
    cursor: pointer;
    ${({$locked}) => $locked && ` filter: brightness(100);`}
`;

export const BoxInputs = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 4px;
`;

export const InputWrapper = styled.div`
    position: relative;
    input {
        padding: 6px 4px 6px 18px;
        width: 40px;
        background: #262626;
    }
`;
