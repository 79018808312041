export const noSelfAssign = (lines: string[], markers: any[], monaco: any) => {
    lines.forEach((line, index) => {
        const trimmedLine = line.trim();

        if (trimmedLine.includes("self = ")) {
            const isDeclaration =
                trimmedLine.startsWith("const self") ||
                trimmedLine.startsWith("let self") ||
                trimmedLine.startsWith("var self");

            if (!isDeclaration) {
                const startIndex = line.indexOf("self = ");
                const rightSide = line.substring(startIndex + "self = ".length).trim();

                let endIndex = startIndex + "self = ".length + rightSide.length;

                markers.push({
                    severity: monaco.MarkerSeverity.Error,
                    startLineNumber: index + 1,
                    startColumn: startIndex + 1,
                    endLineNumber: index + 1,
                    endColumn: endIndex + 1,
                    message: "Avoid assigning a value to global 'self'. Declare 'self' using 'const', 'let', or 'var'.",
                });
            }
        }
    });
};
