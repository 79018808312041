import styled from "styled-components";

export const MapImgWrapper = styled.div`
    width: 100%;
    .title {
        font-size: 12px;
        text-align: left;
        width: 100%;
        color: var(--theme-font-unselected-color);
        margin-bottom: 5px;
    }
`;
