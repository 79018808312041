import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../../../../assets/style";

export const PublishPanelContainer = styled.div`
    width: 240px;
    height: auto;
    border-radius: 16px;
    background-color: var(--theme-container-main-dark);
    position: absolute;
    top: -8px;
    left: -16px;
    transform: translateX(-100%);
    padding: 8px;
    border: 1px solid #ffffff1a;
`;

export const MainHeading = styled.div`
    ${flexCenter};
    justify-content: space-between;
    .label {
        ${regularFont("xs")};
        font-weight: var(--theme-font-bold);
    }
`;

export const PostPublishHeading = styled.div`
    ${flexCenter};
    justify-content: flex-start;
    column-gap: 4px;

    img {
        width: 16px;
        height: 16px;
    }
`;

export const Heading = styled.div`
    ${flexCenter};
    justify-content: space-between;
    .label {
        ${regularFont("xxs")};
        font-weight: var(--theme-font-medium);
    }
`;

export const Description = styled.div`
    font-size: 11px;
    color: var(--theme-font-unselected-color);
    line-height: 120%;
    margin-top: 8px;
`;

export const CopyURLContainer = styled.div`
    ${flexCenter};
    column-gap: 4px;
    margin-top: 12px;
    .url {
        width: 188px;
        height: 32px;
        padding: 8px;
        border-radius: 8px;
        background-color: var(--theme-grey-bg);

        ${regularFont("xxs")};
        white-space: nowrap;
        color: var(--theme-font-unselected-secondary-color);
    }
    img {
        width: 16px;
        height: 16px;
    }
`;
