import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";

abstract class BaseBehaviorConverter<T> {
    protected obj: any;

    protected constructor(obj: any) {
        this.obj = obj;
    }

    findBehavior(target: THREE.Object3D, id: string) {
        if (target.userData.behaviors && !Array.isArray(target.userData.behaviors)) return;
        const objBehavior = target.userData.behaviors?.find((userBehavior: any) => userBehavior.id === id);

        return objBehavior;
    }

    abstract getBehavior(target: THREE.Object3D, behaviorId: string): T | null;

    abstract getDefaultBehavior(behaviorId: string): T;

    abstract convert(target: THREE.Object3D, behavior: T): BehaviorUpdater;
}

export default BaseBehaviorConverter;
