import React, {useEffect, useState} from "react";
import Application from "../../../../../Application";
import global from "../../../../../global";
import * as THREE from "three";
import {Container, Content, Title} from "./ScriptsPanel.style";
import {OBJECT_TYPES, ScriptBehaviorInterface} from "../../../../../types/editor";
import {ScriptsPanelItem} from "./ScriptsPanelItem/ScriptsPanelItem";

export const ScriptsPanel = () => {
    const app = global.app as Application;
    const [scripts, setScripts] = useState<ScriptBehaviorInterface[]>([]);

    const getObjectBehavior = (obj: THREE.Object3D) => {
        const objBehavior = obj.userData.behaviors?.find(
            (userBehavior: any) => userBehavior.type === OBJECT_TYPES.SCRIPT,
        );

        return objBehavior as ScriptBehaviorInterface;
    };

    const handleClick = (e: any, script: ScriptBehaviorInterface) => {
        if (e.target.nodeName === "INPUT" || e.target.nodeName === "LABEL") return;
        if (script) {
            const {id, scriptType, source, name} = script;
            app?.call(
                `editScript`,
                this,
                id,
                name,
                scriptType,
                source,
                (id: string, name: string, type: string, source: string) => handleSaveScript(script, source),
            );
        }
    };

    const handleSaveScript = (script: ScriptBehaviorInterface, source: string) => {
        if (script) {
            script.source = source;
        }
    };

    const handleCheckboxChange = (script: ScriptBehaviorInterface, checked: boolean) => {
        script.enabled = checked;
    };

    const update = () => {
        const scene = app.editor?.scene;
        setScripts([]);
        if (scene) {
            scene.traverse(child => {
                if (child.userData.behaviors) {
                    const scriptBehavior = getObjectBehavior(child);
                    if (scriptBehavior) {
                        setScripts(scripts => [...scripts, scriptBehavior]);
                    }
                }
            });
        }
    };

    useEffect(() => {
        update();
        app.on("objectChanged.ScriptsPanel", update);
        return () => {
            setScripts([]);
        };
    }, []);

    return (
        <Container>
            <Title>Scripts</Title>
            <Content>
                {scripts.map((script, index) => (
                    <ScriptsPanelItem
                        key={script.id}
                        script={script}
                        onClick={handleClick}
                        onCheckChange={handleCheckboxChange}
                        isLast={index === scripts.length - 1}
                    />
                ))}
            </Content>
        </Container>
    );
};
