import {useEffect} from "react";
import {LayoutButton} from "../LayoutButton/LayoutButton";
import {ButtonsColumn, Grid} from "./InGameMenu.style";
import {HUD_TABS, IN_GAME_MENU_IDS, LAYOUT_BUTTON_TYPE} from "../types";
import {useHUDInGameMenuContext} from "../../../../../../context";

export const InGameMenu = () => {
    const {useInitialSetup} = useHUDInGameMenuContext();

    useEffect(() => {
        useInitialSetup();
        document.getElementById(IN_GAME_MENU_IDS.LOGO_MID)?.click();
    }, []);

    return (
        <Grid>
            <ButtonsColumn>
                <LayoutButton
                    tab={HUD_TABS.IN_GAME_MENU}
                    // Styling should always be the same as in HUDView/InGameView
                    width="285px"
                    height="285px"
                    type={LAYOUT_BUTTON_TYPE.ADD_GAME_LOGO}
                    id={IN_GAME_MENU_IDS.LOGO_MID}
                    plusIconBig={true}
                />
                {Array(5)
                    .fill(5)
                    .map((_, index) => (
                        <LayoutButton
                            tab={HUD_TABS.IN_GAME_MENU}
                            key={index}
                            // Styling should always be the same as in HUDView/InGameView
                            width="285px"
                            height="32px"
                            type={LAYOUT_BUTTON_TYPE.ADD_GAME_BUTTON}
                            id={`in-game-menu-button-column-mid-${index + 1}` as IN_GAME_MENU_IDS}
                        />
                    ))}
            </ButtonsColumn>
        </Grid>
    );
};
