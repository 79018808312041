import {regularFont} from "../../assets/style";
import styled from "styled-components";

export const FOOTER_HEIGHT = "320px";
export const FOOTER_MARGIN_TOP = "40px";

export const StyledFooter = styled.footer`
    position: relative;
    z-index: 1;
    overflow: visible;
    width: 100vw;
    height: ${FOOTER_HEIGHT};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid var(--theme-homepage-grey-bg-primary);
    font-size: 14px;
    padding: 40px;
    margin-top: ${FOOTER_MARGIN_TOP};
    background-color: var(--theme-container-main-dark);

    .copyright {
        color: var(--theme-homepage-placeholder-color);
    }
`;

export const ShadowContainer = styled.div`
    position: absolute;
    z-index: 2;
    top: -250px;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
`;

export const LeftColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

export const MidColumn = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 80px;
`;

export const InsideColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 16px;

    .label {
        ${regularFont("s")};
        font-weight: 500;
    }

    .option {
        ${regularFont("s")};
        color: var(--theme-homepage-placeholder-color);
    }
    .disabled {
        cursor: not-allowed;
    }
`;

export const RightColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
`;
