import {regularFont} from "../../../../../assets/style";
import styled from "styled-components";
import {StyledButton} from "../../common/StyledButton";
import {useModelAnimationCombinerContext} from "../../../../../context";
import global from "../../../../../global";
import Ajax from "../../../../../utils/Ajax";
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";
import JSZip from "jszip";
import * as THREE from "three";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import {ModelUtils} from "../../../../../utils/ModelUtils";
import {toast} from "react-toastify";

const Section = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 16px;
    margin-top: auto;
    box-sizing: border-box;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    button {
        ${regularFont("s")};
    }
`;

const Title = styled.div`
    ${regularFont("l")};
`;

type Props = {
    onSave: () => void;
    model: any;
};

export const Save = ({onSave, model}: Props) => {
    const app = (global as any).app;
    const {animations, mainModel, toggleLoading, action} = useModelAnimationCombinerContext();

    const detectFormat = (filename: string): string | null => {
        const extension = filename.split(".").pop()?.toUpperCase();
        if (!extension) return null;

        switch (extension) {
            case "GLB":
                return "GLB";
            case "GLTF":
            case "GLTF2":
                return "GLTF";
            default:
                return null;
        }
    };

    const handleSave = () => {
        if (model) {
            Object.assign(model._obj, {
                animations: animations,
            });
            const format = detectFormat(model.userData.Name);
            action?.stop();
            saveModel(format);
            app.editor.scene.userData.lastSaveTime = new Date().toISOString();
            app.saveScene();
            onSave();
        }
    };

    const commitModelChanges = (data: Blob, newName: string, isAdd: boolean) => {
        try {
            const file = new File([data], newName);

            const zipper = new JSZip();

            zipper.file(file.name, file);

            zipper.generateAsync({type: "blob"}).then(async zip => {
                const zippedFile = new File([zip], `${newName}.zip`);
                if (!isAdd) {
                    try {
                        const response = await Ajax.post({
                            url: backendUrlFromPath(`/api/Mesh/Upload`)!,
                            data: {
                                file: zippedFile,
                            },
                        });

                        const res = response?.data;
                        if (res.Code === 200) {
                            if (res.Data.Url) {
                                const payload = {
                                    ID: model.userData.ID,
                                    Name: newName,
                                    Url: res.Data.Url,
                                };
                                app.editModel(payload);

                                app.editor.scene.traverse((child: any) => {
                                    if (child.userData && child.userData.ID === model.userData.ID) {
                                        app.editor.replaceObjectByUuid(child.uuid, {
                                            ...payload,
                                            Type: model.userData.Type,
                                        });
                                    }
                                });
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        toggleLoading();
                    }
                } else {
                    try {
                        const addResponse = await Ajax.post({
                            url: backendUrlFromPath(`/api/Mesh/Add`),
                            data: {
                                file: zippedFile,
                            },
                        });

                        if (addResponse?.data.Code === 200) {
                            const data = addResponse.data.Data;
                            const payload = {
                                ID: data.ID,
                                Name: data.Name,
                                Image: model.userData.Thumbnail,
                                IsPublic: !!model.userData.IsPublic,
                            };
                            app.editModel(payload);

                            const oldModelID = model.userData.ID;

                            await Ajax.post({
                                url: backendUrlFromPath(`/api/Mesh/Delete?ID=${oldModelID}`),
                            });

                            app.editor.scene.traverse((child: any) => {
                                if (child.userData && child.userData.ID === oldModelID) {
                                    app.editor.replaceObjectByUuid(child.uuid, addResponse.data.Data);
                                }
                            });

                            app.saveScene();
                        } else {
                            app.toast("This file type is not supported.", "error");
                        }
                        app.call("fetchModels");
                    } catch (error) {
                        app.toast("Failed to save model", "error");
                    } finally {
                        toggleLoading();
                    }
                }
            });
        } catch (error) {
            toggleLoading();
            console.log(error);
        }
    };

    const saveArrayBuffer = (buffer: ArrayBuffer, name: string, isAdd: boolean) => {
        commitModelChanges(new Blob([buffer]), name, isAdd);
    };

    const saveString = (text: string, name: string, isAdd: boolean) => {
        const blob = new Blob([text], {type: "text/plain"});
        commitModelChanges(blob, name, isAdd);
    };

    const saveModel = (format: string | null) => {
        const gltfExporter = new GLTFExporter();
        // Parse the input and generate the glTF output
        Object.assign(mainModel.animations, animations);
        Object.assign(mainModel._obj.animations, animations);

        try {
            toggleLoading();

            gltfExporter.parse(
                mainModel,
                async result => {
                    const name = format ? model.userData.Name : `${model.userData.Name.split(".")[0]}.glb`;
                    if (result instanceof ArrayBuffer) {
                        let arrayBuffer = result as ArrayBuffer;
                        arrayBuffer = (await ModelUtils.compressModel(arrayBuffer, false, () => {
                            toast.warn("Could not compress model");
                        })) as ArrayBuffer;
                        saveArrayBuffer(arrayBuffer, name, !format);
                    } else {
                        saveString(JSON.stringify(result), name, !format);
                    }
                },
                error => {
                    console.log(error);
                },
                {
                    trs: true,
                    binary: !format || format === "GLB",
                    animations: animations as THREE.AnimationClip[],
                },
            );
        } catch (error) {
            toggleLoading();
            console.log(error);
        }
    };

    return (
        <Section>
            <StyledButton isBlue style={{margin: "0 auto"}} onClick={handleSave} className="blueBtn">
                Save Model
            </StyledButton>
        </Section>
    );
};
