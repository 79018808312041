import styled from "styled-components";

export const PlayButton = styled.button`
    width: 32px;
    height: 32px;
    img {
        width: 100%;
        height: 100%;
    }
`;
