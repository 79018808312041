/* eslint-disable react/no-multi-comp */

import {useCallback, useEffect, useState} from "react";
import * as THREE from "three";
import {useLightingContext} from "../../../../../../context";
import {ITransformValue} from "../../../../../../types/editor";
import {PANEL_TYPES} from "../../helpers";
import global from "../../../../../../global";
import arrowDown from "../../icons/arrow-down.svg";
import pinIcon from "../../icons/pin.svg";
import LightingPanel from "../LightingPanel";
import {Separator} from "../../common/Separator";
import {ExpandButton, PinButton, Row, RowTitle, Wrapper} from "./Panels.styled";
import {TransformationSection} from "../../sections/TransformationSection";
import {PhysicsSection} from "../../sections/PhysicsSection";
import {AnimationSection} from "../../sections/AnimationSection";
import {ModelLigthningSection} from "../../sections/ModelLigthningSection";
import {CollisionSection} from "../../sections/CollisionSection";
import {TextureSection} from "../../sections/TextureSection";
import {MaterialRenderingSection} from "../../sections/MaterialRenderingSection";
import {roundNumber} from "../../../utils/roundNumber";

interface Props {
    label: string;
    panelType: PANEL_TYPES;
    color: string | null;
    setColor: React.Dispatch<React.SetStateAction<string | null>>;
    texture: any;
    showTexture: boolean;
    hideAll: boolean;
    showPhysics: boolean;
    showRigidBody: boolean;
    showModelLighting: boolean;
    showCollision: boolean;
    showPropAnimation: boolean;
    showMaterial?: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<PANEL_TYPES | null>>;
    expanded: PANEL_TYPES | null;
}

export const Panel = ({
    label,
    panelType,
    setColor,
    showCollision,
    showModelLighting,
    showPhysics,
    showPropAnimation,
    showTexture,
    texture,
    showMaterial,
    color,
    hideAll,
    expanded,
    setExpanded,
    showRigidBody,
}: Props) => {
    const {lightState} = useLightingContext();
    const [isPinned, setIsPinned] = useState(false);
    const [hoverActive, setHoverActive] = useState(false);
    const app = global.app;
    const editor = app?.editor;
    const selected = editor?.selected;

    const reset = useCallback(() => {
        setExpanded(null);
        setIsPinned(false);
    }, [selected]);

    useEffect(() => {
        reset();
    }, [selected, reset]);

    const handlePin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (editor) {
            editor.scene.userData.pinnedTabs = {
                ...editor.scene.userData.pinnedTabs,
                [panelType]: !isPinned,
            };
        }
        setIsPinned(!isPinned);
    };

    useEffect(() => {
        if (editor?.scene?.userData?.pinnedTabs) {
            setIsPinned(!!editor.scene.userData.pinnedTabs[panelType]);
        }
    }, [panelType, selected]);

    const handleExpand = () => {
        if (isPinned) return;
        setExpanded(expanded === panelType ? null : panelType);
    };

    if (panelType === PANEL_TYPES.TEXTURE && !showTexture) return;
    if (panelType === PANEL_TYPES.LIGHTING && !lightState.show) return;
    if (panelType === PANEL_TYPES.PROP_ANIMATION && !showPropAnimation) return;
    if (panelType === PANEL_TYPES.COLLISION && !showCollision) return;
    if (panelType === PANEL_TYPES.MODEL_LIGHTING && !showModelLighting) return;
    if (panelType === PANEL_TYPES.MATERIAL_RENDERING && !showMaterial) return;
    if ((!showPhysics && panelType === PANEL_TYPES.PHYSICS) || (!showRigidBody && panelType === PANEL_TYPES.RIGID_BODY))
        return;
    if (hideAll) return;

    return (
        <Wrapper $expanded={isPinned || expanded === panelType}>
            <Row
                onClick={handleExpand}
                onMouseEnter={() => setHoverActive(true)}
                onMouseLeave={() => setHoverActive(false)}
                $pinned={isPinned}>
                <RowTitle>{label}</RowTitle>
                <div>
                    {(isPinned || hoverActive) && (
                        <PinButton className="reset-css" onClick={handlePin}>
                            <img src={pinIcon} alt="pin it" />
                        </PinButton>
                    )}
                    <ExpandButton className="reset-css" $expanded={isPinned || expanded === panelType}>
                        <img src={arrowDown} alt="show more" />
                    </ExpandButton>
                </div>
            </Row>
            {(expanded === panelType || isPinned) && (
                <Content panelType={panelType} texture={texture} color={color} setColor={setColor} />
            )}
            <Separator margin="0" />
        </Wrapper>
    );
};

interface ContentProps {
    panelType: PANEL_TYPES;
    color: string | null;
    setColor: React.Dispatch<React.SetStateAction<string | null>>;
    texture: any;
}

const Content = ({panelType, color, texture, setColor}: ContentProps) => {
    const [positionValue, setPositionValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [rotationValue, setRotationValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [scaleValue, setScaleValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [locked, setLocked] = useState(false);
    const app = global.app;
    const editor = app?.editor;

    const updateTransformationValues = () => {
        const selected = editor.selected;
        if (!selected || Array.isArray(selected)) return;

        setPositionValue({
            x: roundNumber(selected.position.x, 4),
            y: roundNumber(selected.position.y, 4),
            z: roundNumber(selected.position.z, 4),
        });
        setRotationValue({
            x: roundNumber(selected.rotation._x * THREE.MathUtils.RAD2DEG, 2),
            y: roundNumber(selected.rotation._y * THREE.MathUtils.RAD2DEG, 2),
            z: roundNumber(selected.rotation._z * THREE.MathUtils.RAD2DEG, 2),
        });
        setScaleValue({
            x: roundNumber(selected.scale.x, 4),
            y: roundNumber(selected.scale.y, 4),
            z: roundNumber(selected.scale.z, 4),
        });
    };

    useEffect(() => {
        if (editor && app) {
            app.on(`objectSelected.Content`, updateTransformationValues);
            app.on(`objectChanged.Content`, updateTransformationValues);
        }

        return () => {
            app?.on(`objectSelected.Content`, null);
            app?.on(`objectChanged.Content`, null);
        };
    }, [editor]);

    useEffect(() => {
        setLocked(editor?.sceneLockedItems?.includes(editor.selected?.uuid));
    }, [editor?.sceneLockedItems]);

    return (
        <>
            <Separator margin="0 0 8px 0" invisible />
            {panelType === PANEL_TYPES.MOVEMENT && (
                <TransformationSection
                    positionValue={positionValue}
                    setPositionValue={setPositionValue}
                    rotationValue={rotationValue}
                    setRotationValue={setRotationValue}
                    scaleValue={scaleValue}
                    setScaleValue={setScaleValue}
                    isLocked={locked}
                />
            )}
            {panelType === PANEL_TYPES.PROP_ANIMATION && <AnimationSection isLocked={locked} />}
            {panelType === PANEL_TYPES.LIGHTING && <LightingPanel />}
            {panelType === PANEL_TYPES.MODEL_LIGHTING && <ModelLigthningSection isLocked={locked} />}
            {panelType === PANEL_TYPES.COLLISION && <CollisionSection />}
            {panelType === PANEL_TYPES.PHYSICS && <PhysicsSection isLocked={locked} />}
            {panelType === PANEL_TYPES.TEXTURE && color && (
                <TextureSection isLocked={locked} texture={texture} color={color} setColor={setColor} />
            )}
            {panelType === PANEL_TYPES.MATERIAL_RENDERING && <LightingPanel />}
            <Separator margin="8px 0 0 0" invisible />
        </>
    );
};
