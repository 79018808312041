import React, {useEffect, useState} from "react";
import I18n from "i18next";
import global from "../../../../../../../global";
import {UploadButton} from "../../../../../../../editor/assets/v2/common/UploadButton";
import {FileData} from "../../../../types/file";
import {AssetsList} from "../../../../common/AssetsList";
import Ajax from "../../../../../../../utils/Ajax";
import {TitleContainer, TopContainer} from "../AssetsTab.style";
import {SearchInput} from "../../../../common/SearchInput";
import {backendUrlFromPath} from "../../../../../../../utils/UrlUtils";
import {SoundManager} from "../../../../../../../behaviors/hud/SoundManager";

export const SoundsTab = () => {
    const [search, setSearch] = useState("");
    const [soundManager, setSoundManager] = useState<SoundManager | null>(null);
    const [isPlayingNow, setIsPlayingNow] = useState(false);
    const [currentlyPlayingSoundId, setCurrentlyPlayingSoundId] = useState<string | null>(null);
    const [data, setData] = useState<FileData[]>();
    const [filteredData, setFilteredData] = useState<FileData[]>();

    const app = (global as any).app as any;

    useEffect(() => {
        const manager = new SoundManager(app.editor.scene);
        setSoundManager(manager);
        fetchData();

        return () => {
            if (manager) {
                manager.clearLoadedSounds();
            }
        };
    }, []);

    const fetchData = () => {
        Ajax.get({url: backendUrlFromPath(`/api/Audio/List`)})
            .then(response => {
                const obj = response?.data;
                if (obj.Code !== 200) {
                    app.toast(I18n.t(obj.Msg), "warn");
                    return;
                }
                setData(obj.Data);
            })
            .catch(error => {
                app.toast("Failed to fetch data. Please try again later.", "error");
                console.error("Fetch error:", error);
            });
    };

    const handleAdd = () => {
        app.upload(backendUrlFromPath(`/api/Audio/Add`), (obj: any) => {
            if (obj.Code === 200) {
                fetchData();
            } else {
                app.toast(I18n.t(obj.Msg), "warn");
            }
        });
    };

    const handleClick = async (id: string) => {
        const obj = data?.find(item => item.ID === id);
        if (obj) {
            app.call(`selectAudio`, obj, obj);
            const url = backendUrlFromPath(obj.Url);
            try {
                if (!url || !soundManager) return;
                let playingNow = isPlayingNow;
                if (currentlyPlayingSoundId && currentlyPlayingSoundId !== id) {
                    await soundManager.stopSound(currentlyPlayingSoundId);
                    setIsPlayingNow(false);
                    playingNow = false;
                }

                if (isPlayingNow && playingNow) {
                    await soundManager.stopSound(id);
                    setIsPlayingNow(false);
                    setCurrentlyPlayingSoundId(null);
                } else {
                    await soundManager.loadSounds([{id, url, loop: false, volume: 0.1, soundType: "play-now"}]);
                    setIsPlayingNow(true);
                    setCurrentlyPlayingSoundId(id);
                }
            } catch (error) {
                app.toast("Failed to load sound. Please try again later.", "error");
                console.error("Sound load error:", error);
            }
        }
    };

    const handleDelete = (id: string) => {
        const obj = data?.find(item => item.ID === id);
        if (obj) {
            app.confirm({
                title: I18n.t("Confirm"),
                content: `${I18n.t("Delete")} ${obj.Name}?`,
                onOK: () => {
                    Ajax.post({
                        url: backendUrlFromPath(`/api/Audio/Delete?ID=${obj.ID}`),
                    }).then(response => {
                        const obj = response?.data;
                        if (obj.Code !== 200) {
                            app.toast(I18n.t(obj.Msg), "warn");
                            return;
                        }
                        fetchData();
                    });
                },
            });
        }
    };

    useEffect(() => {
        if (!search) {
            setFilteredData(data);
            return;
        } else {
            setFilteredData(
                data?.filter(n => {
                    return n.Name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                }),
            );
        }
    }, [search, data]);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <TopContainer>
                <SearchInput width="224px" placeholder="Search Sounds" onChange={setSearch} value={search} />
            </TopContainer>
            <TitleContainer>
                Sounds
                <UploadButton onClick={handleAdd} />
            </TitleContainer>

            {filteredData && (
                <AssetsList
                    data={filteredData}
                    onClick={handleClick}
                    isSound
                    onDelete={handleDelete}
                    currentlyPlayingSoundId={currentlyPlayingSoundId}
                />
            )}
        </>
    );
};
