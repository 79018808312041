import {useEffect, useState} from "react";
import "react-multi-carousel/lib/styles.css";
import {useNavigate} from "react-router-dom";

import {ROUTES} from "../../../AppRouter";
import {getGamesByQuery} from "../../../api/getGames";
import {IBasicGameInterface, SEARCH_GAME_QUERY} from "../types";
import {GAME_CATEGORY} from "../constants";
import {useHomepageContext} from "../../../context";

import {Header} from "../../Header/Header";
import {Footer} from "../../Footer/Footer";
import {GamesCarousel} from "./GamesCarousel/GamesCarousel";
import {HeroCarousel} from "./HeroCarousel/HeroCarousel";
import {GamesSearchSection} from "./GamesSearchSection/GamesSearchSection";
import {SingleGame} from "./SingleGame/SingleGame";
import {LoadMore} from "../../common/LoadMore/LoadMore";
import {Shadow} from "../../common/Shadow/Shadow.style";
import {AllGamesWrapper, Container, GamesSection, SectionTitle, StyledGamesList, Wrapper} from "./Home.style";

enum GAME_SECTIONS {
    TOP_GAMES = "top-game",
    RECENTLY_PLAYED = "recently-played",
    FPS_GAMES = "fps-games",
    OBBY_GAMES = "Obby Games",
}

interface IGAMES_SECTION {
    title: string;
    tag?: GAME_CATEGORY;
    category: GAME_SECTIONS;
    data: IBasicGameInterface[];
}

const GAMES_VISIBILITY_COUNTER = 12;

export const Home = () => {
    const navigate = useNavigate();
    const commonQuery = {limit: "10", page: "1"};
    const {fetchGames, games} = useHomepageContext();
    const [allGamesResults, setAllGamesResults] = useState<IBasicGameInterface[]>([]);
    const [fpsGames, setFpsGames] = useState<IBasicGameInterface[]>([]);
    const [obbyGames, setObbyGames] = useState<IBasicGameInterface[]>([]);
    const [topGames, setTopGames] = useState<IBasicGameInterface[]>([]);
    const [recentlyPlayedGames, setRecentlyPlayedGames] = useState<IBasicGameInterface[]>([]);

    const GAMES_SECTIONS: IGAMES_SECTION[] = [
        {title: "Top Games", category: GAME_SECTIONS.TOP_GAMES, data: topGames},
        {title: "Recently Played", category: GAME_SECTIONS.RECENTLY_PLAYED, data: recentlyPlayedGames},
        {title: "FPS Games", category: GAME_SECTIONS.FPS_GAMES, data: fpsGames, tag: GAME_CATEGORY.FPS},
        {title: "Obby Games", category: GAME_SECTIONS.FPS_GAMES, data: obbyGames, tag: GAME_CATEGORY.OBBY},
    ];

    useEffect(() => {
        fetchGames();
    }, []);

    useEffect(() => {
        if (games) {
            setTopGames([...games].sort((a, b) => b.PlayCount - a.PlayCount).slice(0, 10));
            setRecentlyPlayedGames(
                [...games]
                    .sort((a, b) => {
                        const dateA = a.LastPlayedTime ? new Date(a.LastPlayedTime) : new Date(0);
                        const dateB = b.LastPlayedTime ? new Date(b.LastPlayedTime) : new Date(0);
                        return dateB.getTime() - dateA.getTime();
                    })
                    .slice(0, 10),
            );
        }
    }, [games]);

    useEffect(() => {
        const getResults = async () => {
            const fpsGamesResults = await getGamesByQuery({tags: GAME_CATEGORY.FPS, ...commonQuery});
            if (fpsGamesResults) {
                setFpsGames(fpsGamesResults);
            }

            const obbyGamesResults = await getGamesByQuery({tags: GAME_CATEGORY.OBBY, ...commonQuery});
            if (obbyGamesResults) {
                setObbyGames(obbyGamesResults);
            }
        };
        getResults();
    }, []);

    return (
        <Container>
            <Shadow />
            <Header />
            <Wrapper>
                <StyledGamesList>
                    <HeroCarousel />
                </StyledGamesList>
                <GamesSearchSection />
                <AllGamesWrapper>
                    {GAMES_SECTIONS.map(({title, category, data, tag}) => (
                        <GamesSection key={title}>
                            <SectionTitle>
                                {title}{" "}
                                {category !== GAME_SECTIONS.RECENTLY_PLAYED && (
                                    <span
                                        className="more"
                                        onClick={() => {
                                            category === GAME_SECTIONS.TOP_GAMES
                                                ? navigate(`view-more/top-games`)
                                                : navigate(
                                                      `view-more/${tag?.toLowerCase()}?${SEARCH_GAME_QUERY.GAME_TAGS}=${tag}`,
                                                  );
                                        }}>
                                        View more
                                    </span>
                                )}
                            </SectionTitle>
                            <StyledGamesList>
                                <GamesCarousel games={data} />
                            </StyledGamesList>
                        </GamesSection>
                    ))}
                    <GamesSection>
                        <SectionTitle>All Games </SectionTitle>
                        <StyledGamesList $allGames>
                            {allGamesResults.map((game, index) => (
                                <SingleGame item={game} key={game.ID} />
                            ))}
                        </StyledGamesList>
                    </GamesSection>
                    <LoadMore
                        visibilityCounter={GAMES_VISIBILITY_COUNTER}
                        itemsToLoad={games}
                        setVisibleResults={setAllGamesResults}
                    />
                </AllGamesWrapper>
            </Wrapper>
            <Footer />
        </Container>
    );
};
