import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../AppRouter";
import {SEARCH_GAME_QUERY} from "../../pages/types";
import search from "../../assets/search.svg";
import {Row, SearchButton, StyledInput} from "./SearchRow.style";

interface Props {
    initValue?: string;
}

export const SearchRow = ({initValue}: Props) => {
    const navigate = useNavigate();
    const [value, setValue] = useState("");

    useEffect(() => {
        initValue && setValue(initValue);
    }, [initValue]);

    const handleSearch = () => {
        if (!!value && value.trim()) {
            navigate(`${ROUTES.SEARCH_RESULTS}?${SEARCH_GAME_QUERY.GAME_NAME}=${value}`);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <Row>
            <StyledInput
                type="text"
                placeholder="Search games, experiences, and more..."
                value={value}
                onChange={e => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <SearchButton onClick={handleSearch} disabled={!value}>
                <img src={search} alt="search" />
            </SearchButton>
        </Row>
    );
};
