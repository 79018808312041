import {useEffect} from "react";
import Application from "../../../../../Application";
import global from "../../../../../global";
import {ITransformValue, TRANSFORMATION_OPTIONS} from "../../../../../types/editor";
import lockIcon from "../../icons/lock-icon.svg";
import {InputSymbol} from "../InputSymbol";
import {NumericInput} from "../NumericInput";
import {Box, BoxInputs, BoxLabels, InputWrapper, LockIcon, RightSide, Wrapper} from "./MovementSection.style";

const INPUT_ARRAY = [
    {
        name: "Position",
        type: TRANSFORMATION_OPTIONS.POSITION,
    },
    {
        name: "Scale",
        type: TRANSFORMATION_OPTIONS.SCALE,
    },
    {
        name: "Rotation",
        type: TRANSFORMATION_OPTIONS.ROTATION,
    },
];

export type AxisType = "x" | "y" | "z";

export const AxisArray: AxisType[] = ["x", "y", "z"];

type SetTransformValueFunctionType = React.Dispatch<React.SetStateAction<ITransformValue>>;

interface Props {
    isLocked?: boolean;
    reset: (type: TRANSFORMATION_OPTIONS) => void;
    positionValue?: ITransformValue;
    rotationValue?: ITransformValue;
    scaleValue?: ITransformValue;
    getSetValueFunc: (type: TRANSFORMATION_OPTIONS, value: number, toUpdate: "x" | "y" | "z") => void;
    children?: any;
    setScaleLocked?: React.Dispatch<React.SetStateAction<boolean>>;
    scaleLocked?: boolean;
    noRotation?: boolean;
    noPosition?: boolean;
    noScale?: boolean;
    setScaleValue?: SetTransformValueFunctionType;
    setPositionValue?: SetTransformValueFunctionType;
    setRotationValue?: SetTransformValueFunctionType;
}

export const MovementSection = ({
    isLocked,
    reset,
    positionValue,
    rotationValue,
    scaleValue,
    getSetValueFunc,
    children,
    scaleLocked,
    setScaleLocked,
    noPosition,
    noRotation,
    noScale,
    setScaleValue,
    setPositionValue,
    setRotationValue,
}: Props) => {
    const app = (global.app as Application) || null;
    const editor = app?.editor;

    useEffect(() => {
        const update = (value: ITransformValue, baseId: string, setFunction?: SetTransformValueFunctionType) => {
            setFunction && setFunction(value);
            const inputX = document.getElementById(baseId + "x");
            const inputY = document.getElementById(baseId + "y");
            const inputZ = document.getElementById(baseId + "z");
            if (inputX) {
                (inputX as HTMLInputElement).value = value.x.toString();
            }
            if (inputY) {
                (inputY as HTMLInputElement).value = value.y.toString();
            }
            if (inputZ) {
                (inputZ as HTMLInputElement).value = value.z.toString();
            }
        };
        if (editor && app) {
            app.on(`scaling.MovementSection`, (value: any) => update(value, "scaleInput", setScaleValue));
            app.on(`rotating.MovementSection`, (value: any) => update(value, "rotateInput", setRotationValue));
            app.on(`translating.MovementSection`, (value: any) => update(value, "translateInput", setPositionValue));
        }

        return () => {
            app?.on(`scaling.MovementSection`, null);
            app?.on(`rotating.MovementSection`, null);
            app?.on(`translating.MovementSection`, null);
        };
    }, [editor]);

    return (
        <Wrapper>
            {children}
            {INPUT_ARRAY.map(({name, type}) => {
                const isScale = type === TRANSFORMATION_OPTIONS.SCALE;
                const isPosition = type === TRANSFORMATION_OPTIONS.POSITION;
                const isRotation = type === TRANSFORMATION_OPTIONS.ROTATION;

                if (noRotation && isRotation) return;
                if (noPosition && isPosition) return;
                if (noScale && isScale) return;

                const shouldBeLocked = !!isLocked && !isScale;

                const currentValue = isScale ? scaleValue : isPosition ? positionValue : rotationValue;

                if (!currentValue) {
                    return console.error("Movement section didn't receive correct value for: ", name);
                }

                return (
                    <Box key={name}>
                        <BoxLabels>
                            <div className="title">{name}</div>
                            <RightSide>
                                {isScale && setScaleLocked && (
                                    <LockIcon
                                        $locked={!!scaleLocked}
                                        src={lockIcon}
                                        onClick={() => setScaleLocked(!scaleLocked)}
                                    />
                                )}
                            </RightSide>
                        </BoxLabels>
                        <BoxInputs>
                            {AxisArray.map((axis: AxisType) => {
                                const id = isScale ? "scaleInput" : isRotation ? "rotateInput" : "translateInput";
                                return (
                                    <InputWrapper key={axis}>
                                        <InputSymbol
                                            symbol={axis.toUpperCase()}
                                            value={currentValue[axis as keyof ITransformValue]}
                                            setValue={value => getSetValueFunc(type, value, axis)}
                                            isLocked={shouldBeLocked}
                                        />
                                        <NumericInput
                                            id={id + axis}
                                            value={currentValue[axis as keyof ITransformValue]}
                                            setValue={value => {
                                                return null;
                                            }}
                                            className="dark-input"
                                            disabled={shouldBeLocked}
                                            onBlur={value => getSetValueFunc(type, value, axis)}
                                            onDragValueChange={value => getSetValueFunc(type, value, axis)}
                                        />
                                    </InputWrapper>
                                );
                            })}
                        </BoxInputs>
                    </Box>
                );
            })}
        </Wrapper>
    );
};
