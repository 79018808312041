import {useEffect, useState} from "react";
import I18n from "i18next";
import {NumericInput} from "../../common/NumericInput";
import "../css/Section.css";
import "../css/AnimationSection.css";
import {TextInput} from "../../common/TextInput";
import {StyledSelect} from "../../common/StyledSelect";
import classNames from "classnames";
import {useAnimationContext} from "../../../../../context";
import global from "../../../../../global";
import {PanelSectionTitleSecondary} from "../RightPanel.style";
import {StyledButton} from "../../common/StyledButton";

enum AnimationType {
    Tween = "Tween",
    Skeletal = "Skeletal",
    Audio = "Audio",
    Filter = "Filter",
    Particle = "Particle",
}

export const BasicAnimationSection = () => {
    const {animationsState, setAnimationsState} = useAnimationContext();
    const [beginTime, setBeginTime] = useState(0);
    const [endTime, setEndTime] = useState(10);
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [target, setTarget] = useState(null);
    const [type, setType] = useState(null);
    const app = (global as any).app as any;

    useEffect(() => {
        handleUpdate();
    }, [animationsState]);

    const handleUpdate = () => {
        const animation = {...animationsState.selectedAnimation};

        if (animation) {
            const state = {
                show: true,
                name: animation?.name || "",
                type: animation?.type || AnimationType.Tween,
                beginTime: animation?.beginTime || 0,
                endTime: animation?.endTime || 10,
                target: animation?.target || null,
            };

            if (!animation.target) {
                state.target = null;
            } else {
                let obj = app.editor.objectByUuid(animation.target);
                if (!obj) {
                    state.target = null;
                    console.warn(
                        `BasicAnimationComponent: ${I18n.t("Animation Object")} ${
                            animation.target
                        } ${I18n.t("is not existed in the scene.")}`,
                    );
                } else {
                    state.target = obj.name;
                }
            }

            setBeginTime(state.beginTime);
            setEndTime(state.endTime);
            setName(state.name);
            setTarget(state.target);
            setType(state.type);
            setShow(state.show);
        }
    };

    const handleSetTarget = () => {
        let selected = app.editor.selected;

        let targetValue: any;
        if (selected === null) {
            targetValue = null;
        } else {
            targetValue = selected.uuid;
        }

        setAnimationsState((prevState: any) => {
            return {
                ...prevState,
                selectedAnimation: {
                    ...prevState.selectedAnimation,
                    target: targetValue,
                },
            };
        });
    };

    const handleChange = (value: any, animName: any) => {
        setAnimationsState((prevState: any) => {
            return {
                ...prevState,
                selectedAnimation: {
                    ...prevState.selectedAnimation,
                    [animName]: value,
                },
            };
        });
    };

    return (
        <div className={classNames("Section", "AnimationSection")}>
            <div className="title">Basic Information</div>
            <div className="box extended">
                <PanelSectionTitleSecondary>Name</PanelSectionTitleSecondary>
                <TextInput
                    className={"text-input"}
                    value={name}
                    setValue={value => handleChange(value, "name")}
                />
            </div>

            <div className="box extended">
                <PanelSectionTitleSecondary>Type</PanelSectionTitleSecondary>
                <StyledSelect
                    value={type || ""}
                    items={Object.values(AnimationType)}
                    onChange={value => handleChange(value, "type")}
                />
            </div>
            <div className="box extended">
                <PanelSectionTitleSecondary>
                    Begin Time
                </PanelSectionTitleSecondary>
                <NumericInput
                    className={"numeric-input"}
                    value={beginTime}
                    setValue={value => handleChange(value || 0, "beginTime")}
                    onBlur={() => !beginTime && handleChange("0", "beginTime")}
                />
            </div>
            <div className="box extended">
                <PanelSectionTitleSecondary>
                    End Time
                </PanelSectionTitleSecondary>
                <NumericInput
                    className={"numeric-input"}
                    value={endTime}
                    setValue={value => handleChange(value, "endTime")}
                    onBlur={() => !endTime && handleChange("0", "endTime")}
                />
            </div>
            <div className="box extended column">
                <div className="box extended">
                    <PanelSectionTitleSecondary>
                        Target
                    </PanelSectionTitleSecondary>
                    <PanelSectionTitleSecondary>{`[${target || "None"}]`}</PanelSectionTitleSecondary>
                </div>
                <StyledButton
                    onClick={handleSetTarget}
                    margin="0 0 0 auto"
                    isBlue>
                    Set Target
                </StyledButton>
            </div>
        </div>
    );
};
