import {useEffect, useState} from "react";
import {DefaultWrapper} from "../../styles/Behaviors.style";
import {SideScrollerOptionsInterface} from "../../../../../../types/editor";
import {NumericInput} from "../../../common/NumericInput";
import {BasicCombobox} from "../../../common/BasicCombobox";
import {Checkbox} from "../../../../../../ui/common/Checkbox";
import global from "../../../../../../global";
import CharacterBehaviorConverter from "../../../../../../serialization/behaviours/CharacterBehaviorConverter";

const initialXRotations = [
    {
        key: "none",
        value: "None",
    },
    {
        key: "-x",
        value: "Negative X",
    },
    {
        key: "+x",
        value: "Positive X",
    },
];

const getSceneModels = () => {
    const editor = (global as any).app.editor;
    const sceneModels = {};

    editor.scene.traverse((obj: any) => {
        if (obj.type === "Group" && obj.parent === editor.scene) {
            //@ts-ignore
            sceneModels[obj.name] = obj.name;
        }
    });

    return sceneModels;
};

export const SideScrollerOptions = () => {
    const app = (global as any).app;
    const editor = app.editor;
    const model = editor.selected;
    const camera = editor.camera;
    const thisModelCharacterBehavior = model.userData.behaviors.find((el: any) => el.type === "Character");
    const targetBehaviorSideScrollerOptions = thisModelCharacterBehavior?.sideScrollerOptions;
    const [targetBehavior, setTargetBehavior] = useState(
        targetBehaviorSideScrollerOptions
            ? {sideScrollerOptions: targetBehaviorSideScrollerOptions}
            : {
                  sideScrollerOptions:
                      CharacterBehaviorConverter.DEFAULT.getDefaultSideScrollerOptions(getSceneModels()),
              },
    );
    const [animations, setAnimations] = useState([{key: "0", value: "none"}]);

    const handleInputChange = (value: string | boolean | number, name: string) => {
        if (camera) {
            setTargetBehavior((prevState: any) => {
                const newState = {...prevState};
                newState.sideScrollerOptions[name] = value;
                return newState;
            });
        }
    };

    const handleSelectChange = (item: {key: string; value: string}, name: string) => {
        setTargetBehavior((prevState: any) => {
            const newState = {...prevState};
            newState.sideScrollerOptions[name] = item.value;
            return newState;
        });
    };

    const handleUpdate = () => {
        if (!model) return;
        const characterBehaviorConverter = CharacterBehaviorConverter.DEFAULT;

        if (!camera.userData.sideScrollerOptions || Object.keys(camera.userData.sideScrollerOptions).length === 0) {
            camera.userData.sideScrollerOptions =
                characterBehaviorConverter.getDefaultSideScrollerOptions(getSceneModels());
        }

        characterBehaviorConverter.updateSideScrollerOptions(model, camera);

        if (model) {
            if (model._obj && model._obj.animations && model._obj.animations.length > 0) {
                const mappedAnimations = model._obj.animations.map((anim: any) => {
                    return {key: `${anim.name}`, value: anim.name};
                });
                setAnimations([{key: "none", value: "none"}, ...mappedAnimations]);
            }
        }
    };

    const updateScene = () => {
        if (camera && targetBehavior && thisModelCharacterBehavior.enabled) {
            camera.userData.sideScrollerOptions = targetBehavior.sideScrollerOptions;

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    useEffect(() => {
        updateScene();
        if (!!thisModelCharacterBehavior) {
            thisModelCharacterBehavior.sideScrollerOptions = targetBehavior.sideScrollerOptions;
        }
    }, [targetBehavior]);

    useEffect(() => {
        handleUpdate();
        app.on(`objectSelected.SideScrollerOptions`, handleUpdate);
        app.on(`objectChanged.SideScrollerOptions`, handleUpdate);
    }, []);

    return (
        <DefaultWrapper>
            Side Scroller Controls
            <DefaultWrapper>
                Walk Speed
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.sideScrollerOptions.walkSpeed}
                    setValue={value => handleInputChange(value, "walkSpeed")}
                    min={0}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Run Speed
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.sideScrollerOptions.runSpeed}
                    setValue={value => handleInputChange(value, "runSpeed")}
                    min={0}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Turn Speed
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.sideScrollerOptions.turnSpeed}
                    setValue={value => handleInputChange(value, "turnSpeed")}
                    min={0}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Animations <br />
                Idle
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.idleAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "idleAnimation")}
                />
                Walk
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.walkAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "walkAnimation")}
                />
                Run
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.runAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "runAnimation")}
                />
                Shoot
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.shootAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "shootAnimation")}
                />
                Reload
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.reloadAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "reloadAnimation")}
                />
                Sword
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.swordAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "swordAnimation")}
                />
                Punch
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.punchAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "punchAnimation")}
                />
                Throw
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.throwAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "throwAnimation")}
                />
                Pick Up
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.pickUpAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "pickUpAnimation")}
                />
                Carry
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.carryAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "carryAnimation")}
                />
                Jump
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.jumpAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "jumpAnimation")}
                />
                Strafe Left
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.leftDirectionAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "leftDirectionAnimation")}
                />
                Strafe Right
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.rightDirectionAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "rightDirectionAnimation")}
                />
                Back Up
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.reverseDirectionAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "reverseDirectionAnimation")}
                />
                Fall
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.fallAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "fallAnimation")}
                />
                Die
                <BasicCombobox
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.sideScrollerOptions.dieAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "dieAnimation")}
                />
                Fall Delay (Seconds)
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.sideScrollerOptions.fallDelay}
                    setValue={value => handleInputChange(value, "fallDelay")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Initial X Rotation
                <BasicCombobox
                    data={initialXRotations}
                    value={initialXRotations.find(
                        rotation => rotation.key === targetBehavior.sideScrollerOptions.initialXRotation,
                    )}
                    onChange={item => handleInputChange(item.key, "initialXRotation")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Jump Height
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.sideScrollerOptions.jumpHeight}
                    setValue={value => handleInputChange(value, "jumpHeight")}
                    min={0}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Player Gravity
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.sideScrollerOptions.playerGravity}
                    setValue={value => handleInputChange(value, "playerGravity")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Camera Min Distance
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.sideScrollerOptions.cameraMINDistance}
                    setValue={value => handleInputChange(value, "cameraMINDistance")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Camera Max Distance
                <NumericInput
                    className="BehaviorNumbericInput"
                    value={targetBehavior.sideScrollerOptions.cameraMAXDistance}
                    setValue={value => handleInputChange(value, "cameraMAXDistance")}
                />
            </DefaultWrapper>
        </DefaultWrapper>
    );
};
