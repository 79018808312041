import styled from "styled-components";
import discordIcon from "../../assets/discord-icon.svg";
import {regularFont} from "../../../assets/style";

export const DiscordButton = ({signup}: {signup?: boolean}) => {
    return (
        <StyledDiscordButton id="discordButton" className="DiscordButton reset-css" disabled={true}>
            <img src={discordIcon} alt="" className="icon" />
            Sign {signup ? "up" : "in"} with Discord
        </StyledDiscordButton>
    );
};

const StyledDiscordButton = styled.button`
    background-color: #5865f2 !important;
    text-align: center;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;

    ${regularFont("xs")};
    color: #fff;
    font-weight: 500;
`;
