import styled from "styled-components";
import {Checkbox} from "../../../../../ui/common/Checkbox";
import {flexCenter} from "../../../../../assets/style";
import {Label} from "../RightPanel.style";
import {StyledSwitch} from "../../common/StyledSwitch";

interface Props {
    text?: string;
    checked: boolean;
    onChange: (e?: any) => void;
    isLocked?: boolean;
    v2?: boolean;
    regular?: boolean;
    isGray?: boolean;
    white?: boolean;
}

export const PanelCheckbox = ({text, checked, onChange, isLocked, v2, white, regular, isGray}: Props) => {
    if (v2) {
        return (
            <Wrapper className="panelCheckboxWrapper">
                {text && (
                    <Label $regular={regular} $isGray={isGray} style={{margin: 0}}>
                        {text}
                    </Label>
                )}
                <StyledSwitch checked={checked} onChange={onChange} />
            </Wrapper>
        );
    } else {
        return (
            <Wrapper>
                <span className="text" style={white ? {color: "white"} : undefined}>
                    {text}
                </span>
                <Checkbox checked={checked} onChange={onChange} disabled={isLocked} customId={text || "customId"} />
            </Wrapper>
        );
    }
};

const Wrapper = styled.div`
    ${flexCenter};
    justify-content: space-between;
    width: 100%;

    .text {
        font-size: var(--theme-font-size-xxs);
        font-weight: var(--theme-font-regular);
        color: var(--theme-font-unselected-color);
        line-height: 120%;
        text-align: left;
    }
`;
