import * as THREE from "three";
import BaseBehaviorConverter from "./BaseBehaviorConverter";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import TeleportBehaviorUpdater from "./TeleportBehaviorUpdater";
import {TeleportBehaviorInterface, OBJECT_TYPES} from "../../types/editor";

class TeleportBehaviorConverter extends BaseBehaviorConverter<TeleportBehaviorInterface> {
    public static DEFAULT = new TeleportBehaviorConverter(null);

    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: any): BehaviorUpdater {
        return new TeleportBehaviorUpdater(target, behavior.teleporterExitObject);
    }

    getBehavior(target: THREE.Object3D, id: string): TeleportBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as TeleportBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have TELEPORT behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): TeleportBehaviorInterface {
        return {
            type: OBJECT_TYPES.TELEPORT,
            teleporterExitObject: "",
            startOnTrigger: false,
            enabled: true,
            id,
        };
    }
}

export default TeleportBehaviorConverter;
