import styled, {css} from "styled-components";

export const StickyBoxRoot = styled.div<{
    $isSticky: boolean;
    $top: string;
    $bottom: string;
    $width: number;
    $maxHeight: number;
}>`
    ${({$isSticky, $top, $bottom, $width, $maxHeight}) => css`
        position: ${$isSticky ? ($bottom !== "initial" ? "absolute" : "fixed") : "static"};
        top: ${$top};
        left: initial;
        bottom: ${$bottom};
        width: ${$isSticky ? `${$width}px` : "auto"};
        max-height: ${$isSticky ? `${$maxHeight}px` : "initial"};
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    `}
`;
