import * as THREE from "three";
import BaseBehaviorConverter from "./BaseBehaviorConverter";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
// import ObjectInteractionBehaviorUpdater from "./ObjectInteractionBehaviorUpdater";
import {ObjectInteractionBehaviorInterface, OBJECT_TYPES, OBJECT_INTERACTION_OPTIONS} from "../../types/editor";

class ObjectInteractionBehaviorConverter extends BaseBehaviorConverter<ObjectInteractionBehaviorInterface> {
    public static DEFAULT = new ObjectInteractionBehaviorConverter(null);

    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: any): BehaviorUpdater {
        // return new ObjectInteractionBehaviorUpdater(target, behavior);
        return null as unknown as BehaviorUpdater;
    }

    getBehavior(target: THREE.Object3D, id: string): ObjectInteractionBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as ObjectInteractionBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have Object Interactions behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): ObjectInteractionBehaviorInterface {
        return {
            type: OBJECT_TYPES.OBJECT_INTERACTIONS,
            interaction: OBJECT_INTERACTION_OPTIONS.PICKUP_DROP,
            enabled: true,
            id,
        };
    }
}

export default ObjectInteractionBehaviorConverter;
