import styled from "styled-components";

const InputWraper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const RangeRight = styled.div<{ width: number }>`
    position: absolute;
    top: 50%;
    right: 0;
    background: var(--theme-grey-bg);
    z-index: 1;
    height: 5px;
    width: ${({ width }) => width}%;
    transform: translateY(-50%);
    width: ${({ width }) => Math.min(width, 100)}%;
`;

const RangeInput = styled.input`
    appearance: none;
    background: transparent;
    width: 100%;
    max-width: 100%;
    height: 20px;
    overflow-x: clip;
    display: block;
    margin: 0;
    border-radius: 20px;
    height: 28px;

    &::-webkit-slider-runnable-track {
        background-color: #0284c7;
        height: 5px;
        border: none;
        border-radius: 2px;
    }

    &::-moz-range-track {
        background-color: #0284c7;
        height: 5px;
        border: none;
        border-radius: 2px;
    }

    &::-ms-track {
        background-color: #0284c7;
        height: 5px;
        border: none;
        border-radius: 2px;
        color: transparent;
    }

    &::-webkit-slider-thumb {
        appearance: none;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #0284c7;
        margin: auto 0;
        transform: translateY(-7px);
        position: relative;
        z-index: 5;
    }

    &::-moz-range-thumb {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #0284c7; 
        border: none;
        cursor: pointer; 
        position: relative;
        z-index: 5;
    }

    &::-ms-thumb {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #0284c7; 
        border: none;
        cursor: pointer; 
    }
`;

type Props = {
    volume: number;
    setVolume: (volume: number) => void;
};

export const MaterialTileRange = ({ volume, setVolume }: Props) => {
    const calculateRightRange = (value: number) => {
        const thumbWidth = 5;
        const fullRangeWidth = 100;
        const rightRangeWidth = (1 - value) * fullRangeWidth - thumbWidth;
        return Math.max(rightRangeWidth, 0);
    };

    return (
        <InputWraper>
            <RangeInput
                type="range"
                min={1}
                max={200}
                step={10}
                value={volume}
                onChange={event => {
                    setVolume(event.target.valueAsNumber);
                }}
            />
            <RangeRight width={calculateRightRange(volume)} />
        </InputWraper>
    );
};