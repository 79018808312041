import * as THREE from "three";

class ShadowUtils {
    static applyShadowSettings = (obj: THREE.Object3D, json: any) => {
        if (json.userData.shadow) {
            if (json.userData.shadow.castShadow !== "undefined") {
                ShadowUtils.applyCastShadow(obj, json.userData.shadow.castShadow, false);
            }
            if (json.userData.shadow.receiveShadow !== "undefined" || json.userData.shadow.castShadow !== "undefined") {
                ShadowUtils.applyReceiveShadow(
                    obj,
                    json.userData.shadow.castShadow || json.userData.shadow.receiveShadow,
                    false,
                );
            }
        }
    };

    static updateMaterial(material: any) {
        if (Array.isArray(material)) {
            material.forEach(n => {
                n.needsUpdate = true;
            });
        } else {
            material.needsUpdate = true;
        }
    }

    static applyReceiveShadow = (obj: THREE.Object3D, value: boolean, saveState: boolean) => {
        const applyReceiveShadow = (target: any) => {
            if (target instanceof THREE.Mesh) {
                target.receiveShadow = value;
                this.updateMaterial(target.material); // Assuming this is necessary for your setup
            }
            if (saveState && (target.userData.Server || target instanceof THREE.Scene)) {
                target.userData.shadow = target.userData.shadow ? target.userData.shadow : {};
                target.userData.shadow.receiveShadow = value;
            }
        };

        if (obj instanceof THREE.Group || obj instanceof THREE.Scene) {
            obj.traverse(obj => applyReceiveShadow(obj));
        } else {
            applyReceiveShadow(obj);
        }
    };

    static applyCastShadow = (obj: THREE.Object3D, value: boolean, saveState: boolean) => {
        const applyCastShadow = (target: THREE.Object3D) => {
            if (!(target instanceof THREE.AmbientLight) && !(target instanceof THREE.HemisphereLight)) {
                target.castShadow = value;
                if (target instanceof THREE.Mesh) {
                    ShadowUtils.updateMaterial(target.material); // Assuming this is necessary for your setup
                }
                //store setting in the model
                if (saveState && (target.userData.Server || target instanceof THREE.Scene)) {
                    target.userData.shadow = target.userData.shadow ? target.userData.shadow : {};
                    target.userData.shadow.castShadow = value;
                }
            }
        };

        if (obj instanceof THREE.Group || obj instanceof THREE.Scene) {
            obj.traverse(obj => applyCastShadow(obj));
        } else {
            applyCastShadow(obj);
        }
    };
}

export default ShadowUtils;
