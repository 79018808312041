import {css} from "styled-components";

export const buttonReset = css`
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: none;
    background: none;
    cursor: pointer;
`;

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const absoluteCenter = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const regularFont = (size: "xxs" | "xs" | "s" | "m" | "l" | "xl") => css`
    font-size: var(--theme-font-size-${size});
    font-weight: var(--theme-font-regular);
    line-height: 120%;
    color: var(--theme-font-main-selected-color);
`;
