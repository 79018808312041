import {regularFont} from "../../../../../assets/style";
import styled from "styled-components";

interface Props {
    children: React.ReactNode;
    margin?: string;
}

export const Heading = ({children, margin}: Props) => {
    return <StyledHeading $margin={margin}>{children}</StyledHeading>;
};

const StyledHeading = styled.div<{$margin?: string}>`
    ${regularFont("xxs")};
    font-weight: var(--theme-font-medium-plus);
    ${({$margin}) => `margin: ${$margin}`};
`;
