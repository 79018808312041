import React, {useEffect, useState} from "react";
import {
    FONT_FAMILIES,
    START_MENU_BUTTON_TYPES,
    START_MENU_IDS,
    StartGameMenuDataType,
} from "../editor/assets/v2/HUD/HUDEditView/types";
import defaultBg from "../editor/assets/v2/HUD/HUDEditView/images/start-menu-example-bg.png";
import global from "../global";

type HUDStartGameMenuContextValue = {
    startGameMenuLayout: StartGameMenuDataType | undefined;
    setStartGameMenuLayout: React.Dispatch<React.SetStateAction<StartGameMenuDataType | undefined>>;
    isReady: boolean;
    useInitialSetup: () => void;
};

export const HUDStartGameMenuContext = React.createContext<HUDStartGameMenuContextValue>(null!);

export interface HUDStartGameMenuContextProviderProps {
    children: React.ReactNode;
    isReady: boolean;
}

const placeholder: StartGameMenuDataType = {
    [START_MENU_IDS.GAME_BUTTON_LEFT_1]: {
        UIButtonType: START_MENU_BUTTON_TYPES.START_GAME,
        buttonColor: "#ffffff",
        fontColor: "#000000",
        fontFamily: FONT_FAMILIES.ROBOTO,
        fontSize: 18,
        radius: 8,
    },
    [START_MENU_IDS.PANEL_BG]: "#1B1B1B",
};

const HUDStartGameMenuContextProvider: React.FC<HUDStartGameMenuContextProviderProps> = ({children, isReady}) => {
    const [startGameMenuLayout, setStartGameMenuLayout] = useState<StartGameMenuDataType>();
    const app = (global as any).app;
    const sceneId = app?.editor?.scene.uuid;

    useEffect(() => {
        if (app?.editor && isReady && sceneId) {
            const gameHUD = app?.editor.scene.userData?.gameUI?.gameStartMenu;

            if (!gameHUD || Object.keys(gameHUD).length === 0) {
                // const logo = drawLogoFromSceneName();
                // const newLogo = logo || logoExample;
                setStartGameMenuLayout({
                    ...placeholder,
                    // [START_MENU_IDS.LOGO_LEFT]: newLogo,
                });
                if (app?.editor.scene.userData) {
                    app.editor.scene.userData.gameUI = {
                        ...app?.editor.scene.userData.gameUI,
                        gameStartMenu: {
                            ...placeholder,
                            // [START_MENU_IDS.LOGO_LEFT]: newLogo,
                        },
                    };
                    app.editor.scene.userData.isStartGameMenuDefaultBanner = true;
                }
            } else {
                setStartGameMenuLayout(app?.editor.scene.userData?.gameUI?.gameStartMenu);
            }
        }
    }, [isReady, sceneId]);

    const useInitialSetup = () => {
        if (app?.editor && isReady && sceneId) {
            setStartGameMenuLayout(app?.editor.scene.userData?.gameUI?.gameStartMenu);
        }
    };

    useEffect(() => {
        app.on("clear.HUDStartGameMenuContext", () => {
            if (app?.editor?.scene.userData?.isStartGameMenuDefaultBanner) {
                setStartGameMenuLayout({
                    ...app?.editor.scene.userData.gameUI.gameStartMenu,
                });

                app.editor.scene.userData.gameUI = {
                    ...app?.editor.scene.userData.gameUI,
                    gameStartMenu: {
                        ...app?.editor.scene.userData.gameUI.gameStartMenu,
                    },
                };
            }
        });
    }, []);

    return (
        <HUDStartGameMenuContext.Provider
            value={{
                startGameMenuLayout,
                setStartGameMenuLayout,
                isReady,
                useInitialSetup,
            }}>
            {children}
        </HUDStartGameMenuContext.Provider>
    );
};

export default HUDStartGameMenuContextProvider;
