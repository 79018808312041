import {useEffect, useState} from "react";
import global from "../../../../../global";
import {getPhysics} from "../../utils/getPhysics";
import {IPhysics} from "../../types/physics";
import {COLLISION_MATERIAL_TYPE} from "../../../../../types/editor";
import {SelectRow} from "../common/SelectRow";
import {Item} from "../../common/BasicCombobox";

export const CollisionSection = () => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor.selected;
    const [physics, setPhysics] = useState(getPhysics(selected?.userData?.physics));
    const [materialOptions, setMaterialOptions] = useState<Item[]>([]);
    // const [selectedMaterial, setSelectedMaterial] = useState<string>(physics.collision_material);
    const handlePhysicsChange = (value: string, name: keyof IPhysics) => {
        if (selected?.userData.physics) {
            selected.userData.physics = {
                ...selected.userData.physics,
                [name]: value,
            };
            setPhysics(selected.userData.physics);
        }

        app?.call(`objectChanged`, selected, selected);
    };

    const createItemsFromMaterials = () => {
        setMaterialOptions(
            Object.values(COLLISION_MATERIAL_TYPE).map((material, index) => ({
                key: index.toString(),
                value: material,
            })),
        );
    };

    const updatePhysics = () => {
        setPhysics(getPhysics(selected?.userData.physics));
    };

    useEffect(() => {
        app?.on("objectChanged.CollisionSection", updatePhysics);
        app?.on("objectSelected.CollisionSection", updatePhysics);

        return () => {
            app?.on("objectChanged.CollisionSection", null);
            app?.on("objectSelected.CollisionSection", null);
        };
    }, []);

    useEffect(() => {
        createItemsFromMaterials();
    }, []);

    return (
        <SelectRow
            label="React like Material"
            data={materialOptions}
            value={materialOptions.find(el => el.value === physics.collision_material)}
            onChange={item => handlePhysicsChange(item.value, "collision_material")}
        />
    );
};
