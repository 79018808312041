import {useEffect, useRef, useState} from "react";
import I18n from "i18next";
import {toast} from "react-toastify";

import global from "../../../../../../global";
import {useAuthorizationContext} from "../../../../../../context";

import {UserMenu} from "../../../common/UserMenu/UserMenu";
import {Section} from "../../../common/Section";
import {Avatar} from "../../../Avatar/Avatar";
import {BorderedWrapper} from "../../RightPanel.style";
import Application from "../../../../../../Application";
import {StyledButton} from "../../../common/StyledButton";
import {PublishPanel} from "./PublishPanels/PublishPanel";
import {getGameUrl} from "../../../../../../v2/pages/services";

type Props = {
    showLoading: (show: boolean) => void;
    showAvatarCreator: () => void;
};

enum PUBLISH_FLOW {
    FIRST_TIME = "FIRST_TIME",
    POST_PUBLISH = "POST_PUBLISH",
}

export const TopMenu = ({showLoading, showAvatarCreator}: Props) => {
    const {dbUser, googleUser} = useAuthorizationContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openPublishPanel, setOpenPublishPanel] = useState<PUBLISH_FLOW>();

    const userMenuButtonRef = useRef<HTMLButtonElement | null>(null);
    const app = global.app as Application;
    const [isPublic, setIsPublic] = useState(app.editor?.isPublic || false);
    const [isCloneable, setIsCloneable] = useState(app.editor?.isCloneable || false);
    const [isPublished, setIsPublished] = useState(app.editor?.isPublished || false);

    const handleOpenMenu = () => {
        (global as any).app.call("removeGunAimer", this, this);
        setIsMenuOpen(!isMenuOpen);
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    const handleOpenSettings = () => {
        if (!app.editor?.isPublished) {
            setOpenPublishPanel(PUBLISH_FLOW.FIRST_TIME);
        } else {
            setOpenPublishPanel(PUBLISH_FLOW.POST_PUBLISH);
        }
    };

    const handleSettingsSave = () => {
        if (!app.editor) return;

        const sceneID = app.editor.sceneID;

        if (!sceneID) {
            app.toast(I18n.t("Please open scene first."), "warn");
            return;
        }

        app.confirm({
            title: I18n.t("Query"),
            content: I18n.t("Are you sure to save current settings?"),
            onOK: () => {
                app.commitSaveScene("", {
                    isPublic,
                    isCloneable,
                    isPublished,
                    onSuccess: () => {
                        if (app.editor) {
                            app.editor.isCloneable = isCloneable;
                            app.editor.isPublic = isPublic;
                            app.editor.isPublished = isPublished;
                        }
                        handleOpenSettings();
                    },
                });
            },
        });
    };

    const handleUndo = () => {
        if (!app || !app.editor) return;
        app.editor.undo();
        app.call("objectUpdated");
    };

    const handleRedo = () => {
        if (!app || !app.editor) return;
        app.editor.redo();
        app.call("objectUpdated");
    };

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 89) {
            event.ctrlKey && handleRedo();
        } else if (event.keyCode === 90) {
            event.ctrlKey && handleUndo();
        }
    };

    useEffect(() => {
        app.on("sceneSaved.TopMenu", () => {
            setIsLoading(false);
            app.toast(I18n.t("Scene Saved"), "success");
            showLoading(false);
        });
        app.on("sceneSaveFailed.TopMenu", () => {
            setIsLoading(false);
            showLoading(false);
        });
        app.on("sceneSaveStart.TopMenu", () => {
            setIsLoading(true);
        });
        app.on(`undo.TopMenu`, handleUndo);
        app.on(`redo.TopMenu`, handleRedo);
        app.on(`keydown.TopMenu`, handleKeyDown);

        return () => {
            app.on("stoppedPlayingGame.TopMenu", null);
            app.on("sceneSaved.TopMenu", null);
            app.on("sceneSaveFailed.TopMenu", null);
            app.on("sceneSaveStart.TopMenu", null);
            app.on(`undo.TopMenu`, null);
            app.on(`redo.TopMenu`, null);
            app.on(`keydown.TopMenu`, null);
        };
    }, []);

    useEffect(() => {
        setIsCloneable(!!app.editor?.isCloneable);
        setIsPublic(!!app.editor?.isPublic);
        setIsPublished(!!app.editor?.isPublished);
    }, [openPublishPanel]);

    const handleSaveScene = () => {
        if (!app.editor?.sceneName) {
            return toast.error("Scene name is required.");
        }
        showLoading(true);
        app.editor.scene.userData.lastSaveTime = new Date().toISOString();
        app.saveScene(true);
    };

    return (
        <BorderedWrapper height="40px">
            <Section $gap="9px" $direction="row" $width="auto" $align="center">
                {isMenuOpen && (
                    <UserMenu
                        close={handleCloseMenu}
                        userMenuButtonRef={userMenuButtonRef}
                        showAvatarCreator={showAvatarCreator}
                        showLoading={showLoading}
                    />
                )}
                {openPublishPanel && !!app.editor?.sceneID && (
                    <PublishPanel
                        handleSettingsSave={handleSettingsSave}
                        firstTimePublish={openPublishPanel === PUBLISH_FLOW.FIRST_TIME}
                        closePanel={() => setOpenPublishPanel(undefined)}
                        fullUrl={getGameUrl(app.editor.sceneID)}
                        isPublic={isPublic}
                        setIsPublic={setIsPublic}
                        isCloneable={isCloneable}
                        setIsCloneable={setIsCloneable}
                        isPublished={isPublished}
                        setIsPublished={setIsPublished}
                    />
                )}
                <button onClick={isLoading ? undefined : handleOpenMenu} className="reset-css" ref={userMenuButtonRef}>
                    <Avatar name={dbUser?.username || undefined} image={googleUser?.photoURL || undefined} size={24} />
                </button>
            </Section>
            <Section $gap="8px" $direction="row" $width="auto" $align="center">
                <StyledButton
                    isActive
                    onClick={handleSaveScene}
                    width="auto"
                    style={{height: "24px", fontSize: "11px"}}>
                    Save
                </StyledButton>
                <StyledButton
                    isGreySecondary
                    onClick={handleOpenSettings}
                    width="auto"
                    style={{height: "24px", fontSize: "11px"}}>
                    Publish
                </StyledButton>
            </Section>
        </BorderedWrapper>
    );
};
